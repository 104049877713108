import { Avatar, Button, Checkbox, Col, DatePicker, Divider, Form, Input, Layout, Row, Select, Space, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'

import Link from 'antd/es/typography/Link'
import Title from 'antd/es/typography/Title'
import { UserOutlined, LeftOutlined, RightOutlined, LeftCircleFilled } from '@ant-design/icons'
import ModalExitoCrearCuenta from '../modal/ModalExitoCrearCuenta'
import { useEffect, useState } from 'react';
import Loader from '../Loader/loader';
import { useKeycloak } from '@react-keycloak/web'
import { getTerminosCondicionesPortal } from '../../services/documentoApi'
import { crearCuentaEmpresa, existeEmpresaRegistradaByRut } from '../../services/empresaApi'
import { existeEmail, getPaises, existeRutRegistrado, getCiudades, getRegiones, getComunas } from '../../services/publicApi'
import { esRutValido, getRutSinDv, getDvRut, cleanRut } from '../../services/Helpers/RutHelper'
import locale from 'antd/es/date-picker/locale/es_ES'
import { daFormatoRut } from '../../services/Helpers/RutHelper'
import { useNavigate } from 'react-router'
import { Calendar, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, User } from 'react-iconly'
import { CalendarNuamDSDark } from "../calendarNuam/index";

import './FormCrearCuentaEmpresa.styles.scss'


const { Text } = Typography

const FormCrearCuentaEmpresa = () => {
    const { keycloak } = useKeycloak()
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [accionCorrecta, setAccionCorrecta] = useState(true)
    const [existeEmailBd, setExisteEmail] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [paises, setPaises] = useState([{ label: 'Chile', value: 'CL' }])
    const [regiones, setRegiones] = useState(null)
    const [ciudades, setCiudades] = useState(null)
    const [comunas, setComunas] = useState(null)
    const [formIsSubmiting, setFormIsSubmiting] = useState(false)
    const [nameDropIsLoading, setNameDropIsLoading] = useState('')
    const [isDownloadDocumentacion, setIsDownloadDocumentacion] = useState(false)


    const [isOpenPais, setIsOpenPais] = useState(false);
    const [isOpenReg, setIsOpenReg] = useState(false);
    const [isOpenCiu, setIsOpenCiu] = useState(false);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onChangeRegion = async (value) => {
        try {
            setNameDropIsLoading('drpComunas')
            form.setFieldValue('comunaId', null)
            const result = await getComunas(value)
            setComunas(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangePais = async (value) => {
        try {
            setNameDropIsLoading('drpRegiones')
            const glosa = paises.filter(x => x.value === value)[0].label
            form.setFieldValue('glosaPais', glosa)
            form.setFieldValue('regionId', null)
            const result = await getRegiones(value)
            setRegiones(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onDownloadTerminosYcondiciones = async () => {
        setIsDownloadDocumentacion(true)
        try {
            const docRes = await getTerminosCondicionesPortal()

            if (docRes) {
                const blob = new Blob([docRes.buffer], { type: 'application/pdf' });
                const linkRedirect = URL.createObjectURL(blob);

                window.open(linkRedirect, "_blank");
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            setIsDownloadDocumentacion(false)
        }
    }

    const onCrearCuenta = () => {
        setFormIsSubmiting(true)
        form.submit()
    }

    const onFinish = async (values) => {
        try {
            setFormIsSubmiting(true)
            values.DVnumeroIdentificacion = getDvRut(values.numeroIdentificacion)
            values.numeroIdentificacion = getRutSinDv(values.numeroIdentificacion)

            values.administrador.fechaNacimiento = values.administrador.fechaNacimiento.toDate()
            values.administrador.DVnumeroIdentificacion = getDvRut(values.administrador.numeroIdentificacion)
            values.administrador.numeroIdentificacion = getRutSinDv(values.administrador.numeroIdentificacion)

            const result = await crearCuentaEmpresa(values)

            if (result.accionFueCorrecta) {
                setAccionCorrecta(true)
            }
            else {
                setAccionCorrecta(false)
                throw new Error('error');
            }
        }
        catch (error) {
            setAccionCorrecta(false)
            console.log(error)
        }
        finally {
            setIsModalOpen(true)
            setFormIsSubmiting(false)
        }
    };

    const onValidateEmail = async (value) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
        const result = regex.test(value);

        if (result) {
            const data = await existeEmail(value)
            setExisteEmail(data)
            setFormIsSubmiting(false)

            if (data.tieneCuentaOnBoarding) {
                return Promise.reject(new Error('El correo ya se encuentra registrado'))
            }
            else {
                return Promise.resolve()
            }
        }
    }

    const onValidateRutEmpresa = async (value) => {
        const esValido = esRutValido(value)
        const existe = esValido ? await existeEmpresaRegistradaByRut(cleanRut(value)) : false
        setFormIsSubmiting(false)

        if (esValido && !existe) {
            return Promise.resolve()
        }
        else if (!esValido) {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
        else if (existe) {
            return Promise.reject(new Error('Este RUT ya tiene una cuenta creada'))
        }
    }

    const onValidateRut = async (value) => {
        const esValido = esRutValido(value)
        const existe = esValido ? await existeRutRegistrado(cleanRut(value)) : false
        setFormIsSubmiting(false)

        if (esValido && !existe) {
            return Promise.resolve()
        }
        else if (!esValido) {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
        else if (existe) {
            return Promise.reject(new Error('Este RUT ya tiene una cuenta creada'))
        }
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleDateChange = (date) => {
        console.log("Fecha seleccionada:", date); // Puedes manejar el valor seleccionado aquí
    };

    return (
        <>
            <Loader isLoadingExt={isDownloadDocumentacion}></Loader>
            <Layout className="app-layout">
                <Content className="content">
                    <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                    <Col xs={24} sm={24} md={24} lg={0} style={{ minHeight: 72, backgroundColor: "#181818", textAlign: "left" }} className='margen-declaracion'>
                            
                            <div style={{ display: "flex", alignItems: "center" }} >
                                <Button
                                onClick={() => navigate(-1)}
                                className="btn-declaracion-m"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "0 10px",
                                }}
                                >
                                <ChevronLeft style={{ color: "#FFFBFA" }} />
                                </Button>
                                <p
                                onClick={() => navigate(-1)}
                                style={{
                                    margin: "0 0 0 10px",
                                    color: "#FFFBFA",
                                    fontFamily: "Degular",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    cursor: "pointer",
                                }}
                                >
                                <strong>Volver</strong>
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ minHeight: '100vh' }} className='containerFormPersona'>
                            <div className="header-container">
                                <Row style={{ textAlign: 'left' }} gutter={{ xs: 4, sm: 0, md: 5, lg: 10 }}>

                                    {/* mobile */}
                                    {/*<Col className="gutter-row" xs={3} sm={2} md={2} lg={0}>
                                        <Avatar style={{ backgroundColor: '#0F00D8' }} className="avatar" size={50} icon={<UserOutlined />} />
                                    </Col>
                                    <Col className="gutter-row" xs={21} sm={14} md={10} lg={0}>
                                        <Title level={4} className='title' >Regístrate en portal persona</Title>
                                    </Col>
                                    <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }}>
                                        <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                            <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                        </Col>
                                        <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
                                            <p className='subtitle' style={{ marginBottom: 0 }}>Ingresa la información solicitada para crear una cuenta</p>
                                        </Col>
                                        <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                            <Avatar size={45} style={{ backgroundColor: 'white', marginTop: 5, color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                        </Col>
                                        <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
                                            <p className='subtitle' style={{ marginTop: 10 }}> Recuerda que para crear una cuenta personal necesitas tu RUT.</p>
                                        </Col>
                                    </Row>*/}
                                    <Col className="gutter-row" xs={6} sm={2} md={2} lg={0}>
                                        <Avatar className="avatar-desktop" size={76} icon={<User stroke='bold' style={{ width: 44, height: 44 }} />} />
                                    </Col>
                                    <Col className="gutter-row" xs={17} sm={14} md={10} lg={0}>
                                        <Text className='title-persona' style={{ fontWeight: 'bold', lineHeight: 1.2 }} level={4} >Regístrate en portal empresa</Text>
                                    </Col>
                                    <Row gutter={0}>
                                        {/*<Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                                <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                            </Col>*/}
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={0}>
                                            <p className='subtitle-persona' style={{ marginBottom: 0 }}>Ingresa la información solicitada para crear una cuenta</p>
                                        </Col>
                                        {/*<Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                                <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779', marginTop: 5 }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                            </Col>*/}
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={0}>
                                            <p className='subtitle-persona' style={{ marginTop: 10 }}> Recuerda que para crear una cuenta personal necesitas tu RUT.</p>
                                        </Col>
                                    </Row>
                                    {/* desktop */}
                                    <Col className="gutter-row" xs={0} sm={0} md={0} lg={3} xl={3} xxl={2}>
                                        <Avatar className="avatar-desktop" size={76} icon={<User stroke='bold' style={{ width: 44, height: 44 }} />} />
                                    </Col>
                                    <Col className="titulo" xs={0} sm={0} md={0} lg={21} xl={20} xxl={21} >
                                        <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} className='margen-title'>
                                                <Text className='title-persona'>Regístrate en portal empresa</Text>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <Text className='subtitle-persona' style={{ marginTop: '5px' }}>
                                                    Ingresa la información solicitada para crear una cuenta
                                                </Text>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <p className='subtitle-persona' style={{ marginTop: 0 }}>
                                                    Recuerda que para crear una cuenta personal necesitas tu RUT.
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Divider className='divider-empresa' />
                                </Row>
                            </div>

                            <Form form={form} onFinish={onFinish} style={{ width: '100%' }} className="form formEmpresa" layout="vertical">
                                <Row className='padd-mobile padd-form-1'>
                                    <Col span={24}>
                                        <Row style={{ textAlign: 'left' }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <Text className='text-mobile subtitle-pass-empresa'>
                                                    Datos empresa
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Form.Item style={{ display: 'none' }} name='glosaPais'><Input /></Form.Item>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item
                                                    name='nombreEmpresa'
                                                    label='Nombre de empresa'
                                                    className="username"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresa el nombre de la empresa",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder='Ingresar información' />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                                <Form.Item
                                                    name='numeroIdentificacion'
                                                    className="inptRut"
                                                    label='Número de RUT empresa'
                                                    normalize={(value) => daFormatoRut(value)}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresa el RUT de la empresa",
                                                        },
                                                        {
                                                            validator: (_, value) => value ? onValidateRutEmpresa(value) : Promise.resolve()
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder='70.454.345-1' />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item name='paisId' label='País' className="username" rules={[
                                                    {
                                                        required: true,
                                                        message: "Ingresa un país",
                                                    },
                                                ]}>
                                                    <Select
                                                        showSearch={false}
                                                        onChange={onChangePais}
                                                        filterOption={filterOption}
                                                        placeholder="Selecciona el país"
                                                        options={paises}
                                                        loading={paises === null}
                                                        popupClassName="dropdown-drpPais"
                                                        suffixIcon={isOpenPais ? <ChevronUp /> : <ChevronDown />}
                                                        onDropdownVisibleChange={(open) => setIsOpenPais(open)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item name='regionId' label='Región' rules={[
                                                    {
                                                        required: true,
                                                        message: "Ingresa una región",
                                                    },
                                                ]}>
                                                    <Select
                                                        onChange={onChangeRegion}
                                                        notFoundContent={nameDropIsLoading === 'drpRegiones' ? 'Cargando...' : 'Debes seleccionar primero un País.'}
                                                        loading={nameDropIsLoading === 'drpRegiones'}
                                                        showSearch={false}
                                                        placeholder="Selecciona la región"
                                                        filterOption={filterOption}
                                                        options={regiones}
                                                        popupClassName="dropdown-drpRegiones"
                                                        suffixIcon={isOpenReg ? <ChevronUp /> : <ChevronDown />}
                                                        onDropdownVisibleChange={(open) => setIsOpenReg(open)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                                <Form.Item name='comunaId' label='Comuna'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresa una ciudad",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        notFoundContent={nameDropIsLoading === 'drpComunas' ? 'Cargando...' : 'Debes seleccionar primero una Región.'}
                                                        loading={nameDropIsLoading === 'drpComunas'}
                                                        showSearch={false}
                                                        placeholder="Selecciona la comuna"
                                                        filterOption={filterOption}
                                                        options={comunas}
                                                        popupClassName="dropdown-drpCiudad"
                                                        suffixIcon={isOpenCiu ? <ChevronUp /> : <ChevronDown />}
                                                        onDropdownVisibleChange={(open) => setIsOpenCiu(open)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Text className='text-mobile subtitle-pass-empresa'>
                                            Datos usuario administrador
                                        </Text>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name={['administrador', 'nombre']}
                                            label='Nombres'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tus nombres",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Ingresa nombres' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name={['administrador', 'apellidos']}
                                            label='Apellidos'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tus apellidos",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Ingresa apellidos' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name={['administrador', 'numeroIdentificacion']}
                                            label='Número de Identificación (RUT)'
                                            normalize={(value) => daFormatoRut(value)}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: " Ingresa tu RUT",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                                                }
                                            ]}
                                        >
                                            <Input placeholder='11.111.111-1' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name={['administrador', 'fechaNacimiento']}
                                            label='Fecha de nacimiento' className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa tu fecha de nacimiento",
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                prevIcon={<LeftOutlined style={{ color: '#fffbfa', fontSize: 24 }}></LeftOutlined>}
                                                nextIcon={<RightOutlined style={{ color: '#fffbfa', fontSize: 24 }}></RightOutlined>}
                                                size='large'
                                                popupClassName='custom-calendar'
                                                locale={locale}
                                                format={"DD/MM/YYYY"}
                                                placeholder='Selecciona la fecha'
                                                style={{ width: '100%' }}
                                                suffixIcon={<Calendar primaryColor='#666666' />}
                                            />
                                            {/*<input />
                                           <CalendarNuamDSDark
                                            onChange={handleDateChange} // Evento para manejar el cambio de fecha
                                            locale="es-ES" // Configuración del idioma
                                            showYearControls={true} // Opcional: muestra los controles de año
                                            events={[]} // Opcional: pasa eventos si son necesarios
                                           />*/}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name={['administrador', 'telefono']} label='Teléfono fijo/ Celular' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresar número válido",
                                            },
                                        ]}>
                                            <Input placeholder='Ingresa información' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name={['administrador', 'email']} label='Correo electrónico' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa tu correo electrónico",
                                            },
                                            {
                                                type: "email",
                                                message: "Ingresa un email válido",
                                            },
                                            {
                                                validator: (_, value) => value ? onValidateEmail(value) : Promise.resolve()
                                            }
                                        ]}>
                                            <Input autoComplete="off" placeholder='tumail@empresa.com' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col style={{ textAlign: 'left', marginTop: 20 }} className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item
                                            normalize={(value) => value ? value : undefined}
                                            name='terminos' valuePropName="checked" rules={[
                                                {
                                                    required: false,
                                                    message: "Debes aceptar los Términos y Condiciones y la Política de Privacidad para poder continuar.",
                                                },
                                            ]}>
                                            {/*<Checkbox style={{ fontWeight: '400' }}>He leído y acepto los <Link href="#" style={{ fontWeight: 'bold' }} onClick={() => onDownloadTerminosYcondiciones()}> Términos y Condiciones y la Política de Privacidad</Link> </Checkbox>*/}
                                            <Space size={8} align="start">
                                                <Checkbox  className='custom-hover-checkbox' style={{
                                                    position: 'relative',
                                                }} onChange={handleCheckboxChange}></Checkbox>
                                                <Text className='termi'>He leído y acepto los <Link href="#" style={{ fontWeight: 'bold' }} className='letraVerde' onClick={() => onDownloadTerminosYcondiciones()}> Términos y Condiciones y la Política de Privacidad</Link></Text>
                                            </Space>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]} justify="center">
                                    {/*<Col xs={0} sm={0} md={12} lg={12}>
                                        <br />
                                        <Button
                                            htmlType="button"
                                            style={{ width: "80%" }}
                                            shape="round"
                                            className="btnVolverEmpresa"
                                            onClick={() => navigate(-1)}
                                        >
                                            Volver
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <br />
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            style={{ width: "80%", backgroundColor: '#0f00d8', color: 'white' }}
                                            shape="round"
                                            onClick={() => onCrearCuenta()}
                                            loading={formIsSubmiting}
                                        >
                                            Crear cuenta
                                        </Button>
                                        </Col>*/}
                                    <Col xs={24} sm={12} md={4} lg={5}>
                                        <Button
                                            className="btn-form-persona-volver btn-mobile"
                                            htmlType="button"
                                            onClick={() => navigate(-1)}

                                        >
                                            Volver
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={4} lg={5}>
                                        <Button
                                            htmlType="button"
                                            onClick={() => onCrearCuenta()}
                                            disabled={!isCheckboxChecked}
                                            className={`${
                                                isCheckboxChecked
                                                    ? "btn-mobile btn-form-persona"
                                                    : "btn-mobile btn-form-persona-disabled"
                                            }`}
                                        >
                                            Crear cuenta
                                        </Button>
                                    </Col>
                                </Row>
                                <p className="" style={{ textAlign: 'center', fontFamily: 'Degular', fontSize: 16, color: '#3D3D3D' }}>
                                    ¿Ya tienes una cuenta?{" "}
                                    <Link to="#" onClick={() => keycloak.login({ redirectUri: `${process.env.REACT_APP_URL}/puente` })} className="letraVerdeInicio">
                                        Inicia Sesión
                                    </Link>
                                </p>
                            </Form>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={0}>
                            <div style={{ width: '45%', position: 'fixed' }}>
                                <div className="right-section">
                                    <div className="background-image">
                                        <div className="return-to-home">
                                            <div className="return-icon">
                                                <LeftCircleFilled href={process.env.REACT_APP_URL_ONBOARDING_PUBLICO} style={{ fontSize: 24, color: '#ff4201' }} />
                                            </div>
                                            <div className="return-text">
                                                <p onClick={() => window.location.replace(process.env.REACT_APP_URL_ONBOARDING_PUBLICO)} >Volver al Inicio</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
            <ModalExitoCrearCuenta redirectToLogin={true} fueCorrecto={accionCorrecta} isModalOpen={isModalOpen} handleOk={handleOk} tipoCuenta={"Empresa"} poseeCuentaKeyCloack={existeEmailBd?.tieneCuentakeyCloack} />
        </>
    )
}

export default FormCrearCuentaEmpresa