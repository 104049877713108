import { Button, Col, Form, Input, Row, Divider, Select, DatePicker } from 'antd'
import { UsergroupAddOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { CapitalizeFirstLetter } from '../../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../../services/Helpers/RutHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, setRefrescarNotificaciones } from '../../../redux/reducers/AppReducer';
import { getPaises, getNacionalidades, getCargos, getAreaCargos } from '../../../services/publicApi'
import { esRutValido } from '../../../services/Helpers/RutHelper';
import locale from 'antd/es/date-picker/locale/es_ES'
import { eliminarRepresentanteLegal, crearRepresentantesLegales } from '../../../services/empresaApi';
import { getRutSinDv, getDvRut } from '../../../services/Helpers/RutHelper';
const dayjs = require('dayjs')

const EditarRepresentantesLegales = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const usuario = useSelector(state => state.aplicacion.infoEmpresa)
    const [nacionalidades, setNacionalidades] = useState(null)
    const [desplegarBtnGuardar, setDesplegarBtnGuardar] = useState(false)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)
    const [paises, setPaises] = useState(null)
    const [form] = Form.useForm()

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    useEffect(() => {
        const fetchData = async () => {
            if (usuario) {
                Promise.all([getPaises(),
                getNacionalidades(),
                getCargos(),
                getAreaCargos(),
                ]).then((values) => {
                    setPaises(values[0])
                    setNacionalidades(values[1])
                    setCargos(values[2])
                    setAreaCargos(values[3])
                }).finally(() =>
                    console.log()
                );
            }
            else {
                navigate('/perfilEmpresa')
            }
        }
        fetchData()

    }, [usuario])

    const onValidateRut = async (value) => {
        const esValido = esRutValido(value)

        if (esValido) {
            return Promise.resolve()
        }
        else {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
    }

    const onEliminarRepresentanteLegal = async (id) => {
        try {
            dispatch(setIsLoading(true))
            await eliminarRepresentanteLegal(id)
            dispatch(setRefrescarNotificaciones(true))
            navigate('/perfilEmpresa')
        }
        catch {

        }
        finally {
            dispatch(setIsLoading(false))
        }
    }

    const onFinish = async (values) => {
        try {
            dispatch(setIsLoading(true))

            values.representantesLegales?.map((item) => {
                item.rutDV = getDvRut(item.rut)
                item.rut = getRutSinDv(item.rut)
                item.fechaNacimiento = item.fechaNacimientoRep ? item.fechaNacimientoRep.toDate() : null
                item.glosaPaisResidencia = paises.filter(x => x.value === item.paisResidenciaId)[0].label
                item.glosaAreaCargo = areaCargos.filter(x => x.value === item.areaCargoId)[0]?.label
            })

            await crearRepresentantesLegales(values.representantesLegales)
            dispatch(setRefrescarNotificaciones(true))
        }
        catch (error) {
            console.log(error)
        }
        finally {
            dispatch(setIsLoading(false))
            navigate('/perfilEmpresa')
        }
    }

    const onAgregarRepresentante = (add) => {
        add()
        setDesplegarBtnGuardar(true)
    }

    return (
        <>
            {usuario ? <>
                <Form onFinish={onFinish} form={form} className='formMisDatosLectura formLabelStrongs' layout='vertical'>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                            <Title level={3} style={{ fontWeight: 'bold', textAlign: 'left' }}>Datos Representante(s) legal(es)</Title>
                        </Col>
                    </Row>
                    {usuario.representantesLegales.map((item, index) => {
                        return <div key={index}><Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {index > 0 ? <Divider></Divider> : null}

                            <Col span={24} style={{ textAlign: 'left' }}>
                                <Title level={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                    Datos Representante legal # {index + 1}
                                </Title>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Nombres Representante'>
                                    <Input placeholder={CapitalizeFirstLetter(item.nombreRepresentante)} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Apellidos Representante'>
                                    <Input placeholder={CapitalizeFirstLetter(item.apellidosRepresentante)} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Número de identificación (RUT)'>
                                    <Input placeholder={daFormatoRut(`${item.rut}${item.rutDV}`)} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Nacionalidad'>
                                    <Input placeholder={item.nacionalidad} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Páis de Residencia'>
                                    <Input placeholder={item.paisResidencia} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Fecha de nacimiento'>
                                    <Input placeholder={item.fechaNacimiento ? dayjs(item.fechaNacimiento).format('DD/MM/YYYY') : null} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Cargo'>
                                    <Input placeholder={item.cargo} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Área del cargo'>
                                    <Input placeholder={item.areaCargo} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Correo electrónico'>
                                    <Input placeholder={item.email} bordered={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={11} sm={11} md={8} lg={5}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            shape="round"
                                            disabled={false}
                                            style={{ width: '100%' }}
                                            onClick={() => onEliminarRepresentanteLegal(item.idRepresentante)}
                                        >
                                            Eliminar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    })}

                    {/* --------------------------------------aca------------------------------------------------------ */}
                    <Form.List name="representantesLegales">
                        {(fields, { add }) => (
                            <div                    >
                                {fields.map((field, index) => (
                                    <div key={index}>
                                        {index > 0 ? <Divider></Divider> : null}
                                        <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <Title level={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                    Datos Representante legal {index > 0 ? `#${index + 1}` : ''}
                                                </Title>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item
                                                    name={[field.name, 'nombreRepresentante']}
                                                    label='Nombre representante'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresa nombre",
                                                        },
                                                    ]}
                                                >
                                                    <Input style={{ padding: 4 }} placeholder='nombres...' />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item
                                                    name={[field.name, 'apellidosRepresentante']}
                                                    label='Apellidos representante'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresa apellidos",
                                                        },
                                                    ]}
                                                >
                                                    <Input style={{ padding: 4 }} placeholder='apellidos...' />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item
                                                    name={[field.name, 'rut']}
                                                    label='Número de indentificación'
                                                    normalize={(value) => daFormatoRut(value)}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresa número de identificación",
                                                        },
                                                        {
                                                            validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                                                        }
                                                    ]}
                                                >
                                                    <Input style={{ padding: 4 }} placeholder='11.111.111-1' />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item
                                                    name={[field.name, 'nacionalidadId']}
                                                    label="Nacionalidad"
                                                    className="username"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresa una nacionalidad",
                                                        },
                                                    ]}>
                                                    <Select
                                                        showSearch
                                                        filterOption={filterOption}
                                                        placeholder="Selecciona una nacionalidad"
                                                        options={nacionalidades}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item
                                                    name={[field.name, 'paisResidenciaId']}
                                                    label="País de residencia"
                                                    className="username"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Ingresa país de residencia",
                                                        },
                                                    ]}>
                                                    <Select
                                                        showSearch
                                                        filterOption={filterOption}
                                                        placeholder="Selecciona país de residencia"
                                                        options={paises}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item name={[field.name, 'fechaNacimientoRep']} label='Fecha de Nacimiento' className="username">
                                                    <DatePicker locale={locale} format={"DD/MM/YYYY"} placeholder='Ingresa fecha de nacimiento' style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item name={[field.name, 'cargoId']} label="Cargo" className="username">
                                                    <Select
                                                        showSearch
                                                        filterOption={filterOption}
                                                        placeholder="Selecciona cargo"
                                                        options={cargos}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item name={[field.name, 'areaCargoId']} label="Área del cargo" className="username">
                                                    <Select
                                                        showSearch
                                                        filterOption={filterOption}
                                                        placeholder="Selecciona área de cargo"
                                                        options={areaCargos}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                                                <Form.Item name={[field.name, 'email']} label='Correo Electrónico' className="username" rules={[
                                                    {
                                                        required: true,
                                                        message: "Ingresa un correo electrónico",
                                                    },
                                                    {
                                                        type: "email",
                                                        message: "Ingresa un email válido",
                                                    }
                                                ]}>
                                                    <Input style={{ padding: 4 }} placeholder='correo@correo.cl' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Divider></Divider>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={11} sm={11} md={8} lg={5}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="button"
                                                shape="round"
                                                disabled={false}
                                                style={{ width: '100%' }}
                                                onClick={() => navigate('/perfilEmpresa')}
                                            >
                                                Cancelar edición
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    {desplegarBtnGuardar ?
                                        <Col xs={11} sm={11} md={8} lg={5}>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    shape="round"
                                                    disabled={false}
                                                    style={{ width: '100%' }}
                                                >
                                                    Guardar
                                                </Button>
                                            </Form.Item>
                                        </Col> : null}
                                    <Col xs={13} sm={13} md={8} lg={5}>
                                        <Form.Item>
                                            <Button
                                                icon={<UsergroupAddOutlined />}
                                                type="primary"
                                                htmlType="button"
                                                onClick={() => onAgregarRepresentante(add)}
                                                style={{ paddingRight: 15, paddingLeft: 15 }}
                                                shape="round"
                                            >
                                                Agregar representante legal
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Form.List>


                </Form>
            </> : null}



        </>
    )
}

export default EditarRepresentantesLegales