import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom';
import { Table, Button, Row, Col, Card, Avatar, Space } from 'antd';
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux';
import { getColorEstado } from '../../services/Helpers/stringHelper';
import { useLocation } from 'react-router-dom';
import ListaUsuariosGrupo from '../Empresa/Usuarios/listaUsuariosGrupo';
const dayjs = require('dayjs')
const typeDefs = require('../../interface/typeDefs')

/**
 * 
 * @param {{productos:Array.<typeDefs.ProductoContratadoDto>}} 
 * @returns {React.ElementType} 
 */
const TableMisProductos = ({ productos }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLoading = useSelector(store => store.aplicacion.isLoading)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)
  const [urlRedirect, setUrlRedirect] = useState('detalle-producto')

  useEffect(() => {
    if (location.pathname?.toLowerCase() === '/productosempresa') {
      setUrlRedirect('detalle-empresa-producto')
    }
  }, [location])

  const columns = [
    {
      title: 'Nombre producto',
      width: 200,
      dataIndex: 'NombreProducto',
      key: 'NombreProducto',
      sorter: (a, b) => a.NombreProducto.localeCompare(b.NombreProducto, 'es', { ignorePunctuation: true }),
    },
    {
      title: 'Estado',
      width: 130,
      dataIndex: 'NombreEstadoSuscripcion',
      key: 'NombreEstadoSuscripcion',
      sorter: (a, b) => a.NombreEstadoSuscripcion.localeCompare(b.NombreEstadoSuscripcion, 'es', { ignorePunctuation: true }),
      render: (value, row) => <><Space size={2} wrap><Avatar size={8}
        style={{
          marginBottom: 2,
          backgroundColor: `${getColorEstado(row.IdEstadoSuscripcion)}`
        }}>
      </Avatar> {value}</Space>
      </>
    },
    {
      title: 'Plan suscrito',
      width: 120,
      dataIndex: 'NombreTipoPlan',
      sorter: (a, b) => a.NombreTipoPlan.localeCompare(b.NombreTipoPlan, 'es', { ignorePunctuation: true }),
      key: 'NombreTipoPlan',
      render: (value) => <>{value === 'Básico' ? 'Básico' : value}</>
    },
    {
      title: 'Usuarios asociados',
      dataIndex: 'UsuariosAsignados',
      width: 100,
      sorter: false,
      key: 'UsuariosAsignados',
      render: (value) => <ListaUsuariosGrupo usuarios={value}></ListaUsuariosGrupo>
    },
    {
      title: 'Activo desde',
      dataIndex: 'FechaInicio',
      key: 'FechaInicio',
      width: 100,
      sorter: (a, b) => a.FechaInicio.localeCompare(b.FechaInicio),
      render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>,
    },
    {
      title: 'Vigente hasta',
      dataIndex: 'ValidaHasta',
      key: 'ValidaHasta',
      width: 100,
      sorter: (a, b) => a.ValidaHasta.localeCompare(b.ValidaHasta),
      render: (value, record) => <>{record.NombreTipoPlan !== 'Básico' && value ? dayjs(value).format('DD/MM/YYYY') : 'Gratis'}</>,
    },
    {
      title: 'Medio de pago',
      dataIndex: 'MedioPago',
      key: 'MedioPago',
      sorter: (a, b) => a.MedioPago.localeCompare(b.MedioPago, 'es', { ignorePunctuation: true }),
      width: 100
    },
    {
      title: '',
      key: 'IdProductoContratado',
      // fixed: 'right',
      width: 70,
      /**
       * @param {typeDefs.ProductoContratadoDto} value 
       * @param {*} record 
       * @returns {React.ElementType}
       */
      render: (value, record) => <>
        <Button style={{ marginRight: '10px' }} shape='round' type='primary' onClick={() => navigate(`/${urlRedirect}/${value.IdProductoContratado}/${value.IdSuscripcion}`)}>Detalle suscripción</Button>

        {/* <Button shape='round' type='primary' onClick={() => window.open(`${record.urlAccesoProducto}`, '_blank', 'noopener,noreferrer')}>Ver</Button> */}
      </>,
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    if (pagination.pageSize !== registrosPorPagina) {
      setRegistrosPorPagina(pagination.pageSize)
    }
  };

  //desactiva busqueda en paginador, ya que ant. no lo permite
  useEffect(() => {
    const element = document.querySelectorAll('input[type=search]')[0]

    if (element) {
      element.setAttribute('readonly', true)
    }
  }, [document.querySelectorAll('input[type=search]')])


  const defineMsjSinProductos = () => {
    if (location.pathname === '/productosEmpresa') {
      return 'Todavía no se han contratado productos.'
    }
    else {
      return 'Todavía no has contratado productos.'
    }
  }

  return (
    <>
      {isTabletOrMobile ?
        <>
          {productos?.map((item, index) => (
            <Card key={`prd-${index}`} className="cardTable" onClick={() => navigate(`/${urlRedirect}/${item.IdProductoContratado}/${item.IdSuscripcion}`)}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col span={12}>
                  <p className="titulo"><strong>Nombre</strong></p>
                </Col>
                <Col span={12}>
                  <p className="titulo"><strong>Activo desde</strong></p>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col span={12}>
                  <p className="subTitulo">{item.NombreProducto}</p>
                </Col>
                <Col span={12}>
                  <p className="subTitulo">{dayjs(item.fechaInicioVigencia).format('DD/MM/YYYY')}</p>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col span={12}>
                  <p className="titulo"><strong>Estado</strong></p>
                </Col>
                <Col span={12} >
                  <p className="titulo"><strong>
                    Vigente hasta
                  </strong></p>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col span={12}>
                  <p className="subTitulo"><Avatar size={8}
                    style={{
                      marginBottom: 2,
                      marginRight: 2,
                      backgroundColor: `${getColorEstado(item.IdEstadoSuscripcion)}`
                    }}>
                  </Avatar>{item.NombreEstadoSuscripcion}</p>
                </Col>
                <Col span={12}>
                  <p className="subTitulo">{dayjs(item.fechaTerminoVigencia).format('DD/MM/YYYY')}</p>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col span={12}>
                  <p className="titulo"><strong>Plan suscrito</strong></p>
                </Col>
                <Col span={12}>
                  <p className="titulo"><strong>Medio de pago</strong></p>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col span={12}>
                  <p className="subTitulo">{item.NombreTipoPlan}</p>
                </Col>
                <Col span={12}>
                  <p className="subTitulo">{item.MedioPago}</p>
                </Col>
              </Row>
              {location.pathname?.toLowerCase() === '/productosempresa' && (
                <>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col span={24}>
                      <p className="titulo"><strong>Usuarios asociados</strong></p>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col span={24} style={{ textAlign: 'left' }}>
                      <ListaUsuariosGrupo claseTexto="subTitulo" usuarios={item.UsuariosAsignados}></ListaUsuariosGrupo>
                    </Col>
                  </Row>
                </>)}
            </Card>
          ))}
        </>
        :
        <Table
          style={{ marginTop: '10px' }}
          locale={{ emptyText: `${isLoading ? ' cargando...' : defineMsjSinProductos()}` }}
          dataSource={productos}
          rowKey='IdSuscripcion'
          onChange={handleChange}
          columns={location.pathname?.toLowerCase() === '/productosempresa' ? columns : columns.filter(x => x.dataIndex !== 'UsuariosAsignados')}
          pagination={{
            locale: {
              items_per_page: " / por página",
              prev_page: 'Página anterior',
              next_page: 'Siguiente página',
              prev_5: '5 páginas anteriores',
              next_5: 'Siguientes 5 páginas',
              prev_3: '3 páginas anteriores',
              next_3: 'Siguientes 3 páginas'
            },
            showSearch: false,
            pageSize: registrosPorPagina,
            pageSizeOptions: [5, 10, 15, 20],
            showSizeChanger: true,
          }}
        >
        </Table>}
    </>)
};

TableMisProductos.propTypes = {
  productos: PropTypes.arrayOf(
    PropTypes.shape({
      CodigoProducto: PropTypes.string,
      NombreProducto: PropTypes.string,
      DescripcionProducto: PropTypes.string,
      IdProductoContratado: PropTypes.number,
      IdSuscripcion: PropTypes.number,
      NombreTipoPlan: PropTypes.string,
      IdTipoPlan: PropTypes.number,
      IdTipoProducto: PropTypes.number,
      NombreTipoProducto: PropTypes.string,
      FechaInicio: PropTypes.string,
      ValidaHasta: PropTypes.string
      // FechaInicio: PropTypes.instanceOf(Date),
      // ValidaHasta: PropTypes.instanceOf(Date)
    })
  )
}

export default TableMisProductos