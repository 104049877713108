import { EstadoSuscripcionId } from "../../enum/enums";
import { Link } from "react-router-dom";

export const GetRequestDisponibles = (requesAlMes, requestRealizados) => {
    let restantes = requesAlMes - requestRealizados

    if (restantes > 0) {
        return restantes
    }
    else {
        return 0
    }
}

export const truncateString = (str, length) => {
    if (str && str?.length > length) {
        return str.substring(0, length) + '...'
    }
    else {
        return str
    }

}

export const CapitalizeFirstLetter = (str) => {
    if (str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        return splitStr.join(' ');
    }

    return str
}


export const itemRenderBreadcump = (currentRoute, idUsuario = undefined) => {
    if (currentRoute?.title) {
        const partes = currentRoute.title.split('/')
        if (idUsuario) {
            const lastPart = partes.pop()

            return <p>
                {partes.map((titulo, index) => {
                    let id = titulo?.trim() === 'Detalle de usuario' ? idUsuario : undefined
                    return <Link className={`${index === 0 ? '' : 'marginlink'}`} to={`${getUrlByTitle(titulo, id)}`} key={`tit-${index}`}>{`${titulo}/`} </Link>
                })}
                <span className='actual'>{lastPart}</span>
            </p>
        }
        else if (partes.length > 1) {
            const lastPart = partes.pop()

            return <p>
                {partes.map((titulo, index) => {
                    const tieneID = titulo.includes('-')
                    let id = null

                    if (tieneID) {
                        const ids = titulo.split('-')
                        id = ids[1]

                        titulo = titulo.replace(`-${id}-`, '')
                    }


                    return <Link className={`${index === 0 ? '' : 'marginlink'}`} to={`${getUrlByTitle(titulo, id)}`} key={`tit-${index}`}>{`${titulo}/`} </Link>
                })}
                <span className='actual'>{lastPart}</span>
            </p>
        }
        else {
            return <p className='actual'>{currentRoute.title}</p>
        }
    }
    else {
        return <span></span>
    }
}

export const SeparadorMiles = (value) => {
    if (value) {
        const partes = value.toString().split('.')
        const parteNumerica = partes[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        if (partes.length > 1) {
            return `${parteNumerica},${partes[1]}`
        }
        else {
            return parteNumerica
        }
    }
    else
        return '0'
}

export const getInicialPorPalabra = (str, largo) => {
    if (str) {
        const partes = str.split(' ')
        let iniciales = ''

        for (let index = 0; index < largo; index++) {
            iniciales = iniciales + partes[index].substring(0, 1)
        }
        return iniciales
    }
    return str
}

export const getInicial = (str) => {
    if (str) {
        return str.substring(0, 1)
    }

    return str
}

export const convertirNumeroaArray = (value, tipo) => {
    if (tipo === 'number') {
        return [value]
    }
    else if (tipo === 'object') {
        return value
    }
    else return null
}

export const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };

    });
};

export function clearNumeroTelefono(value) {
    if (value) {
        return value.replace(/[^0-9\\+]+/g, "");
    }
    else {
        return value
    }
}

export const getColorEstado = (idEstado) => {
    switch (idEstado) {
        case EstadoSuscripcionId.CADUCADA:
        case EstadoSuscripcionId.DADO_DE_BAJA:
            return '#FF1414'
        case EstadoSuscripcionId.VIGENTE:
            return '#52C41A'
        case EstadoSuscripcionId.INACTIVA:
            return '#F1D779'
        default:
            return '#52C41A';
    }
}

const getUrlByTitle = (title, id) => {
    //si viene id es porque esta en contratar por lo que su url de retorno deberia ser los planes
    if (id && title?.trim() === 'Detalle de usuario') {
        return `detalle-usuario/${id}`
    }
    else if (id) {
        return `detalle-producto-servicio/${id}`
    }
    else {
        switch (title.toLowerCase().trim()) {
            case 'inicio':
                return '/home'
            case 'solicitudes':
                return '/solicitudes'
            case 'productos de la empresa':
                return '/productosEmpresa'
            case 'catálogo de productos':
                return '/producto-servicio'
            case 'mis productos':
                return '/productos'
            case 'mi perfil':
                return '/perfil'
            case 'perfil empresa':
                return '/perfilEmpresa'
            case 'usuarios y permisos':
                return '/Usuarios'
            default:
                return '/home'
        }
    }
}


export const timeToMilliseconds = (hrs, min, sec) => (hrs * 60 * 60 + min * 60 + sec) * 1000;