import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { SeparadorMiles } from '../../services/Helpers/stringHelper';
import { Button, Row, Col, Card, Avatar, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Content } from 'antd/es/layout/layout'
import '../khipu/./Khipu.styles.scss'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux';
import { getResultadoWebPay } from '../../services/medioPagoApi'
import { setIsLoading } from '../../redux/reducers/AppReducer';
const { Text, Title } = Typography

const ResultadoPago = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const [detalleTransaccion, setDetalleTransaccion] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (id != 'null') {
                try {
                    dispatch(setIsLoading(true))
                    const data = await getResultadoWebPay(id)
                    setDetalleTransaccion(data)
                }
                catch (error) {
                    console.log(error)
                }
                finally {
                    dispatch(setIsLoading(false))
                }
            }
        }
        fetchData()
    }, [id])

    return (
        <Content>
            <Row justify="center" >
                <Col md={12} xs={24} >
                    <br />
                    <Card className="card-container">
                        {id === 'null' || !id ?
                            <>
                                <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<CloseCircleOutlined />} />
                                <>
                                    <Title level={3}>
                                        Ha ocurrido un error
                                    </Title>
                                    <Text>
                                        <p>Por favor, vuelve a intentar pagar tu suscripción, si aún así sigues teniendo problemas contáctate con soporte al correo soporte.marketplace @nuamx.com</p>
                                    </Text >
                                </>
                            </> :
                            <div>
                                {detalleTransaccion && (
                                    <>
                                        <Avatar style={{ backgroundColor: '#2EEBFF' }} size={80} icon={<CheckCircleOutlined />} />

                                        {detalleTransaccion.codigoRespuesta === 0 ?
                                            <Title level={3}>Pago procesado correctamente  </Title> :
                                            <Title level={3}>Pago no procesado  </Title>}
                                        <Text>
                                            <p><strong> Monto</strong>: {SeparadorMiles(detalleTransaccion.monto)}</p>
                                            <p><strong>Estado del pago</strong>: {detalleTransaccion.codigoRespuesta === 0 ? <span>Transacción aprobada.</span> : <span>Transacción rechazada.</span>}</p>
                                            {detalleTransaccion.numeroTarjeta ? <p><strong>Número de tarjeta</strong>: {detalleTransaccion.numeroTarjeta}</p> : null}
                                            <p><strong>Tipo de documento</strong>: {detalleTransaccion.tipoDocumento}</p>
                                        </Text >
                                        {detalleTransaccion.codigoRespuesta === 0 ?
                                            <Button onClick={() => navigate(`/detalle-producto/${detalleTransaccion.idProducto}/${detalleTransaccion.idSuscripcion}`)} shape="round" type="primary">Ir al producto</Button> : null}
                                    </>)}
                            </div>}
                    </Card>
                </Col>
            </Row>

        </Content>)
};

export default ResultadoPago