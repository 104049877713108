import { CheckCircleOutlined, CreditCardOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal, Row, Col } from 'antd'
import './Modal.styles.scss'

const ModalAvisoPagoMensual = ({ isModalOpen, onClose, fueCorrecto }) => {
    return (
        <div>
            <Modal
                closable={false}
                centered
                open={isModalOpen}
                onOk={onClose}
                width={450}
                footer={[
                    <Button key="submit" type="primary" shape="round" onClick={onClose}>
                        {fueCorrecto ? 'Ir al producto' : 'Aceptar'}
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    {fueCorrecto ?
                        <Avatar className="colorFondo" size={80} icon={<CheckCircleOutlined />} /> :
                        <Avatar style={{ backgroundColor: '#ff4201' }} size={80} icon={<CreditCardOutlined />} />
                    }

                    <Row>
                        <Col span={24}>
                            <p className='msjTitulo'>
                                {fueCorrecto ? 'Pago exitoso' : 'Ocurrió un problema con el pago'}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            {fueCorrecto ?
                                <>
                                    <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>Te enviamos una notificación por correo con el detalle de tu suscripción.</p>
                                    <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>La fecha de vencimiento del producto se ha actualizado, para más información hack click en el siguiente botón:</p>
                                </> :
                                <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>
                                    Por favor, vuelve a intentar pagar tu suscripción, si aún así sigues teniendo problemas contáctate con soporte al correo <span style={{ color: '#ff4201' }}> sacbursatil@bolsadesantiago.com</span>
                                </p>
                            }
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default ModalAvisoPagoMensual