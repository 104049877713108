import React, { useState } from 'react'
import {  Upload, Row, Col, Button, Form, Input, Select, message, Typography } from 'antd'
import Title from 'antd/es/typography/Title'
import { useKeycloak } from '@react-keycloak/web'
import { DeleteOutlined, FileSyncOutlined, UploadOutlined } from '@ant-design/icons'
import { ChevronDown, ChevronUp, Document, Delete } from 'react-iconly'

const extensionesPermitidas = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']

const { Text } = Typography;

const Paso1 = ({ setPasoActual, form, pasoActual, rubrosComerciales, cargos, areaCargos }) => {
    const { keycloak } = useKeycloak()
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const [isOpenRubro, setIsOpenRubro] = useState(false);
    const [isOpenCargo, setIsOpenCargo] = useState(false);
    const [isOpenArea, setIsOpenArea] = useState(false);

    const [fileUploaded, setFileUploaded] = useState(null);

    const beforeUpload = (file) => {
        const isPDF = file.type === 'application/pdf';
        if (!isPDF) {
        alert('Solo se permiten archivos en formato PDF.');
        }
        return isPDF || Upload.LIST_IGNORE;
    };

    const handleRemoveFile = () => {
        setFileUploaded(null); 
    };

    const onNextPage = async () => {
        form.validateFields(['direccion', 'rubroComercialId', 'documentoTributario'])
            .then((values) => {
                setPasoActual(1)
            }).catch((errorInfo) => { })
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0].label
        form.setFieldValue(['informacionExtraAdministrador', 'glosaAreaCargo'], glosa)
    }

    /*const beforeUpload = (file) => {
        const esValido = extensionesPermitidas.some(x => x === file.type)

        if (esValido) {
            return true
        }
        else {
            message.error('Sólo se permiten archivos PDF e imágenes');
            return Upload.LIST_IGNORE;
        }
    }*/

    return (
        <div className='paso1' style={{ display: `${pasoActual === 0 ? '' : 'none'}` }}>
            <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                    <Text className='text-mobile subtitle-pass-empresa' style={{ fontWeight: 700, marginTop: '15px', marginBottom: '15px' }}>
                        Datos empresa
                    </Text>
                </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name='rubroComercialId' label="Rubro comercial" className="username"
                        rules={[
                            {
                                required: true,
                                message: "Ingresa un rubro comercial",
                            },
                        ]}>
                        <Select
                           
                            showSearch
                            filterOption={filterOption}
                            placeholder="Selecciona un rubro"
                            options={rubrosComerciales}
                            loading={rubrosComerciales === null}
                            
                            dropdownClassName="dropdown-drpRubro"
                            suffixIcon={isOpenRubro ? <ChevronUp /> : <ChevronDown />}
                            onDropdownVisibleChange={(open) => setIsOpenRubro(open)}
                            //open={true}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                        name='direccion'
                        label='Dirección'
                        rules={[
                            {
                                required: true,
                                message: "Ingresa dirección de la empresa",
                            },
                        ]}
                    >
                        <Input placeholder=' Ingresa una dirección' autoComplete="off" className='custom-placeholder' />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                        style={{ textAlign: 'left' }}
                        name="documentoTributario"
                        label="e-RUT empresa"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[
                            {
                                required: true,
                                message: "Adjunta documento e-RUT",
                            }
                        ]}
                    >
                        <Upload accept={extensionesPermitidas.join(',')} name="logo"
                            beforeUpload={beforeUpload}
                            maxCount={1}
                            customRequest={({ file, onSuccess }) => {
                                return onSuccess()
                            }}
                            listType="text">
                            <Button className="upload-button" placeholder=''>Haz clic aquí para subir el documento</Button>
                            <Text className='text-doc'>El documento debe ser formato PDF.</Text>
                        </Upload>
                        
                        </Form.Item>
                     {/*<Form.Item
                        style={{ textAlign: 'left' }}
                        name="documentoTributario"
                        label="e-RUT empresa"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e?.fileList}
                        rules={[
                            {
                            required: true,
                            message: "Adjunta documento e-RUT",
                            }
                        ]}
                        >
                        <Upload
                            accept=".pdf"
                            beforeUpload={(file) => {
                                const isPDF = beforeUpload(file);
                                if (isPDF) {
                                  setFileUploaded(file);
                                }
                                return false;
                              }}
                            maxCount={1}
                            showUploadList={false}
                        >
                            {!fileUploaded ? (
                            <Button className="upload-button">
                                Haz clic aquí para subir el documento
                            </Button>
                            ) : (
                            <div className="upload-container">
                                <span className="file-name">{fileUploaded.name}</span>
                                <Delete
                                className="delete-icon"
                                onClick={handleRemoveFile}
                                style={{ cursor: 'pointer', color: '#333' }}
                                />
                            </div>
                            )}
                        </Upload>
                        <Text className='text-doc'>El documento debe ser formato PDF.</Text>
                            </Form.Item>*/}
                </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                    <Text className='text-mobile subtitle-pass-empresa' style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '15px' }}>
                        Datos usuario administrador
                    </Text>
                </Col>
            </Row>
            <Form.Item style={{ display: 'none' }} name={['informacionExtraAdministrador', 'glosaAreaCargo']}><Input /></Form.Item>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['informacionExtraAdministrador', 'cargoId']}
                        label="Cargo" className="username">
                        <Select
                       
                            showSearch
                            filterOption={filterOption}
                            placeholder="Selecciona tu cargo"
                            options={cargos}
                            loading={cargos === null}
                            dropdownClassName="dropdown-drpCargo"
                            suffixIcon={isOpenCargo ? <ChevronUp /> : <ChevronDown />}
                            onDropdownVisibleChange={(open) => setIsOpenCargo(open)}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={['informacionExtraAdministrador', 'areaCargoId']}
                        label="Área del cargo"
                        className="username">
                        <Select
                            showSearch
                            filterOption={filterOption}
                            placeholder="Selecciona tu área de cargo"
                            options={areaCargos}
                            loading={areaCargos === null}
                            onChange={value => onChangeAreaCargo(value, true)}
                            dropdownClassName="dropdown-drpArea"
                            suffixIcon={isOpenArea ? <ChevronUp /> : <ChevronDown />}
                            onDropdownVisibleChange={(open) => setIsOpenArea(open)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <br />
            <Row justify="center" gutter={[20, 20]}>
                {/*<Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        htmlType="default"
                        className="btnVolver"
                        htmlType="button"
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        shape="round"
                        onClick={() => keycloak.logout({ redirectUri: process.env.REACT_APP_URL_ONBOARDING_PUBLICO })}
                    >
                        Volver
                    </Button>
                </Col>
                <Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        type="primary"
                        className="btnAzul"
                        htmlType="button"
                        onClick={() => onNextPage()}
                        shape="round"
                    >
                        Siguiente
                    </Button>
                </Col>*/}
                <Col xs={24} sm={12} md={8} lg={6}>
                                        <Button
                                        className="btn-mobile btn-form-pasos-volver"
                                        htmlType="button"
                                        onClick={() => keycloak.logout({ redirectUri: process.env.REACT_APP_URL_ONBOARDING_PUBLICO })}
                                        >
                                        Volver
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={6}>
                                        <Button
                                        className="btn-mobile btn-form-pasos"
                                        type="primary"
                                        htmlType="button"
                                        onClick={() => onNextPage()}
                                        >
                                        Siguiente
                                        </Button>
                                    </Col>
            </Row>
        </div>
    )
}

export default Paso1