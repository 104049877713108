import { Card, Space, Row, Col, Image, Radio } from 'antd'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { MedioPagoId } from '../../enum/enums'
import khipu_200x75_1 from '../../assets/images/khipu/logo_kipu_banco.png'
import logowebpay from '../../assets/images/webpay/logo_pago_webpay.png'

const SeleccionMetodoPago = ({ onSelectMedioPago }) => {
    const dispatch = useDispatch()
    const [tarjetas, setTarjeta] = useState(null)

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             dispatch(setIsLoading(true))
    //             const data = await getTarjetasCliente()

    //             if (data) {
    //Se envia 1 como idMedioPago ya que 
    // las tarjetas corresponden a pagos por Oneclick
    // TODO - Actualizar cuando se incorpore kiphu para dejar el valor dinamico
    //                 onSelectMedioPago(1, data[0].idTarjeta)
    //             }

    //             setTarjeta(data)
    //         }
    //         catch (error) {
    //             console.log(error)
    //         }
    //         finally {
    //             dispatch(setIsLoading(false))
    //         }
    //     }
    //     fetchData()
    // }, [])

    const onRadioButtonClick = (metodoPago) => {
        onSelectMedioPago(metodoPago.idMedioPago, null)
    }

    return (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

            <Col className="gutter-row" span={24}>
                <Radio.Group
                    // onChange={onChange}
                    // value={value}
                    style={{ width: '100%', textAlign: 'left' }}>
                    {/* {tarjetas && tarjetas?.map((item, index) => (
                        <Card key={`md-${index}`} className='cardAgregarTarjeta'>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={2}>
                                    <Radio
                                        onClick={() => onRadioButtonClick({
                                            idMedioPago: MedioPagoId.ONE_CLICK,
                                            idTarjeta: item.idTarjeta,

                                        })}
                                        style={{ marginTop: 15 }}
                                        value={{
                                            idMedioPago: MedioPagoId.ONE_CLICK,
                                            idTarjeta: item.idTarjeta
                                        }}
                                    >
                                    </Radio>
                                </Col>
                                <Col span={20}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 1, backgroundColor: '#ff4201', borderRadius: 6, maxHeight: 50 }}></div>
                                        <div style={{ flex: 3, paddingLeft: 16 }}>
                                            <p style={{ marginTop: 5 }}><strong>Tarjeta {item.tipoTarjeta}</strong></p>
                                            <p style={{ lineHeight: 0, fontWeight: 400 }}>**** {item.numeroTarjeta}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    ))} */}
                    {/* Medios de pago NO recurrentes */}
                    <Card className='cardAgregarTarjeta'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={2}>
                                <Radio
                                    // disabled={process.env.REACT_APP_HABILITAR_WEB_PAY === 'false'}
                                    style={{ marginTop: 15 }}
                                    onClick={() => onRadioButtonClick({
                                        idMedioPago: MedioPagoId.WEBPAY
                                    })}
                                    value={MedioPagoId.WEBPAY}
                                >
                                </Radio>
                            </Col>
                            <Col span={20}>
                                <Row gutter={0}>
                                    <Col span={8}>
                                        <Image
                                            preview={false}
                                            src={logowebpay}
                                            style={{ maxWidth: 125 }}
                                        />
                                    </Col>
                                    <Col span={16} style={{ paddingLeft: 20 }}>
                                        <p style={{ marginTop: 0, marginBottom: 0 }}>
                                            <strong>Tarjeta - Webpay </strong>
                                        </p>
                                        <p style={{ fontWeight: 400, marginTop: 0 }}>
                                            Paga con tarjeta de crédito, débito o prepago.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <Card className='cardAgregarTarjeta'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={2}>
                                <Radio
                                    style={{ marginTop: 15 }}
                                    onClick={() => onRadioButtonClick({
                                        idMedioPago: MedioPagoId.KIPHU,
                                        idTarjeta: null,

                                    })}
                                    value={MedioPagoId.KIPHU}
                                >
                                </Radio>
                            </Col>
                            <Col span={20}>
                                <Row gutter={0}>
                                    <Col span={8}>
                                        <Image
                                            preview={false}
                                            src={khipu_200x75_1}
                                        />
                                    </Col>
                                    <Col span={16} style={{ paddingLeft: 20 }}>
                                        <p style={{ marginTop: 0, marginBottom: 0 }}>
                                            <strong>Transferencia - Khipu </strong>
                                        </p>
                                        <p style={{ fontWeight: 400, marginTop: 0 }}>
                                            Paga con transferencia con tu banco.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </Card>
                </Radio.Group>
            </Col>
        </Row >
    )
}

export default SeleccionMetodoPago