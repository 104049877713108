import Productos from "../personas/Productos"
import { useEffect } from "react"
import { Row, Col, Card } from "antd"
import { setIsLoading } from "../../redux/reducers/AppReducer"
import { setProductos, setProductosFiltrados } from "../../redux/reducers/ProductoReducer"
import { getProductosEmpresa } from "../../services/api"
import { PerfilUsuarioId } from "../../enum/enums"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"

const ProductosEmpresa = () => {
    const dispatch = useDispatch()
    const idPerfilUsuario = useSelector(state => state.aplicacion.usuario.perfilId)
    const navigate = useNavigate()
    const isLoading = useSelector(store => store.aplicacion.isLoading)

    useEffect(() => {
        const fetchData = async () => {
            if (idPerfilUsuario !== PerfilUsuarioId.ADMIN_EMPRESA) {
                navigate('/productos')
            }
            else {
                try {
                    dispatch(setIsLoading(true))
                    const data = await getProductosEmpresa()
                    dispatch(setProductos(data))
                    dispatch(setProductosFiltrados(data))
                }
                catch (error) {
                    console.log(error)
                }
                finally {
                    dispatch(setIsLoading(false))
                }
            }
        }
        fetchData()
    }, [idPerfilUsuario])

    return (
        <>
            {isLoading ?
                <Row>
                    <Col className="gutter-row" xs={24} style={{ marginBottom: '25px' }}>
                        <Card>
                            <p style={{ color: '#838383', fontSize: 16 }}> cargando...</p>
                        </Card>
                    </Col>
                </Row> : <Productos></Productos>}
        </>
    )
}

export default ProductosEmpresa