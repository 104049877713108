import { configureStore } from '@reduxjs/toolkit'
import AppReducer from '../reducers/AppReducer'
import ProductoReducer from '../reducers/ProductoReducer'

export const store = configureStore({
    reducer: {
        aplicacion: AppReducer,
        producto: ProductoReducer
    },
    devTools: process.env.REACT_APP_HABILITA_DEV_TOOL === 'true',
})