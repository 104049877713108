import React, { useState } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Button, Col, Layout, Row, theme } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { Outlet, useNavigate, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { CapitalizeFirstLetter, getInicial } from '../../services/Helpers/stringHelper';
import FormBusqueda from '../formCrearcuentaPersona/FormBusqueda';
import Loader from '../Loader/loader';
import { useBreadcrumb } from '../../utils/BreadcrumbContext';
import MenuMobile from './MenuMobile';
import CustomHeader from './CustomHeader';
import './Layout.scss'
import OpcionesMenu from './opcionesMenu';
import ModalCerrarSesion from '../modal/ModalCerrarSesion';
import { itemRenderBreadcump } from '../../services/Helpers/stringHelper';
import BtnSalir from './BtnSalir';
import { useParams } from 'react-router';
import NombreUsuario from './NombreUsuario';
import ActualizaNotificaciones from './ActualizaNotificaciones'
const { Header, Content, Sider } = Layout;

const Layouts = () => {

  const location = useLocation()
  const navigate = useNavigate()
  const [collapsed, setCollapsedMenu] = useState(false)
  const [openMenuMobile, setOpenMenuMobile] = useState(false)
  const [openModalSalir, setOpenModalSalir] = useState(false)
  const { idUsuarioEditar } = useParams()
  const usuario = useSelector(state => state.aplicacion.usuario)
  const subtitulo = useSelector(state => state.aplicacion.subtituloHeader)

  const { breadcrumbs, setBreadcrumbs } = useBreadcrumb()
  const pageFiltro = ['/productos', '/Usuarios', '/producto-servicio', '/productosEmpresa']

  const handleMenuClick = (key, label, parentKey) => {
    navigate(parentKey ? `/${parentKey}/${key}` : `/${key}`)
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken()


  return (
    <Layout>
      <Header className='header'
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
        }}
      >
        <CustomHeader isMenuCollapsed={collapsed} setCollapseMenu={() => setCollapsedMenu(!collapsed)} openMenu={setOpenMenuMobile}></CustomHeader>
      </Header>
      <Layout style={{ marginTop: -1 }}>
        <MenuMobile
          openMenuMobile={openMenuMobile}
          closeMenuMobile={() => setOpenMenuMobile(false)}
          handleMenuClick={handleMenuClick}
        ></MenuMobile>
        <ModalCerrarSesion
          isModalOpen={openModalSalir}
          onClose={() => setOpenModalSalir(false)}
        ></ModalCerrarSesion>
        <Sider className='customsider' trigger={null} collapsible collapsed={collapsed}
          width={220}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            background: colorBgContainer
          }}>
          <div style={{ minHeight: 410 }}>
            {usuario ? <NombreUsuario isMenuCollapsed={collapsed} usuario={usuario} /> : null}
            <br />
            <OpcionesMenu isMenuCollapsed={collapsed} handleMenuClick={handleMenuClick}></OpcionesMenu>
            <ActualizaNotificaciones></ActualizaNotificaciones>
          </div>
          <div>
            <BtnSalir setOpenModalSalir={() => setOpenModalSalir(true)} isMenuCollapsed={collapsed} />
          </div>
        </Sider>
        <Layout
          style={{
            padding: 0,
            backgroundColor: 'white'
          }}
        >
          <Row className={`bienvenida ${collapsed ? 'collapsed' : ''}`}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row>
                <Col xs={0} sm={0} md={24} lg={24}>
                  <Paragraph style={{ fontSize: '20px', textAlign: 'left', marginBottom: 0 }}><strong>Hola </strong>{usuario ? CapitalizeFirstLetter(` ${usuario?.nombres} ${usuario?.apellidos}`) : ''} </Paragraph>
                  <Paragraph style={{ fontSize: '14px', textAlign: 'left', visibility: `${subtitulo !== '' ? '' : 'hidden'}` }}>{subtitulo !== '' ? subtitulo : 'a'}</Paragraph>
                </Col>
                <Col style={{ textAlign: 'left' }} xs={3} sm={3} md={0} lg={0}>
                  <Avatar onClick={() => navigate(-1)} style={{ backgroundColor: '#ff4201' }} className="avatar" icon={<LeftOutlined />} />
                </Col>
                <Col xs={20} sm={20} md={20} lg={24}>
                  {pageFiltro.includes(location.pathname) ? <FormBusqueda></FormBusqueda> :
                    <Breadcrumb
                      itemRender={e => itemRenderBreadcump(e, idUsuarioEditar)}
                      className='breadCump'
                      items={[{
                        title: breadcrumbs.label,
                        // onClick: () => navigate(breadcrumbs.url),
                      }]} />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Content className={`contenido-principal ${collapsed ? 'collapsed' : ''}`}
            style={{
              padding: 24,
              margin: 0,
              minHeight: '100vh',

              background: colorBgContainer,
            }}
          >
            <Loader></Loader>
            <Outlet></Outlet>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Layouts