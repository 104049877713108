import { useNavigate } from 'react-router-dom';
import { Table, Avatar, Tag, Button, Space, Row, Col } from 'antd';
import { SeparadorMiles } from '../../../services/Helpers/stringHelper';
import { CapitalizeFirstLetter, getInicial, getInicialPorPalabra } from '../../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../../services/Helpers/RutHelper';
import { useSelector } from 'react-redux';


const TablaUsuarios = ({ onEditarRol, usuarios, onEliminarUsuario }) => {
    const navigate = useNavigate();
    const idUsuarioSession = useSelector(state => state.aplicacion.usuario.id)

    const columns = [
        {
            title: 'Usuario',
            width: 200,
            dataIndex: 'nombreUsuario',
            key: 'nombreUsuario',
            sorter: (a, b) => a.nombreUsuario.localeCompare(b.nombreUsuario, 'es', { ignorePunctuation: true }),
            render: (value) => <Space>
                <Avatar
                    className="avatarTabla"
                    style={{
                        backgroundColor: '#ff4201',
                        verticalAlign: 'middle',
                        marginRight: 10,
                        fontSize: 13,
                    }}
                    size="small"
                    shape='circle'
                    size={35}
                >
                    {getInicialPorPalabra(value, 2)}
                </Avatar>
                {CapitalizeFirstLetter(value)}
            </Space>
        },
        {
            title: 'Rut',
            sorter: (a, b) => a.rutUsuario.localeCompare(b.rutUsuario, 'es', { ignorePunctuation: true }),
            width: 120,
            dataIndex: 'rutUsuario',
            key: 'rutUsuario',
            render: (value) => <>
                {daFormatoRut(value)}
            </>,
        },
        {
            title: 'Rol',
            width: 50,
            sorter: (a, b) => a.rolAsignado.localeCompare(b.rolAsignado, 'es', { ignorePunctuation: true }),
            dataIndex: 'rolAsignado',
            key: 'rolAsignado',
        },
        {
            title: 'Productos',
            width: 20,
            dataIndex: 'cantidadProductos',
            key: 'cantidadProductos',
            align: 'center',
            sorter: (a, b) => a.cantidadProductos - b.cantidadProductos,
            render: (value) => <>{SeparadorMiles(value)}</>,
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            sorter: (a, b) => a.estado.localeCompare(b.estado, 'es', { ignorePunctuation: true }),
            width: 100,
            render: (value) => <>
                {value === 'Activado' ?
                    <Tag style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 5, paddingTop: 5 }} color="#ff4201">Activo</Tag> :
                    <Tag style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 5, paddingTop: 5 }} color="default">Inactivo</Tag>
                }
            </>,
        },
        {
            title: '',
            dataIndex: 'idUsuario',
            key: 'idUsuario',
            width: 200,
            render: (value, record) => <>
                <Row gutter={0}>
                    <Col span={24}>
                        <Space>
                            <Button disabled={record.estado !== 'Activado'} onClick={() => navigate(`/editar-usuario/${value}`)} style={{ marginBottom: 10, minWidth: 125 }} type="primary" shape="round" htmlType="button">
                                Editar datos
                            </Button>
                            <Button disabled={value === idUsuarioSession || record.estado !== 'Activado'} onClick={() => onEditarRol(record)} style={{ marginBottom: 10, minWidth: 125 }} type="primary" shape="round" htmlType="button">
                                Editar rol
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={0}>
                    <Col span={24}>
                        <Space>
                            <Button onClick={() => navigate(`/detalle-usuario/${value}`)} style={{ marginBottom: 10, minWidth: 125 }} type="primary" shape="round" htmlType="button">
                                Ver productos
                            </Button>
                            <Button onClick={() => onEliminarUsuario(record)} style={{ marginBottom: 10, minWidth: 125 }} disabled={value === idUsuarioSession || record.estado !== 'Activado'} type="primary" shape='round' danger >
                                Eliminar
                            </Button>
                        </Space>
                    </Col>
                </Row>




            </>,
        }
    ];


    return (
        <>
            <Table style={{ marginTop: '10px' }} dataSource={usuarios} rowKey='idUsuario'
                locale={{ emptyText: "No se encontraron usuarios." }} columns={columns}
            >
            </Table>
        </>)
};

export default TablaUsuarios