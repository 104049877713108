import { Button, Col, Form, Input, Row, Select } from 'antd'
import Title from 'antd/es/typography/Title'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { CapitalizeFirstLetter } from '../../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../../services/Helpers/RutHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, setRefrescarNotificaciones } from '../../../redux/reducers/AppReducer';
import { getPaises, getCiudades, getRegiones, getComunas } from '../../../services/publicApi'
import { updateInfoEmpresa } from '../../../services/empresaApi';
import ModalAvisos from '../../modal/ModalAvisos';

const EditarInformacionEmpresa = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const usuario = useSelector(state => state.aplicacion.infoEmpresa)
    const [regiones, setRegiones] = useState(null)
    const [comunas, setComunas] = useState(null)
    const [paises, setPaises] = useState(null)
    const [isOpenModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            if (usuario) {
                dispatch(setIsLoading(true))

                Promise.all([getPaises(),
                getRegiones(usuario.paisId),
                getComunas(usuario.regionId)
                ]).then((values) => {
                    setPaises(values[0])
                    setRegiones(values[1])
                    setComunas(values[2])
                }).finally(() =>
                    dispatch(setIsLoading(false))
                );
            }
            else {
                navigate('/perfilEmpresa')
            }
        }
        fetchData()
    }, [usuario])

    const onChangeRegion = async (value) => {
        try {
            form.setFieldValue('ciudadId', null)
            const result = await getComunas(value)
            setComunas(result)
        }
        catch {
        }
    }

    const onChangePais = async (value) => {
        try {
            const glosa = paises.filter(x => x.value === value)[0].label
            form.setFieldValue('glosaPais', glosa)
            const result = await getRegiones(value)
            setRegiones(result)
        }
        catch {
        }
    }

    const oncCloseModal = () => {
        navigate('/perfilEmpresa')
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onFinish = async (values) => {
        try {
            dispatch(setIsLoading(true))
            const data = await updateInfoEmpresa(values)

            dispatch(setRefrescarNotificaciones(true))
            setDataModal({
                titulo: 'Edición de datos exitosa',
                mensaje: 'Se envió un correo para notificar los cambios realizados',
                fueCorrecto: true
            })
        }
        catch (error) {
            console.log(error)
            setDataModal({
                titulo: 'Error',
                mensaje: '¡Ha ocurrido un error, por favor inténtelo nuevamente!',
                fueCorrecto: false
            })
            setOpenModal(true)
        }
        finally {
            setOpenModal(true)
            dispatch(setIsLoading(false))
        }
    }

    return (
        <>
            {usuario ? <>
                <ModalAvisos
                    isModalOpen={isOpenModal}
                    onClose={() => oncCloseModal()}
                    mensaje={dataModal?.mensaje}
                    titulo={dataModal?.titulo}
                    fueCorrecto={dataModal?.fueCorrecto}
                ></ModalAvisos>
                <Title level={3} style={{ fontWeight: 'bold', textAlign: 'left' }}>Datos Empresa</Title>
                <Form onFinish={onFinish} className='formMisDatosLectura formLabelStrongs' layout='vertical'
                    initialValues={{
                        direccion: usuario.direccion,
                        regionId: usuario.regionId,
                        paisId: usuario.paisId,
                        comunaId: usuario.comunaId,
                        glosaPais: usuario.pais
                    }} >
                    <Form.Item style={{ display: 'none' }} name='glosaPais'><Input /></Form.Item>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label="Nombre Empresa" tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} style={{ paddingLeft: '0px' }} placeholder={CapitalizeFirstLetter(usuario?.razonSocial)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Número de Identificación (RUT)' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} placeholder={daFormatoRut(usuario.numeroIdentificacion)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Rubro comercial' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} placeholder={usuario.rubroComercial} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='paisId'
                                label='País'
                                onChange={onChangePais}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese el país',
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Selecciona un país"
                                    filterOption={filterOption}
                                    options={paises}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='regionId'
                                label='Región'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresar la región',
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    onChange={onChangeRegion}
                                    placeholder="Selecciona una región"
                                    filterOption={filterOption}
                                    options={regiones}
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item
                                name='ciudadId'
                                label='Ciudad'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Selecciona una ciudad',
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    onChange={onChangeCiudad}
                                    placeholder="Selecciona una ciudad"
                                    filterOption={filterOption}
                                    options={ciudades}
                                />
                            </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='comunaId'
                                label='Comuna'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Selecciona una comuna',
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Selecciona una comuna"
                                    filterOption={filterOption}
                                    options={comunas}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='direccion'
                                label='Dirección'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese dirección',
                                    }
                                ]}
                            >
                                <Input style={{ padding: 4 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={11} sm={11} md={8} lg={5}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    className="btncancelar"
                                    htmlType="submit"
                                    shape="round"
                                    danger
                                    disabled={false}
                                    style={{ width: '100%' }}
                                    onClick={() => navigate('/perfilEmpresa')}
                                >
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={13} sm={13} md={8} lg={5}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    disabled={false}
                                    style={{ width: '100%' }}
                                >
                                    Guardar cambios
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </> : null}



        </>
    )
}

export default EditarInformacionEmpresa