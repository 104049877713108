import React from 'react'
import { Typography } from 'antd'
import { useSelector } from 'react-redux'
import Cancelado from '../../components/khipu/Cancelado'

const CancelPage = () => {

  return (<Cancelado />)
}

export default CancelPage