import { Form, Button, Modal, Select, Row, Col } from 'antd'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setIsLoading, setRefrescarNotificaciones } from '../../../redux/reducers/AppReducer'
import { updateRolUsuario } from '../../../services/empresaApi'

const roles = [{ label: 'Administrador', value: 2 }, { label: 'Consumidor', value: 3 }]

const ModalEditarRol = ({ isModalOpen, handleOk, handleCancel, usuario }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        if (usuario) {
            form.setFieldValue('idRol', usuario?.idRolAsignado)
        }
    }, [usuario])

    const onFinish = async (values) => {
        try {
            if (usuario.idRolAsignado !== values.idRol) {
                dispatch(setIsLoading(true))
                values.idUsuario = usuario.idUsuario

                await updateRolUsuario(values)
                dispatch(setRefrescarNotificaciones(true))
                handleOk()
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            handleCancel()
            dispatch(setIsLoading(false))
        }
    }

    return (
        <div>
            <Modal
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={350}
                footer={<></>}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <h3>Editar rol de usuario</h3>

                    <Form form={form}
                        onFinish={onFinish}
                        className="form"
                        layout='vertical'
                        style={{ width: '80%' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item name='idRol' label='Selecciona rol:'>
                                    <Select
                                        showSearch={false}
                                        placeholder="Seleccione un rol"
                                        options={roles}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button className="btncancelar" style={{ marginRight: 10 }} type='primary' danger key="back" shape='round' onClick={handleCancel}>
                                    Cancelar
                                </Button>
                                <Button htmlType="submit" type="primary" shape='round'>
                                    Actualizar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default ModalEditarRol