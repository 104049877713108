import { Avatar, Badge, Button, Tooltip } from 'antd';
import { BellOutlined, MenuOutlined, UserOutlined, QuestionCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { CapitalizeFirstLetter, truncateString } from '../../services/Helpers/stringHelper';
import { useState } from 'react';
import Notificacion from '../notificaciones/Notificacion';
import { useNavigate } from 'react-router';
import { PerfilUsuarioId } from '../../enum/enums';

const CustomHeader = ({ openMenu, setCollapseMenu, isMenuCollapsed }) => {
    const usuario = useSelector(state => state.aplicacion.usuario)
    const notificaciones = useSelector(state => state.aplicacion.notificaciones)
    const [showNotificationes, setShownotificationes] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <Notificacion openNotificaciones={showNotificationes} closeNotificaciones={() => setShownotificationes(false)}></Notificacion>

            <Row justify="space-between" gutter={0}>
                {/* desktop */}
                <Col style={{ textAlign: 'left', marginTop: 5, marginBottom: 10 }} xs={0} sm={0} md={12} lg={12}>
                    <Row gutter={0}>
                        <Col span={'auto'}>
                            <img src='/logo-bolsa-b.svg' alt='Logo' style={{ width: 'auto', height: 35, marginLeft: -29, marginTop: 0 }} />
                        </Col>
                        <Col span={'auto'} style={{ marginTop: -10 }}>
                            <Button
                                type="text"
                                icon={isMenuCollapsed ? <MenuUnfoldOutlined style={{ fontSize: 20, color: 'white' }} /> : <MenuFoldOutlined style={{ color: 'white', fontSize: 20 }} />}
                                onClick={() => setCollapseMenu()}
                                style={{
                                    fontSize: 16,
                                    marginLeft: 20,
                                    color: 'white'
                                }}
                            />
                        </Col>
                    </Row>

                </Col>
                <Col style={{ textAlign: 'right', marginTop: 0 }} xs={0} sm={0} md={12} lg={12}>
                    <Avatar onClick={() => navigate('/contactenos')} size={48} shape='square' icon={<QuestionCircleOutlined />} style={{ cursor: 'pointer', marginTop: -18 }} />
                    <Badge count={notificaciones?.length ?? 0} style={{ boxShadow: 'none', marginTop: 0, marginRight: 11, backgroundColor: '#ff4201', cursor: 'pointer', }} onClick={() => setShownotificationes(true)}>
                        <Avatar size={48} shape='square' icon={<BellOutlined />} style={{ cursor: 'pointer', marginTop: -16 }} onClick={() => setShownotificationes(true)} />
                    </Badge>
                </Col>

                {/* mobile */}
                <Col style={{ textAlign: 'left', marginLeft: -30, }} xs={4} sm={4} md={0} lg={0}>
                    <Avatar shape='circle' onClick={() => openMenu(true)} icon={<MenuOutlined />} style={{ backgroundColor: '#ff4201', cursor: 'pointer' }} />
                </Col>
                <Col style={{ textAlign: 'left' }} xs={4} sm={14} md={0} lg={0}>
                    <Avatar style={{ backgroundColor: '#ff4201' }} className="avatar" size={45} icon={<UserOutlined />} />
                </Col>
                <Col style={{ textAlign: 'left' }} xs={12} sm={12} md={0} lg={0}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                        <Tooltip title={CapitalizeFirstLetter(`${usuario?.nombres} ${usuario?.apellidos}`)}><p className="headerTitulo" style={{ marginTop: '-10px', marginBottom: '0px', height: '25px' }}><strong>{truncateString(CapitalizeFirstLetter(`${usuario?.nombres} ${usuario?.apellidos}`), 17)}</strong></p></Tooltip>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>

                        {usuario?.perfilId === PerfilUsuarioId.PERSONA_NATURAL ?
                            <p className="headerTitulo" style={{ fontStyle: 'italic', marginTop: '0px', marginBottom: '0px', height: '25px' }}>{CapitalizeFirstLetter(usuario?.profesionOficio)}</p>
                            :
                            <p className="headerTitulo" style={{ fontStyle: 'italic', marginTop: '0px', marginBottom: '0px', height: '25px' }}>{CapitalizeFirstLetter(usuario?.nombreCliente)}</p>
                        }
                    </Col>
                </Col>

                <Col style={{ textAlign: 'left' }} xs={4} sm={4} md={0} lg={0}>
                    <Avatar style={{ backgroundColor: 'transparent' }} onClick={() => navigate('/contactenos')} size={55} shape='square' icon={<QuestionCircleOutlined />} style={{ cursor: 'pointer', marginTop: -3, backgroundColor: 'transparent', color: usuario?.perfilId === PerfilUsuarioId.PERSONA_NATURAL ? 'black' : 'white' }} />
                </Col>
                <Col style={{ textAlign: 'right' }} xs={2} sm={2} md={0} lg={0}>
                    <Badge count={notificaciones?.length ?? 0}>
                        <Avatar shape='circle' icon={<BellOutlined />} style={{ cursor: 'pointer', backgroundColor: '#ff4201' }} onClick={() => setShownotificationes(true)} />
                    </Badge>
                </Col>
            </Row>
        </>
    )
}

export default CustomHeader