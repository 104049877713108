import axiosConfig from "./Helpers/privateInterceptorAxios"
const urlApi = process.env.REACT_APP_BACKEND_URL
const axios = axiosConfig.iAxios

export const leerNotificacion = async (id) => {
    return axios.delete(`${urlApi}/Notificacion/Leer/${id}`).then((res) => { return res.data })
}

export const getNotificacionesUsuario = async () => {
    return axios.get(`${urlApi}/Notificacion/Notificaciones`).then((res) => { return res.data })
}
