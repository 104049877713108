import axiosConfig from "./Helpers/privateInterceptorAxios"
const typeDefs = require('../interface/typeDefs')

const urlApi = process.env.REACT_APP_BACKEND_URL
const axios = axiosConfig.iAxios

export const getDetalleProductoContratar = async (id) => {
    return axios.get(`${urlApi}/Producto/AContratar/${id}`).then((res) => { return res.data })
}

export const getValorUF = async () => {
    return axios.get(`${urlApi}/Util/UF`).then((res) => { return res.data })
}

export const getProductosAsignablesUsuario = async () => {
    return axios.get(`${urlApi}/Producto/ProductosAsignablesUsuario`).then((res) => { return res.data })
}

export const getProductosEmpresa = async () => {
    return axios.get(`${urlApi}/Producto/ProductosEmpresa`).then((res) => { return res.data })
}

export const getProductosUsuario = async () => {
    return axios.get(`${urlApi}/Producto/ProductosUsuario`).then((res) => { return res.data })
}

export const getProductosContratables = async () => {
    return axios.get(`${urlApi}/Producto/Productos`).then((res) => { return res.data })
}

export const getUserSession = async () => {
    return axios.get(`${urlApi}/Usuario/Informacion`).then((res) => { return res.data })
}

/**
 * 
 * @param {number} idProducto - Identificador del producto
 * @param {number} idSuscripcion - Identificador de la suscripcion
 * @return {Promise<typeDefs.DetalleProductoContratadoDto>} Detalle del producto contratado
 */
export const getDetalleProducto = async (idProducto, idSuscripcion) => {
    return axios.get(`${urlApi}/Producto/Detalle/${idProducto}/${idSuscripcion}`).then((res) => { return res.data })
}

export const saveTransaccionUsuario = async (accionId) => {
    return axios.put(`${urlApi}/Usuario/accion?idAccion=${accionId}`).then((res) => { return res.data })
}

export const sendEmailCambioPassword = async () => {
    return axios.put(`${urlApi}/Usuario/updatePassword`).then((res) => { return res.data })
}

export const updateUsuario = async (data) => {
    return axios.post(`${urlApi}/Usuario/Actualizar`, data).then((res) => { return res.data })
}