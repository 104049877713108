import axiosConfig from "./Helpers/privateInterceptorAxios"
const typeDefs = require('../interface/typeDefs')
const urlApi = process.env.REACT_APP_BACKEND_URL
const axios = axiosConfig.iAxios


/**
 * Obtiene un documento de un producto según su tipo
 * @param {number} idProduto - identificador del producto
 * @param {number} idTipoDocumento - identificador del tipo de documento del producto. 1-> Contrato de producto; 2->Folleto producto; 3-> Documentacion
 * @returns {Promise<typeDefs.BlobFileResponseDto>} Archivo en formato "arrayBuffer"
 */
export const getDocumento = async (idProducto, idTipoDocumento) => {
    try {

        return axios.
            get(
                `${urlApi}/Documento/Producto/${idProducto}/${idTipoDocumento}`,
                { responseType: 'arraybuffer' }
            )
            .then((res) => {
                const contentDisposition = res.headers["content-disposition"]
                let startFileNameIndex = contentDisposition.indexOf('"') + 1
                let endFileNameIndex = contentDisposition.lastIndexOf('"');
                let filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);

                return {
                    buffer: res.data,
                    contentType: res.headers["content-type"],
                    fileName: filename
                }
            })
    }
    catch (error) {
        return Promise.reject(error)
    }
}


export const getTerminosCondicionesPortal = async () => {
    try {
        return axios.
            get(
                `${urlApi}/Documento/TerminoCondicionesPortal`,
                { responseType: 'arraybuffer' }
            )
            .then((res) => {
                const contentDisposition = res.headers["content-disposition"]
                let startFileNameIndex = contentDisposition.indexOf('"') + 1
                let endFileNameIndex = contentDisposition.lastIndexOf('"');
                let filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);

                return {
                    buffer: res.data,
                    contentType: res.headers["content-type"],
                    fileName: filename
                }
            })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const getGuiaInicio = async (idTipoCliente) => {
    try {
        return axios.
            get(
                `${urlApi}/Documento/GuiaInicio/${idTipoCliente}`,
                { responseType: 'arraybuffer' }
            )
            .then((res) => {
                const contentDisposition = res.headers["content-disposition"]
                let startFileNameIndex = contentDisposition.indexOf('"') + 1
                let endFileNameIndex = contentDisposition.lastIndexOf('"');
                let filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);

                return {
                    buffer: res.data,
                    contentType: res.headers["content-type"],
                    fileName: filename
                }
            })
    }
    catch (error) {
        return Promise.reject(error)
    }
}