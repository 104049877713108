import { useEffect, useState } from "react"
import { getEncuesta } from "../../services/publicApi"
import { useDispatch } from "react-redux"
import { setIsLoading } from "../../redux/reducers/AppReducer"
import { TipoPreguntaId } from "../../enum/enums"
import { Button, Form, Input, Space, Checkbox, Row, Col, Radio } from 'antd'
import { convertirNumeroaArray } from "../../services/Helpers/stringHelper"
import { guardarEncuestaSatisfaccion } from "../../services/suscripcionApi"
import Title from 'antd/es/typography/Title'
import './encuesta.style.scss'
import { useNavigate, useParams } from "react-router"

const { TextArea } = Input;

const Encuesta = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { idSuscripcion } = useParams()
    const [encuesta, setEncuesta] = useState(null)

    const onFinish = async (values) => {
        try {
            dispatch(setIsLoading(true))
            const data = []
            const keys = Object.keys(values.respuestas);

            keys.map((key) => {
                const tipoRespuesta = typeof values.respuestas[key]

                data.push({
                    preguntaId: parseInt(key.split('-')[1]),
                    respuestaTexto: tipoRespuesta === 'string' ? values.respuestas[key] : null,
                    respuestaNumerica: convertirNumeroaArray(values.respuestas[key], tipoRespuesta),
                    suscripcionId: parseInt(idSuscripcion)
                })
            })

            await guardarEncuestaSatisfaccion(data)
        }
        catch (error) {
            console.log(error)
        }
        finally {
            dispatch(setIsLoading(false))
            navigate('/productos')
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const data = await getEncuesta()
                setEncuesta(data.Preguntas)
            }
            catch (error) {
                console.log(error)
            }
            finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData()
    }, [])

    return (
        <>
            <Row>
                <Col className="titulo" xs={24} sm={24} md={24} lg={24}>
                    <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                            <p style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 22, marginTop: 10, marginBottom: 10 }}>Encuesta de Satisfacción y Cancelación de Suscripción</p>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                            <Title level={5} style={{ marginTop: '5px', marginBottom: '10px', textAlign: 'left', fontStyle: 'italic' }}>Ayúdanos a mejorar</Title>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'left', marginBottom: '20px' }}>
                            <span style={{ marginTop: '5px', fontWeight: '400', textAlign: 'left', textAlign: 'justify' }}>
                                Agradecemos tu participación en esta encuesta. Tu opinión es fundamental para nuestro continuo desarrollo y mejora de servicios. Por favor, dedica unos minutos a compartir tus comentarios de manera honesta y constructiva. Todas las respuestas son confidenciales.
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form
                id="formEncuesta"
                className="form"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.List name="respuestas">
                    {(fields) => (
                        <>
                            {encuesta?.map((item, index) => (
                                <Space
                                    key={index}
                                    style={{ display: 'flex', minWidth: '80%' }}
                                    align="baseline"
                                >
                                    {item.IdTipoPregunta === TipoPreguntaId.TEXTO ?
                                        <Form.Item label={`${index + 1}. ${item.Pregunta}`}
                                            name={`id-${item.IdPregunta}`}
                                            rules={[
                                                {
                                                    required: item.EsObligatoria,
                                                    message: 'Campo requerido',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={3} maxLength="1000" />
                                        </Form.Item> : item.IdTipoPregunta === TipoPreguntaId.MULTI_ALTERNATIVA ?
                                            <Form.Item name={`id-${item.IdPregunta}`} label={`${index + 1}. ${item.Pregunta}`}
                                                rules={[{ required: item.EsObligatoria, message: 'Campo requerido' }]}>
                                                <Checkbox.Group>
                                                    <Row>
                                                        {item.Alternativas?.map((opc, indexAlt) => (
                                                            <Col span={24} key={`chk-${index}-${indexAlt}`}>
                                                                <Checkbox value={opc.IdAlternativa}>
                                                                    {opc.Alternativa}
                                                                </Checkbox>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Checkbox.Group>
                                            </Form.Item> : item.IdTipoPregunta === TipoPreguntaId.ALTERNATIVA ?
                                                <Form.Item name={`id-${item.IdPregunta}`} label={`${index + 1}. ${item.Pregunta}`}
                                                    rules={[{ required: item.EsObligatoria, message: 'Campo requerido' }]}>
                                                    <Radio.Group>
                                                        <Row>
                                                            {item.Alternativas?.map((opc, indexAlt) => (
                                                                <Col span={24} key={`dr-${index}-${indexAlt}`}>
                                                                    <Radio value={opc.IdAlternativa}>{opc.Alternativa}</Radio>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Radio.Group>
                                                </Form.Item> : null
                                    }
                                </Space>
                            ))}
                        </>
                    )}
                </Form.List>
                <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'left', marginBottom: '5px' }}>
                        <span style={{ marginTop: '5px', fontWeight: '400', textAlign: 'left', textAlign: 'justify' }}>
                            Agradecemos sinceramente tu tiempo y feedback. Tus respuestas son esenciales para nuestro compromiso continuo con la excelencia en el servicio. ¡Gracias por ser parte de nuestra comunidad!
                        </span>
                    </Col>
                </Row>
                <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'left', marginBottom: '15px' }}>
                        <span style={{ marginTop: '0px', fontWeight: '600', textAlign: 'left', textAlign: 'justify' }}>
                            Por favor, danos la oportunidad de demostrarte que valoramos tu retroalimentación y que estamos comprometidos a hacer mejoras significativas. Si decides reconsiderar tu suscripción en el futuro, esperamos poder ofrecerte una experiencia mejorada. ¡Gracias por tu confianza y paciencia!
                        </span>
                    </Col>
                </Row>
                <Form.Item style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit">
                        Enviar
                    </Button>
                </Form.Item>
            </Form >
        </>
    )
}

export default Encuesta