import { Button, Col, Form, Input, Row, Select } from 'antd'
import Title from 'antd/es/typography/Title'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, setRefrescarNotificaciones } from '../../../redux/reducers/AppReducer';
import { getPaises, getRegiones, getComunas, getCargos, getAreaCargos } from '../../../services/publicApi'
import { CapitalizeFirstLetter } from '../../../services/Helpers/stringHelper';
import { updateDatosFacturacionEmpresa } from '../../../services/empresaApi';
import ModalAvisos from '../../modal/ModalAvisos';
const dataOpcionesHes = [{ value: 1, label: 'Sí' }, { value: 0, label: 'No' }]

const EditarDatosFacturacion = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const usuario = useSelector(state => state.aplicacion.infoEmpresa?.datosFacturacion)
    const [regiones, setRegiones] = useState(null)
    const [ciudades, setCiudades] = useState(null)
    const [comunas, setComunas] = useState(null)
    const [paises, setPaises] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)
    const [isOpenModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState(null)
    const valueHojaHes = Form.useWatch('solicitaHes', form)

    useEffect(() => {
        const fetchData = async () => {
            if (usuario) {
                dispatch(setIsLoading(true))

                Promise.all([getPaises(),
                getRegiones(usuario.paisId),
                getComunas(usuario.regionId),
                getCargos(),
                getAreaCargos()]).then((values) => {
                    setPaises(values[0])
                    setRegiones(values[1])
                    setComunas(values[2])
                    setCargos(values[3])
                    setAreaCargos(values[4])
                }).finally(() =>
                    dispatch(setIsLoading(false))
                );
            }
            else {
                navigate('/perfilEmpresa')
            }
        }
        fetchData()
    }, [usuario])

    const onChangeRegion = async (value) => {
        try {
            form.setFieldValue('comunaId', null)
            const result = await getComunas(value)
            setCiudades(result)
        }
        catch {
        }
    }


    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0].label
        form.setFieldValue('glosaAreaCargo', glosa)
    }

    const onChangePais = async (value) => {
        try {
            const glosa = paises.filter(x => x.value === value)[0].label
            form.setFieldValue('glosaPais', glosa)

            const result = await getRegiones(value)
            setRegiones(result)
            form.setFieldValue('comunaId', null)
            form.setFieldValue('regionId', null)
        }
        catch {

        }
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const oncCloseModal = () => {
        navigate('/perfilEmpresa')
    }

    const onFinish = async (values) => {
        try {
            dispatch(setIsLoading(true))
            values.solicitaHes = values.solicitaHes === 1 ? true : false
            const data = await updateDatosFacturacionEmpresa(values)

            dispatch(setRefrescarNotificaciones(true))
            setDataModal({
                titulo: 'Edición de datos exitosa',
                mensaje: 'Se envió un correo para notificar los cambios realizados',
                fueCorrecto: true
            })
        }
        catch (error) {
            console.log(error)
            setDataModal({
                titulo: 'Error',
                mensaje: '¡Ha ocurrido un error, por favor inténtelo nuevamente!',
                fueCorrecto: false
            })
        }
        finally {
            dispatch(setIsLoading(false))
            setOpenModal(true)
        }
    }

    return (
        <>
            <ModalAvisos
                isModalOpen={isOpenModal}
                onClose={() => oncCloseModal()}
                mensaje={dataModal?.mensaje}
                fueCorrecto={dataModal?.fueCorrecto}
                titulo={dataModal?.titulo}
            ></ModalAvisos>
            {usuario ? <>
                <Form form={form} onFinish={onFinish} className=' formLabelStrongs' layout='vertical'
                    initialValues={{
                        nombre: CapitalizeFirstLetter(usuario.nombre),
                        apellidos: CapitalizeFirstLetter(usuario.apellidos),
                        cargoId: usuario.cargoId,
                        areaCargoId: usuario.areaCargoId,
                        telefono: usuario.telefono,
                        paisId: usuario.paisId,
                        regionId: usuario.regionId,
                        comunaId: usuario.comunaId,
                        direccionFacturacion: usuario.direccionFacturacion,
                        emailFacturacion: usuario.emailFacturacion,
                        solicitaHes: usuario.solicitaHes ? 1 : 0,
                        glosaPais: usuario.pais,
                        glosaAreaCargo: usuario.areaCargo
                    }} >
                    <Form.Item style={{ display: 'none' }} name='glosaPais'><Input /></Form.Item>
                    <Form.Item style={{ display: 'none' }} name='glosaAreaCargo'><Input /></Form.Item>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                            <Title level={3} style={{ fontWeight: 'bold', textAlign: 'left' }}>Datos de facturación</Title>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='nombre'
                                label='Nombres'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese nombre',
                                    }
                                ]}
                            >
                                <Input style={{ padding: 4 }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='apellidos'
                                label='Apellidos'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese apellidos',
                                    }
                                ]}
                            >
                                <Input style={{ padding: 4 }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='telefono'
                                label='Télefono fijo o Celular'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese su télefono fijo o Celular'
                                    }
                                ]}
                            >
                                <Input style={{ padding: 4 }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='paisId'
                                label='País'

                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese el país',
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    onChange={onChangePais}
                                    placeholder="Selecciona un país"
                                    filterOption={filterOption}
                                    options={paises}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='regionId'
                                label='Región'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresar la región',
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    onChange={onChangeRegion}
                                    placeholder="Selecciona una región"
                                    filterOption={filterOption}
                                    options={regiones}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='comunaId'
                                label='Comuna'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Selecciona una comuna',
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Selecciona una comuna"
                                    filterOption={filterOption}
                                    options={comunas}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='direccionFacturacion'
                                label='Dirección'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese dirección',
                                    }
                                ]}
                            >
                                <Input style={{ padding: 4 }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='emailFacturacion'
                                label='Correo electrónico'
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingresa tu correo electrónico",
                                    },
                                    {
                                        type: "email",
                                        message: "Ingresa un email válido",
                                    }
                                ]}
                            >
                                <Input autoComplete="off" style={{ padding: 4 }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='cargoId'
                                label='Cargo'
                            >
                                <Select
                                    showSearch
                                    placeholder='Seleccione un cargo'
                                    optionFilterProp='children'
                                    options={cargos}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='areaCargoId'
                                label='Área del Cargo'
                            >
                                <Select
                                    showSearch
                                    placeholder='Seleccione un area'
                                    optionFilterProp='children'
                                    options={areaCargos}
                                    onChange={onChangeAreaCargo}
                                />
                            </Form.Item>
                        </Col>


                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item style={{ marginBottom: 0 }} name='solicitaHes' label='Hoja de entrada servicios (HES/OC)' rules={[
                                {
                                    required: true,
                                    message: "Selecciona tu entrada",
                                },
                            ]}>
                                <Select
                                    showSearch
                                    placeholder="Selecciona una entrada"
                                    filterOption={filterOption}
                                    options={dataOpcionesHes}
                                />

                            </Form.Item>
                            {valueHojaHes === 1 ?
                                <div className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-amq5gd" role="alert">
                                    <div style={{ color: '#ff4d4f' }} className="ant-form-item-explain-error">Cuando corresponda, envía tu documento HES u OC al siguiente correo sacbursatil@bolsadesantiago.com</div>
                                    <br />
                                </div> : null}
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={11} sm={11} md={8} lg={5}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    className="btncancelar"
                                    danger
                                    disabled={false}
                                    style={{ width: '100%' }}
                                    onClick={() => navigate('/perfilEmpresa')}
                                >
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={13} sm={13} md={8} lg={5}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    disabled={false}
                                    style={{ width: '100%' }}
                                >
                                    Guardar cambios
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </> : null
            }



        </>
    )
}

export default EditarDatosFacturacion