import { useEffect } from 'react'
import Loader from '../../components/Loader/loader'
import { useNavigate } from 'react-router-dom'
import { saveTransaccionUsuario } from '../../services/api'
import { AccionId, PerfilUsuarioId } from '../../enum/enums'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const Puente = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const idProducto = searchParams.get("idProducto")
    const esPruebaGratis = searchParams.get("esPruebaGratis")
    const usuario = useSelector(state => state.aplicacion.usuario)

    useEffect(() => {
        const fetchData = async () => {
            if (usuario) {
                saveTransaccionUsuario(AccionId.LOGIN_USUARIO)

                if (!usuario.finalizoRegistro && usuario.perfilId === PerfilUsuarioId.ADMIN_EMPRESA) {
                    navigate('/completar-cuenta')
                }
                else if (idProducto && esPruebaGratis) {
                    if (esPruebaGratis === 'true')
                        navigate(`/periodo-prueba/${idProducto}`)
                    else
                        navigate(`/contratar-producto/${idProducto}`)
                }
                else {
                    navigate('home')
                }
            }
        }
        fetchData()
    }, [usuario])

    return (
        <Loader isLoadingExt={true}></Loader>
    )
}

export default Puente