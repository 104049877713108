import { Table, Avatar, Button, Input, Space } from 'antd';
import { useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { CapitalizeFirstLetter, getInicial } from '../../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../../services/Helpers/RutHelper';


const TablaAsignacionDesasignacionProducto = ({ onAsignarDesasignarUsuario, usuariosAsignados, usuarios }) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const onClearFiltros = (clearFilters, selectedKeys, confirm, dataIndex) => {
        handleReset(clearFilters)
        handleSearch(selectedKeys, confirm, dataIndex)
    }


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder='buscar...'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => clearFilters && onClearFiltros(clearFilters, selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpiar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cancelar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const columns = [
        {
            title: 'Nombre',
            width: 250,
            dataIndex: 'nombreUsuario',
            key: 'nombreUsuario',
            sorter: (a, b) => a.nombreUsuario.localeCompare(b.nombreUsuario, 'es', { ignorePunctuation: true }),
            ...getColumnSearchProps('nombreUsuario'),
            render: (value) => <>
                <Avatar
                    style={{
                        backgroundColor: '#ff4201',
                        verticalAlign: 'middle',
                        marginRight: 10,

                    }}
                    size="small"
                    shape='circle'
                >
                    {getInicial(value)}
                </Avatar>
                {CapitalizeFirstLetter(value)}
            </>,

        },
        {
            title: 'Rut',
            width: 120,
            dataIndex: 'rutUsuario',
            key: 'rutUsuario',
            ...getColumnSearchProps('rutUsuario'),
            sorter: (a, b) => a.rutUsuario.localeCompare(b.rutUsuario, 'es', { ignorePunctuation: true }),
            render: (value) => <>
                {daFormatoRut(value)}
            </>,

        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys) => {
            onAsignarDesasignarUsuario(selectedRowKeys)
        }
    };

    return (
        <>
            <Table style={{ marginTop: '10px' }} dataSource={usuarios} rowKey='idUsuario'
                rowSelection={{
                    selectedRowKeys: usuariosAsignados,
                    hideSelectAll: true,
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{ pageSize: 5 }}
                locale={{ emptyText: "No hay productos por asignar o remover." }} columns={columns}
            >
            </Table>
        </>)
};

export default TablaAsignacionDesasignacionProducto