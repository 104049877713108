import { Card, Space, Row, Col } from 'antd'
import { useState, useEffect } from 'react'
import { DeleteOutlined, StarOutlined } from '@ant-design/icons';
import ModalDeleteMedioPago from '../modal/ModalDeleteMedioPago';
import { getMediosPago, deleteMediosPago, getTarjetasCliente } from '../../services/medioPagoApi';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/reducers/AppReducer';

const MetodosPago = () => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [refreshData, setRefreshData] = useState(true)
  const [eliminacionCorrecta, setEliminacionCorrecta] = useState(false)
  const [mediosPago, setMediosPago] = useState(null)

  const handleOk = () => {
    setIsModalOpen(false)

    if (eliminacionCorrecta)
      setRefreshData(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)

    if (eliminacionCorrecta)
      setRefreshData(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (refreshData) {
        try {
          dispatch(setIsLoading(true))
          const data = await getTarjetasCliente()

          setMediosPago(data)
        }
        catch (error) {
          console.log(error)
        }
        finally {
          dispatch(setIsLoading(false))
          setRefreshData(false)
        }
      }
    }
    fetchData()
  }, [refreshData])

  const onEliminarMedioPago = async (id) => {
    try {
      dispatch(setIsLoading(true))
      const data = await deleteMediosPago(id)

      setEliminacionCorrecta(data.accionFueCorrecta)
    }
    catch (error) {
      console.log(error)
    }
    finally {
      setIsModalOpen(true)
      dispatch(setIsLoading(false))
    }
  }

  return (
    <div>
      <br />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {(mediosPago === null || mediosPago.length === 0) ?
          <>
            <Col className="gutter-row" xs={24} style={{ marginBottom: '25px' }}>
              <Card>
                    <p>Sin tarjetas registradas</p>
              </Card>
            </Col>
          </>
          :
          <>
            {mediosPago?.map((item, index) => (
              <Col key={index} className="gutter-row" xs={24} sm={24} md={24} lg={12} xxl={8} style={{ marginBottom: '25px' }}>
                <Card style={{ height: 130 }} className='cardAgregarTarjeta'>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, backgroundColor: '#ff4201', borderRadius: 6 }}></div>
                    <div style={{ flex: 3, paddingLeft: 16 }}>
                      <p><strong>Tarjeta {item.tipoTarjeta}</strong></p>
                      <p style={{ lineHeight: 0 }}>****{item.numeroTarjeta}</p>
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <Space direction="horizontal">
                        <span onClick={() => onEliminarMedioPago(item.idTarjeta)} style={{ cursor: 'pointer' }}>
                          <DeleteOutlined style={{ fontSize: '24px' }} />
                        </span>
                      </Space>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </>
        }

      </Row>

      <ModalDeleteMedioPago eliminacionCorrecta={eliminacionCorrecta} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </div>

  )
}

export default MetodosPago