import logoApi from '../../../assets/images/logos/logo_api2.png'
import logoDashboards from '../../../assets/images/logos/logo_dashboards.svg'
import logoInnovationLab from '../../../assets/images/logos/logo_innovation_lab.svg'
import { TipoProductoId } from '../../../enum/enums'

const LogoProducto = ({ idTipoProducto }) => {
    return (
        <>
            {idTipoProducto === TipoProductoId.PACK_APIS ?
                <img
                    alt="api"
                    src={logoApi}
                    style={{ height: 90 }}
                />
                : idTipoProducto === TipoProductoId.PACK_REPORTES || idTipoProducto === TipoProductoId.REPORTE ?
                    <img
                        alt="Dashboard"
                        style={{ marginTop: 30, marginBottom: 0, height: 100 }}
                        src={logoDashboards}
                    /> : idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                        <img
                            alt="innovationLab"
                            style={{ marginTop: 30, marginBottom: 0, height: 100 }}
                            src={logoInnovationLab}
                        />
                        : null}
        </>
    )
}

export default LogoProducto