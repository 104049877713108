import { InfoCircleFilled } from '@ant-design/icons'
import { Avatar, Button, Card, Checkbox, Modal, Form } from 'antd'
import { iniciarInscripcionTarjeta } from '../../services/medioPagoApi'
import visa from '../../assets/images/visa.png';
import { useState } from 'react';
import ModalSuccessMedioPago from './ModalSuccessMedioPago';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/reducers/AppReducer';
import { redirectPostOnePay } from '../Transbank/FormRedirect';

const ModalNuevoMedioPago = ({ isOpen, handleOk, handleCancel }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const onFinish = async (values) => {
        try {
            dispatch(setIsLoading(true))
            const data = await iniciarInscripcionTarjeta()

            if (data) {
                redirectPostOnePay(data.token, data.url)
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            dispatch(setIsLoading(false))
        }
    };

    return (
        <Modal
            title="Agregar nuevo medio de pago"
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={550}
            centered
            footer={[

            ]}
        >
            <Form form={form} onFinish={onFinish} className="form" style={{ width: '100%' }}>
                <div>
                    <p>Al ingresar tu tarjeta <b>aceptas que Webpay guarde tu información para realizar cobros.</b></p>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Card style={{ marginBottom: 16, width: 500 }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 'auto', display: 'flex', alignItems: 'center' }}>
                                <InfoCircleFilled style={{ fontSize: '24px', color: 'orange' }} />
                            </div>
                            <div style={{ flex: 14 }}>
                                <p style={{ fontWeight: 400 }}>
                                    Podrás seguir con el pago una vez se haga un{' '}
                                    <b>cobro de $50, los cuales posteriormente se devolverán a tu tarjeta.</b>
                                    Recuerda solo utilizar medios de pago emitidos en Chile.</p>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                    <Form.Item normalize={(value) => value ? value : undefined} name='terminos' valuePropName="checked" rules={[
                        {
                            required: true,
                            message: "Debes aceptar los términos y condiciones.",
                        },
                    ]}>
                        <Checkbox style={{ fontWeight: 400 }}>Acepto que se guarde los datos de la tarjeta utilizada</Checkbox>
                    </Form.Item>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Card style={{ marginBottom: 16, width: 500 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar shape="square" src={visa} size={64} />
                            <div style={{ flex: 1, marginLeft: 16 }}>
                                <p style={{ margin: 0, marginLeft: 8, display: 'inline-block' }}>
                                    <strong>Tarjeta de crédito</strong>
                                </p>
                            </div>
                            <Button htmlType="submit" type="link">Agregar tarjeta</Button>
                        </div>
                    </Card>
                </div>
            </Form>
        </Modal>
    )
}

export default ModalNuevoMedioPago