import { CloseCircleOutlined } from '@ant-design/icons'
import { getUsuariosEmpresa } from '../../../services/empresaApi'
import { Form, Button, Modal, Select, Row, Col } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsLoading } from '../../../redux/reducers/AppReducer'
import TablaAsignacionDesasignacionProducto from '../Tablas/TablaAsignacionDesasignacionProducto'

const ModalAsignarUsuarioProducto = ({ onGuardarAsignacionUsuarios, usuariosAsignados, isModalOpen, setShowModal }) => {
    const dispatch = useDispatch()
    const [usuarios, setUsuarios] = useState(null)
    const [usuariosYaAsignados, setUsuariosYaAsignados] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            if (isModalOpen && usuarios === null) {
                try {
                    dispatch(setIsLoading(true))
                    const data = await getUsuariosEmpresa()
                    setUsuarios(data?.filter(x => x.estado === 'Activado'))
                    setUsuariosYaAsignados(usuariosAsignados?.map(x => x.idUsuario))
                }
                catch (ex) {
                    console.log(ex)
                }
                finally {
                    dispatch(setIsLoading(false))
                }
            }
            else {
                setUsuariosYaAsignados(usuariosAsignados?.map(x => x.idUsuario))
            }
        }
        fetchData()
    }, [isModalOpen])

    const onAsignarDesasignarUsuario = (ids) => {
        setUsuariosYaAsignados(ids)
    }

    const onGuardarAsignacion = () => {
        setShowModal(false)
        onGuardarAsignacionUsuarios(usuariosYaAsignados)
    }

    return (
        <div>
            <Modal
                centered
                open={isModalOpen}
                onOk={() => onGuardarAsignacion()}
                onCancel={() => setShowModal(false)}
                width={500}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <h3>Asignar / remover usuarios</h3>
                    {usuarios ? <TablaAsignacionDesasignacionProducto onAsignarDesasignarUsuario={onAsignarDesasignarUsuario} usuariosAsignados={usuariosYaAsignados} usuarios={usuarios}></TablaAsignacionDesasignacionProducto> : null}
                </div>
            </Modal>
        </div>
    )
}

export default ModalAsignarUsuarioProducto