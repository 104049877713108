import React from 'react'
import { Avatar, Button, Card, Checkbox, Typography, Row, Col, Form } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import visa from '../../assets/images/visa.png';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/reducers/AppReducer';
import { iniciarInscripcionTarjeta } from '../../services/medioPagoApi';
import { redirectPostOnePay } from '../Transbank/FormRedirect';

const { Title, Text } = Typography
const AgregarMetodoPago = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const onFinish = async (values) => {
        try {
            dispatch(setIsLoading(true))
            const data = await iniciarInscripcionTarjeta()

            if (data) {
                redirectPostOnePay(data.token, data.url)
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            dispatch(setIsLoading(false))
        }
    };

    return (
        <Form form={form} onFinish={onFinish} className="form" style={{ width: '100%' }}>
            <Row style={{ textAlign: 'left' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xxl={24} style={{ marginBottom: '10px' }}>
                    <Title level={4} >Agregar medio de pago</Title>
                </Col>
            </Row>
            <Row style={{ textAlign: 'left' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xxl={24} style={{ marginBottom: '10px' }}>
                    <Text>Al ingresar tu tarjeta, <Text strong>aceptas que Webpay guarde tu información para realizar cobros.</Text></Text>
                </Col>
            </Row>
            <Row style={{ textAlign: 'left' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xxl={12} style={{ marginBottom: '0px' }}>
                    <Card style={{ marginBottom: 16 }} className='cardAgregarTarjeta'>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 'auto', display: 'flex', alignItems: 'center' }}>
                                <InfoCircleOutlined style={{ fontSize: '24px', color: 'orange' }} />
                            </div>
                            <div style={{ flex: 14 }}>
                                <p>
                                    Podrás seguir con el pago una vez se haga un{' '}
                                    <strong>cobro de $50, los cuales posteriormente se devolverán a tu tarjeta.</strong>
                                    Recuerda solo utilizar medios de pago emitidos en Chile.</p>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row style={{ textAlign: 'left' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xxl={24} style={{ marginBottom: '0px' }}>
                    <Form.Item name='terminos' normalize={(value) => value ? value : undefined} valuePropName="checked" rules={[
                        {
                            required: true,
                            message: "Debes aceptar los términos y condiciones.",
                        },
                    ]}>
                        <Checkbox>Acepto que se guarde los datos de la tarjeta utilizada</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ textAlign: 'left' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xxl={12} style={{ marginBottom: '25px' }}>
                    <Card style={{ marginBottom: 16 }} className='cardAgregarTarjeta'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar shape="square" src={visa} size={64} />
                            <div style={{ flex: 1, marginLeft: 16 }}>
                                <p style={{ margin: 0, marginLeft: 8, display: 'inline-block' }}>
                                    <strong>Tarjeta de crédito/débito</strong>
                                </p>
                            </div>
                            <Button htmlType="submit" type="link">Agregar tarjeta</Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Form>
    )
}

export default AgregarMetodoPago