import React, { useEffect, useState } from 'react'
import { Avatar, Card, Col, Row, Spin, Typography, Button } from 'antd'
import { useSelector } from 'react-redux'
import { CapitalizeFirstLetter } from '../../services/Helpers/stringHelper'
import { useLocation } from 'react-router'
import { consultaEstadoPago } from '../../services/suscripcionApi'
import { MedioPagoId } from '../../enum/enums'
import { Content } from 'antd/es/layout/layout'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import './Khipu.styles.scss';
const { Text, Title } = Typography

const Resultado = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [pagoSuscripcion, setPagoSuscripcion] = useState(null)
  const location = useLocation()

  useEffect(() => {

    const fetchData = async (idTransaccionKhipu) => {
      setIsLoading(true)
      // se consulta estado hasta obtener el pago
      const intervalId = setInterval(async () => {

        try {
          const res = await consultaEstadoPago(MedioPagoId.KIPHU, idTransaccionKhipu)
          if (res && res.accionFueCorrecta && res.data.idEstadoPago === 2) {//aprobado
            setPagoSuscripcion(res.data)
            setIsLoading(false)
            clearInterval(intervalId)
          }
        } catch (error) {
          console.log(error)
          setIsLoading(false)
          clearInterval(intervalId)
        }
      }, 1000)

    }

    const params = new URLSearchParams(location.search);
    const idTransaccion = params.get('Id');
    if (idTransaccion) {
      fetchData(idTransaccion)
    }


  }, [location])

  return (
    <Content>
      <Row justify="center" >
        <Col md={12} xs={24} >
          <Card className="card-container">


            <Spin tip="Estamos validando tu pago" size="large" spinning={isLoading}>
              <div  >
                <Avatar style={{ backgroundColor: '#2EEBFF' }} size={80} icon={<CheckCircleOutlined />} />

                {pagoSuscripcion && (
                  <>
                    <Title level={3}>
                      Pago procesado correctamente
                    </Title>
                    <Text>
                      <p><strong> Monto:</strong> {pagoSuscripcion.monto}</p>
                      <p><strong>Estado del pago:</strong> {pagoSuscripcion.nombreEstadoPago}</p>
                      <p><strong>Producto contratado:</strong> {pagoSuscripcion.nombreProductoContratado}</p>
                    </Text >

                    {pagoSuscripcion.idEstadoPago === 2 ?
                      <Button onClick={() => navigate(`/detalle-producto/${pagoSuscripcion.idProducto}/${pagoSuscripcion.idSuscripcion}`)} shape="round" type="primary">Ir al producto</Button> : null}

                  </>)
                }

              </div>
            </Spin>



          </Card>
        </Col>
      </Row>

    </Content>

  )
}

export default Resultado