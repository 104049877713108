import { getProductosAsignablesUsuario } from '../../../services/api'
import { Modal, Button } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsLoading } from '../../../redux/reducers/AppReducer'
import TablaAsignacionDesasignacionUsuarios from '../Tablas/TablaAsignacionDesasignacionUsuarios'

const ModalAsignarProductosUsuario = ({ onGuardarAsignacionProductos, productosAsignados, isModalOpen, setShowModal }) => {
    const dispatch = useDispatch()
    const [productos, setProductos] = useState(null)
    const [productosYaAsignados, setProductosYaAsignados] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            if (isModalOpen && productos === null) {
                try {
                    dispatch(setIsLoading(true))
                    const data = await getProductosAsignablesUsuario()
                    setProductos(data)
                    setProductosYaAsignados(productosAsignados?.map(x => { return { idProducto: x.idProducto, idSuscripcion: x.idSuscripcion, idDetalleSuscripcionProductoUsuario: x.idDetalleSuscripcionProductoUsuario } }))
                }
                catch (ex) {
                    console.log(ex)
                }
                finally {
                    dispatch(setIsLoading(false))
                }
            }
            else {
                setProductosYaAsignados(productosAsignados?.map(x => { return { idProducto: x.idProducto, idSuscripcion: x.idSuscripcion, idDetalleSuscripcionProductoUsuario: x.idDetalleSuscripcionProductoUsuario } }))
            }
        }
        fetchData()
    }, [isModalOpen])

    const onAsignarDesasignarProductos = (ids) => {
        ids?.map(y => {
            y.idDetalleSuscripcionProductoUsuario = productosAsignados.filter(x => x.idProducto === y.idProducto && x.idSuscripcion === y.idSuscripcion)[0]?.idDetalleSuscripcionProductoUsuario
        })

        setProductosYaAsignados(ids)
    }

    const onGuardarAsignacion = () => {
        setShowModal(false)
        onGuardarAsignacionProductos(productosYaAsignados)
    }


    return (
        <div>
            <Modal
                centered
                open={isModalOpen}
                onOk={() => onGuardarAsignacion()}
                onCancel={() => setShowModal(false)}
                width={700}
                footer={[
                    <Button className="btncancelar" type="primary" danger key="back" shape='round' onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" shape='round' onClick={() => onGuardarAsignacion()}>
                        Aceptar
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <h3>Asignar / remover productos</h3>

                    {productos ? <TablaAsignacionDesasignacionUsuarios
                        onAsignarDesasignarProductos={onAsignarDesasignarProductos}
                        productosAsignados={productosYaAsignados}
                        productos={productos}></TablaAsignacionDesasignacionUsuarios> : null}

                </div>
            </Modal>
        </div>
    )
}

export default ModalAsignarProductosUsuario