import { CheckOutlined } from '@ant-design/icons'
import { SeparadorMiles } from '../../services/Helpers/stringHelper'
import './ProductoServicio.style.scss'

const DescripcionPlanApi = ({ requesAlMes, mercadoProducto, nombrePlan, agnoInfoHistorica, tiempoDelay }) => {
    return (
        <div>
            {requesAlMes ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}> {SeparadorMiles(requesAlMes)} Requests / {nombrePlan === 'Básico' ? 'día' : 'mes'}</span></p> : null}
            {mercadoProducto ? <p className='descripcion' style={{ textAlign: 'left' }}><CheckOutlined className="letraColorCliente" style={{ marginRight: 2 }} /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}>{mercadoProducto} </span></p> : null}
            {nombrePlan === 'Básico' ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}> Data End of day </span></p> :
                <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" style={{ marginRight: 2 }} /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}>Delay de {tiempoDelay} minutos </span></p>}

            {nombrePlan === 'Básico' ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" />  <span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}>10 instrumentos aleatorios  </span></p> :
                <p className='descripcion' style={{ textAlign: 'left' }}><CheckOutlined className="letraColorCliente" /> <span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}> Puedes seleccionar cualquier instrumento</span></p>}

            <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}> Historicidad de {agnoInfoHistorica} {agnoInfoHistorica > 1 ? 'años' : 'año'}</span></p>
        </div>
    )
}

export default DescripcionPlanApi
