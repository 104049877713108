import { Button, Modal, Form, Input, Row, Col, Divider, Tooltip } from 'antd'
import ModalNuevoMedioPago from './ModalNuevoMedioPago';
import { useState, useEffect } from 'react';
import { setIsLoading } from '../../redux/reducers/AppReducer';
import { useDispatch } from 'react-redux';
import SeleccionMetodoPago from '../metodos/SeleccionMetodoPago';
import { getValorUF } from '../../services/api';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { SeparadorMiles } from '../../services/Helpers/stringHelper';
import './Modal.styles.scss'

const ModalMedioPago = ({ producto, isModalOpen, handleOk, handleCancel }) => {
  const [isNuevoMedioPagoModalOpen, setNuevoMedioPagoModalOpen] = useState(false)
  const [valorUF, setValorUF] = useState(null)
  const [pasoActual, setPasoActual] = useState(1)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onCerrarModal = () => {
    setPasoActual(1)
    handleCancel()
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setIsLoading(true))
        const valor = await getValorUF()
        setValorUF(valor)
      }
      catch (error) {
        console.log(error)
      }
      finally {
        dispatch(setIsLoading(false))
      }
    }

    if (!valorUF && isModalOpen)
      fetchData()
  }, [isModalOpen, valorUF])


  const handleAgregarOtroMedioPago = () => {
    setNuevoMedioPagoModalOpen(true);
    handleCancel();
  };

  const onSelectMedioPago = (idMedioPago, idtarjeta) => {
    form.setFieldValue('idMedioPago', parseInt(idMedioPago),)
  }

  const onFinish = async (values) => {
    handleOk(values.idMedioPago)
  }

  return (
    <div>
      <Modal
        className="modalMedioPago"
        title={`${pasoActual === 1 ? "Detalle de tu pago" : "Selecciona el medio de pago a utilizar"}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={onCerrarModal}
        width={480}
        centered
        footer={[
          pasoActual === 1 ?
            <>
              <Button key="back" shape='round' onClick={onCerrarModal}>
                Cancelar
              </Button>
              <Button type="primary" shape='round' onClick={() => setPasoActual(2)}>
                Siguiente
              </Button>
            </>
            :
            <>
              <Button key="back" shape='round' onClick={onCerrarModal}>
                Cancelar
              </Button>
              <Button key="submit" type="primary" shape='round' onClick={() => form.submit()}>
                Ir a pagar
              </Button>
            </>
        ]}
      >
        {pasoActual === 1 ? <>
          <Row gutter={0}>
            <Col span={24}>
              <br />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ textAlign: 'left', margin: 0 }}>{producto?.nombreProducto}</p>
                  <p style={{ textAlign: 'right', margin: 0 }}></p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ textAlign: 'left', margin: 0 }}>Suscripción "{producto?.tipoPlan?.nombreTipoPlan}"</p>
                  <p style={{ textAlign: 'right', margin: 0 }}>{producto?.precioProductoContratado} UF</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Tooltip placement="top" title="Valor obtenido de la Comisión para el Mercado Financiero (CMF)."><p style={{ textAlign: 'left', margin: 0, fontWeight: '400' }}>Valor actual UF <QuestionCircleOutlined style={{ fontSize: 12 }} /></p></Tooltip>
                  <p style={{ textAlign: 'right', margin: 0, fontWeight: '400' }}>${SeparadorMiles(valorUF)}</p>
                </div>
              </div>
              <Divider className='dividerTotal' />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ textAlign: 'left', margin: 0, fontWeight: 'bold' }}>Total a pagar</p>
                  <p style={{ textAlign: 'right', margin: 0, fontWeight: 'bold' }}>${SeparadorMiles(Math.round(producto?.precioProductoContratado * valorUF))} </p>
                </div>
              </div>
              {/* <div style={{ textAlign: 'left' }}>
                <p style={{ marginBottom: 0, marginTop: 5, fontSize: 14 }}><strong>Documento a emitir:</strong> Boleta</p>
              </div> */}
            </Col>
          </Row>
        </> : <>

          <Form form={form} onFinish={onFinish}>
            <br />
            {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
            <Button type='primary' shape='round' onClick={handleAgregarOtroMedioPago}>Agregar otro medio de pago</Button>
          </div> */}


            <SeleccionMetodoPago onSelectMedioPago={onSelectMedioPago}></SeleccionMetodoPago>
            <Form.Item style={{ marginBottom: 30, textAlign: 'left' }} className="hiddenInput" name='idMedioPago' rules={[
              {
                required: true,
                message: "Selecciona un medio de pago",
              },
            ]}>
              <Input />
            </Form.Item>
          </Form>
        </>}
      </Modal >
      <ModalNuevoMedioPago isOpen={isNuevoMedioPagoModalOpen} handleCancel={() => setNuevoMedioPagoModalOpen(false)} />
    </div >
  );
}

export default ModalMedioPago
