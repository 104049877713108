import { useState } from "react";
import { CheckOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Flex, Button, Col, Card, Row, Typography, Form } from "antd";
import { SeparadorMiles } from "../../../../services/Helpers/stringHelper";
import PlanProducto from '../../../productoServicio/planes/PlanProducto'
import DescripcionOtroTipoProducto from "../../../productoServicio/planes/DescripcionOtroTipo";
import { setIsLoading } from "../../../../redux/reducers/AppReducer";
import { useDispatch } from "react-redux";
import { updateEstadoSolicitud } from "../../../../services/empresaApi";
import ModalAvisos from "../../../modal/ModalAvisos";
import { useNavigate } from "react-router";
import { EstadoSolicitudId, TipoProductoId } from "../../../../enum/enums";
import DescripcionPlanApi from "../../../productoServicio/DescripcionPlanApi";
import DescripcionPlanDashboard from "../../../productoServicio/planes/DescripcionPlanDashboard";
import DescripcionPlanInnovationLabs from "../../../productoServicio/DescripcionPlanInnovationLabs";

const { Title } = Typography;
const dayjs = require('dayjs')

const ModificacionProducto = ({ solicitud }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dataModal, setDataModal] = useState(null)

    const onAdministrarSolicitud = async (apuebaSolicitud) => {
        try {
            const mensaje = apuebaSolicitud ? 'Solicitud aprobada correctamente, será redirigido para modificar suscripción de producto solicitado.' : 'Solicitud Rechazada correctamente.'
            dispatch(setIsLoading(true))
            const data = await updateEstadoSolicitud({
                idSolicitud: solicitud.idSolicitud,
                apruebaSolicitud: apuebaSolicitud
            })

            setDataModal({
                fueCorrecto: data.accionFueCorrecta,
                titulo: data.accionFueCorrecta ? 'Éxito' : 'Error',
                mensaje: data.accionFueCorrecta ? mensaje : 'ha ocurrido un error inténtelo nuevamente.',
                apuebaSolicitud: apuebaSolicitud
            })
        }
        catch (error) {
            console.log(error)
            setDataModal({
                fueCorrecto: false,
                titulo: "Error al administrar solicitud.",
                mensaje: 'ha ocurrido un error inténtelo nuevamente.',
                apuebaSolicitud: apuebaSolicitud
            })
        }
        finally {
            dispatch(setIsLoading(false))
            setIsModalOpen(true)
        }
    }

    const onCloseModal = () => {
        if (dataModal.apuebaSolicitud && dataModal.fueCorrecto) {
            navigate(`/detalle-producto/${solicitud.productoContratado.productoId}/${solicitud.idSuscripcion}`)
        }
        else if (dataModal.fueCorrecto) {
            navigate('/solicitudes')
        }
        else {
            setIsModalOpen(false)
        }
    }

    return (
        <>
            <ModalAvisos
                isModalOpen={isModalOpen}
                onClose={() => onCloseModal()}
                mensaje={dataModal?.mensaje}
                titulo={dataModal?.titulo}
                fueCorrecto={dataModal?.fueCorrecto}></ModalAvisos>
            <Form form={form} className="form" layout='inline' style={{ width: '100%' }}>
                <Col span={24} style={{ textAlign: 'left' }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>Solicitud de modificación</p>
                    <p style={{ textAlign: 'justify', fontWeight: 'bold', marginBottom: 0 }}>{solicitud.productoContratado?.nombre}</p>
                    <p style={{ textAlign: 'justify', color: '#838383', fontWeight: '400', marginTop: 0 }}>{solicitud.productoContratado?.descripcion}</p>

                    {solicitud.idEstadoSolicitud !== EstadoSolicitudId.ESPERA_APROBACION ? <>
                        <p style={{ textAlign: 'justify', fontWeight: 'bold', marginBottom: 0, paddingBottom: 0 }}>Fecha de {solicitud.idEstadoSolicitud === EstadoSolicitudId.APROBADA ? 'aprobación' : 'rechazo'}: <span></span></p>
                        <p style={{ textAlign: 'justify', color: '#838383', fontWeight: '400', marginTop: 0, paddingTop: 0 }}>{dayjs(solicitud.fechaActualizacion).format('DD/MM/YYYY')}</p>
                    </> : null}

                    <p style={{ textAlign: 'justify', fontWeight: 'bold', marginBottom: 0, paddingBottom: 0 }}>Fecha de compra:</p>
                    <p style={{ textAlign: 'justify', color: '#838383', fontWeight: '400', marginTop: 0, paddingTop: 0 }}>{dayjs(solicitud.productoContratado?.FechaDesde).format('DD/MM/YYYY')}</p>
                    <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>Tipo de suscripción</p>
                </Col>
                <Col xs={22} sm={18} md={16} lg={9} xl={12} xxl={7} style={{ textAlign: 'left' }}>
                    <PlanProducto ajustarAlto={true} idTipoProducto={solicitud?.idTipoProducto} planProducto={solicitud.productoContratado?.tipoPlan} justificarInicio={true}></PlanProducto>
                    {" "}
                    <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}>UF {SeparadorMiles(solicitud.productoContratado?.valor)}/mes</Title>
                    {solicitud?.idTipoProducto === TipoProductoId.PACK_APIS ?
                        <DescripcionPlanApi
                            requesAlMes={solicitud?.productoContratado?.tipoPlan?.requesAlMes}
                            mercadoProducto={solicitud?.mercado}
                            nombrePlan={solicitud?.productoContratado?.tipoPlan?.nombreTipoPlan}
                            agnoInfoHistorica={solicitud?.productoContratado?.tipoPlan?.agnoInfoHistorica}
                            tiempoDelay={solicitud?.productoContratado?.tipoPlan?.tiempoDelay}
                        >
                        </DescripcionPlanApi> : solicitud?.idTipoProducto === TipoProductoId.PACK_REPORTES ?
                            <DescripcionPlanDashboard texto={solicitud?.productoContratado?.tipoPlan.descripcionDashboard}></DescripcionPlanDashboard> :
                            solicitud?.idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                <DescripcionPlanInnovationLabs descripcion={solicitud?.productoContratado?.descripcionPlanDashboard} usuariosSugeridosInnovationLab={solicitud?.productoContratado?.usuariosSugeridosInnovationLab} cantidadNucleos={solicitud?.productoContratado?.tipoPlan.cantidadNucleos} memoriaRam={solicitud?.productoContratado?.tipoPlan.memoriaRam}></DescripcionPlanInnovationLabs> :
                                <DescripcionOtroTipoProducto plan={solicitud?.productoContratado?.tipoPlan}></DescripcionOtroTipoProducto>}
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <br /><br /><br /><br /><br />
                    {solicitud?.idTipoProducto === TipoProductoId.PACK_APIS ?
                        <>
                            <p style={{ marginTop: 30 }}><ArrowRightOutlined /></p>
                            <p><ArrowRightOutlined /></p>
                            <p><ArrowRightOutlined /></p>
                            <p><ArrowRightOutlined /></p>
                            <p><ArrowRightOutlined /></p>
                        </> : solicitud?.idTipoProducto === TipoProductoId.PACK_REPORTES ? <>
                            <p><ArrowRightOutlined /></p>
                            <p><ArrowRightOutlined /></p></> :
                            <><p><ArrowRightOutlined /></p>
                                <p><ArrowRightOutlined /></p></>}

                </Col>


                <Col xs={22} sm={18} md={16} lg={9} xl={11} xxl={7} style={{ textAlign: 'left' }}>
                    <PlanProducto ajustarAlto={true} idTipoProducto={solicitud?.idTipoProducto} planProducto={solicitud.productoAContratar?.tipoPlan} justificarInicio={true}></PlanProducto>
                    {" "}
                    <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}>UF {SeparadorMiles(solicitud.productoAContratar?.valor)} /mes</Title>
                    {solicitud?.idTipoProducto === TipoProductoId.PACK_APIS ?
                        <DescripcionPlanApi
                            requesAlMes={solicitud?.productoAContratar?.tipoPlan?.requesAlMes}
                            mercadoProducto={solicitud?.mercado}
                            nombrePlan={solicitud?.productoAContratar?.tipoPlan?.nombreTipoPlan}
                            agnoInfoHistorica={solicitud?.productoAContratar?.tipoPlan?.agnoInfoHistorica}
                            tiempoDelay={solicitud?.productoAContratar?.tipoPlan?.tiempoDelay}
                        >
                        </DescripcionPlanApi> : solicitud?.idTipoProducto === TipoProductoId.PACK_REPORTES ?
                            <DescripcionPlanDashboard texto={solicitud?.productoAContratar?.tipoPlan.descripcionDashboard}></DescripcionPlanDashboard> :
                            solicitud?.idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                <DescripcionPlanInnovationLabs descripcion={solicitud?.productoAContratar?.descripcionPlanDashboard} usuariosSugeridosInnovationLab={solicitud?.productoAContratar?.tipoPlan.usuariosSugeridosInnovationLab} cantidadNucleos={solicitud?.productoAContratar?.tipoPlan.cantidadNucleos} memoriaRam={solicitud?.productoAContratar?.tipoPlan.memoriaRam}></DescripcionPlanInnovationLabs> :
                                <DescripcionOtroTipoProducto plan={solicitud?.productoAContratar?.tipoPlan}></DescripcionOtroTipoProducto>}
                </Col>
                {solicitud.idEstadoSolicitud === EstadoSolicitudId.ESPERA_APROBACION ?
                    <Col span={24}>
                        <Flex justify='flex-end' >
                            <Button danger onClick={() => onAdministrarSolicitud(false)} style={{ marginRight: 15 }} shape="round" type="primary">Rechazar solicitud</Button>
                            <Button onClick={() => onAdministrarSolicitud(true)} type="primary" shape="round">Aceptar solicitud</Button>
                        </Flex>
                    </Col> : null}
            </Form>
        </>
    )
}

export default ModificacionProducto