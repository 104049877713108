import { useEffect } from 'react';
import { Button, Col, Form, Input, Row, Space, Breadcrumb } from 'antd'
import { useDispatch, useSelector } from "react-redux"
import { setTipoDespliegueProductos } from '../../redux/reducers/ProductoReducer';
import { setProductosFiltrados, setProductosContratablesFiltrados } from '../../redux/reducers/ProductoReducer';
import { useLocation } from 'react-router-dom';
import { setUsuariosFiltrados } from '../../redux/reducers/AppReducer';
import { cleanRut } from '../../services/Helpers/RutHelper';
import { setDesplegarUsuariosTabla } from '../../redux/reducers/AppReducer';
import { itemRenderBreadcump } from '../../services/Helpers/stringHelper';
import { useNavigate } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import './FormBusqueda.scss'

const FormBusqueda = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const desplegarEnCard = useSelector(state => state.producto.desplegarProductosEnCard)
    const productos = useSelector(state => state.producto.productosOriginales)
    const productosContratables = useSelector(state => state.producto.productosContratablesOriginales)

    const usuarios = useSelector(state => state.aplicacion.usuarios)
    const desplegarEnTabla = useSelector(state => state.aplicacion.desplegarUsuariosEnTabla)

    useEffect(() => {
        form.resetFields()
    }, [location.pathname])

    const onFinish = async (values) => {
        try {
            if (location.pathname === '/Usuarios') {
                onBuscarUsuario(values)
            }
            else {
                onBuscarProducto(values)
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    const getTitleBreadcump = () => {
        if (location.pathname === '/productosEmpresa') {
            return 'Inicio / Productos de la empresa'

        }
        if (location.pathname === '/producto-servicio') {
            return 'Inicio / Catálogo de productos'

        }
        else if (location.pathname === '/Usuarios') {
            return 'Inicio / Usuarios y permisos'
        }
        else {
            return 'Inicio / Mis Productos'
        }
    }

    const onBuscarProducto = (values) => {
        if (location.pathname === '/producto-servicio') {
            if (values.nombre) {
                const filtrado = productosContratables?.filter(x => x.NombreProducto.toLowerCase().includes(values.nombre.toLowerCase()))
                dispatch(setProductosContratablesFiltrados(filtrado))
            }
            else {
                dispatch(setProductosContratablesFiltrados(productosContratables))
            }
        }
        else {
            if (values.nombre) {
                const filtrado = productos?.filter(x => x.NombreProducto.toLowerCase().includes(values.nombre.toLowerCase()))
                dispatch(setProductosFiltrados(filtrado))
            }
            else {
                dispatch(setProductosFiltrados(productos))
            }
        }
    }

    const onBuscarUsuario = (values) => {
        if (values.nombre) {
            const filtroRut = cleanRut(values.nombre)
            const value = values.nombre.toLowerCase()
            const filtrado = usuarios?.filter(x => x.nombreUsuario.toLowerCase().includes(value) || x.rutUsuario.toUpperCase() === filtroRut)
            
           
            dispatch(setUsuariosFiltrados(filtrado))
        }
        else {
            dispatch(setUsuariosFiltrados(usuarios))
        }
    }

    const onchangeValue = (e) => {
        if (e.target.value === '') {
            if (location.pathname === '/producto-servicio') {
                dispatch(setProductosContratablesFiltrados(productosContratables))
            }
            else if (location.pathname === '/productos') {
                dispatch(setProductosFiltrados(productos))
            }
            else {
                dispatch(setUsuariosFiltrados(usuarios))
            }
        }
    }


    return (
        <>
            <Form form={form} onFinish={onFinish} className="form" layout='inline'>
                <Row gutter={0} style={{ width: '100%' }}>
                    <Col className="gutter-row" xs={18} sm={20} md={20} lg={6} xl={9} xxl={6}>
                        <Form.Item
                            name='nombre'
                            className="username"
                            style={{ marginRight: 5 }}

                        >
                            <Input
                                allowClear
                                onChange={(e) => onchangeValue(e)}
                                className='inputBusqueda' placeholder={location.pathname === '/Usuarios' ? 'Ingresa el nombre de algún usuario' : 'Ingresa el nombre de algún producto aquí'} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={6} sm={4} md={4} lg={2} xl={2} xxl={2} style={{ paddingLeft: '0px', textAlign: 'left' }}>
                        <Form.Item>
                            <Button type="primary" danger shape="round" htmlType="submit">
                                Buscar {location.pathname === '/Usuarios' && !isTabletOrMobile ? 'Usuario' : ''}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row justify="space-between" style={{ marginBottom: 5, marginTop: 15 }}>
                <div className='breadCumpBuscador' >
                    <Breadcrumb
                        className='breadCumpForm'
                        itemRender={itemRenderBreadcump}
                        items={[
                            {
                                title: getTitleBreadcump()
                            },
                        ]} />
                </div>

                {location.pathname !== '/producto-servicio' && (
                    <>
                        {location.pathname === '/Usuarios' ?
                            <Space className="divBtnsBuscar" style={{ marginRight: '3%' }}>
                                <Button onClick={() => dispatch(setDesplegarUsuariosTabla(!desplegarEnTabla))} danger type="primary" shape="round">{desplegarEnTabla ? 'Ver en cuadrícula' : 'Ver en lista'}</Button>
                                <Button onClick={() => navigate('/crear-usuario')} danger type="primary" shape="round">Agregar usuario</Button>
                            </Space>
                            :
                            <Space className="divBtnsBuscar" style={{ marginRight: '3%' }}>
                                <Button className="divBtnsBuscar" tyle={{ marginTop: 8 }} onClick={() => dispatch(setTipoDespliegueProductos(!desplegarEnCard))} danger type="primary" shape="round">{desplegarEnCard ? 'Ver en lista' : 'Ver en cuadrícula'}</Button>
                            </Space>
                        }
                    </>)}
            </Row>
        </>
    )
}

export default FormBusqueda