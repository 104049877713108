export const redirectPostTransbank = (token, url) => {
    var mapForm = document.createElement('form');
    mapForm.method = 'post';
    mapForm.action = url;

    var mapInput = document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = 'token_ws';
    mapInput.setAttribute('value', token);
    mapForm.appendChild(mapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();
}

export const redirectPostOnePay = (token, url) => {
    var mapForm = document.createElement('form');
    mapForm.method = 'post';
    mapForm.action = url;

    var mapInput = document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = 'TBK_TOKEN';
    mapInput.setAttribute('value', token);
    mapForm.appendChild(mapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();
}