import PropTypes from 'prop-types'
import { Col, Row, Typography, Image } from 'antd'
import { ApiOutlined, CrownOutlined, RocketOutlined, AimOutlined } from '@ant-design/icons'
import React from 'react'
import '../../../assets/styles/CardContent.styles.scss'
import logoDashboard from '../../../assets/images/logos/dahboardPago.svg'
import logoDashboardEmpresa from '../../../assets/images/logos/dahboardPago-empresa.svg'
import { TipoProductoId, PerfilUsuarioId } from "../../../enum/enums";
import { useSelector } from 'react-redux'
const { Title } = Typography;
const typeDefs = require('../../../interface/typeDefs')

/**
 * 
 * @param {{planProducto:typeDefs.TipoPlanDto}} planProducto - Plan del producto contratado por usuario
 * @param {{justificarInicio:boolean}} justificarInicio - Boleano para aplicar o no justificacion
 * @returns {React.ElementType}
 */
const PlanProductoEnCard = ({ idTipoProducto, planProducto, ajustarAlto, precio, sacarMargen, ajustarAltoInnovationLab, maxHeigthTitulo }) => {
    const perfilId = useSelector(state => state.aplicacion.usuario.perfilId)

    return (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xxl: 50 }} style={{ textAlign: 'start' }}>
            <Col span={24} className={`${sacarMargen ? 'eliminarBordeDerecho' : ''} `}>
                {idTipoProducto === TipoProductoId.PACK_APIS && (<>
                    {planProducto?.nombreTipoPlan === 'Básico' ?
                        <>
                            <Row gutter={30}>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={3}>
                                    <RocketOutlined className="colorTipoPlan" style={{ fontSize: 50, marginLeft: -5, marginTop: `${ajustarAlto ? '10px' : '0px'}` }} />
                                </Col>

                                {precio ?
                                    <>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={10} xxl={8}>
                                            <Row>
                                                <Col span={24} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} `, marginTop: 0 }}>
                                                    <p className="tipoProducto">
                                                        {planProducto.nombreTipoPlan}
                                                    </p>
                                                </Col>
                                                <Col span={24}>
                                                    <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={10} xxl={9}>
                                            <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                        </Col>
                                    </> :
                                    <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={21}>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                                <p className="tipoProducto">
                                                    {planProducto.nombreTipoPlan}
                                                </p>
                                            </Col>
                                            <Col span={24}>
                                                <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                            </Col>
                                        </Row>
                                    </Col>}
                            </Row>
                        </>
                        :
                        planProducto?.nombreTipoPlan === 'Starter' ?
                            <>
                                <Row gutter={30}>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={3}>

                                        <RocketOutlined className="colorTipoPlan" style={{ fontSize: 47, marginLeft: -2, marginTop: `${ajustarAlto ? '10px' : '0px'}` }} />
                                    </Col>

                                    {precio ?
                                        <>
                                            <Col xs={20} sm={20} md={20} lg={20} xl={8} xxl={6}>
                                                <Row>
                                                    <Col span={24} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} `, marginTop: 0 }}>
                                                        <p className="tipoProducto">
                                                            {planProducto.nombreTipoPlan}
                                                        </p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={20} sm={20} md={20} lg={20} xl={8} xxl={6}>
                                                <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                            </Col>
                                        </> :
                                        <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={21}>
                                            <Row>
                                                <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                                    <p className="tipoProducto">
                                                        {planProducto.nombreTipoPlan}
                                                    </p>
                                                </Col>
                                                <Col span={24}>
                                                    <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                </Col>
                                            </Row>
                                        </Col>}
                                </Row>

                            </>
                            :
                            planProducto?.nombreTipoPlan === 'Developer' ?
                                <Row gutter={50}>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={3}>
                                        <ApiOutlined className="colorTipoPlan" style={{ fontSize: 47, marginTop: `${ajustarAlto ? '10px' : '0px'}` }} />
                                    </Col>

                                    {precio ?
                                        <>
                                            <Col xs={20} sm={20} md={20} lg={20} xl={10} xxl={6}>
                                                <Row>
                                                    <Col span={24} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} `, marginTop: 0 }}>
                                                        <p className="tipoProducto">
                                                            {planProducto.nombreTipoPlan}
                                                        </p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={20} sm={20} md={20} lg={20} xl={10} xxl={8}>
                                                <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                            </Col>
                                        </> :
                                        <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={21}>
                                            <Row>
                                                <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                                    <p className="tipoProducto">
                                                        {planProducto.nombreTipoPlan}
                                                    </p>
                                                </Col>
                                                <Col span={24}>
                                                    <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                </Col>
                                            </Row>
                                        </Col>}
                                </Row>
                                :
                                planProducto?.nombreTipoPlan === 'Advance' ?
                                    <Row gutter={0}>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={5} xxl={3}>
                                            <CrownOutlined className="colorTipoPlan" style={{ fontSize: 47, marginLeft: -5, marginTop: `${ajustarAlto ? '7px' : '0px'}` }} />
                                        </Col>

                                        {precio ?
                                            <>
                                                <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={6}>
                                                    <Row>
                                                        <Col span={24} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} `, marginTop: 0 }}>
                                                            <p className="tipoProducto">
                                                                {planProducto?.nombreTipoPlan ?? 'Advance'}
                                                            </p>
                                                        </Col>
                                                        <Col span={24}>
                                                            <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={8}>
                                                    <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                                </Col>
                                            </> :
                                            <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={21}>
                                                <Row>
                                                    <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                                        <p className="tipoProducto">
                                                            {planProducto?.nombreTipoPlan}
                                                        </p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                    </Col>
                                                </Row>
                                            </Col>}
                                    </Row> :
                                    <Row gutter={0}>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={precio ? 5 : 5} xxl={3}>
                                            <CrownOutlined className="colorTipoPlan" style={{ fontSize: 47, marginTop: `${ajustarAlto ? '10px' : '0px'}` }} />
                                        </Col>

                                        {precio ?
                                            <>
                                                <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={6}>
                                                    <Row>
                                                        <Col span={24} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} `, marginTop: 0 }}>
                                                            <p className="tipoProducto">
                                                                {planProducto?.nombreTipoPlan}
                                                            </p>
                                                        </Col>
                                                        <Col span={24}>
                                                            <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={8}>
                                                    <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                                </Col>
                                            </> :
                                            <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={21}>
                                                <Row>
                                                    <Col span={24} style={{ marginTop: 0 }}>
                                                        <p className="tipoProducto">
                                                            {planProducto?.nombreTipoPlan}
                                                        </p>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                    </Col>
                                                </Row>
                                            </Col>}
                                    </Row>}
                </>)}
                {idTipoProducto === TipoProductoId.PACK_REPORTES && (<>
                    {planProducto?.nombreTipoPlan === 'Prueba gratuita' ?
                        <Row gutter={0}>
                            <Col xs={4} sm={4} md={4} lg={4} xl={5} xxl={3}>
                                <RocketOutlined className="colorTipoPlan" style={{ fontSize: 47, marginLeft: -5, marginTop: `${ajustarAlto ? '10px' : '0px'}` }} />
                            </Col>

                            {precio ?
                                <>
                                    <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={6}>
                                        <Row>
                                            <Col span={24} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} `, marginTop: 0 }}>
                                                <p className="tipoProducto">
                                                    {planProducto?.nombreTipoPlan ?? 'Advance'}
                                                </p>
                                            </Col>
                                            <Col span={24}>
                                                <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto?.cantidadDashboard} {planProducto.subtitulo}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={8}>
                                        <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                    </Col>
                                </> :
                                <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={21}>
                                    <Row>
                                        <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                            <p className="tipoProducto">
                                                {planProducto?.nombreTipoPlan ?? 'Advance'}
                                            </p>
                                        </Col>
                                        <Col span={24}>
                                            <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto?.cantidadDashboard} {planProducto.subtitulo}</p>
                                        </Col>
                                    </Row>
                                </Col>}
                        </Row> :
                        planProducto?.nombreTipoPlan === 'Individual' ?
                            <Row gutter={0}>
                                <Col xs={4} sm={4} md={4} lg={4} xl={precio ? 4 : 5} xxl={3}>
                                    <AimOutlined className="colorTipoPlan" style={{ fontSize: 47, marginTop: `${ajustarAlto ? '10px' : '0px'}` }} />
                                </Col>

                                {precio ?
                                    <>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={6}>
                                            <Row>
                                                <Col span={24} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} `, marginTop: 0 }}>
                                                    <p className="tipoProducto">
                                                        {planProducto?.nombreTipoPlan ?? 'Advance'}
                                                    </p>
                                                </Col>
                                                <Col span={24}>
                                                    <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto?.cantidadDashboard} {planProducto.subtitulo}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={8}>
                                            <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                        </Col>
                                    </> :
                                    <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={21}>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                                <p className="tipoProducto">
                                                    {planProducto?.nombreTipoPlan ?? 'Advance'}
                                                </p>
                                            </Col>
                                            <Col span={24}>
                                                <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto?.cantidadDashboard} {planProducto.subtitulo}</p>
                                            </Col>
                                        </Row>
                                    </Col>}
                            </Row> :
                            <Row gutter={0}>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={3} style={{ marginTop: 10 }}>
                                    <Image
                                        style={{ height: 55, marginLeft: -5, marginTop: -5 }}
                                        preview={false}
                                        src={perfilId === PerfilUsuarioId.PERSONA_NATURAL ? logoDashboard : logoDashboardEmpresa}
                                    />
                                </Col>
                                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                                    <Row>
                                        <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                            <p className="tipoProducto">
                                                {planProducto?.nombreTipoPlan ?? 'Advance'}
                                            </p>
                                        </Col>
                                        <Col span={24}>
                                            <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto?.cantidadDashboard} {planProducto.subtitulo}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                {precio ?
                                    <>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={3}></Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                                            <Row>
                                                <Col span={24} style={{ marginTop: 0 }}>
                                                    <Title level={3} style={{ marginTop: 0, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </> : null}
                            </Row>}
                </>)}
                {idTipoProducto === TipoProductoId.INNOVATION_LAB && (<>
                    <Row gutter={0}>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={3} style={{ marginTop: ajustarAltoInnovationLab ? 0 : 10 }}>
                            <RocketOutlined className="colorTipoPlan" style={{ fontSize: 46, marginLeft: -5, marginTop: 0 }} />
                        </Col>
                        <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                            <Row>
                                <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                    <p className="tipoProducto">
                                        {planProducto?.nombreTipoPlan ?? 'Advance'}
                                    </p>
                                </Col>
                                <Col span={24}>
                                    <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                </Col>
                            </Row>
                        </Col>
                        {precio ?
                            <>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={3}></Col>
                                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={21}>
                                    <Row>
                                        <Col span={24} style={{ marginTop: 0 }}>
                                            <Title level={3} style={{ marginTop: 0, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                        </Col>
                                    </Row>
                                </Col>
                            </> : null}
                    </Row>
                </>)}
                {idTipoProducto !== TipoProductoId.PACK_REPORTES &&
                    idTipoProducto !== TipoProductoId.PACK_APIS &&
                    idTipoProducto !== TipoProductoId.INNOVATION_LAB && (<>
                        <>
                            <Row gutter={30}>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={3}>
                                    <RocketOutlined className="colorTipoPlan" style={{ fontSize: 50, marginLeft: -5, marginTop: `${ajustarAlto ? '10px' : '0px'}` }} />
                                </Col>

                                {precio ?
                                    <>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={10} xxl={8}>
                                            <Row>
                                                <Col span={24} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} `, marginTop: 0 }}>
                                                    <p className="tipoProducto">
                                                        {planProducto.nombreTipoPlan}
                                                    </p>
                                                </Col>
                                                <Col span={24}>
                                                    <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={10} xxl={9}>
                                            <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}> {precio === 'gratis' ? 'gratis' : `UF ${precio} /mes`} </Title>
                                        </Col>
                                    </> :
                                    <Col xs={20} sm={20} md={20} lg={20} xl={19} xxl={21}>
                                        <Row>
                                            <Col span={24} style={{ marginTop: 0 }} style={{ maxHeight: `${maxHeigthTitulo ? `${maxHeigthTitulo}px` : 'none'} ` }}>
                                                <p className="tipoProducto">
                                                    {planProducto.nombreTipoPlan}
                                                </p>
                                            </Col>
                                            <Col span={24}>
                                                <p className="subProducto" style={{ marginTop: 0, marginBottom: 0 }}>{planProducto.subtitulo}</p>
                                            </Col>
                                        </Row>
                                    </Col>}
                            </Row>
                        </>
                    </>)}
            </Col>
        </Row >
    )
}

PlanProductoEnCard.propTypes = {
    planProducto: PropTypes.shape({
        nombreTipoPlan: PropTypes.string,
        idTipoPlan: PropTypes.number,
    }),
    justificarInicio: PropTypes.bool,
}

export default PlanProductoEnCard