import { Button, Result } from "antd"
import { useKeycloak } from '@react-keycloak/web'

const SinPermiso = () => {
    const { keycloak } = useKeycloak()

    return (
        <Result
            status="403"
            title="Error"
            subTitle="No esta autorizado para acceder al sistema."
            extra={<Button onClick={() => keycloak.logout({ redirectUri: process.env.REACT_APP_URL_ONBOARDING_PUBLICO })} type="primary">Cerrar sesión</Button>}
        />
    )
}

export default SinPermiso