import { Menu } from 'antd';
import { AuditOutlined, AppstoreOutlined, CreditCardOutlined, HomeOutlined, ShoppingOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { PerfilUsuarioId } from '../../enum/enums';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const keysMenus = [
  {
    parentKey: '1',
    key: '1',
    url: '/home',
    children: []
  },
  {
    parentKey: '2',
    key: '2',
    url: '/perfil',
    children: [
      {
        parentKey: '2',
        key: '2',
        url: '/editar-perfil',
        children: []
      }
    ]
  },
  {
    parentKey: '3',
    key: '3',
    url: '/historial-pagos',
    children: []
  },
  {
    parentKey: '4',
    key: '4',
    url: '/productos',
    children: [
      {
        parentKey: '4',
        key: '4',
        url: '/detalle-producto',
        children: []
      }
    ]
  },
  {
    parentKey: '5',
    key: '5',
    url: '/producto-servicio',
    children: [
      {
        parentKey: '5',
        key: '5',
        url: '/detalle-producto-servicio',
        children: []
      },
      {
        parentKey: '5',
        key: '5',
        url: '/periodo-prueba',
        children: []
      },
      {
        parentKey: '5',
        key: '5',
        url: '/contratar-producto',
        children: []
      }
    ]
  },
  {
    parentKey: '6',
    key: '6',
    children: [
      {
        parentKey: '6',
        key: '6.1',
        url: '/perfilEmpresa'
      },
      {
        parentKey: '6',
        key: '6.2',
        url: '/Usuarios'
      },
      {
        parentKey: '6',
        key: '6.2',
        url: '/detalle-usuario'
      },
      {
        parentKey: '6',
        key: '6.2',
        url: '/editar-usuario'
      },
      {
        parentKey: '6',
        key: '6.2',
        url: '/crear-usuario'
      },
      {
        parentKey: '6',
        key: '6.3',
        url: '/solicitudes'
      },
      {
        parentKey: '6',
        key: '6.3',
        url: '/detalleSolicitud'
      },
      {
        parentKey: '6',
        key: '6.4',
        url: '/productosEmpresa'
      },
      {
        parentKey: '6',
        key: '6.4',
        url: '/detalle-empresa-producto'
      },
    ]
  },
]

const OpcionesMenu = ({ handleMenuClick, isMenuCollapsed }) => {
  const location = useLocation()
  const usuario = useSelector(state => state.aplicacion.usuario)
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(null)
  const [openKeys, setOpenKeys] = useState(null)

  useEffect(() => {
    if (isMenuCollapsed) {
      setOpenKeys(null)
    }
  }, [isMenuCollapsed, location.pathname])

  const onRedirect = (titulo, url) => {
    if (isMenuCollapsed) {
      setOpenKeys(null)
    }

    handleMenuClick(titulo, url)
  }

  useEffect(() => {
    const fetchData = async () => {
      const path = `/${location.pathname.split('/')[1]}`
      const opcion = keysMenus.filter(x => x.url === path || x.children.some(y => y.url === path))[0] ?? keysMenus[0]
      const key = opcion.url === path ? opcion.key : opcion.children.filter(x => x.url === path)[0]?.key ?? opcion.key

      if (!isMenuCollapsed) {
        setOpenKeys(opcion.parentKey)
      }

      setDefaultSelectedKeys(key)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    fetchData()
  }, [location.pathname])

  const onclickParentWithChildren = (parent) => {
    setOpenKeys(prev => prev !== parent ? parent : '0')
  }

  return (
    <>
      {defaultSelectedKeys ?
        <>
          {usuario.perfilId === PerfilUsuarioId.PERSONA_NATURAL ?
            <Menu
              className='menu-principal'
              theme='light'
              mode='inline'
              style={{ border: 'none' }}
              defaultSelectedKeys={[defaultSelectedKeys]}
              selectedKeys={[defaultSelectedKeys]}
              openKeys={[openKeys]}
              defaultOpenKeys={[openKeys]}
              items={[
                {
                  key: '1',
                  icon: <HomeOutlined />,
                  label: 'Inicio',
                  onClick: () => onRedirect('home', 'Inicio')
                },
                {
                  key: '2',
                  icon: <UserOutlined />,
                  label: 'Mi Perfil',
                  onClick: () => onRedirect('perfil', 'Inicio / Mi perfil'),
                },
                {
                  key: '3',
                  icon: <CreditCardOutlined />,
                  label: 'Historial de pagos',
                  onClick: () => onRedirect('historial-pagos', 'Inicio / Mis medios de pago / Transferencias'),
                },
                {
                  key: '4',
                  icon: <ShoppingOutlined />,
                  label: 'Mis Productos',
                  onClick: () => onRedirect('productos', 'Inicio / Mis Productos')
                },
                {
                  key: '5',
                  icon: <AppstoreOutlined />,
                  label: 'Catálogo de productos',
                  onClick: () => onRedirect('producto-servicio', 'Inicio / Catálogo de productos')
                }
              ]}
            /> : usuario.perfilId === PerfilUsuarioId.CONSUMIDOR_EMPRESA ?
              <Menu
                className='menu-principal'
                theme='light'
                mode='inline'
                style={{ border: 'none' }}
                defaultSelectedKeys={[defaultSelectedKeys]}
                selectedKeys={[defaultSelectedKeys]}
                openKeys={[openKeys]}
                defaultOpenKeys={[openKeys]}
                items={[
                  {
                    key: '1',
                    icon: <HomeOutlined />,
                    label: 'Inicio',
                    onClick: () => onRedirect('home', 'Inicio')
                  },
                  {
                    key: '2',
                    icon: <UserOutlined />,
                    label: 'Mi Perfil',
                    onClick: () => onRedirect('perfil', 'Mi perfil'),
                  },
                  {
                    key: '6',
                    icon: <AuditOutlined />,
                    label: 'Perfil Empresa',
                    onTitleClick: () => onclickParentWithChildren('6'),
                    children: [
                      {
                        key: '6.3',
                        label: 'Solicitudes',
                        onClick: () => onRedirect('solicitudes', 'Mis solicitudes'),
                      }
                    ]
                  },
                  {
                    key: '4',
                    icon: <ShoppingOutlined />,
                    label: 'Mis Productos',
                    onClick: () => onRedirect('productos', 'Mis productos')
                  },
                  {
                    key: '5',
                    icon: <AppstoreOutlined />,
                    label: 'Catálogo de productos',
                    onClick: () => onRedirect('producto-servicio', 'Producto y Servicios')
                  }
                ]}
              />
              : usuario.perfilId === PerfilUsuarioId.ADMIN_EMPRESA ?
                <Menu
                  className='menu-principal'
                  theme='light'
                  mode='inline'
                  style={{ border: 'none' }}
                  defaultSelectedKeys={[defaultSelectedKeys]}
                  selectedKeys={[defaultSelectedKeys]}
                  openKeys={[openKeys]}
                  defaultOpenKeys={[openKeys]}
                  items={[
                    {
                      key: '1',
                      icon: <HomeOutlined />,
                      label: 'Inicio',
                      onClick: () => onRedirect('home', 'Inicio')
                    },
                    {
                      key: '2',
                      icon: <UserOutlined />,
                      label: 'Mi Perfil',
                      onClick: () => onRedirect('perfil', 'Mi perfil'),
                    },
                    {
                      key: '6',
                      icon: <AuditOutlined />,
                      label: 'Perfil Empresa',
                      onTitleClick: () => onclickParentWithChildren('6'),
                      children: [
                        {
                          key: '6.1',
                          label: 'Perfil empresa',
                          onClick: () => onRedirect('perfilEmpresa', 'Perfil empresa'),
                        },
                        {
                          key: '6.2',
                          label: 'Usuarios y permisos',
                          onClick: () => onRedirect('Usuarios', 'usuarios'),
                        },
                        {
                          key: '6.3',
                          label: 'Solicitudes',
                          onClick: () => onRedirect('solicitudes', 'Mis solicitudes'),
                        },
                        {
                          key: '6.4',
                          label: 'Productos de la empresa',
                          onClick: () => onRedirect('productosEmpresa', 'Productos de la empresa')
                        },
                      ]
                    },
                    {
                      key: '3',
                      icon: <CreditCardOutlined />,
                      label: 'Historial de pagos',
                      onClick: () => onRedirect('historial-pagos', 'Inicio / Mis medios de pago / Transferencias')
                    },
                    {
                      key: '4',
                      icon: <ShoppingOutlined />,
                      label: 'Mis Productos',
                      onClick: () => onRedirect('productos', 'Mis productos')
                    },
                    {
                      key: '5',
                      icon: <AppstoreOutlined />,
                      label: 'Catálogo de productos',
                      onClick: () => onRedirect('producto-servicio', 'Producto y Servicios')
                    }
                  ]}
                /> : null}
        </> : null}
    </>
  )
}

export default OpcionesMenu