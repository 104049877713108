import React from 'react'
import '../../../assets/styles/CardContent.styles.scss'
import parse from 'html-react-parser';
import { CheckOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

const DescripcionPlanDashboard = ({ texto }) => {
    return (
        <div className='descripcionDashboard' style={{ maxWidth: '100%' }}>
            {
                texto?.split('<p>')?.map((x, index) => {
                    if (x !== '') {
                        return <Row gutter={0} key={`${index}-desc`}>
                            <Col span={1}><CheckOutlined className="letraColorCliente" /></Col>
                            <Col span={23}>  {parse(`<p style="padding-left:10px;margin-bottom:10px">${x}`)}</Col>
                        </Row>
                    }
                })
            }
        </div>
    )
}

export default DescripcionPlanDashboard