import { CreditCardOutlined } from '@ant-design/icons'
import { SeparadorMiles } from '../../services/Helpers/stringHelper'
import { Avatar, Button, Modal } from 'antd'
import React from 'react'

const ModalCambioSuscripcion = ({ nuevaSuscripcion, isOpen, handleOk, handleCancel }) => {
  return (
    <div>
      <Modal
        centered
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={395}
        footer={[
          <Button shape='round' onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" shape='round' onClick={handleOk}>
            Guardar cambios
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Avatar className="colorFondo colorLetra" size={80} icon={<CreditCardOutlined />} />
          <h2 style={{ marginBottom: 0 }}>Se va a realizar un cambio de plan en esta suscripción</h2>
          <p style={{ marginBottom: 0 }}>
            El nuevo plan será: <span className="letraColorCliente" style={{ fontWeight: '700' }}>"{nuevaSuscripcion?.nombrePlan}"</span>
          </p>
          <p style={{ marginTop: 0 }}>
            Donde la nueva tarifa mensual a pagar será: UF <span className="letraColorCliente" style={{ fontWeight: '700' }}>  {SeparadorMiles(nuevaSuscripcion?.precio)} </span> /mes
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default ModalCambioSuscripcion