import { CheckOutlined, TagOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Divider, Row, Select, Typography, Form, Avatar } from "antd";
import Link from 'antd/es/typography/Link'
import DescripcionOtroTipoProducto from "../productoServicio/planes/DescripcionOtroTipo";
import { getDocumento } from "../../services/documentoApi";
import { saveAs } from 'file-saver'
import { useSelector } from "react-redux";
import { PerfilUsuarioId } from "../../enum/enums";
import PlanProducto from "../productoServicio/planes/PlanProducto";
import { getDetalleProductoContratar } from "../../services/api";
import { savePeriodoPrueba } from "../../services/suscripcionApi";
import parse from 'html-react-parser';
import { getEjecutivos } from "../../services/publicApi";
import ModalAvisos from "../modal/ModalAvisos";
import { useDispatch } from 'react-redux';
import { setProductoContratar } from "../../redux/reducers/ProductoReducer";
import { setIsLoading, setRefrescarNotificaciones } from '../../redux/reducers/AppReducer';
import { useNavigate, useParams } from 'react-router-dom'
import { TipoProductoId } from "../../enum/enums";
import DescripcionPlanApi from '../productoServicio/DescripcionPlanApi'
import DescripcionPlanDashboard from "../productoServicio/planes/DescripcionPlanDashboard";
import './Contratar.scss'
import DescripcionPlanInnovationLabs from "../productoServicio/DescripcionPlanInnovationLabs";
import { setNombreProductoSeleccionado } from "../../redux/reducers/ProductoReducer";
const { Title } = Typography;

const SolicitarPeriodoPrueba = () => {
    const dispatch = useDispatch()
    const producto = useSelector(state => state.producto.productoContratar)
    const usuario = useSelector(state => state.aplicacion.usuario)
    const [ejecutivos, setEjecutivos] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [dataModal, setDataModal] = useState(null)
    const { idProducto } = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            if (usuario) {
                if (!usuario.habilitadoComprar)
                    navigate('/home')

                try {
                    const data = await getEjecutivos()
                    setEjecutivos(data)
                }
                catch (error) {
                    console.log(error)
                }
            }
        }
        fetchData()
    }, [idProducto, usuario])

    //entra si viene de sitio publico o refrezca la pagina
    useEffect(() => {
        const fetchData = async () => {
            if (!producto) {
                try {
                    dispatch(setIsLoading(true))
                    const data = await getDetalleProductoContratar(idProducto)
                    dispatch(setProductoContratar(data))
                    dispatch(setNombreProductoSeleccionado({ nombre: data?.nombre, id: data?.idProducto }))

                    if (!data.poseePeriodoPrueba)
                        navigate('/home')
                }
                catch (error) {
                    console.log(error)
                }
                finally {
                    dispatch(setIsLoading(false))
                }
            }
        }
        fetchData()
    }, [producto])

    const onFinish = async (values) => {
        try {
            dispatch(setIsLoading(true))
            values.idProducto = parseInt(idProducto)
            const result = await savePeriodoPrueba(values)

            if (result.accionFueCorrecta) {
                dispatch(setRefrescarNotificaciones(true))
            }

            if (usuario?.perfilId === PerfilUsuarioId.CONSUMIDOR_EMPRESA) {
                setDataModal({
                    mensaje: result.mensaje,
                    fueCorrecto: result.accionFueCorrecta,
                    titulo: result.accionFueCorrecta ? 'Solicitud enviada' : '',
                    msjBoton: result.accionFueCorrecta ? 'Ir a mis productos' : 'Aceptar'
                })
            }
            else {
                setDataModal({
                    mensaje: result.mensaje,
                    fueCorrecto: result.accionFueCorrecta,
                    titulo: result.accionFueCorrecta ? 'Activación exitosa' : '',
                    msjBoton: result.accionFueCorrecta ? 'Ir a mis productos' : 'Aceptar'
                })
            }
        }
        catch (error) {
            console.log(error)
            setDataModal({
                mensaje: 'Ha ocurrido un error, inténtelo nuevamente.',
                fueCorrecto: false,
                titulo: 'Error'
            })
        }
        finally {
            setShowModal(true)
            dispatch(setIsLoading(false))
        }
    };

    const onCloseModal = () => {
        if (dataModal.fueCorrecto && usuario.perfilId === PerfilUsuarioId.PERSONA_NATURAL)
            navigate('/productos')
        if (dataModal.fueCorrecto && usuario.perfilId === PerfilUsuarioId.ADMIN_EMPRESA)
            navigate('/productosEmpresa')
        if (dataModal.fueCorrecto && usuario.perfilId === PerfilUsuarioId.CONSUMIDOR_EMPRESA)
            navigate('/solicitudes')
        else
            setShowModal(false)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const infoPlanProducto = () => {
        const planProductoSeleccionado = producto?.planesProducto?.
            filter(x => x.idPlanProducto === parseInt(idProducto))[0]
        if (planProductoSeleccionado) {
            return {
                nombreTipoPlan: planProductoSeleccionado.nombrePlan,
                idTipoPlan: planProductoSeleccionado.idTipoPlan,
                subtitulo: planProductoSeleccionado.subtitulo
            }
        } else {
            return null
        }
    }

    const onDownloadDocumentacion = async (idtipoDocumento) => {
        dispatch(setIsLoading(true))
        try {
            const docRes = await getDocumento(idProducto, idtipoDocumento)
            if (docRes) {
                const blobFile = new Blob(
                    [docRes.buffer],
                    { type: docRes.contentType }
                )
                saveAs(blobFile, docRes.fileName)
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    return (
        <>
            <ModalAvisos
                isModalOpen={showModal}
                onClose={() => onCloseModal()}
                mensaje={dataModal?.mensaje}
                titulo={dataModal?.titulo}
                fueCorrecto={dataModal?.fueCorrecto}></ModalAvisos>
            <p style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 24, marginTop: 10, marginBottom: 10 }}>Producto a contratar</p>
            <br />
            <Card style={{ background: '#FFF', boxShadow: '0px 0px 50px -20px rgba(0, 0, 0, 0.25)' }}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Form form={form} onFinish={onFinish} className="form" layout='inline' style={{ width: '100%' }}>
                        <Col xs={0} sm={1} md={1} lg={1} xl={2} xxl={1}>
                            <Avatar className="colorLetra colorFondo" style={{ marginTop: 18 }} size={48} icon={<TagOutlined />} />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={11} xl={11} xxl={11} style={{ paddingLeft: 5 }}>
                            <p style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: 16, marginBottom: 0 }}>{producto?.nombre}</p>
                            <p className="descripcionDetalle descripcionProducto" style={{ textAlign: 'justify', marginTop: 8, marginBottom: 4, fontWeight: '400', maxWidth: '80%' }}>
                                {parse(producto?.descripcion ?? '')}
                            </p>
                            {producto?.idTipoProducto === TipoProductoId.PACK_APIS && (
                                <p className="descripcionDetalle descripcionProducto" style={{ textAlign: 'justify', marginTop: 8, marginTop: 0, fontWeight: '400', maxWidth: '80%' }}>
                                    La licencia otorgada al contratar esta API es solo para uso interno del cliente, si deseas distribuir la información entregada por API Brain Data a terceros contáctate con nosotros para revisar las tarifas y condiciones asociadas.
                                </p>)}
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={8} xl={21} xxl={16} >
                                    <PlanProducto maxHeigthTitulo={35} idTipoProducto={producto?.idTipoProducto} planProducto={infoPlanProducto()} ajustarAlto={true}></PlanProducto>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={21} xxl={16} style={{ textAlign: 'left' }}>
                                    <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)', marginTop: 5 }}> UF 0 /mes </Title>
                                </Col>
                            </Row>
                            <div>
                                <p style={{ textDecoration: 'underline', textAlign: 'justify', fontWeight: 'bold' }}>Este plan incluye:</p>

                                {producto?.planesProducto?.filter(x => x.idPlanProducto === parseInt(idProducto)).map((item, index) => {
                                    return <React.Fragment key={index}>
                                        {producto.idTipoProducto === TipoProductoId.PACK_APIS ?
                                            <DescripcionPlanApi
                                                requesAlMes={item?.cantidadRequest}
                                                mercadoProducto={item?.mercados}
                                                nombrePlan={infoPlanProducto()?.nombreTipoPlan}
                                                agnoInfoHistorica={item?.infoHistorica}
                                                tiempoDelay={item?.periodoTiempo}>
                                            </DescripcionPlanApi> : producto.idTipoProducto === TipoProductoId.PACK_REPORTES ?
                                                <div style={{ maxWidth: '80%' }}>
                                                    <DescripcionPlanDashboard texto={item.descripcionPlanDashboard}></DescripcionPlanDashboard>
                                                </div> :
                                                producto.idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                                    <DescripcionPlanInnovationLabs descripcion={item.descripcionPlanDashboard} cantidadNucleos={item.cantidadNucleos} memoriaRam={item.memoriaRam} usuariosSugeridosInnovationLab={item.usuariosSugeridosInnovationLab}></DescripcionPlanInnovationLabs> :
                                                    <DescripcionOtroTipoProducto plan={item}></DescripcionOtroTipoProducto>}
                                    </React.Fragment>
                                })}

                                {!producto?.planesProducto ? <p style={{ textAlign: 'justify' }}><CheckOutlined style={{ color: '#00FF91' }} /> Muchos beneficios </p> : null}
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={11} xl={11} xxl={11}>
                            {usuario.perfilId !== PerfilUsuarioId.CONSUMIDOR_EMPRESA ? <>
                                <div className='terminos'>
                                    <Form.Item name='ejecutivoId' tooltip="Campo opcional" label='Seleccionar ejecutivo' className='ant-form-vertical rounded-input labeltitulo' >
                                        <Select
                                            showSearch={false}
                                            // style={{ width: '60%' }}
                                            filterOption={filterOption}
                                            placeholder="Seleccionar ejecutivo"
                                            loading={ejecutivos === null}
                                            options={ejecutivos}
                                        />
                                    </Form.Item>
                                </div>
                                <div className='terminos'>
                                    <Form.Item normalize={(value) => value ? value : undefined} name='terminos' valuePropName="checked" rules={[
                                        {
                                            required: true,
                                            message: "Debes aceptar los términos y condiciones.",
                                        },
                                    ]}>
                                        <Checkbox style={{ fontWeight: '400' }}>He leído y acepto los <Link href="#" style={{ fontFamily: "roboto" }} className='letraNaranja' onClick={() => onDownloadDocumentacion(1)}> Términos y Condiciones</Link> de este producto</Checkbox>
                                    </Form.Item>
                                </div></> : null}

                            <Row gutter={0}>
                                <Col span={24}>
                                    <p style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: 16 }}>Detalle de tu compra</p>
                                </Col>
                                <Col xs={24} sm={18} md={18} lg={18} xl={18} xxl={15}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginInlineEnd: 16 }}>

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ textAlign: 'left', margin: 0 }}>{producto?.nombre}</p>
                                            <p style={{ textAlign: 'right', margin: 0 }}></p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ textAlign: 'left', margin: 0 }}>Periodo de prueba</p>
                                            <p style={{ textAlign: 'right', margin: 0 }}>0 UF</p>
                                        </div>
                                    </div>
                                    <Divider className='dividerTotal' />
                                    <div style={{ display: 'flex', flexDirection: 'column', marginInlineEnd: 16 }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ textAlign: 'left', margin: 0, fontWeight: 'bold' }}>Total a pagar</p>
                                            <p style={{ textAlign: 'right', margin: 0, fontWeight: 'bold' }}>$0 </p>
                                        </div>
                                    </div>

                                </Col>
                                <Col span={24}                                >
                                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginInlineEnd: 16, alignItems: 'flex-end', marginTop: 20 }}>
                                        <Button htmlType="submit" type="primary" shape="round">{usuario?.perfilId === PerfilUsuarioId.CONSUMIDOR_EMPRESA ? 'Solicitar' : 'Activar prueba gratuita'}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Form>
                </Row>
            </Card>
        </>
    )
}

export default SolicitarPeriodoPrueba