import { useState, useEffect } from 'react';
import { Table, Row, Col, Card, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../redux/reducers/AppReducer';
import { getMisSolicitudes } from '../../services/empresaApi';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router';
import { PerfilUsuarioId } from '../../enum/enums';
const dayjs = require('dayjs')

const TableMisSolicitudes = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [solicitudes, setSolicitudes] = useState(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const idPerfilUsuarioSession = useSelector(state => state.aplicacion.usuario.perfilId)

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const data = await getMisSolicitudes()
                setSolicitudes(data)
            }
            catch (error) {
                console.log(error)
            }
            finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData()
    }, [])

    const columns = [
        {
            title: 'Producto',
            dataIndex: 'nombreProducto',
            key: 'nombreProducto',
            ellipsis: false,
        },
        {
            title: 'Tipo de solicitud',
            dataIndex: 'tipoSolicitud',
            key: 'tipoSolicitud',
            ellipsis: false,
        },
        {
            title: 'Fecha de solicitud',
            dataIndex: 'fechaSolicitud',
            key: 'fechaSolicitud',
            render: (text) =>
                <>
                    {dayjs(text).format('DD/MM/YYYY')}
                </>
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            sorter: (a, b) => a.estado.localeCompare(b.estado, 'es', { ignorePunctuation: true }),
            sortOrder: sortedInfo.columnKey === 'estado' ? sortedInfo.order : null,
            filters: [
                {
                    text: 'En espera de Aprobación',
                    value: 'En espera de Aprobación',
                },
                {
                    text: 'Aprobada',
                    value: 'Aprobada',
                },
                {
                    text: 'Rechazada',
                    value: 'Rechazada',
                }
            ],
            filteredValue: filteredInfo.estado || null,
            onFilter: (value, record) => record.estado.includes(value),
            ellipsis: false,
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            render: (value, record) => <>
                <Button onClick={() => navigate(`/detalleSolicitud/${value}`)} style={{ marginRight: 10, marginBottom: 10 }} type="primary" shape="round" htmlType="button">
                    Ver solicitud
                </Button>
            </>,
        }
    ];
    return (
        <>
            {isTabletOrMobile ?
                <>
                    {solicitudes?.map((item, index) => (
                        <Card key={`sol-${index}`} className="cardTable" >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={24}>
                                    <p className="titulo"><strong>Producto</strong></p>
                                </Col>
                                <Col span={24}>
                                    <p className="subTitulo">{item.nombreProducto}</p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={24}>
                                    <p className="titulo"><strong>Tipo de Solicitud</strong></p>
                                </Col>
                                <Col span={24}>
                                    <p className="subTitulo">{item.tipoSolicitud}</p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={24}>
                                    <p className="titulo verde"><strong>Fecha de Solicitud</strong></p>
                                </Col>
                                <Col span={24}>
                                    <p className="subTitulo">{dayjs(item.fechaSolicitud).format('DD/MM/YYYY hh:mm:ss A')}</p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={24}>
                                    <p className="titulo"><strong>Estado</strong></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={24}>
                                    <p className="subTitulo">{item.estado}</p>
                                </Col>
                            </Row>
                            {idPerfilUsuarioSession === PerfilUsuarioId.ADMIN_EMPRESA ?
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                    <Col span={24}>
                                        <Button disabled={item.estado !== 'En espera de Aprobación'} onClick={() => navigate(`/detalleSolicitud/${item.id}`)} style={{ marginRight: 10, marginBottom: 10 }} type="primary" shape="round" htmlType="button">
                                            Ver solicitud
                                        </Button>
                                    </Col>
                                </Row> : null}
                        </Card>
                    ))}
                </>
                :
                <Table locale={{ emptyText: `${idPerfilUsuarioSession === PerfilUsuarioId.ADMIN_EMPRESA ? 'Aún no se han recibido solicitudes.' : 'Aún no haz realizado solicitudes.'}` }} rowKey='id' columns={idPerfilUsuarioSession === PerfilUsuarioId.ADMIN_EMPRESA ? columns : columns.filter(col => col.dataIndex !== 'id')} dataSource={solicitudes} onChange={handleChange} />}
        </>)
};
export default TableMisSolicitudes