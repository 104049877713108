import { CheckCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Modal, Row } from 'antd'
import React from 'react'

const ModalEnvioMsj = ({ isModalOpen, onClose }) => {
  return (
    <div>
            <Modal
                closable={false}
                centered
                open={isModalOpen}
                onOk={onClose}
                width={450}
                footer={[]
                }            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Avatar className="colorFondo" size={80} icon={<CheckCircleOutlined />} />
                    <Row>
                        <Col span={24}>
                            <p>Mensaje enviado correctamente.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button key="submit" type="primary" shape="round" onClick={onClose}>
                                Aceptar
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
  )
}

export default ModalEnvioMsj