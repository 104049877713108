import axios from 'axios'

let iAxios = axios.create({
    defaultInterceptors: true,
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
})

iAxios.interceptors.request.use(
    async (config) => {
        try {
            let tokenJWT = localStorage.getItem('accesToken')
            config.headers['Authorization'] = `Bearer ${tokenJWT}`

            return config
        }
        catch (error) {
            console.log(error)
            return config
        }
    },
    function (error) {
        return Promise.reject(error)
    }
)

iAxios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        console.log(error)
        return Promise.reject(error)
    }
)

const config = { iAxios }

export default config