import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Card, Col, Row, Typography, Divider, Flex } from 'antd'
import { useDispatch } from 'react-redux';
import { PerfilUsuarioId } from '../../enum/enums';
import ModalActualizarToken from '../modal/ModalActualizarToken';
import ModalAvisos from '../modal/ModalAvisos';
import { generaNuevaApiKey } from '../../services/suscripcionApi';
import { setIsLoading, setRefrescarNotificaciones } from '../../redux/reducers/AppReducer';
import { SeparadorMiles } from '../../services/Helpers/stringHelper';
import { EstadoSuscripcionId } from '../../enum/enums';
import { GetRequestDisponibles } from '../../services/Helpers/stringHelper';
const typeDefs = require('../../interface/typeDefs')
const { Title, Text } = Typography;

/**
 * Muestra información de la suscripción a las APIs. Ej: Cantidad de solicitudes, Token/ApiKey, etc.
 * @component
 * @param {{detalleProductoApi:typeDefs.DetalleProductoApiStartupDto}} detalleProductoApi - Detalle de la suscripción al producto tipo PACK_API
 * @param {{idPerfilUsuarioLogeado:number}} idPerfilUsuarioLogeado - Identificador del tipo de perfil que posee el usuario logeado
 * @returns {React.ReactElement}
 * 
*/
function DetalleProductoApiStartup({ estadoSuscripcionId, idPerfilUsuarioLogeado, idSuscripcion, detalleProductoApi, notifyParent, plan }) {

  const dispatch = useDispatch()

  const [isModalActualizarTokenOpen, setIsModalActualizarTokenOpen] = useState()
  const [dataModalAvisos, setDataModalAvisos] = useState(
    /**
     * @type {typeDefs.DataModalAvisosDto}
     */
    (null)
  )

  const onActualizarToken = async () => {
    try {
      dispatch(setIsLoading(true))

      const res = await generaNuevaApiKey(idSuscripcion)
      dispatch(setRefrescarNotificaciones(true))

      /**
      * @type {typeDefs.DataModalAvisosDto}
      */
      const dataModal = {
        fueCorrecto: res.accionFueCorrecta,
        mensaje: res.mensaje,
        titulo: res.accionFueCorrecta ? "Modificación realizada correctamente" : "Ocurrió un error"
      }

      setDataModalAvisos(dataModal)

      if (res.accionFueCorrecta)
        notifyParent()

    } catch (error) {
      console.log(error)
      setDataModalAvisos({ mensaje: "Ocurrio un error inesperado", titulo: "Error", fueCorrecto: false })

    } finally {
      setIsModalActualizarTokenOpen(false)
      dispatch(setIsLoading(false))
    }

  }

  return (
    <>
      <ModalActualizarToken
        isModalOpen={isModalActualizarTokenOpen}
        onClose={() => setIsModalActualizarTokenOpen(false)}
        onAceptar={onActualizarToken}
      />
      <ModalAvisos
        isModalOpen={dataModalAvisos ? true : false}
        onClose={() => setDataModalAvisos(null)}
        mensaje={dataModalAvisos?.mensaje}
        titulo={dataModalAvisos?.titulo}
        fueCorrecto={dataModalAvisos?.fueCorrecto} />

      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          <br />
          <Card style={{ paddingBottom: 0, height: '90%' }} className='cardRequest'>
            <Flex vertical={true} align="left" gap="small">
              <Title style={{ marginTop: 15, marginBottom: 0 }} level={4}>Token de seguridad</Title>
              <Text >{detalleProductoApi.apiKey}</Text>
              {
                (idPerfilUsuarioLogeado === PerfilUsuarioId.ADMIN_EMPRESA ||
                  idPerfilUsuarioLogeado === PerfilUsuarioId.PERSONA_NATURAL) &&
                  estadoSuscripcionId === EstadoSuscripcionId.VIGENTE
                  ? (
                    <>
                      <Button
                        type="primary"
                        shape="round"
                        style={{ width: '65%', marginTop: 10 }}
                        onClick={() => setIsModalActualizarTokenOpen(true)}
                      >Actualizar token</Button></>
                  ) : <><br /><br /></>}
            </Flex>
          </Card>
        </Col>

        {/* aca */}
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          <br />
          <Card style={{ paddingBottom: 0, height: '90%' }} className='cardRequest'>
            <Flex vertical={true} align="center" gap="small">
              <Title className='request' style={{ marginTop: 15, marginBottom: 0, fontWeight: 'bold' }} level={1}>{SeparadorMiles(detalleProductoApi.requesAlMes)}</Title>
              <Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
                Request permitidos
              </Title>
            </Flex>
          </Card>
        </Col>
        {/* aca */}
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          <br />
          <Card style={{ paddingBottom: 0, height: '90%' }} className='cardRequest'>
            <Flex vertical={true} align="center" gap="small">
              <Title className='request' style={{ marginTop: 15, marginBottom: 0, fontWeight: 'bold' }} level={1}>{SeparadorMiles(GetRequestDisponibles(detalleProductoApi.requesAlMes, detalleProductoApi.requestRealizados))}</Title>
              <Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
                Request disponibles
              </Title>
            </Flex>
          </Card>
        </Col>
      </Row>
    </>
  )
}
DetalleProductoApiStartup.propTypes = {
  idSuscripcion: PropTypes.number.isRequired,
  detalleProductoApi: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    requesAlMes: PropTypes.number.isRequired,
    requestRealizados: PropTypes.number.isRequired,
  }).isRequired,
  idPerfilUsuarioLogeado: PropTypes.number.isRequired,
  notifyParent: PropTypes.func.isRequired
}

export default DetalleProductoApiStartup