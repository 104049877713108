import { useKeycloak } from '@react-keycloak/web'
import AppRoutes from '../routing'
import { useIdleTimer } from 'react-idle-timer'
import { timeToMilliseconds } from '../services/Helpers/stringHelper'
const Inactividad = () => {
    const { keycloak } = useKeycloak()
    const isLoggedIn = keycloak.authenticated;

    const handleOnIdle = event => {
        if (isLoggedIn) {
            keycloak.logout({ redirectUri: process.env.REACT_APP_URL_ONBOARDING_PUBLICO })
        }
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: timeToMilliseconds(0, Number(process.env.REACT_APP_MINUTOS_INACTIVIDAD), 0),
        onIdle: handleOnIdle,
        debounce: 500
    })

    return (
        <div className="App">
            <AppRoutes></AppRoutes>
        </div>
    )
}

export default Inactividad