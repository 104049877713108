import React, { useEffect } from 'react'
import TableMisProductos from '../../components/Table/TableMisProductos'
import '../../assets/styles/ProductoPersona.styles.scss'
import { getProductosUsuario } from '../../services/api'
import CardContent from '../../components/Card/Card'
import { useSelector, useDispatch } from "react-redux"
import { setTipoDespliegueProductos } from '../../redux/reducers/ProductoReducer'
import { Row, Col, Typography, Space, Avatar } from 'antd';
import { PlusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Title } = Typography;

const Productos = () => {
  const dispatch = useDispatch()
  const desplegarEnCard = useSelector(state => state.producto.desplegarProductosEnCard)
  const productos = useSelector(state => state.producto.productosFiltrados)

  return (
    <>
      <Row>
        <Col style={{ textAlign: 'left' }} xs={12} sm={12} md={12} lg={0}>
          <Title level={4} style={{ fontWeight: 'bold', color: 'black' }}>Mis Productos</Title>
        </Col>
        <Col style={{ textAlign: 'right' }} xs={12} sm={12} md={12} lg={0}>
          <Space>
            <p style={{ color: 'black', marginTop: 31 }} onClick={() => dispatch(setTipoDespliegueProductos(!desplegarEnCard))} >
              <strong>
                {desplegarEnCard ? 'Ver en lista' : 'Ver en cuadrícula'}
              </strong>
            </p>
            <PlusCircleOutlined style={{ fontSize: 20, color: '#FF4201', paddingTop: 20 }} />
          </Space>
        </Col>
      </Row>
      <Row style={{ textAlign: 'left' }}>
        <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
          <ExclamationCircleOutlined style={{ fontSize: 20, color: '#F1D779', paddingTop: 18 }} />
        </Col>
        <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
          <p style={{ color: 'black', textAlign: 'justify' }}><strong>Selecciona una tarjeta para ver el detalle del producto.</strong></p>
        </Col>
      </Row>

      {desplegarEnCard ?
        <div className="product-container">
          <CardContent productos={productos} />
        </div> :
        <TableMisProductos productos={productos} />}
    </>
  )
}

export default Productos