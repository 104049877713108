import { ApiOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal, Row, Col } from 'antd'
import './Modal.styles.scss'

const ModalActualizarToken = ({ isModalOpen, onClose, onAceptar }) => {
    return (
        <div>
            <Modal
                closable={false}
                centered
                open={isModalOpen}
                onOk={onClose}
                width={450}
                footer={[
                    <Button key="cancelar" shape="round" onClick={onClose}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" shape="round" onClick={onAceptar}>
                        Aceptar
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Avatar className="colorFondo" size={80} icon={<ApiOutlined />} />
                    <Row>
                        <Col span={24}>
                            <p className='msjTitulo'>
                                Se actualizará el token de seguridad
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <p style={{ marginBottom: 5 }}>
                                Confirme que desea actualizar el Token de seguridad.
                            </p>
                            <p style={{ marginTop: 0 }}>
                                Esta acción no se puede deshacer.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default ModalActualizarToken