import { useState, useEffect } from 'react';
import { Table, Row, Col, Card } from 'antd';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/reducers/AppReducer';
import { getMisSolicitudes } from '../../services/empresaApi';
import { useMediaQuery } from 'react-responsive'
import { daFormatoRut } from '../../services/Helpers/RutHelper';
const dayjs = require('dayjs')

const TableUsuariosSuscripcion = ({ usuarios }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    // const handleChange = (pagination, filters, sorter) => {
    //     setFilteredInfo(filters);
    //     setSortedInfo(sorter);
    // };

    const columns = [
        {
            title: 'RUT',
            dataIndex: 'rutCompleto',
            key: 'rutCompleto',
            ellipsis: true,
            render: (text) =>
                <>
                    {daFormatoRut(text)}
                </>
        },
        {
            title: 'Nombre',
            dataIndex: 'nombreCompleto',
            key: 'nombreCompleto',
            ellipsis: true,
        }
    ];
    return (
        <>
            {isTabletOrMobile ?
                <>
                    {usuarios?.map((item, index) => (
                        <Card key={`sol-${index}`} className="cardTable" >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={24}>
                                    <p className="titulo"><strong>RUT</strong></p>
                                </Col>
                                <Col span={24}>
                                    <p className="subTitulo">{daFormatoRut(item.rutCompleto)}</p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={24}>
                                    <p className="titulo"><strong>Nombre</strong></p>
                                </Col>
                                <Col span={24}>
                                    <p className="subTitulo">{item.nombreCompleto}</p>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </>
                :
                <Table locale={{ emptyText: "Esta suscripción aún no tiene usuarios asignados." }} rowKey='idUsuario' columns={columns} dataSource={usuarios} />}
        </>)
};
export default TableUsuariosSuscripcion