import { Button, Col, Form, Input, Row, Typography } from 'antd'
import ModalExitoEditar from '../modal/ModalExitoEditar'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const { Title } = Typography
const EditarContraseña = () => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Title level={4} style={{ textAlign: 'left' }}>Modificar contraseña</Title>
            <br />
            <Form layout='vertical'>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item name='contrasenia' label="Contraseña actual" tooltip="This is a required field" rules={[
                            {
                                required: true,
                                message: "Ingresa tu actual contraseña",
                            },
                        ]}>
                            <Input placeholder="Contraseña actual" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item name='contrasenia' label="Nueva Contraseña" tooltip="This is a required field" rules={[
                            {
                                required: true,
                                message: "Ingresa tu nueva contraseña",
                            },
                        ]}>
                            <Input placeholder="Nueva Contraseña" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item name='contrasenia' label="Repetir Contraseña" tooltip="This is a required field" rules={[
                            {
                                required: true,
                                message: "Tienes que volver a ingresar tu nueva contraseña",
                            },
                        ]}>
                            <Input placeholder="Repetir Contraseña" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={4}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="button"
                                shape="round"
                                danger
                                onClick={() => navigate('/perfil')}
                                style={{ width: '100%' }}
                            >
                                Cancelar Edición
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                style={{ width: '100%' }}
                                onClick={showModal}
                            >
                                Guardar Cambios
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
            <ModalExitoEditar isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
        </>
    )
}

export default EditarContraseña