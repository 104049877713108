import { Drawer, Alert, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './notificacion.scss'
import { CloseOutlined, BellOutlined, DeleteOutlined } from '@ant-design/icons'
import { leerNotificacion } from '../../services/notificacionApi';
import { setNotificaciones } from '../../redux/reducers/AppReducer';
const dayjs = require('dayjs')

const Notificacion = ({ openNotificaciones, closeNotificaciones }) => {
  const notificaciones = useSelector(state => state.aplicacion.notificaciones)
  const dispatch = useDispatch()

  const onCloseAlert = (id) => {
    try {
      leerNotificacion(id)
      const newValues = notificaciones.filter(x => x.id !== id)

      dispatch(setNotificaciones(newValues))
    }
    catch (error) {
      console.log(error)
    }
  }

  const getTiempoDesdeNotificacion = (fechaNotificacion) => {
    const dayFechaNotificacion = dayjs(fechaNotificacion)
    const dayFechaActual = dayjs()
    const diferenciaHoras = dayFechaActual.diff(dayFechaNotificacion, 'hour', true)

    if (diferenciaHoras <= 1) {
      const diferenciaMinutos = dayFechaActual.diff(dayFechaNotificacion, 'minute')

      return diferenciaMinutos > 1 || diferenciaMinutos === 0 ? `hace ${diferenciaMinutos} minutos` : `hace ${diferenciaMinutos} minuto`
    }
    else if (diferenciaHoras >= 24) {
      const diferenciaDias = dayFechaActual.diff(dayFechaNotificacion, 'day')

      return diferenciaDias > 1 || diferenciaDias === 0 ? `hace ${diferenciaDias} días` : `hace ${diferenciaDias} día`
    }
    else {
      const diferenciaTruncate = Math.trunc(diferenciaHoras)

      return diferenciaTruncate > 1 || diferenciaTruncate === 0 ? `hace ${diferenciaTruncate} horas` : `hace ${diferenciaTruncate} hora`
    }
  }

  return (
    <Drawer
      title={<p style={{ marginBottom: 0, marginTop: 0, fontWeight: 'bold' }}><BellOutlined className='logoNotificacion' ></BellOutlined> Notificaciones</p>}
      placement={'right'}
      closable={false}
      onClose={closeNotificaciones}
      open={openNotificaciones}
      key={'right'}
      className='notificaciones'
      extra={
        <CloseOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} onClick={() => closeNotificaciones()} />
      }
    >

      {notificaciones?.length > 0 ? <>

        {notificaciones.map(x => (
          <Alert
            closable={true}
            closeIcon={<DeleteOutlined style={{fontSize:18}}></DeleteOutlined>}
            key={x.id}
            onClose={() => onCloseAlert(x.id)}
            className='alertNotificacion'
            showIcon={false}
            message={`${x.descripcion}`}
            description={getTiempoDesdeNotificacion(x.fechaCreacion)}
            banner
          />
        ))}
      </> :
        <div style={{ textAlign: 'center' }}>
          <p className='sinNotificacion'>No posees notificaciones.</p>
        </div>}
    </Drawer>
  )
}

export default Notificacion;

