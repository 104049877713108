import PropTypes from 'prop-types'
import { Card, Col, Row, Avatar, Tooltip, Divider } from 'antd'
import ListaUsuariosGrupo from '../Empresa/Usuarios/listaUsuariosGrupo'
import React from 'react'
import { useEffect, useState } from 'react'
import PlanProductoEnCard from '../productoServicio/planes/PlanProductoEnCard'
import '../../assets/styles/CardContent.styles.scss'
import { useNavigate } from 'react-router'
import LogoProducto from '../productoServicio/Logos/LogoProducto'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
const typeDefs = require('../../interface/typeDefs')
const dayjs = require('dayjs')

/**
 * 
 * @param {{productos:Array.<typeDefs.ProductoContratadoDto>}} productos - Productos contratados por el usuario
 * @returns {React.ElementType}
 */
function CardContent({ productos }) {
  const navigate = useNavigate()
  const location = useLocation()
  const isLoading = useSelector(store => store.aplicacion.isLoading)
  const [urlRedirect, setUrlRedirect] = useState('detalle-producto')

  useEffect(() => {
    if (location.pathname?.toLowerCase() === '/productosempresa') {
      setUrlRedirect('detalle-empresa-producto')
    }

  }, [location])


  /**
   * 
   * @param {typeDefs.ProductoContratadoDto} item 
   * @returns {React.ElementType}
   */
  const defineTituloPago = (item) => {
    //Producto 01: Dashboards/API.../Innovation Lab
    //Fecha próximo pago: 
    // prueba gratuita de dashboards -> Fecha vencimiento
    // [api gratuita,innovation lab gratuito] -> "gratis"
    // contratado con transferencia -> Fecha vencimiento y tooltip ("Este producto fue suscrito con transferencia, por lo que solo estará activo el mes en curso pagado.Para seguir utilizando el servicio el próximo mes debes volver a pagar.")

    //botón Cancelar:
    //suscrito con transferencia-> reemplazar por boton "Pagar próximo mes".
    //Si es prueba gratuita de dashboards no debe aparecer este botón.

    if (item) {
      if (item.NombreTipoPlan === 'Básico' || !item.ValidaHasta) {
        return <>Gratis</>
      }
      else {
        return <>Vigente hasta:</>
      }
    }
  }

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {productos && productos.length > 0 ? (
          <>
            {productos?.map((item, index) => (
              <Col key={index} className="gutter-row" xs={24} sm={24} md={12} lg={8} style={{ marginBottom: '25px', display: 'flex' }}>
                <Card
                  title=" "
                  className="cardProducto"
                  hoverable={true}
                  onClick={() => navigate(`/${urlRedirect}/${item.IdProductoContratado}/${item.IdSuscripcion}`)}
                  cover={<LogoProducto idTipoProducto={item.IdTipoProducto} ajustarAlto={true}></LogoProducto>}
                >
                  <div className='descripcionDelProducto'>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start" style={{ display: 'flex' }}>
                      <Col span={24} style={{ textAlign: 'left', display: 'flex' }}>
                        <p className="tituloProducto">{item.NombreProducto}</p>
                      </Col>
                      <Col span={24} style={{ display: 'flex' }}>
                        <p className='descripcionProducto'>{item.DescripcionProducto}</p>
                      </Col>
                    </Row>
                  </div>
                  {location.pathname?.toLowerCase() === '/productosempresa' && (
                    <>
                      <Row>
                        <Col span={24} style={{ display: 'flex', marginTop: 5 }}>
                          <p className='tituloProducto' style={{ marginBottom: 0, paddingBottom: 0 }}>Usuarios asociados</p>
                        </Col>
                        <Col span={24} style={{ display: 'flex', marginTop: 5 }}>
                          <ListaUsuariosGrupo claseTexto={'descripcionProducto'} usuarios={item.UsuariosAsignados}></ListaUsuariosGrupo>
                        </Col>
                      </Row>
                      <br />
                    </>)}
                  <div className='planDeProducto'>
                    <PlanProductoEnCard maxHeigthTitulo={20} idTipoProducto={item.IdTipoProducto} ajustarAltoInnovationLab={true} sacarMargen={true} planProducto={{ nombreTipoPlan: item.NombreTipoPlan, idTipoPlan: item.IdTipoPlan, subtitulo: item.subtitulo }} ></PlanProductoEnCard>
                  </div>
                  <br />
                  <div className='vigenciaDeProducto'>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ textAlign: 'left' }}>
                      <Col span={12} style={{ textAlign: 'left' }}>
                        <p className="activo">Activo desde:</p>
                      </Col>
                      <Col span={12} style={{ fontWeight: '600' }}>
                        <p>{defineTituloPago(item)}</p>
                      </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ textAlign: 'left' }}>
                      <Col span={12} style={{ textAlign: 'left' }}>
                        <label>{dayjs(item.FechaInicio).format('DD/MM/YYYY')}</label>
                      </Col>
                      {item.NombreTipoPlan !== 'Básico' && item.ValidaHasta ?
                        <Col span={12}>
                          <label>{dayjs(item.ValidaHasta).format('DD/MM/YYYY')}</label>
                        </Col> : null}
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </>
        ) : (
          <Col className="gutter-row" xs={24} style={{ marginBottom: '25px' }}>
            <Card>
              <p style={{ color: '#838383', fontSize: 16 }}>{isLoading ? ' cargando...' : 'Todavía no has contratado productos.'}</p>
            </Card>
          </Col>
        )}

      </Row>
    </>
  )
}
CardContent.propTypes = {
  productos: PropTypes.arrayOf(
    PropTypes.shape({
      CodigoProducto: PropTypes.string,
      NombreProducto: PropTypes.string,
      DescripcionProducto: PropTypes.string,
      IdProductoContratado: PropTypes.number,
      IdSuscripcion: PropTypes.number,
      NombreTipoPlan: PropTypes.string,
      IdTipoPlan: PropTypes.number,
      IdTipoProducto: PropTypes.number,
      NombreTipoProducto: PropTypes.string,
      FechaInicio: PropTypes.string,
      ValidaHasta: PropTypes.string
      // FechaInicio: PropTypes.instanceOf(Date),
      // ValidaHasta: PropTypes.instanceOf(Date)
    })
  )
}
export default CardContent