import { UserAddOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal } from 'antd'
import React from 'react'

const ModalExitoCrearCuentaConsumidor = ({ isModalOpen, handleOk, fueCorrecto }) => {

    const onAceptar = () => {
        handleOk()
    }

    return (
        <div>
            <Modal
                closable={false}
                centered
                open={isModalOpen}
                onOk={handleOk}
                width={450}
                footer={[
                    <Button key="submit" type="primary" shape="round" onClick={() => onAceptar()}>
                        Aceptar
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    {fueCorrecto ?
                        <Avatar style={{ backgroundColor: '#0F00D8' }} size={80} icon={<UserAddOutlined />} /> :
                        <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<CloseCircleOutlined />} />
                    }

                    {fueCorrecto ? <h2 style={{ marginBottom: '5px' }}>Creación de usuario exitosa</h2> : null}
                    {fueCorrecto ?
                        <p>
                            Se envió un correo al usuario para que active su cuenta y pueda ingresar al portal
                        </p>
                        :
                        <p>
                            ¡Ha ocurrido un error, intentelo nuevamente!
                        </p>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default ModalExitoCrearCuentaConsumidor