import { Button, Card, Col, Checkbox, Collapse, Form, Input, Row, Select, Typography } from 'antd';

import Link from 'antd/es/typography/Link'
import { getGuiaInicio } from '../../services/documentoApi';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setIsLoading, setRefrescarNotificaciones } from '../../redux/reducers/AppReducer';
import ModalEnvioMsj from './ModalEnvioMsj';
import { CapitalizeFirstLetter } from '../../services/Helpers/stringHelper';
import { sendContacto } from '../../services/publicApi';
import PreguntasFrecuentesEmpresa from './PreguntasFrecuentesEmpresa';
import PreguntasFrecuentesPersona from './PreguntasFrecuentesPersona';
import { PerfilUsuarioId } from '../../enum/enums';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const Contacto = () => {
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const [form] = Form.useForm()
    const usuario = useSelector(state => state.aplicacion.usuario)

    useEffect(() => {
        const fetchData = async () => {
            if (usuario) {
                form.setFieldValue('nombre', CapitalizeFirstLetter(`${usuario.nombres} ${usuario.apellidos}`))
                form.setFieldValue('correo', usuario.email)
            }
        }
        fetchData()
    }, [usuario])

    const clearAnyInputs = () => {
        form.setFieldValue('mensaje', '')
        form.setFieldValue('motivo', null)
    }

    const onFinish = async (values) => {

        try {
            dispatch(setIsLoading(true))
            await sendContacto(values)
            dispatch(setRefrescarNotificaciones(true))
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setOpenModal(true)
            dispatch(setIsLoading(false))
            clearAnyInputs()
        }
    }

    const validateEmail = (rule, value) => {
        if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject('Ingresa un correo electrónico válido');
    };

    const onDownloadGuiaInicio = async (idTipoCliente) => {
        dispatch(setIsLoading(true))
        try {
            const docRes = await getGuiaInicio(idTipoCliente)

            if (docRes) {
                const blob = new Blob([docRes.buffer], { type: 'application/pdf' });
                const linkRedirect = URL.createObjectURL(blob);

                window.open(linkRedirect, "_blank");
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    return (
        <>
            <div className='content-contacto'>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} style={{ textAlign: 'left' }}>
                        <Title level={2}>Preguntas frecuentes</Title>
                        <Row gutter={[16, 16]}>
                            <Col className="gutter-row" span={24}>
                                <Text>A continuación, te entregamos una selección de las preguntas que recibimos con mayor frecuencia y sus respuestas.</Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className='collapse-questions' >
                    {usuario?.perfilId === PerfilUsuarioId.PERSONA_NATURAL ? <PreguntasFrecuentesPersona onDownloadGuiaInicio={onDownloadGuiaInicio}></PreguntasFrecuentesPersona> : <PreguntasFrecuentesEmpresa onDownloadGuiaInicio={onDownloadGuiaInicio}></PreguntasFrecuentesEmpresa>}
                </div>
                <Row gutter={[16, 16]}>
                    <Col className="gutter-row" span={24} style={{ textAlign: 'left' }}>
                        <Title level={2}> Soporte Marketplace</Title>
                    </Col>
                    <Col span={24} className='cardhablemos'>
                        <Card style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 50px -20px' }}>
                            <Form form={form} onFinish={onFinish} layout="vertical" >
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                        <Row gutter={0}>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Text >Completa el siguiente formulario para enviarnos un mensaje:</Text>
                                                <Form.Item
                                                    style={{ marginTop: 10 }}
                                                    name="nombre"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Ingresa tu nombre',
                                                        }
                                                    ]}
                                                >
                                                    <Input readOnly={true} placeholder='Nombre' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Row gutter={0}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="motivo"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Selecciona tu consulta',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                placeholder='Selecciona el motivo de tu consulta'
                                                                options={[
                                                                    {
                                                                        value: 'consulta',
                                                                        label: 'Consulta',
                                                                    },
                                                                    {
                                                                        value: 'sugerencia',
                                                                        label: 'Sugerencia',
                                                                    },
                                                                    {
                                                                        value: 'ayuda',
                                                                        label: 'Necesito ayuda',
                                                                    },
                                                                    {
                                                                        value: 'otro',
                                                                        label: 'Otro',
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="correo"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Ingresa tu correo electrónico',
                                                                },
                                                                {
                                                                    validator: validateEmail,
                                                                }
                                                            ]}
                                                        >
                                                            <Input readOnly={true} placeholder='Ingresa tu correo' />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                        <Row>
                                            <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
                                                <div style={{ height: 30 }}></div>
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'left' }}>
                                                <Form.Item
                                                    name="mensaje"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Ingresa un mensaje'
                                                        }
                                                    ]}
                                                >
                                                    <Input.TextArea rows={4} placeholder='Escribe tu mensaje...' name="message" />
                                                </Form.Item>
                                            </Col>
                                            <Col className="contactenos" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'right' }}>
                                                <Button shape='round' htmlType="submit" type='primary' style={{ marginTop: 0, fontWeight: '500', width: '70%', maxWidth: 200 }}>Enviar mensaje</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>

            <ModalEnvioMsj isModalOpen={openModal} onClose={() => setOpenModal(false)} />
        </>
    )
}

export default Contacto