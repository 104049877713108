import { Button, Card, Col, Row, Avatar, Image } from 'antd'
import { EyeOutlined, TagOutlined, DollarOutlined, PushpinOutlined, DatabaseOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from '../../redux/reducers/AppReducer'
import { getProductosContratables } from '../../services/api'
import { SeparadorMiles } from '../../services/Helpers/stringHelper'
import { PerfilUsuarioId } from '../../enum/enums'
import { setProductosContratables, setProductosContratablesFiltrados } from '../../redux/reducers/ProductoReducer'
import './ProductoServicio.style.scss'
import finger from '../../assets/images/logos/finger.svg'
import fingerEmpresa from '../../assets/images/logos/finger-empresa.svg'

const ProductoYServicio = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(1)
    // const productos = useSelector(state => state.producto.productosContratablesOriginales)
    const productosFiltrados = useSelector(state => state.producto.productosContratablesFiltrados)
    const perfilId = useSelector(state => state.aplicacion.usuario.perfilId)

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const data = await getProductosContratables()
                dispatch(setProductosContratables(data))
                dispatch(setProductosContratablesFiltrados(data))
            }
            catch (error) {
                console.log(error)
            }
            finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData()
    }, [])

    const getClassCategoria = (idCategoria) => {
        if (categoriaSeleccionada === null) {
            return 'categoriaSeleccionada'
        }
        else {
            if (categoriaSeleccionada === idCategoria) {
                return 'categoriaSeleccionada'
            }
            else {
                return 'categoriaNoSeleccionada'
            }
        }
    }

    return (
        <>
            <Row gutter={{ xs: 8, sm: 10, md: 20, lg: 20 }} style={{ marginTop: 20, marginBottom: 20 }}>
                <Col xs={10} sm={7} md={4} lg={3} xl={4} xxl={3}>
                    <Card
                        className={`${getClassCategoria(1)} colorFondo`}
                        style={{
                            height: 125,
                            cursor: 'pointer'
                        }}
                        onClick={() => setCategoriaSeleccionada(1)}
                    >
                        <PushpinOutlined className="colorLetra" style={{ fontSize: 45 }} />
                        <p className="colorLetra" style={{ fontWeight: 'bold', marginTop: 5, fontSize: 14 }}> Todo </p>
                    </Card>
                </Col>
                <Col xs={10} sm={7} md={4} lg={3} xl={4} xxl={3}>
                    <Card
                        className={`${getClassCategoria(2)} colorFondo`}
                        style={{
                            height: 125,
                            cursor: 'pointer'
                        }}
                        onClick={() => setCategoriaSeleccionada(2)}
                    >
                        <DatabaseOutlined className="colorLetra" style={{ fontSize: 45 }} />
                        <p className="colorLetra" style={{ fontWeight: 'bold', marginTop: 5, fontSize: 14 }}> Servicios de información </p>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={{ xs: 8, sm: 10, md: 20, lg: 20 }}>
                {productosFiltrados?.map((item, index) => (
                    <Col xs={24} sm={12} md={12} lg={8} key={index} style={{ marginBottom: '20px' }}>
                        <Card style={{ height: '100%' }} className='cardMisProductos'>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: '10px', marginLeft: '1px' }}>
                                <Avatar className="colorFondo colorLetra" size={55} icon={<TagOutlined />} />
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start" style={{ display: 'flex' }}>
                                <Col span={24} style={{ textAlign: 'left', display: 'flex' }}>
                                    <p className="tituloProducto">{item.NombreProducto}</p>
                                </Col>
                                <Col span={24} style={{ display: 'flex' }}>
                                    <p className='descripcionProducto'>{item.DescripcionProducto}</p>
                                </Col>
                            </Row>
                            <Row gutter={0} style={{ marginBottom: '0px', marginTop: '10px' }}>
                                <Col span={'auto'} className="containerLogoMano">
                                    <Image
                                        style={{ height: 50 }}
                                        preview={false}
                                        src={perfilId === PerfilUsuarioId.PERSONA_NATURAL ? finger : fingerEmpresa}
                                    />
                                </Col>
                                <Col span={'auto'} style={{ textAlign: 'left' }}>
                                    <p style={{ marginTop: 20 }}><span style={{ paddingBottom: 0, paddingTop: 10, marginLeft: -5, fontWeight: 400, color: '#838383' }}> {item.CantidadPlanes}  {item.CantidadPlanes > 1 ? 'planes disponibles' : 'plan disponible'}</span></p>
                                </Col>
                            </Row>
                            <Row gutter={0} style={{ marginBottom: '10px', marginTop: '0px' }}>
                                <Col span={'auto'}>
                                    <DollarOutlined className="letraColorCliente" style={{ fontSize: 25, marginRight: 5, marginTop: 12 }} />
                                </Col>
                                <Col span={'auto'} style={{ textAlign: 'left' }}>
                                    <p><span style={{ paddingBottom: 10, fontWeight: '600', color: '#838383' }}> Precio desde:</span> <span style={{ color: '#838383', fontWeight: 500 }}>UF {SeparadorMiles(item.PrecioReferencia)} /mes</span></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <Col span={24}>
                                    <Button style={{ width: '100%', height: '40px' }} icon={<EyeOutlined />} shape='round' type='primary' onClick={() => navigate(`/detalle-producto-servicio/${item.IdProducto}`)}>Ver más detalle</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default ProductoYServicio