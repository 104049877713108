import { useEffect } from 'react'
import FormCompletarCuentaEmpresa from '../../components/formCrearCuentaEmpresa/FormCompletarCuentaEmpresa'
import { useSelector, useDispatch } from 'react-redux'
import { getUserSession } from '../../services/api'
import { setIsLoading, setUsuario } from '../../redux/reducers/AppReducer'
import { useNavigate } from 'react-router'

const CompletarCuentaEmpresa = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const usuario = useSelector(state => state.aplicacion.usuario)

  useEffect(() => {
    const fetchData = async () => {
      if (!usuario) {
        try {
          dispatch(setIsLoading(true))
          const data = await getUserSession()
          dispatch(setUsuario(data))
          document.body.classList.add('bg-empresa');

          if (data.finalizoRegistro) {
            navigate('/home')
          }
        }
        catch {
        }
        finally {
          dispatch(setIsLoading(false))
        }
      }
    }
    fetchData()
  }, [usuario])

  return (
    <div><FormCompletarCuentaEmpresa /></div>
  )
}

export default CompletarCuentaEmpresa