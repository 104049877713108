import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Table, Button, Row, Col, Card } from 'antd';
import { useMediaQuery } from 'react-responsive'
import { SeparadorMiles } from '../../services/Helpers/stringHelper';
import { useSelector } from 'react-redux';
const dayjs = require('dayjs')

const TableMisPagos = ({ pagos }) => {
    const isLoading = useSelector(store => store.aplicacion.isLoading)
    const navigate = useNavigate()
    const [registrosPorPagina, setRegistrosPorPagina] = useState(10)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    //desactiva busqueda en paginador, ya que ant. no lo permite
    useEffect(() => {
        const element = document.querySelectorAll('input[type=search]')[0]

        if (element) {
            element.setAttribute('readonly', true)
        }
    }, [document.querySelectorAll('input[type=search]')])


    const columns = [
        {
            title: 'Producto',
            dataIndex: 'producto',
            sorter: (a, b) => a.producto.localeCompare(b.producto, 'es', { ignorePunctuation: true }),
        },
        {
            title: 'Plan',
            dataIndex: 'planProducto',
            sorter: (a, b) => a.planProducto.localeCompare(b.planProducto, 'es', { ignorePunctuation: true }),
        },
        {
            title: 'Monto UF',
            dataIndex: 'montoUF',
            align: 'center',
            sorter: (a, b) => a.montoUF - b.montoUF,
            render: (value) => <>{SeparadorMiles(value)}</>
        },
        {
            title: 'Monto CLP',
            dataIndex: 'montoClP',
            align: 'center',
            sorter: (a, b) => a.montoClP - b.montoClP,
            render: (value) => <>${SeparadorMiles(value)}</>
        },
        {
            title: 'Fecha de pago',
            dataIndex: 'fechaPago',
            render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>,
            sorter: (a, b) => a.fechaPago.localeCompare(b.fechaPago)
        },
        {
            title: 'Fecha de vencimiento',
            dataIndex: 'fechaVencimiento',
            sorter: (a, b) => a.fechaVencimiento.localeCompare(b.fechaVencimiento),
            render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>
        },
        {
            title: 'Medio de pago',
            dataIndex: 'metodoPago',
            sorter: (a, b) => a.metodoPago.localeCompare(b.metodoPago, 'es', { ignorePunctuation: true }),
        }
    ];

    const handleChange = (pagination, filters, sorter) => {
        if (pagination.pageSize !== registrosPorPagina) {
            setRegistrosPorPagina(pagination.pageSize)
        }
    };


    return (
        <>
            {isTabletOrMobile ?
                <>
                    {pagos?.map((item, index) => (
                        <Card key={`prd-${index}`} className="cardTable">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="titulo"><strong>Producto</strong></p>
                                </Col>
                                <Col span={12}>
                                    <p className="titulo"><strong>Plan</strong></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="subTitulo">{item.producto}</p>
                                </Col>
                                <Col span={12}>
                                    <p className="subTitulo">{item.planProducto}</p>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="titulo"><strong>Monto UF</strong></p>
                                </Col>
                                <Col span={12}>
                                    <p className="titulo"><strong>Monto CLP</strong></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="subTitulo">{SeparadorMiles(item.montoUF)}</p>
                                </Col>
                                <Col span={12}>
                                    <p className="subTitulo">${SeparadorMiles(item.montoClP)}</p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="titulo"><strong>Fecha de pago</strong></p>
                                </Col>
                                <Col span={12}>
                                    <p className="titulo"><strong>Fecha de vencimiento</strong></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="subTitulo">{dayjs(item.fechaPago).format('DD/MM/YYYY')}</p>
                                </Col>
                                <Col span={12}>
                                    <p className="subTitulo">{dayjs(item.fechaVencimiento).format('DD/MM/YYYY')}</p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >

                                <Col span={12}>
                                    <p className="titulo"><strong>Método de pago</strong></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="subTitulo">{item.metodoPago}</p>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </>
                :
                <Table
                    columns={columns}
                    dataSource={pagos}
                    rowKey='idPago'
                    onChange={handleChange}
                    locale={{ emptyText: `${isLoading ? ' cargando...' : 'Todavía no se han realizado pagos.'}` }}
                    pagination={{
                        locale: {
                            items_per_page: " / por página",
                            prev_page: 'Página anterior',
                            next_page: 'Siguiente página',
                            prev_5: '5 páginas anteriores',
                            next_5: 'Siguientes 5 páginas',
                            prev_3: '3 páginas anteriores',
                            next_3: 'Siguientes 3 páginas'
                        },
                        showSearch: false,
                        pageSize: 10,
                        readonly: true,
                        pageSize: registrosPorPagina,
                        pageSizeOptions: [5, 10, 15, 20],
                        showSizeChanger: true,
                    }}
                />
            }
        </>)
};

export default TableMisPagos