import { CheckOutlined } from '@ant-design/icons'

import './ProductoServicio.style.scss'

const DescripcionPlanInnovationLabs = ({ descripcion, cantidadNucleos, memoriaRam, usuariosSugeridosInnovationLab }) => {
    return (
        <div>
            <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" style={{ marginRight: 2 }} />Usuarios: {usuariosSugeridosInnovationLab}</p>
            <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" style={{ marginRight: 2 }} />Memoria Ram: {memoriaRam}</p>
            <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" /> vCores: {cantidadNucleos}</p>
            <p className='descripcion containerInnoLab' style={{ textAlign: 'left' }}><CheckOutlined className="letraColorCliente" /> <span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}>{descripcion}</span></p>
        </div>
    )
}

export default DescripcionPlanInnovationLabs
