import DetalleSolicitud from '../../components/Empresa/Solicitudes/DetalleSolicitud'
import { useParams } from 'react-router-dom'

const DetalleSolicitudPage = () => {
  const { id } = useParams()

  return (
    <div><DetalleSolicitud id={id} /></div>
  )
}

export default DetalleSolicitudPage