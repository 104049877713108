import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal, Row, Col } from 'antd'
import './Modal.styles.scss'

const ModalAvisos = ({ isModalOpen, onClose, mensaje, fueCorrecto, titulo, msjBoton }) => {
    return (
        <div className="modalAvisos">
            <Modal
                closable={false}
                centered
                open={isModalOpen}
                onOk={onClose}
                width={450}
                footer={[
                    <Button key="submit" type="primary" shape="round" onClick={onClose}>
                        {msjBoton ?? 'Aceptar'}
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    {fueCorrecto ?
                        <Avatar className="avatar-correcto"  size={80} icon={<CheckCircleOutlined />} /> :
                        <Avatar style={{ backgroundColor: '#ff4201' }} size={80} icon={<CloseCircleOutlined />} />
                    }

                    {titulo ?
                        <Row>
                            <Col span={24}>
                                <p className='msjTitulo'>
                                    {titulo}
                                </p>
                            </Col>
                        </Row> : null}

                    <Row>
                        {mensaje?.split('##').map((value, index) => (
                            <Col key={`m-${index}`} span={24}>
                                <p style={{ fontSize: 16, marginBottom: 0, textAlign: 'center' }}>
                                    {value}
                                </p>
                            </Col>
                        ))}

                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default ModalAvisos