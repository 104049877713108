
import { Col, Form, Input, Row, Typography, Button } from 'antd'
import { useSelector } from 'react-redux';
import { CapitalizeFirstLetter } from '../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../services/Helpers/RutHelper';
import { useState } from 'react';
import { sendEmailCambioPassword } from '../../services/api';
import ModalAvisos from '../modal/ModalAvisos';
import './FormMisDatos.styles.scss'
import { PerfilUsuarioId } from '../../enum/enums';
import { useNavigate } from 'react-router';
const dayjs = require('dayjs')
const { Title } = Typography;

const FormPerfilPersonaLectura = () => {
    const navigate = useNavigate()
    const [isOpenModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState(null)
    const [generandoEmailContrasena, setGenerandoEmailContrasena] = useState(false)
    const usuario = useSelector(state => state.aplicacion.usuario)

    const onCambioContrasena = async () => {
        try {
            setGenerandoEmailContrasena(true)
            const result = await sendEmailCambioPassword()

            if (result) {
                setDataModal({
                    mensaje: 'Te enviamos un correo con instrucciones para que puedas actualizar tu contraseña',
                    fueCorrecto: true
                })
            }
        }
        catch {
            setDataModal({
                mensaje: '¡Ha ocurrido un error, por favor inténtelo nuevamente!',
                fueCorrecto: false
            })
        }
        finally {
            setGenerandoEmailContrasena(false)
            setOpenModal(true)
        }
    }

    const onCloseModalAvisos = () => {
        window.location.replace(process.env.REACT_APP_URL_ONBOARDING_PUBLICO);
        setOpenModal(false)
    }


    return (
        <>
            <ModalAvisos
                isModalOpen={isOpenModal}
                onClose={() => onCloseModalAvisos()}
                mensaje={dataModal?.mensaje}
                fueCorrecto={dataModal?.fueCorrecto}
            ></ModalAvisos>
            <Title level={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>Mis datos personales</Title>
            <br />
            <Form className='formMisDatosLectura formLabelStrongs' layout='vertical' >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label="Nombres" tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                            <Input readOnly={true} style={{ paddingLeft: '0px' }} placeholder={CapitalizeFirstLetter(usuario?.nombres)} bordered={false} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label='Apellidos' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                            <Input readOnly={true} placeholder={CapitalizeFirstLetter(usuario?.apellidos)} bordered={false} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label='Número de Identificación (RUT)' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                            <Input readOnly={true} placeholder={daFormatoRut(usuario?.numeroIdentificacion)} bordered={false} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label='Fecha de nacimiento' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                            <Input readOnly={true} placeholder={dayjs(usuario?.fechaNacimiento).format('DD/MM/YYYY')} bordered={false} />
                        </Form.Item>
                    </Col>
                    {usuario.perfilId === PerfilUsuarioId.PERSONA_NATURAL ? <>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label="Nacionalidad">
                                <Input readOnly={true} placeholder={usuario?.nacionalidad} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='País'>
                                <Input readOnly={true} placeholder={usuario?.pais} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Región'>
                                <Input readOnly={true} placeholder={usuario?.region} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Ciudad'>
                                <Input readOnly={true} placeholder={usuario?.comuna} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Dirección'>
                                <Input readOnly={true} placeholder={usuario?.direccion} bordered={false} />
                            </Form.Item>
                        </Col>
                    </> : null}
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label='Télefono fijo o Celular'>
                            <Input readOnly={true} placeholder={usuario?.celular} bordered={false} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label='Correo electrónico'>
                            <Input readOnly={true} placeholder={usuario?.email} bordered={false} />
                        </Form.Item>
                    </Col>
                    {usuario?.profesionOficio ?
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item label='Profesión u Oficio'>
                                <Input readOnly={true} placeholder={usuario?.profesionOficio} bordered={false} />
                            </Form.Item>
                        </Col> : null}

                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label='Cargo'>
                            <Input readOnly={true} placeholder={usuario?.cargo} bordered={false} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label='Área del cargo'>
                            <Input readOnly={true} placeholder={usuario?.areaCargo} bordered={false} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8}>
                        <Form.Item label='Contraseña'>
                            <Input readOnly={true} placeholder='************' bordered={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={11} sm={11} md={8} lg={5}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                disabled={false}
                                style={{ width: '100%', marginTop: 5 }}
                                onClick={() => navigate('/editar-perfil')}
                            >
                                Editar perfil
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={13} sm={13} md={8} lg={5}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="button"
                                shape="round"
                                loading={generandoEmailContrasena}
                                disabled={false}
                                style={{ width: '100%', marginTop: 5 }}
                                onClick={() => onCambioContrasena()}
                            >
                                Cambiar contraseña
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default FormPerfilPersonaLectura