import React, { useEffect } from 'react'
import Layouts from '../components/layout/Layout'
import { useDispatch, useSelector } from "react-redux";
import { getUserSession } from '../services/api';
import { setUsuario, setIsLoading } from '../redux/reducers/AppReducer';
import { BreadcrumbProvider } from '../utils/BreadcrumbContext'
import { PerfilUsuarioId } from '../enum/enums';
import { useNavigate } from 'react-router';
import Loader from '../components/Loader/loader';

const Principal = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const usuario = useSelector(state => state.aplicacion.usuario)

  useEffect(() => {
    const fetchData = async () => {
      try {

        const data = await getUserSession()
        if (data) {
          dispatch(setUsuario(data))

          if (data.perfilId === PerfilUsuarioId.PERSONA_NATURAL) {
            document.body.classList.add('bg-persona');
          } else if (data.perfilId === PerfilUsuarioId.CONSUMIDOR_EMPRESA || data.perfilId === PerfilUsuarioId.ADMIN_EMPRESA) {
            document.body.classList.add('bg-empresa');
          }

          if (!data.finalizoRegistro && data.perfilId === PerfilUsuarioId.ADMIN_EMPRESA) {
            navigate('/completar-cuenta')
          }
        }
        else {
          navigate('/noAutorizado')
        }
      }
      catch {
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {usuario ?
        <BreadcrumbProvider>
          <Layouts />
        </BreadcrumbProvider>
        :
        <Loader isLoadingExt={true}></Loader>
      }
    </>
  )
}

export default Principal