import { useState, useEffect } from "react"
import { Avatar, Tooltip } from "antd"
import { getInicial, CapitalizeFirstLetter } from "../../../services/Helpers/stringHelper"

const ListaUsuariosGrupo = ({ usuarios, claseTexto }) => {
    const [cantidadMaxima, setCantidadMaxima] = useState(3)
    const [usuariosFaltantes, setUsuariosFaltantes] = useState(0)

    useEffect(() => {
        if (usuarios?.length > cantidadMaxima) {
            setUsuariosFaltantes(usuarios.length - cantidadMaxima)
        }
    }, [usuarios])


    return (
        <>
            {usuarios?.length > 0 ?
                <Avatar.Group
                    size='small'
                    max={{
                        count: 2,
                    }}
                >
                    {usuarios?.map((item, index) => {
                        if (index < cantidadMaxima) {
                            return <Tooltip key={`${index}-usuAsig`} placement="top" title={CapitalizeFirstLetter(item)}>
                                <Avatar style={{ backgroundColor: 'rgb(255, 66, 1)' }}>{getInicial(item)}</Avatar>
                            </Tooltip>

                        }
                    })}

                    {usuariosFaltantes > 0 && (
                        <Avatar style={{ backgroundColor: 'rgb(255, 66, 1)' }}>+{usuariosFaltantes}</Avatar>
                    )}
                </Avatar.Group> : <p style={{ marginTop: 0 }} className={claseTexto}>No posee usuarios asociados</p>}
        </>
    )
}

export default ListaUsuariosGrupo