import { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import Loader from '../Loader/loader'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { enviaMailBienvenida, enviaMailCambioPassword } from '../../services/publicApi'


const RedirectToLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { keycloak } = useKeycloak()
  const isLoggedIn = keycloak.authenticated

  useEffect(() => {
    const idProducto = searchParams.get("idProducto")
    const esPruebaGratis = searchParams.get("esPruebaGratis")
    const email = searchParams.get("email")
    const emailPassword = searchParams.get("emailPassword")
    let urlPath = ''

    if (emailPassword) {
      enviaMailCambioPassword(emailPassword)
    }

    if (email) {
      enviaMailBienvenida(email)
    }

    if (idProducto && esPruebaGratis) {
      urlPath = `?idProducto=${idProducto}&esPruebaGratis=${esPruebaGratis}`
    }

    if (isLoggedIn) {
      navigate(`/puente${urlPath}`)
    }
    else {
      keycloak.login({ redirectUri: `${process.env.REACT_APP_URL}/puente${urlPath}` })
    }

  }, [])

  return (<Loader isLoadingExt={true}></Loader>)
}

export default RedirectToLogin