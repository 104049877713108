import { useSelector } from "react-redux"
import { useEffect } from "react"
import { PerfilUsuarioId } from "../../enum/enums"
import DetalleProducto from "../../components/detalleProducto/DetalleProducto"
import { useNavigate } from "react-router"

const DetalleProductoEmpresa = () => {
    const idPerfilUsuario = useSelector(state => state.aplicacion.usuario.perfilId)
    const navigate = useNavigate()

    useEffect(() => {
        if (idPerfilUsuario !== PerfilUsuarioId.ADMIN_EMPRESA) {
            navigate('/productos')
        }
    }, [idPerfilUsuario])

    return (
        <>
            <DetalleProducto></DetalleProducto>
        </>
    )
}

export default DetalleProductoEmpresa