import React from 'react'
import { Typography } from 'antd'

import Resultado from '../../components/khipu/Resultado'
const { Text, Title } = Typography

const ResultPage = () => {

  return (
    <Resultado />

  )
}

export default ResultPage