import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Principal from '../pages/Principal'
import HomePersona from '../pages/personas/HomePersona'
import ProductoPersona from '../pages/personas/ProductoPersona'
import ProductosEmpresa from '../pages/empresa/ProductosEmpresa'
import PerfilPersona from '../pages/personas/PerfilPersona'
import MediosDePagoPersona from '../pages/personas/MediosDePagoPersona'
import Contacto from '../components/contacto/Contacto'
import EditarContraseña from '../components/contraseña/EditarContraseña'
import AgregarMetodoPago from '../components/metodos/AgregarMetodoPago'
import CrearCuenta from '../pages/personas/CrearCuenta'
import DetalleSolicitudPage from '../pages/empresa/DetalleSolicitud'
import SolicitarPeriodoPrueba from '../components/contratarProducto/SolicitarPeriodoPrueba'
import DeclaracionCompliance from '../pages/personas/DeclaracionCompliance'
import ProductoServicio from '../pages/personas/ProductoServicio'
import { useKeycloak } from '@react-keycloak/web'
import DetalleProductoServicio from '../components/detalleProductoServicio/DetalleProductoServicio'
import ContratarProducto from '../components/contratarProducto/ContratarProducto'
import Loader from '../components/Loader/loader'
import Puente from '../pages/puente/puente'
import Encuesta from '../components/encuesta/Encuesta'
import ResultadoPago from '../components/Transbank/ResultadoPago'
import CrearCuentaEmpresa from '../pages/empresa/CrearCuentaEmpresa'
import RedirectToLogin from '../components/RedirectToLogin/RedirectToLogin'
import CompletarCuentaEmpresa from '../pages/empresa/CompletarCuentaEmpresa'
import MisSolicitudesPage from '../pages/empresa/MisSolicitudes'
import MisUsuariosPage from '../pages/empresa/MisUsuarios'
import DetalleUsuarioProductosPage from '../pages/empresa/DetalleUsuarioProductos'
import EditarUsuarioEmpresa from '../components/Empresa/Usuarios/EditarUsuarioEmpresa'
import CrearUsuarioEmpresa from '../pages/empresa/CrearUsuarioEmpresa'
import PerfilEmpresa from '../components/Empresa/Perfil/PerfilEmpresa'
import EditarInformacionEmpresa from '../components/Empresa/Perfil/EditarInformacionEmpresa'
import EditarRepresentantesLegales from '../components/Empresa/Perfil/EditarRepresentantesLegales'
import EditarDatosFacturacion from '../components/Empresa/Perfil/EditarDatosFacturacion'
import Pagos from '../pages/personas/Pagos'
import SinPermiso from '../pages/personas/SinPermiso'
import ResultPage from '../pages/khipu/ReturnPage'
import CancelPage from '../pages/khipu/CancelPage'
import FormPerfilPersonaEdicion from '../components/formPerfilPersona/FormPerfilPersonaEdicion'
import DetalleProductoEmpresa from '../pages/empresa/DetalleProductoEmprsa'
import DetalleProductoPersona from '../pages/personas/DetalleProductoPersona'

const AppRoutes = () => {
  const { keycloak, initialized } = useKeycloak()
  const isLoggedIn = keycloak.authenticated;

  if (!initialized) {
    return <Loader isLoadingExt={true}></Loader>
  }

  return (
    //modificar para crear rutas segun rol y no tener que manejar permisos
    <BrowserRouter>
      {isLoggedIn ?
        //rutas privadas
        <Routes>
          <Route path='completar-cuenta' element={<CompletarCuentaEmpresa />} />
          <Route element={<Principal />}>
            <Route path='home' element={<HomePersona />} />
            <Route path='puente' element={<Puente />} />
            <Route path='productos' element={<ProductoPersona />} />
            <Route path='productosEmpresa' element={<ProductosEmpresa />} />
            <Route path='perfil' element={<PerfilPersona />} />
            <Route path='editar-perfil' element={<FormPerfilPersonaEdicion />} />
            <Route path='medios-de-pago/:estado?' element={<MediosDePagoPersona />} />
            <Route path='contactenos' element={<Contacto />} />
            <Route path='detalle-producto/:idProducto/:idSuscripcion/:estado?/:idTransaccion?' element={<DetalleProductoPersona />} />
            <Route path='detalle-empresa-producto/:idProducto/:idSuscripcion/:estado?/:idTransaccion?' element={<DetalleProductoEmpresa />} />
            <Route path='editar-contraseña' element={<EditarContraseña />} />
            <Route path='nuevo-metodo-pago' element={<AgregarMetodoPago />} />
            <Route path='historial-pagos' element={<Pagos />} />
            <Route path='producto-servicio' element={<ProductoServicio />} />
            <Route path='detalle-producto-servicio/:idProducto' element={<DetalleProductoServicio />} />
            <Route path='contratar-producto/:idProducto/:estado?/:idTransaccion?' element={<ContratarProducto />} />
            <Route path='periodo-prueba/:idProducto' element={<SolicitarPeriodoPrueba />} />
            <Route path='encuesta/:idSuscripcion' element={<Encuesta />} />
            <Route path='transbank/resultadoPago/:id?' element={<ResultadoPago />} />
            <Route path='solicitudes' element={<MisSolicitudesPage />} />
            <Route path='usuarios' element={<MisUsuariosPage />} />
            <Route path='detalle-usuario/:idUsuario' element={<DetalleUsuarioProductosPage />} />
            <Route path='editar-usuario/:idUsuarioEditar' element={<EditarUsuarioEmpresa />} />
            <Route path='crear-usuario' element={<CrearUsuarioEmpresa />} />
            <Route path='perfilEmpresa' element={<PerfilEmpresa />} />
            <Route path='perfilEmpresa/informacion' element={<EditarInformacionEmpresa />} />
            <Route path='perfilEmpresa/representantesLegales' element={<EditarRepresentantesLegales />} />
            <Route path='perfilEmpresa/datosFacturacion' element={<EditarDatosFacturacion />} />
            <Route path='detalleSolicitud/:id' element={<DetalleSolicitudPage />} />
            <Route path='khipu/resultado' element={< ResultPage />} />
            <Route path='khipu/cancelado' element={< CancelPage />} />
            <Route path='*' element={<Navigate to='/home' />} />
          </Route>
          <Route path='loginKeyCloack' element={<RedirectToLogin />} />
          <Route path='noAutorizado' element={<SinPermiso />} />
        </Routes>
        :
        <Routes>
          {/* rutas publicas */}
          <Route path='/' element={<DeclaracionCompliance />} />
          <Route path='crear-cuenta' element={<CrearCuenta />} />
          <Route path='crear-cuenta-empresa' element={<CrearCuentaEmpresa />} />
          <Route path='loginKeyCloack' element={<RedirectToLogin />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      }
    </BrowserRouter>
  )
}

export default AppRoutes