import { UserAddOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal } from 'antd'
import React from 'react'
import { AddUser } from 'react-iconly'
import { useNavigate } from 'react-router-dom'

const ModalCompletarDatosEmpresa = ({ isModalOpen, handleOk, fueCorrecto, redirectToLogin }) => {
  const onAceptar = () => {
    if (fueCorrecto && redirectToLogin) {
      window.location.replace(process.env.REACT_APP_URL_ONBOARDING_PUBLICO)
    }
    else {
      handleOk()
    }
  }

  return (
    <div>
      <Modal
        style={{ borderRadius: '4px 20px !important' }}
        className='modal-create-user'
        closable={false}
        centered
        width={335}
        open={isModalOpen}
        onOk={handleOk}
        footer={[
          <Button className='btn-modal-user' key="submit" type="primary" shape="round" onClick={() => onAceptar()}>
            <span className='txtBtnAceptaCuenta'> Aceptar</span>
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          {fueCorrecto ?
            <AddUser stroke='bold' style={{ color: '#FF411C', width: 48, height: 48 }} /> :
            <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<CloseCircleOutlined />} />
          }

          {fueCorrecto ? <h2 className='modal-user-title' style={{ marginBottom: '5px' }}>Datos completados exitosamente</h2> : null}
          {fueCorrecto ?
            <p style={{ fontFamily: 'Degular', fontSize: 16, color: '#515151', letterSpacing: '0.64', fontWeigth: 400 }}>
              Se han completado los datos de la empresa correctamente. Te enviamos un correo con información de los próximos pasos.
            </p>
            :
            <p>
              ¡Ha ocurrido un error, intentelo nuevamente!
            </p>
          }
        </div>
      </Modal>
    </div>
  )
}

export default ModalCompletarDatosEmpresa