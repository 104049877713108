import { Table, Button } from 'antd';
import { useEffect, useState } from 'react';
import { getHistorialPagos } from '../../services/medioPagoApi';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/reducers/AppReducer';
import TableMisPagos from '../Table/TableMisPagos';
const dayjs = require('dayjs')

const HistorialPagos = () => {
  const dispatch = useDispatch()
  const [pagos, setPagos] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setIsLoading(true))
        const data = await getHistorialPagos()

        setPagos(data)
      }
      catch (error) {
        console.log(error)
      }
      finally {
        dispatch(setIsLoading(false))
      }
    }
    fetchData()
  }, [])




  return (
    <div>
      <TableMisPagos pagos={pagos}></TableMisPagos>
    </div>
  )
}

export default HistorialPagos