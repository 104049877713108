import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Image, Button, Card, Col, Row, Space, Typography, Divider, Alert, Tooltip, notification } from 'antd'
import ModalMedioPago from '../modal/ModalMedioPago';
import ModalSuscripciones from '../modal/ModalSuscripciones';
import DescripcionOtroTipoProducto from '../productoServicio/planes/DescripcionOtroTipo';
import { setIsLoading, setRefrescarNotificaciones } from '../../redux/reducers/AppReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getDetalleProducto } from '../../services/api';
import DescripcionPlanInnovationLabs from '../productoServicio/DescripcionPlanInnovationLabs';
import { SeparadorMiles } from '../../services/Helpers/stringHelper';
import PlanProducto from '../productoServicio/planes/PlanProducto'
import { darBajaSuscripcion, asignarRemoverUsuariosAProducto, actualizarMedioPagoSuscripcion, generaPagoProximoMesInnovationLabFree, generaPagoProximoMes } from '../../services/suscripcionApi';
import ModalAdvertenciaBajaSuscripcion from '../modal/ModalEliminacionSuscripcion';
import { EstadoSuscripcionId, MedioPagoId, TipoProductoId, PerfilUsuarioId, TipoPagoKhipuId } from '../../enum/enums';
import ModalAvisos from '../modal/ModalAvisos';
import { useNavigate } from 'react-router-dom'
import TableUsuariosSuscripcion from '../Table/TableUsuariosSuscripcion';
import ModalAsignarUsuarioProducto from '../Empresa/Modal/ModalAsignarUsuarioProducto';
import TableAccesoProductos from '../Table/TableAccesoProductos';
import DetalleProductoApiStartup from './DetalleProductoApiStartup';
import khipu_200x75_1 from '../../assets/images/khipu/07-Khipu-200x75-1.svg'
import webPayImg from '../../assets/images/webpay/webpay.png'
import CardImage from '../PaymentCards/CardImage';
import ModalAvisoPagoMensual from '../modal/ModalAvisoPagoMensual';
import { redirectPostTransbank } from '../Transbank/FormRedirect';
import DescripcionPlanApi from '../productoServicio/DescripcionPlanApi'
import { setNombreProductoSeleccionado } from '../../redux/reducers/ProductoReducer';
import DescripcionPlanDashboard from '../productoServicio/planes/DescripcionPlanDashboard';
import parse from 'html-react-parser';
import { useLocation } from 'react-router'
import './DetalleProductoStyle.scss'
import ModalValidandoKhipu from '../modal/ModalValidandoKhipu';
const typeDefs = require('../../interface/typeDefs')
const dayjs = require('dayjs')
const { Title, Text, Paragraph } = Typography;

const DetalleProducto = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const urlDespliegaBotonesAdmin = '/detalle-empresa-producto'
  const idPerfilUsuario = useSelector(state => state.aplicacion.usuario.perfilId)
  const habilitadoComprar = useSelector(state => state.aplicacion.usuario.habilitadoComprar)
  const rutEmpresa = useSelector(state => state.aplicacion.usuario.rutEmpresa)
  const { idProducto, idSuscripcion, estado, idTransaccion } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [accion, setTipoAccion] = useState('')
  const [showModalAsignacion, setShowModalAsignacion] = useState(false)
  const [openModalAdvertenciaSuscripcion, setModalAdvertenciaSuscripcion] = useState(false)
  const [isModalSuscripcion, setIsModalSuscripcion] = useState(false)
  const [openModalKhipu, setOpenModalKhipu] = useState(false)
  const [isModalOpenAvisoPago, setIsModalOpenAvisoPago] = useState(false)

  // Fuerza una actualizacion del detalle del producto. Se modifica desde componentes hijos
  const [updateProductoDetail, setUpdateProductoDetail] = useState(false)


  const [detalleProducto, setDetalleProducto] = useState(
    /**
       * @type {typeDefs.DetalleProductoContratadoDto}
       */
    (null)
  )
  const [showModalResultado, setShowModalResultado] = useState(false)
  const [dataModal, setDataModal] = useState(null)

  const fetchDetalleProducto = async (idProducto, idSuscripcion) => {
    try {
      dispatch(setIsLoading(true))
      const data = await getDetalleProducto(idProducto, idSuscripcion)
      setDetalleProducto(data)
      dispatch(setNombreProductoSeleccionado({ nombre: data?.nombreProducto, id: data?.idProductoContratado }))
    }
    catch (error) {
      console.log(error)
    }
    finally {
      dispatch(setIsLoading(false))
    }
  }

  useEffect(() => {
    if (estado) {
      if (estado === 'PROCESANDO' || estado === 'CANCELADO') {
        setOpenModalKhipu(true)
      }
      else {
        setIsModalOpenAvisoPago(true)
      }
    }
  }, [estado])

  useEffect(() => {
    fetchDetalleProducto(idProducto, idSuscripcion)
  }, [idProducto, idSuscripcion])

  useEffect(() => {
    if (updateProductoDetail) {
      fetchDetalleProducto(idProducto, idSuscripcion)
    }
  }, [updateProductoDetail])

  useEffect(() => {
    const fetchData = async () => {

      if (accion === 'asignacionUsuario') {
        try {
          dispatch(setIsLoading(true))
          const data = await getDetalleProducto(idProducto, idSuscripcion)
          setDetalleProducto(data)
          setTipoAccion('')
        }
        catch (error) {
          console.log(error)
        }
        finally {
          dispatch(setIsLoading(false))
        }
      }
    }
    fetchData()
  }, [accion])

  const onBajarSuscripcion = async () => {
    try {
      setTipoAccion('DarDeBaja')
      dispatch(setIsLoading(true))
      setModalAdvertenciaSuscripcion(false)
      const result = await darBajaSuscripcion({ productoId: parseInt(idProducto), suscripcionId: parseInt(idSuscripcion) })

      setDataModal({
        mensaje: result.mensaje === '' ? 'Suscripción cancelada exitosamente' : result.mensaje,
        fueCorrecto: result.accionFueCorrecta,
        titulo: result.accionFueCorrecta ? 'Éxito' : 'Error',
        msjBoton: 'Aceptar'
      })

    }
    catch (error) {
      console.log(error)
      setDataModal({
        mensaje: 'Ha ocurrido un error, inténtelo nuevamente.',
        fueCorrecto: false,
        titulo: 'Error'
      })
    }
    finally {
      dispatch(setIsLoading(false))
      setShowModalResultado(true)
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onCloseModalResultado = () => {
    if (dataModal.fueCorrecto && accion === 'DarDeBaja') {
      navigate(`/encuesta/${idSuscripcion}`)
    }
    else {
      setShowModalResultado(false)
    }
  }

  const onGuardarAsignacionUsuarios = async (usuariosSeleccionados) => {
    try {
      dispatch(setIsLoading(true))
      setModalAdvertenciaSuscripcion(false)
      const result = await asignarRemoverUsuariosAProducto({
        idProducto: parseInt(idProducto),
        idSuscripcion: parseInt(idSuscripcion),
        idsUsuarios: usuariosSeleccionados
      })

      setDataModal({
        mensaje: result.mensaje === '' ? 'Se ha actualizado correctamente los usuarios asignados a la suscripción.' : result.mensaje,
        fueCorrecto: result.accionFueCorrecta,
        titulo: result.accionFueCorrecta ? 'Actualización exitosa' : 'Error',
        msjBoton: 'Aceptar'
      })

      setTipoAccion('asignacionUsuario')
    }
    catch (error) {
      console.log(error)
      setDataModal({
        mensaje: 'Ha ocurrido un error, inténtelo nuevamente.',
        fueCorrecto: false,
        titulo: 'Error'
      })
    }
    finally {
      dispatch(setIsLoading(false))
      setShowModalResultado(true)
    }

    if (detalleProducto.esPruebaGratuita && rutEmpresa !== process.env.REACT_APP_RUT_EMPRESA_NUAM) {
      if (usuariosSeleccionados.length > detalleProducto.cantidadMaximaUsuariosPruebaGratis) {
        setDataModal({
          mensaje: `Al ser un período de prueba, no puede asignar más de ${detalleProducto.cantidadMaximaUsuariosPruebaGratis} usuarios.`,
          fueCorrecto: false,
          titulo: 'Error'
        })

        setShowModalResultado(true)
      }
    }
  }

  const defineTituloPago = (item) => {
    //Producto 01: Dashboards/API.../Innovation Lab
    //Fecha próximo pago: 
    // prueba gratuita de dashboards -> Fecha vencimiento
    // [api gratuita,innovation lab gratuito] -> "gratis"
    // contratado con transferencia -> Fecha vencimiento y tooltip ("Este producto fue suscrito con transferencia, por lo que solo estará activo el mes en curso pagado.Para seguir utilizando el servicio el próximo mes debes volver a pagar.")

    //botón Cancelar:
    //suscrito con transferencia-> reemplazar por boton "Pagar próximo mes".
    //Si es prueba gratuita de dashboards no debe aparecer este botón.

    if (item) {
      if (item.tipoPlan?.nombreTipoPlan === 'Básico' || !item.validaHasta) {
        return <>Gratis</>
      }
      else {
        return (<>Vigente hasta:</>)
      }
    }
  }

  const onPagarProximoMes = async (idMedioPago) => {
    if (habilitadoComprar) {

      dispatch(setIsLoading(true))
      try {
        const solicitud = {
          idSuscripcion: parseInt(idSuscripcion),
          idMedioPago
        }
        const data = await generaPagoProximoMes(solicitud)

        if (data && data.accionFueCorrecta && data.data?.token) {
          redirectPostTransbank(data.data.token, data.data.url)
        }
        else if (data && data.accionFueCorrecta && data.data?.simplifiedTransferUrl) {
          window.location.replace(data.data.simplifiedTransferUrl)
        } else {
          //se informa resultado en modal
          setDataModal({
            fueCorrecto: data.accionFueCorrecta,
            titulo: data.accionFueCorrecta ? "Éxito" : "Error",
            mensaje: data.mensaje
          })
          setShowModalResultado(true)
        }

      } catch (error) {
        console.log(error)
        setDataModal({
          fueCorrecto: false,
          titulo: "Error inesperado",
          mensaje: "Ocurrio un error inesperado al intentar iniciar el pago. Por favor, vuelve a intentar pagar tu suscripción, si aún así sigues teniendo problemas contáctate con soporte al correo sacbursatil@bolsadesantiago.com"
        })
        setShowModalResultado(true)
      } finally {
        dispatch(setIsLoading(false))
      }
    }

  }

  const onPagarInnovationfree = async () => {
    const urlRedireccion = idPerfilUsuario === PerfilUsuarioId.ADMIN_EMPRESA ? 'detalle-empresa-producto' : 'detalle-producto'

    try {
      dispatch(setIsLoading(true))

      const solicitud = {
        idSuscripcion: parseInt(idSuscripcion),
        idMedioPago: 0
      }
      const dataResult = await generaPagoProximoMesInnovationLabFree(solicitud)

      if (dataResult.accionFueCorrecta) {
        setUpdateProductoDetail(true)
        dispatch(setRefrescarNotificaciones(true))
        navigate(`/${urlRedireccion}/${idProducto}/${idSuscripcion}/OK`)
      }
    }
    catch (error) {
      console.log(error)
      navigate(`/${urlRedireccion}/${idProducto}/${idSuscripcion}/NOK`)
    }
    finally {
      dispatch(setIsLoading(false))
    }
  }

  const onPagarMes = () => {
    if (habilitadoComprar) {
      if (detalleProducto?.idTipoProductoContratado === TipoProductoId.INNOVATION_LAB && detalleProducto?.precioProductoContratado === 0) {
        onPagarInnovationfree()
      }
      else {
        setTipoAccion('pagarMes')
        setIsModalOpen(true)

      }
    }
    else {
      notification.open({
        message: 'Empresa en proceso de validación',
        description: 'La empresa está en proceso de aprobación interna. Te enviaremos una notificación cuando ya se encuentre habilitada para contratar productos.',
        duration: 5,
      });
    }
  }

  const onCloseModalKhipu = () => {
    setOpenModalKhipu(false)

    if (estado !== 'CANCELADO') {
      setUpdateProductoDetail(true)
    }
  }


  return (
    <div>
      <ModalAvisoPagoMensual
        isModalOpen={isModalOpenAvisoPago}
        onClose={() => setIsModalOpenAvisoPago(false)}
        fueCorrecto={estado === 'OK'}
      ></ModalAvisoPagoMensual>
      <ModalValidandoKhipu
        tipoAccionId={TipoPagoKhipuId.PAGO_MENSUAL}
        isModalOpen={openModalKhipu}
        onClose={() => onCloseModalKhipu()}
        idTransaccion={idTransaccion}
        status={estado}
      ></ModalValidandoKhipu>
      <ModalAsignarUsuarioProducto onGuardarAsignacionUsuarios={onGuardarAsignacionUsuarios} usuariosAsignados={detalleProducto?.usuariosAsignados} isModalOpen={showModalAsignacion} setShowModal={setShowModalAsignacion}></ModalAsignarUsuarioProducto>
      <ModalAdvertenciaBajaSuscripcion
        isModalOpen={openModalAdvertenciaSuscripcion}
        onClose={() => setModalAdvertenciaSuscripcion(false)}
        idProducto={idProducto}
        onAceptar={onBajarSuscripcion} >
      </ModalAdvertenciaBajaSuscripcion>
      <ModalAvisos
        isModalOpen={showModalResultado}
        onClose={() => onCloseModalResultado()}
        mensaje={dataModal?.mensaje}
        titulo={dataModal?.titulo}
        fueCorrecto={dataModal?.fueCorrecto}></ModalAvisos>

      <Row >
        <Col span={24}>
          <Title level={3} style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 0 }}>Detalle suscripción</Title>
        </Col>
        <Col xs={24} style={{ marginTop: 10 }}>
          {/* Consultar por texto a mostrar cuando la suscripción no esté vigente*/}
          {detalleProducto?.idEstadoSuscripcion === EstadoSuscripcionId.DADO_DE_BAJA && (
            <Alert
              message="Suscripción dada de baja"
              description={idProducto === '1' ? 'Estimado usuario, se ha dado de baja su suscripción a este producto, por lo no podrá acceder a él.' : `Estimado usuario, se ha dado de baja su suscripción a este producto. Podrá acceder a el hasta ${dayjs(detalleProducto?.validaHasta).format('DD/MM/YYYY')}`}
              type="warning"
            />

          )}


          {detalleProducto?.idEstadoSuscripcion === EstadoSuscripcionId.CADUCADA && (
            <Alert
              className='suscripcionCaducada'
              message={"Suscripción caducada"}
              type="error"
            />
          )}
        </Col>

        {detalleProducto?.idTipoProductoContratado === TipoProductoId.PACK_APIS
          && (
            <Col xs={24} sm={24} md={24} lg={24}>
              <DetalleProductoApiStartup
                plan={detalleProducto.tipoPlan?.nombreTipoPlan}
                idSuscripcion={Number(idSuscripcion)}
                detalleProductoApi={detalleProducto?.detalleProductoApiStartup}
                idPerfilUsuarioLogeado={idPerfilUsuario}
                notifyParent={() => setUpdateProductoDetail(true)}
                estadoSuscripcionId={detalleProducto?.idEstadoSuscripcion}
              />
            </Col>)
        }
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 40 }}>
        {/* aca */}
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ paddingRight: 50 }}>
          <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 5 }}>Nombre del producto</p>
          <p style={{ textAlign: 'justify', color: '#838383', marginTop: 0 }}>{detalleProducto?.nombreProducto}</p>
          <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 5 }}>Descripción del producto</p>
          <div className="descripcionDetalle" style={{ textAlign: 'justify', color: '#838383', marginTop: 0 }}>{parse(detalleProducto?.descripcionProducto ?? '')}</div>
          <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 5 }}>Activo desde:</p>
          <p style={{ textAlign: 'justify', color: '#838383', marginTop: 0 }}>{dayjs(detalleProducto?.fechaInicio).format('DD/MM/YYYY')}</p>

          <p style={{ fontWeight: 'bold', textAlign: 'justify', marginBottom: 5 }}>
            {defineTituloPago(detalleProducto)}
          </p>
          {detalleProducto?.tipoPlan?.nombreTipoPlan !== 'Básico' && detalleProducto?.validaHasta ?
            <p style={{ textAlign: 'justify', color: '#838383', marginTop: 0 }}>{dayjs(detalleProducto?.validaHasta).format('DD/MM/YYYY')}</p> : null}

          <div style={{ textAlign: 'left' }}>
            {!detalleProducto?.esPruebaGratuita
              && detalleProducto?.idEstadoSuscripcion === EstadoSuscripcionId.VIGENTE
              && (location.pathname.includes(urlDespliegaBotonesAdmin) || idPerfilUsuario === PerfilUsuarioId.PERSONA_NATURAL) ? (
              <>
                {detalleProducto.medioPago?.idMedioPago === MedioPagoId.KIPHU || detalleProducto.medioPago?.idMedioPago === MedioPagoId.WEBPAY || detalleProducto?.idTipoProductoContratado === TipoProductoId.INNOVATION_LAB ? (
                  <Button
                    type="primary"
                    shape='round'
                    style={{ marginTop: 10 }}
                    onClick={() => onPagarMes()}  >
                    Pagar próximo mes
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    shape='round'
                    danger
                    onClick={() => setModalAdvertenciaSuscripcion(true)}  >
                    Dar de baja suscripción
                  </Button>
                )}
              </>
            ) : null}
          </div>
          <div style={{ textAlign: 'left' }}>
            {idPerfilUsuario !== PerfilUsuarioId.CONSUMIDOR_EMPRESA
              && (detalleProducto?.tipoPlan?.nombreTipoPlan === 'Básico' || detalleProducto?.idTipoProductoContratado === TipoProductoId.INNOVATION_LAB)
              && detalleProducto?.idEstadoSuscripcion === EstadoSuscripcionId.VIGENTE ? (
              <>
                <Button
                  type="primary"
                  shape='round'
                  danger
                  style={{ marginTop: 10 }}
                  onClick={() => setModalAdvertenciaSuscripcion(true)}  >
                  Dar de baja suscripción
                </Button>
              </>
            ) : null}


          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ paddingRight: 40 }} >
          <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>Plan suscrito</p>
          <PlanProducto idTipoProducto={detalleProducto?.idTipoProductoContratado} planProducto={detalleProducto?.tipoPlan} justificarInicio={true} ajustarAltoInnovationLab={true}></PlanProducto>
          <p style={{ textDecoration: 'underline', textAlign: 'justify', fontWeight: 'bold' }}>Este plan incluye:</p>
          {detalleProducto?.idTipoProductoContratado === TipoProductoId.PACK_APIS ?
            <DescripcionPlanApi
              requesAlMes={detalleProducto?.tipoPlan?.requesAlMes}
              mercadoProducto={detalleProducto?.mercadoProducto}
              nombrePlan={detalleProducto?.tipoPlan?.nombreTipoPlan}
              agnoInfoHistorica={detalleProducto?.tipoPlan?.agnoInfoHistorica}
              tiempoDelay={detalleProducto?.tipoPlan?.tiempoDelay}>
            </DescripcionPlanApi> :
            detalleProducto?.idTipoProductoContratado === TipoProductoId.PACK_REPORTES ?
              <DescripcionPlanDashboard texto={detalleProducto?.tipoPlan?.descripcionDashboard}></DescripcionPlanDashboard> :
              detalleProducto?.idTipoProductoContratado === TipoProductoId.INNOVATION_LAB ?
                <DescripcionPlanInnovationLabs descripcion={detalleProducto?.tipoPlan?.descripcionDashboard} cantidadNucleos={detalleProducto?.tipoPlan?.cantidadNucleos} memoriaRam={detalleProducto?.tipoPlan?.memoriaRam} usuariosSugeridosInnovationLab={detalleProducto?.tipoPlan?.usuariosSugeridosInnovationLab}></DescripcionPlanInnovationLabs> :
                <DescripcionOtroTipoProducto plan={detalleProducto?.tipoPlan}></DescripcionOtroTipoProducto>
          }

          {detalleProducto?.idTipoProductoContratado !== TipoProductoId.INNOVATION_LAB && (
            <div style={{ textAlign: 'left' }}>
              <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}>UF {SeparadorMiles(detalleProducto?.precioProductoContratado)} /mes</Title>
            </div>)}
          {detalleProducto?.idEstadoSuscripcion === EstadoSuscripcionId.VIGENTE &&
            detalleProducto.idTipoProductoContratado !== TipoProductoId.REPORTE &&
            !detalleProducto?.esPruebaGratuita &&
            (
              <div style={{ textAlign: 'left' }}>
                <Button style={{ marginTop: 10 }} type='primary' onClick={() => setIsModalSuscripcion(true)} shape='round' >Modificar suscripción</Button>
              </div>
            )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          {detalleProducto?.medioPago ?
            <>
              <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>Medio de pago asociado</p>
              {detalleProducto.medioPago.idMedioPago === MedioPagoId.KIPHU && (
                <>
                  <Card className='cardAgregarTarjeta'>
                    <Row align={"middle"} gutter={16}>
                      <Col span={24}>
                        <Image
                          style={{ height: 50 }}
                          preview={false}
                          src={khipu_200x75_1}
                        />
                      </Col>
                      <Col span={24}>
                        <Text style={{ textAlign: "justify" }}>
                          <p>El último pago se realizó el {dayjs(detalleProducto?.ultimoPagoSuscripcion.fechaHora).format('DD/MM/YYYY')} por un monto de ${SeparadorMiles(detalleProducto?.ultimoPagoSuscripcion.monto)}.</p>
                          <p>
                            Esta suscripción estará vigente hasta {dayjs(detalleProducto?.validaHasta).format('DD/MM/YYYY')}. Si desea continuar utilizando este producto debe volver a pagar el siguiente periodo en esta misma sección o contratar el producto de nuevo.
                          </p>
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </>
              )}
              {detalleProducto.medioPago.idMedioPago === MedioPagoId.WEBPAY && (
                <>
                  <Card className='cardAgregarTarjeta'>
                    <Row align={"middle"} gutter={16}>
                      <Col span={24}>
                        <Image
                          style={{ height: 50 }}
                          preview={false}
                          src={webPayImg}
                        />
                      </Col>
                      <Col span={24}>
                        <Text style={{ textAlign: "justify" }}>
                          <p>El último pago se realizó el {dayjs(detalleProducto?.ultimoPagoSuscripcion.fechaHora).format('DD/MM/YYYY')} por un monto de ${SeparadorMiles(detalleProducto?.ultimoPagoSuscripcion.monto)}.</p>
                          <p>
                            Esta suscripción estará vigente hasta {dayjs(detalleProducto?.validaHasta).format('DD/MM/YYYY')}. Si desea continuar utilizando este producto debe volver a pagar el siguiente periodo en esta misma sección o contratar el producto de nuevo.
                          </p>
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </>
              )}
              {detalleProducto.medioPago?.idMedioPago === MedioPagoId.ONE_CLICK && (
                <>
                  <Card style={{ height: 130 }} className='cardAgregarTarjeta'>
                    <Row align={"middle"}>
                      <Col xs={24} md={6} >
                        <CardImage cardType={detalleProducto.tarjeta?.tipoTarjeta} />
                      </Col>
                      <Col xs={24} md={18}>
                        <p><strong>Tarjeta {detalleProducto.tarjeta?.tipoTarjeta}</strong></p>
                        <p style={{ lineHeight: 0 }}>****{detalleProducto.tarjeta?.numeroTarjeta}</p>
                      </Col>
                    </Row>
                  </Card>
                </>
              )}

              {/* {detalleProducto?.idEstadoSuscripcion === EstadoSuscripcionId.VIGENTE && (
                <Button type="primary" onClick={showModal} shape='round' >Modificar medio de pago</Button>
              )} */}
            </> : null}
        </Col>



        <Col xs={24} sm={24} md={24} lg={24}>
          <Divider orientation="left" style={{ marginBottom: 0 }}>
            <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>Acceso producto</p>
          </Divider>

          <TableAccesoProductos productosAsociados={detalleProducto?.productosAsociados} />
        </Col>

        {PerfilUsuarioId.ADMIN_EMPRESA === idPerfilUsuario && location.pathname.includes(urlDespliegaBotonesAdmin) ?
          <>
            <Divider orientation="left" style={{ marginBottom: 0 }}>
              <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>Usuarios asignados</p>
            </Divider>
            <Col xs={24} sm={24} md={24} lg={15}>
              <TableUsuariosSuscripcion usuarios={detalleProducto?.usuariosAsignados}></TableUsuariosSuscripcion>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} style={{ marginBottom: 15, textAlign: 'left' }}>
              <Button type="primary" className="btncancelar" onClick={() => setShowModalAsignacion(true)} shape='round' >Asignar / Remover usuarsios</Button>
            </Col>

          </> : null}
      </Row>
      <ModalMedioPago producto={detalleProducto} isModalOpen={isModalOpen} handleOk={onPagarProximoMes} handleCancel={handleCancel} />
      {
        detalleProducto?.tipoPlan?.nombreTipoPlan ?
          <ModalSuscripciones idTipoProducto={detalleProducto?.idTipoProductoContratado} isModalOpen={isModalSuscripcion} handleCancel={() => setIsModalSuscripcion(false)} />
          : null
      }
    </div >
  )
}

export default DetalleProducto