import { Button, Card, Col, Row, Tag, Typography, Space } from 'antd'
import './DetalleProductoServicio.scss'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../../redux/reducers/AppReducer'
import { setProductoContratar, setNombreProductoSeleccionado } from '../../redux/reducers/ProductoReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import DescripcionOtroTipoProducto from '../productoServicio/planes/DescripcionOtroTipo'
import { getDetalleProductoContratar } from '../../services/api'
import { SeparadorMiles } from '../../services/Helpers/stringHelper'
import PlanProductoEnCard from '../productoServicio/planes/PlanProductoEnCard'
import { notification } from 'antd';
import parse from 'html-react-parser';
import { saveAs } from 'file-saver'
import { TipoProductoId, TipoDocumentoId, MotivoContactoInnovationId } from '../../enum/enums'
import { getDocumento } from '../../services/documentoApi'
import DescripcionPlanApi from '../productoServicio/DescripcionPlanApi'
import DescripcionPlanDashboard from '../productoServicio/planes/DescripcionPlanDashboard'
import DescripcionPlanInnovationLabs from '../productoServicio/DescripcionPlanInnovationLabs'
import { generaSolicitudContratacionInnovationLab } from '../../services/suscripcionApi'
import ModalAvisos from '../modal/ModalAvisos'
const { Title } = Typography

const DetalleProductoServicio = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const producto = useSelector(state => state.producto.productoContratar)
    const usuario = useSelector(state => state.aplicacion.usuario)
    const isLoading = useSelector(store => store.aplicacion.isLoading)
    const { idProducto } = useParams()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [dataModal, setDataModal] = useState({
        mensaje: "",
        titulo: "",
        fueCorrecto: false,
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const data = await getDetalleProductoContratar(idProducto)
                dispatch(setProductoContratar(data))
                dispatch(setNombreProductoSeleccionado({ nombre: data?.nombre, id: data?.idProducto }))
            }
            catch (error) {
                console.log(error)
            }
            finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData()
    }, [idProducto])

    const onSeleccionarPeriodoPrueba = async (producto, accion, idTipoProducto) => {
        if (usuario.habilitadoComprar) {
            if (idTipoProducto === TipoProductoId.INNOVATION_LAB) {
                try {
                    dispatch(setIsLoading(true))
                    const data = await generaSolicitudContratacionInnovationLab(MotivoContactoInnovationId.CONTRATAR_PLAN, producto.idTipoPlan)

                    setDataModal({
                        fueCorrecto: data.accionFueCorrecta,
                        titulo: data.accionFueCorrecta ? "Se ha generado una solicitud para habilitar un Workspace" : "Error",
                        mensaje: data.accionFueCorrecta ? 'Pronto serás contactado por alguien de nuestro equipo para finalizar la habilitación.' : 'Ha ocurrido un error, inténtelo nuevamente.'
                    })

                    setIsModalOpen(true)
                }
                catch (error) {
                    console.log(error)
                }
                finally {
                    dispatch(setIsLoading(false))
                }
            }
            else {
                if (accion === 'prueba')
                    navigate(`/periodo-prueba/${producto.idPlanProducto ? producto.idPlanProducto : idProducto}`)
                else
                    navigate(`/contratar-producto/${producto.idPlanProducto ? producto.idPlanProducto : idProducto}`)
            }
        }
        else {
            notification.open({
                message: 'Empresa en proceso de validación',
                description: 'La empresa está en proceso de aprobación interna. Te enviaremos una notificación cuando ya se encuentre habilitada para contratar productos.',
                duration: 5,
            });
        }
    }

    const onDownloadDocumentacion = async (idProducto, idtipoDocumento) => {
        dispatch(setIsLoading(true))
        try {
            const docRes = await getDocumento(idProducto, idtipoDocumento)
            if (docRes) {
                const blobFile = new Blob(
                    [docRes.buffer],
                    { type: docRes.contentType }
                )
                saveAs(blobFile, docRes.fileName)
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const getTextoBtnPrueba = (idTipoProducto) => {
        switch (idTipoProducto) {
            case TipoProductoId.INNOVATION_LAB:
                return 'Solicitar Workspace'
            case TipoProductoId.PACK_REPORTES:
                return 'Próximamente'
            default:
                return 'Prueba gratis'
        }
    }

    const getTextoBtnContratar = (idTipoProducto) => {
        switch (idTipoProducto) {
            case TipoProductoId.INNOVATION_LAB:
                return 'Solicitar Workspace'
            case TipoProductoId.PACK_REPORTES:
                return 'Próximamente'
            default:
                return 'Contratar plan'
        }
    }

    return (
        <>
            <ModalAvisos
                isModalOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                mensaje={dataModal?.mensaje}
                titulo={dataModal?.titulo}
                fueCorrecto={dataModal?.fueCorrecto}>
            </ModalAvisos>

            {isLoading ? null :
                <div>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
                        <Col xs={24} sm={24} md={18} lg={19}>
                            <Title level={3} style={{ textAlign: 'left', fontWeight: 'bold', marginTop: '0px', marginBottom: 0 }}>{producto?.nombre}</Title>
                        </Col>
                        <Col xs={0} sm={0} md={6} lg={5} style={{ textAlign: 'right' }}>
                            <Button style={{ backgroundColor: '#ff4201', color: 'white' }} type='primary' shape='round' onClick={() => navigate('/producto-servicio')}>Volver al catálogo</Button>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {/* mobile */}
                        <Col xs={24} sm={24} md={0} style={{ textAlign: 'left' }}>
                            <Space>
                                <p style={{ textAlign: 'left', fontWeight: 'bold' }}>Descripción del producto  </p>
                                <Tag className="tag" style={{ marginRight: 0 }}><strong> Servicios de información </strong></Tag>
                            </Space>

                            <p className='descripcionComprar descripcionDetalle' style={{ textAlign: 'justify' }}>
                                {parse(producto?.descripcionDetalle ?? '')}
                            </p>
                            <Row style={{ textAlign: 'left', marginTop: 20 }} gutter={20} >
                                <Col span={'auto'}>
                                    <Button disabled={producto?.idTipoProducto === TipoProductoId.PACK_REPORTES} style={{ marginBottom: 10 }} onClick={() => onDownloadDocumentacion(idProducto, TipoDocumentoId.CONTRATO)} type='primary' shape='round'>Contrato del producto</Button>
                                </Col>
                                <Col span={'auto'}>
                                    <Button style={{ minWidth: 175 }} onClick={() => onDownloadDocumentacion(idProducto, TipoDocumentoId.FOLLETO)} type='primary' shape='round'> Folleto del producto</Button>
                                </Col>
                            </Row>
                        </Col>

                        {/* desktop */}
                        <Col xs={0} sm={0} md={24}>
                            <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>Descripción del producto <Tag style={{ marginLeft: '3%' }} className="tag">Servicios de información</Tag> </p>
                            <p className='descripcionComprar descripcionDetalle' style={{ textAlign: 'justify' }}>
                                {parse(producto?.descripcionDetalle ?? '')}
                            </p>
                            <Row style={{ textAlign: 'left', marginTop: 20 }} gutter={20} >
                                <Col span={'auto'}>
                                    <Button disabled={producto?.idTipoProducto === TipoProductoId.PACK_REPORTES} style={{ marginBottom: 10 }} onClick={() => onDownloadDocumentacion(idProducto, TipoDocumentoId.CONTRATO)} type='primary' shape='round'>Contrato del producto</Button>
                                </Col>
                                <Col span={'auto'}>
                                    <Button style={{ minWidth: 175 }} onClick={() => onDownloadDocumentacion(idProducto, TipoDocumentoId.FOLLETO)} type='primary' shape='round'> Folleto del producto</Button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={24}>
                            <br />
                            <p style={{ textAlign: 'justify', fontWeight: 'bold', marginBottom: 0, fontSize: 18 }}>Tipos de planes disponibles:</p>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} style={{ marginTop: 20, textAlign: 'center' }} >
                        {producto?.planesProducto.map((item, index) => (
                            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8} key={index} style={{ marginBottom: 20 }} >
                                <Card className='card-detail-prod' bordered={false} style={{ boxShadow: '0px 0px 50px -20px rgba(0, 0, 0, 0.25)' }} >
                                    <div>
                                        <PlanProductoEnCard
                                            maxHeigthTitulo={35}
                                            idTipoProducto={producto?.idTipoProducto}
                                            sacarMargen={true}
                                            ajustarAlto={true}
                                            planProducto={{ nombreTipoPlan: item.nombrePlan, idTipoPlan: item.idTipoPlan, cantidadDashboard: item.cantidadDashboard, subtitulo: item.subtitulo }}
                                        />
                                    </div>
                                    {producto.idTipoProducto !== TipoProductoId.INNOVATION_LAB && (
                                        <div style={{ textAlign: 'left' }}>
                                            <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)', marginTop: 7 }}>UF {SeparadorMiles(item.precio)} /mes</Title>
                                        </div>)}
                                    <div>
                                        <p style={{ textDecoration: 'underline', textAlign: 'justify', fontWeight: 'bold' }}>Este plan incluye:</p>

                                        {producto.idTipoProducto === TipoProductoId.PACK_APIS ?
                                            <DescripcionPlanApi
                                                requesAlMes={item?.cantidadRequest}
                                                mercadoProducto={item?.mercados}
                                                nombrePlan={item.nombrePlan}
                                                agnoInfoHistorica={item?.infoHistorica}
                                                tiempoDelay={item?.periodoTiempo}>
                                            </DescripcionPlanApi> : producto.idTipoProducto === TipoProductoId.PACK_REPORTES ?
                                                <DescripcionPlanDashboard texto={item.descripcionPlanDashboard}></DescripcionPlanDashboard> :
                                                producto.idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                                    <DescripcionPlanInnovationLabs descripcion={item.descripcionPlanDashboard} usuariosSugeridosInnovationLab={item.usuariosSugeridosInnovationLab} cantidadNucleos={item.cantidadNucleos} memoriaRam={item.memoriaRam}></DescripcionPlanInnovationLabs> : 
                                                    <DescripcionOtroTipoProducto plan={item}></DescripcionOtroTipoProducto>}
                                        <br /><br />
                                    </div>
                                    <div className={producto?.idTipoProducto === TipoProductoId.INNOVATION_LAB ? 'botonesContratarInnoLab' : 'botonesContratar'} style={{ textAlign: 'center' }}>
                                        {item?.precio === 0 && producto?.idTipoProducto !== TipoProductoId.INNOVATION_LAB ? <Button disabled={producto?.idTipoProducto === TipoProductoId.PACK_REPORTES} type='primary' onClick={() => onSeleccionarPeriodoPrueba(item, 'prueba', producto.idTipoProducto)} shape='round'>{getTextoBtnPrueba(producto.idTipoProducto)}</Button> : null}
                                        {item?.precio > 0 || producto?.idTipoProducto === TipoProductoId.INNOVATION_LAB ? <Button disabled={producto?.idTipoProducto === TipoProductoId.PACK_REPORTES} type='primary' shape='round' onClick={() => onSeleccionarPeriodoPrueba(item, 'contratar', producto.idTipoProducto)}>
                                            {getTextoBtnContratar(producto?.idTipoProducto)}
                                        </Button> : null}
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div >
            }
        </>
    )
}

export default DetalleProductoServicio