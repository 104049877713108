import { Button, Col, Form, Input, Row, Divider, Tooltip, Space } from 'antd'
import Title from 'antd/es/typography/Title'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { CapitalizeFirstLetter } from '../../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../../services/Helpers/RutHelper';
import { getPerfilEmpresa } from '../../../services/empresaApi';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { setIsLoading, setInformacionEmpresa } from '../../../redux/reducers/AppReducer';

const dayjs = require('dayjs')
const PerfilEmpresa = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const usuario = useSelector(state => state.aplicacion.infoEmpresa)

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const data = await getPerfilEmpresa()
                dispatch(setInformacionEmpresa(data))
            }
            catch (error) {
                console.log(error)
            }
            finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData()
    }, [])



    return (
        <>
            {usuario ? <>
                <Title level={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>Datos Empresa</Title>
                <Form className='formMisDatosLectura formLabelStrongs' layout='vertical' >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label="Nombre Empresa" tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} style={{ paddingLeft: '0px' }} placeholder={CapitalizeFirstLetter(usuario?.razonSocial)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Número de Identificación' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} placeholder={daFormatoRut(usuario.numeroIdentificacion)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Rubro comercial' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} placeholder={usuario.rubroComercial} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='País'>
                                <Input readOnly={true} placeholder={usuario.pais} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Región'>
                                <Input readOnly={true} placeholder={usuario.region} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label="Comuna">
                                <Input readOnly={true} placeholder={usuario.comuna} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Dirección'>
                                <Input readOnly={true} placeholder={usuario.direccion} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    disabled={false}
                                    onClick={() => navigate('informacion')}
                                >
                                    Editar información empresa
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24} style={{ textAlign: 'left', marginBottom: 20 }}>
                            <Space>
                                <Title level={3} style={{ fontWeight: 'bold', marginBottom: 20 }}>Datos Representante(s) legal(es)</Title>
                                <Tooltip placement="top" title="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace."> <QuestionCircleOutlined style={{ cursor: 'help', marginTop: 15, color: 'rgba(0, 0, 0, 0.45)', fontSize: 20 }} /></Tooltip>
                            </Space>
                        </Col>
                    </Row>
                    {usuario.representantesLegales.map((item, index) => {
                        return <Row style={{ marginTop: -30 }} key={index} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {index > 0 ? <Divider></Divider> : null}

                            <Col span={24} style={{ textAlign: 'left' }}>
                                <Title level={4} style={{ marginTop: 0, marginBottom: 10 }}>
                                    Datos Representante legal # {index + 1}
                                </Title>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Nombres Representante'>
                                    <Input readOnly={true} placeholder={CapitalizeFirstLetter(item.nombreRepresentante)} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Apellidos Representante'>
                                    <Input readOnly={true} placeholder={CapitalizeFirstLetter(item.apellidosRepresentante)} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Número de identificación (RUT)'>
                                    <Input readOnly={true} placeholder={daFormatoRut(`${item.rut}${item.rutDV}`)} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Nacionalidad'>
                                    <Input readOnly={true} placeholder={item.nacionalidad} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Páis de Residencia'>
                                    <Input readOnly={true} placeholder={item.paisResidencia} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Fecha de nacimiento'>
                                    <Input readOnly={true} placeholder={item.fechaNacimiento ? dayjs(item.fechaNacimiento).format('DD/MM/YYYY') : ''} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Cargo'>
                                    <Input placeholder={item.cargo} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Área del cargo'>
                                    <Input readOnly={true} placeholder={item.areaCargo} bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item label='Correo electrónico'>
                                    <Input readOnly={true} placeholder={item.email} bordered={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                    })}
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                            <Title level={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>Datos de facturación</Title>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label="Nombres">
                                <Input readOnly={true} style={{ paddingLeft: '0px' }} placeholder={CapitalizeFirstLetter(usuario.datosFacturacion.nombre)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Apellidos'>
                                <Input readOnly={true} placeholder={CapitalizeFirstLetter(usuario.datosFacturacion.apellidos)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Teléfono fijo o Celular'>
                                <Input readOnly={true} placeholder={usuario.datosFacturacion.telefono} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='País'>
                                <Input readOnly={true} placeholder={usuario.datosFacturacion.pais} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Región'>
                                <Input readOnly={true} placeholder={usuario.datosFacturacion.region} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label="Comuna">
                                <Input readOnly={true} placeholder={usuario.datosFacturacion.comuna} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form.Item label='Dirección'>
                                <Input readOnly={true} placeholder={usuario.datosFacturacion.direccionFacturacion} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Correo electrónico'>
                                <Input readOnly={true} placeholder={usuario.datosFacturacion.emailFacturacion} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Cargo' >
                                <Input readOnly={true} placeholder={usuario.datosFacturacion.cargo} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Área del cargo'>
                                <Input readOnly={true} placeholder={usuario.datosFacturacion.areaCargo} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            {usuario.datosFacturacion.solicitaHes ?
                                <Form.Item label='Hoja de entrada servicios (HES/OC)' tooltip="Cuando corresponda, envía tu documento HES u OC al siguiente correo sacbursatil@bolsadesantiago.com">
                                    <Input readOnly={true} placeholder={usuario.datosFacturacion.solicitaHes ? 'SI' : 'NO'} bordered={false} />
                                </Form.Item> :
                                <Form.Item label='Hoja de entrada servicios (HES/OC)'>
                                    <Input readOnly={true} placeholder={usuario.datosFacturacion.solicitaHes ? 'SI' : 'NO'} bordered={false} />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                disabled={false}
                                onClick={() => navigate('datosFacturacion')}
                            >
                                Editar datos de facturación
                            </Button>
                        </Form.Item>
                    </Col>

                </Form>
            </> : null}



        </>
    )
}

export default PerfilEmpresa