import React from 'react'
import FormDeclaracionCompliance from '../../components/declaracion/FormDeclaracionCompliance'

const DeclaracionCompliance = () => {
  return (
    <div>
        <FormDeclaracionCompliance/>
    </div>
  )
}

export default DeclaracionCompliance