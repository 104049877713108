import { Spin } from 'antd';
import "./style.scss";
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = ({ isLoadingExt }) => {
    const isLoading = useSelector(store => store.aplicacion.isLoading)

    return (
        <>
            {isLoading || isLoadingExt ? (
                <div className="load-screen">
                    <div className="global-loader">
                        {/*<LoadingOutlined style={{ fontSize: 66, color: '#FF411C' }}  />*/}
                        <div class="loader"></div>
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default Loader

// import { useState } from 'react';
// import { Alert, Spin } from 'antd';

// const Loader = () => {
//     const [loading, setLoading] = useState(true)

//     return (
//         <Spin spinning={spinning} />
//     );
// };
// export default Loader;