import { Col, Button, Form, Input, Row, Select, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CapitalizeFirstLetter } from '../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../services/Helpers/RutHelper';
import { existeEmail, getPaises, getCargos, getAreaCargos, getRegiones, getComunas, getNacionalidades } from '../../services/publicApi'
import { setIsLoading, setUsuario } from '../../redux/reducers/AppReducer';
import { updateUsuario, getUserSession } from '../../services/api';
import ModalAvisos from '../modal/ModalAvisos';
import { useKeycloak } from '@react-keycloak/web'
import { PerfilUsuarioId } from '../../enum/enums';
import { clearNumeroTelefono } from '../../services/Helpers/stringHelper';
import { useNavigate } from 'react-router';
const _ = require('lodash');
const dayjs = require('dayjs')
const { Title } = Typography;

const FormPerfilPersonaEdicion = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { keycloak } = useKeycloak()
    const [form] = Form.useForm()
    const usuario = useSelector(state => state.aplicacion.usuario)
    const isLoading = useSelector(store => store.aplicacion.isLoading)
    const [isOpenModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState(null)
    const [paises, setPaises] = useState(null)
    const [nacionalidades, setNacionalidades] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)
    const [actualizoMail, setActualizoMail] = useState(false)
    const [regiones, setRegiones] = useState(null)
    const [comunas, setComunas] = useState(null)
    const [formIsSubmiting, setFormIsSubmiting] = useState(false)
    const [nameDropIsLoading, setNameDropIsLoading] = useState('')

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        const fetchData = async () => {
            if (usuario && !formIsSubmiting) {
                dispatch(setIsLoading(true))

                Promise.all([getPaises(),
                getNacionalidades(),
                getCargos(),
                getAreaCargos(),
                getRegiones(usuario.paisId),
                getComunas(usuario.regionId)
                ]).then((values) => {
                    setPaises(values[0])
                    setNacionalidades(values[1])
                    setCargos(values[2])
                    setAreaCargos(values[3])
                    setRegiones(values[4])
                    setComunas(values[5])
                }).finally(() =>
                    dispatch(setIsLoading(false))
                );
            }
        }
        fetchData()
    }, [usuario])

    const onChangeRegion = async (value) => {
        try {
            setNameDropIsLoading('drpComunas')
            form.setFieldValue('comunaId', null)
            const result = await getComunas(value)
            setComunas(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangePais = async (value) => {
        try {
            const glosa = paises.filter(x => x.value === value)[0].label
            form.setFieldValue('glosaPais', glosa)
            setNameDropIsLoading('drpRegiones')
            form.setFieldValue('regionId', null)
            const result = await getRegiones(value)

            setRegiones(result)
        }
        catch (ex) {
            console.log(ex)
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0].label
        form.setFieldValue('glosaAreaCargo', glosa)
    }

    const onValidateEmail = async (value) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
        const result = regex.test(value);

        if (result && value !== usuario.email) {
            const data = await existeEmail(value)

            if (data.tieneCuentaOnBoarding || data.tieneCuentakeyCloack) {
                return Promise.reject(new Error('Email ingresado ya se encuentra registrado'))
            }
            else {
                return Promise.resolve()
            }
        }
    }

    const onFinish = async (values) => {
        try {
            setFormIsSubmiting(true)
            const dataUpdated = {}
            let actualizoMail = false

            if (values.direccion !== usuario.direccion)
                dataUpdated.direccion = values.direccion
            if (values.telefono !== usuario.celular)
                dataUpdated.celular = values.telefono
            if (values.correo !== usuario.email) {
                dataUpdated.email = values.correo
                actualizoMail = true
            }
            if (values.profesion !== usuario.profesionOficio)
                dataUpdated.profesionOficio = values.profesion
            if (values.nacionalidadId !== usuario.nacionalidadId)
                dataUpdated.nacionalidadId = values.nacionalidadId
            if (values.comunaId !== usuario.comunaId)
                dataUpdated.comunaId = values.comunaId
            if (values.cargoId !== usuario.cargoId)
                dataUpdated.cargoId = values.cargoId
            if (values.areaCargoId !== usuario.areaCargoId)
                dataUpdated.areaCargoId = values.areaCargoId

            const formValues = { ...values }
            formValues.dataUpdated = dataUpdated

            if (!_.isEqual({}, dataUpdated)) {
                const result = await updateUsuario(formValues)
                const msjCorreo = actualizoMail ? '##Has actualizado tu correo electrónico. Te enviamos un email al nuevo correo para que lo valides y luego inicies sesión con tus nuevas credenciales.' : ''

                if (result?.accionFueCorrecta) {
                    setActualizoMail(actualizoMail)
                    setDataModal({
                        titulo: 'Edición de datos exitosa',
                        mensaje: `Te enviamos un correo para notificar los cambios realizados ${msjCorreo}`,
                        fueCorrecto: true,
                        msjBoton: actualizoMail ? 'Aceptar' : 'Volver a mi perfil'
                    })

                    //se actualiza datos en sesion
                    if (!actualizoMail) {
                        const data = await getUserSession()
                        dispatch(setUsuario(data))
                    }

                    setOpenModal(true)
                }
            }
        }
        catch (error) {
            console.log(error)
            setDataModal({
                titulo: 'Error',
                mensaje: '¡Ha ocurrido un error, por favor inténtelo nuevamente!',
                fueCorrecto: false
            })
            setOpenModal(true)
        }
        finally {
            setFormIsSubmiting(false)
        }
    };

    const oncCloseModal = () => {
        if (actualizoMail) {
            keycloak.logout({ redirectUri: process.env.REACT_APP_URL_ONBOARDING_PUBLICO })
        }
        else {
            setOpenModal(false)
            navigate('/perfil')
        }
    }

    return (
        <>
            <ModalAvisos
                isModalOpen={isOpenModal}
                titulo={dataModal?.titulo}
                msjBoton={dataModal?.msjBoton}
                onClose={() => oncCloseModal()}
                mensaje={dataModal?.mensaje}
                fueCorrecto={dataModal?.fueCorrecto}
            ></ModalAvisos>
            <Title level={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>Mis datos personales</Title>
            <br />
            {!isLoading ? <>

                <Form className='formMisDatosEdicion formLabelStrongs' form={form} layout='vertical' onFinish={onFinish}
                    initialValues={{
                        direccion: usuario.direccion, telefono: usuario.celular, correo: usuario.email,
                        profesion: usuario.profesionOficio, nacionalidadId: usuario.nacionalidadId,
                        regionId: usuario.regionId, paisId: usuario.paisId, ciudadId: usuario.ciudadId,
                        comunaId: usuario.comunaId, cargoId: usuario.cargoId, areaCargoId: usuario.areaCargoId,
                        glosaAreaCargo: usuario.areaCargo, glosaPais: usuario.pais
                    }}
                >
                    <Form.Item style={{ display: 'none' }} name='glosaPais'><Input /></Form.Item>
                    <Form.Item style={{ display: 'none' }} name='glosaAreaCargo'><Input /></Form.Item>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label="Nombres" tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input className='soloLectura' readOnly={true} placeholder={CapitalizeFirstLetter(usuario?.nombres)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Apellidos' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input className='soloLectura' readOnly={true} placeholder={CapitalizeFirstLetter(usuario?.apellidos)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Número de Identificación (RUT)' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input className='soloLectura' readOnly={true} placeholder={daFormatoRut(usuario?.numeroIdentificacion)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Fecha de nacimiento' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input className='soloLectura' readOnly={true} placeholder={dayjs(usuario?.fechaNacimiento).format('DD/MM/YYYY')} bordered={false} />
                            </Form.Item>
                        </Col>
                        {usuario.perfilId === PerfilUsuarioId.PERSONA_NATURAL ? <>



                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item
                                    name='nacionalidadId'
                                    label="Nacionalidad"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Seleccione su nacionalidad",
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Selecciona una nacionalidad"
                                        filterOption={filterOption}
                                        options={nacionalidades}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item
                                    name='paisId'
                                    label='País'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingrese el país',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        onChange={onChangePais}
                                        placeholder="Selecciona un país"
                                        filterOption={filterOption}
                                        options={paises}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item
                                    name='regionId'
                                    label='Región'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingresar la región',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        onChange={onChangeRegion}
                                        placeholder="Selecciona un país"
                                        filterOption={filterOption}
                                        options={regiones}
                                        loading={nameDropIsLoading === 'drpRegiones'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item
                                    name='comunaId'
                                    label='Ciudad'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Selecciona una comuna',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Selecciona una comuna"
                                        filterOption={filterOption}
                                        options={comunas}
                                        loading={nameDropIsLoading === 'drpComunas'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item
                                    name='direccion'
                                    label='Dirección'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese dirección',
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                            </Col>
                        </> : null}
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='telefono'
                                normalize={(value) => clearNumeroTelefono(value)}
                                label='Télefono fijo o Celular'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese su télefono fijo o Celular'
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='correo'
                                label='Correo electrónico'
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingresa tu correo electrónico",
                                    },
                                    {
                                        type: "email",
                                        message: "Ingresa un email válido",
                                    },
                                    {
                                        validator: (_, value) => value ? onValidateEmail(value) : Promise.resolve()
                                    }
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                        {usuario.perfilId !== PerfilUsuarioId.ADMIN_EMPRESA ?
                            <Col xs={24} sm={12} md={12} lg={8}>
                                <Form.Item
                                    name='profesion'
                                    label='Profesión u Oficio'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese su profesion/Oficio',
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col> : null}
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='cargoId'
                                label='Cargo'
                            >
                                <Select
                                    showSearch
                                    placeholder='Seleccione un cargo'
                                    optionFilterProp='children'
                                    options={cargos}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='areaCargoId'
                                label='Área del cargo'
                            >
                                <Select
                                    showSearch
                                    placeholder='Seleccione un area'
                                    optionFilterProp='children'
                                    options={areaCargos}
                                    onChange={onChangeAreaCargo}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 5, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={11} sm={11} md={8} lg={5}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    className="btncancelar"
                                    htmlType="submit"
                                    shape="round"
                                    style={{ width: '100%' }}
                                    danger
                                    onClick={() => navigate('/perfil')}
                                >
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={11} sm={11} md={8} lg={5}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={formIsSubmiting}
                                    style={{ width: '100%' }}
                                >
                                    Guardar cambios
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </> : null}
        </>
    )
}

export default FormPerfilPersonaEdicion