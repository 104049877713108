import { CloseCircleOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setIsLoading, setRefrescarNotificaciones } from '../../../redux/reducers/AppReducer'
import { deleteUsuario } from '../../../services/empresaApi'

const ModalEliminarUsuario = ({ isModalOpen, handleOk, handleCancel, usuarioSeleccionado }) => {
    const dispatch = useDispatch()

    const onEliminarUsuario = async () => {
        try {
            dispatch(setIsLoading(true))
            await deleteUsuario(usuarioSeleccionado.idUsuario)
            dispatch(setRefrescarNotificaciones(true))
            handleOk()
        }
        catch (error) {
            console.log(error)
        }
        finally {
            handleCancel()
        }
    }

    return (
        <div>
            <Modal
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={350}
                footer={[
                    <Button className="btncancelar" type='primary' danger key="back" shape='round' onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" shape='round' onClick={onEliminarUsuario}>
                        Aceptar
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<CloseCircleOutlined />} />
                    <h2>¿Estás seguro de eliminar este usuario?</h2>
                    <p>
                        Si eliminas este usuario, se darán de baja todos los productos que tiene asociado. Se enviará un correo al usuario notificando esta acción.
                    </p>
                </div>
            </Modal>
        </div>
    )
}

export default ModalEliminarUsuario