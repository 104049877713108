import { createSlice } from '@reduxjs/toolkit'

const appSSlice = createSlice({
    name: 'app',
    initialState: {
        isLoading: false,
        usuario: null,
        usuarios: null,
        usuariosFiltrados: null,
        desplegarUsuariosEnTabla: false,
        infoEmpresa: null,
        subtituloHeader: '',
        notificaciones: null,
        refrescarNotificaciones: false,
        declaracionCompliance: null
    },
    reducers: {
        setRefrescarNotificaciones(state, action) {
            state.refrescarNotificaciones = action.payload
        },
        setNotificaciones(state, action) {
            state.notificaciones = action.payload
        },
        setSubtituloHeader(state, action) {
            state.subtituloHeader = action.payload
        },
        setInformacionEmpresa(state, action) {
            state.infoEmpresa = action.payload
        },
        setDesplegarUsuariosTabla(state, action) {
            state.desplegarUsuariosEnTabla = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setUsuario(state, action) {
            state.notificaciones = action.payload?.notificaciones

            let estado = action.payload
            estado.notificaciones = undefined
            state.usuario = estado
        },
        setUsuarios(state, action) {
            state.usuarios = action.payload
            state.usuariosFiltrados = action.payload
        },
        setUsuariosFiltrados(state, action) {
            state.usuariosFiltrados = action.payload
        },
        setDeclaracionCompliance(state, action) {
            state.declaracionCompliance = action.payload
        }
    }
})

export const { setDeclaracionCompliance, setRefrescarNotificaciones, setNotificaciones, setSubtituloHeader, setInformacionEmpresa, setIsLoading, setUsuario, setUsuarios, setUsuariosFiltrados, setDesplegarUsuariosTabla } = appSSlice.actions

export default appSSlice.reducer