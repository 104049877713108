import { Table, Row, Col, Card, Button } from 'antd';
import { useMediaQuery } from 'react-responsive'
import { FileSearchOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver'
import { getDocumento } from '../../services/documentoApi';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../../redux/reducers/AppReducer';
import { TipoDocumentoId } from '../../enum/enums';
const typeDefs = require('../../interface/typeDefs')


/**
 * 
 * @param {{productosAsociados:Array.<typeDefs.ProductoAsociadoDto>}} productosAsociados - Productos contratados por el usuario
 * @returns {React.ElementType}
 */
const TableAccesoProductos = ({ productosAsociados }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    // const handleChange = (pagination, filters, sorter) => {
    //     setFilteredInfo(filters);
    //     setSortedInfo(sorter);
    // };

    const dispatch = useDispatch()

    const onDownloadDocumentacion = async (idProducto) => {
        dispatch(setIsLoading(true))
        try {
            const docRes = await getDocumento(idProducto, TipoDocumentoId.DOCUMENTACION)
            if (docRes) {
                const blobFile = new Blob(
                    [docRes.buffer],
                    { type: docRes.contentType }
                )
                saveAs(blobFile, docRes.fileName)
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'nombreProducto',
            key: 'nombreProducto',
            ellipsis: true,

        },
        {
            title: 'Documentación',
            dataIndex: 'idProducto',
            key: 'idProducto',
            ellipsis: true,
            render: (idProducto, item) =>
                <>
                    <Button
                        type="primary"
                        shape="round"
                        icon={<FileSearchOutlined />}
                        // href={item.urlAcesso} TODO - Cambiar por descargar documentacion
                        // href="#"
                        // target="_blank"
                        onClick={() => onDownloadDocumentacion(idProducto)}
                    >
                        Documentación
                    </Button>
                </>
        },
        {
            title: 'Acceso',
            dataIndex: 'idProducto',
            key: 'idProducto',
            ellipsis: true,
            render: (idProducto, item) =>
                <>
                    <Button
                        type="primary"
                        shape="round"
                        href={item.urlAcesso}
                        target="_blank">
                        {"</> "}Acceder
                    </Button>
                </>
        }
    ];
    return (
        <>
            {isTabletOrMobile ?
                <>
                    {productosAsociados?.map((item, index) => (
                        <Card key={`sol-${index}`} className="cardTable" >
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={24}>
                                    <p className="titulo"><strong>Nombre</strong></p>
                                </Col>
                                <Col span={24}>
                                    <p className="subTitulo">{item.nombreProducto}</p>
                                </Col>
                                <Col span={12}>
                                    <Button type="primary"
                                        icon={<FileSearchOutlined />}
                                        onClick={() => onDownloadDocumentacion(item.idProducto)} >
                                        Documentación
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        type="primary"
                                        target="_blank"
                                        style={{ marginBottom: 10, marginLeft: 25 }}
                                        href={item.urlAcesso}>
                                        {"</> "}Acceder
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </>
                :
                <Table
                    pagination={{
                        pageSize: 5,
                        hideOnSinglePage: true
                    }}
                    locale={{ emptyText: "No se encontraron productos asociados." }}
                    rowKey='idProducto'
                    columns={columns}
                    showHeader={false}
                    dataSource={productosAsociados ?? []} />}
        </>)
};
export default TableAccesoProductos