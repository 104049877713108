import { Avatar, Button, Checkbox, Col, DatePicker, Divider, Form, Input, Layout, Row, Select, Space, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Link from 'antd/es/typography/Link'
import './FormCrearCuentaPersonas.styles.scss'
import ModalExitoCrearCuenta from '../modal/ModalExitoCrearCuenta'
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web'
import { useSelector } from 'react-redux'
import { existeEmail, getPaises, existeRutRegistrado, getCargos, getCiudades, getAreaCargos, getRegiones, getComunas, getNacionalidades, savePersonaNatural } from '../../services/publicApi'
import { esRutValido, getRutSinDv, getDvRut, cleanRut, daFormatoRut } from '../../services/Helpers/RutHelper'
import { clearNumeroTelefono } from '../../services/Helpers/stringHelper'
import { useNavigate } from 'react-router'
import Loader from '../Loader/loader';
import { getTerminosCondicionesPortal } from '../../services/documentoApi'
import locale from 'antd/es/date-picker/locale/es_ES'
import { Calendar, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, User } from 'react-iconly'

import show from '../../assets/images/show1.svg';
import hide from '../../assets/images/hide1.svg';

const { Text } = Typography;

const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&\-_\.])[A-Za-z\d@$!#%*?&\-_\.]{12,}$/;

const FormCrearCuentaPersonas = () => {
    const { keycloak } = useKeycloak()
    const declaracionCompliance = useSelector(store => store.aplicacion.declaracionCompliance)
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [accionCorrecta, setAccionCorrecta] = useState(true)
    const [existeEmailBd, setExisteEmail] = useState(null)
    const [isDownloadDocumentacion, setIsDownloadDocumentacion] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [paises, setPaises] = useState([{ label: 'Chile', value: 'CL' }])
    const [nacionalidades, setNacionalidades] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)
    const [regiones, setRegiones] = useState(null)
    const [comunas, setComunas] = useState(null)
    const [formIsSubmiting, setFormIsSubmiting] = useState(false)
    const [nameDropIsLoading, setNameDropIsLoading] = useState('')
    const valuePassword = Form.useWatch('password', form)
    const valuePasswordRepeat = Form.useWatch('passwordRepeat', form)

    const [isOpenNac, setIsOpenNac] = useState(false);
    const [isOpenPais, setIsOpenPais] = useState(false);
    const [isOpenReg, setIsOpenReg] = useState(false);
    const [isOpenCiu, setIsOpenCiu] = useState(false);
    const [isOpenCar, setIsOpenCar] = useState(false);
    const [isOpenArea, setIsOpenArea] = useState(false);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    useEffect(() => {
        const fetchData = async () => {
            Promise.all([
                // getPaises(),
                getNacionalidades(),
                getCargos(),
                getAreaCargos()]).then((values) => {
                    // setPaises(values[0])
                    setNacionalidades(values[0])
                    setCargos(values[1])
                    setAreaCargos(values[2])
                }).finally(() =>
                    console.log()
                );
        }
        fetchData()
    }, [])

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onChangeRegion = async (value) => {
        try {
            setNameDropIsLoading('drpComunas')
            form.setFieldValue('comunaId', null)
            const result = await getComunas(value)
            setComunas(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangePais = async (value) => {
        try {
            setNameDropIsLoading('drpRegiones')
            const glosa = paises.filter(x => x.value === value)[0].label
            form.setFieldValue('regionId', null)
            form.setFieldValue('glosaPais', glosa)

            const result = await getRegiones(value)
            setRegiones(result)
        }
        catch {
        }
        finally {
            setNameDropIsLoading('')
        }
    }

    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0].label
        form.setFieldValue('glosaAreaCargo', glosa)
    }

    const onFinish = async (values) => {
        try {
            setFormIsSubmiting(true)
            values.DVnumeroIdentificacion = getDvRut(values.numeroIdentificacion)
            values.numeroIdentificacion = getRutSinDv(values.numeroIdentificacion)
            values.fechaNacimiento = values.fechaNacimiento ? values.fechaNacimiento.toDate() : null
            values.esPep = declaracionCompliance.esPep
            values.idTiempoPep = declaracionCompliance.tiempoPep

            const result = await savePersonaNatural(values)

            if (result.accionFueCorrecta) {
                setAccionCorrecta(true)
            }
            else {
                setAccionCorrecta(false)
                throw new Error('error');
            }
        }
        catch (error) {
            setAccionCorrecta(false)
        }
        finally {
            setIsModalOpen(true)
            setFormIsSubmiting(false)
        }
    };

    const onValidateEmail = async (value) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
        const result = regex.test(value);

        if (result) {
            const data = await existeEmail(value)
            setExisteEmail(data)
            setFormIsSubmiting(false)

            if (data.tieneCuentaOnBoarding) {
                return Promise.reject(new Error('Email ingresado ya se encuentra registrado'))
            }
            else {
                return Promise.resolve()
            }
        }
    }

    const onValidateRut = async (value) => {
        const esValido = esRutValido(value)
        const existe = esValido ? await existeRutRegistrado(cleanRut(value)) : false
        setFormIsSubmiting(false)

        if (esValido && !existe) {
            return Promise.resolve()
        }
        else if (!esValido) {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
        else if (existe) {
            return Promise.reject(new Error('Este RUT ya tiene una cuenta creada'))
        }
    }

    const onValidatePassword = async (value) => {
        const passwordValue = form.getFieldValue('password')

        if (passwordValue !== value)
            return Promise.reject(new Error('Las contraseñas no coindicen'))
        else
            return Promise.resolve()
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onVolver = () => {
        navigate('/')
        window.scrollTo(0, 0)
    }

    const onDownloadTerminosYcondiciones = async () => {
        setIsDownloadDocumentacion(true)
        try {
            const docRes = await getTerminosCondicionesPortal()

            if (docRes) {
                
                const blob = new Blob([docRes.buffer], { type: 'application/pdf' });
                const linkRedirect = URL.createObjectURL(blob);

                window.open(linkRedirect, "_blank");
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            setIsDownloadDocumentacion(false)
        }
    }

    const onCrearCuenta = () => {
        setFormIsSubmiting(true)
        form.submit()
    }

    return (
        <>
            <Loader isLoadingExt={isDownloadDocumentacion}></Loader>
            <Layout className="app-layout">
                <Content className="content">
                    <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                        <Col xs={24} sm={24} md={24} lg={0} style={{ minHeight: 72, backgroundColor: "#181818", textAlign: "left" }} className='margen-declaracion'>
                            
                            <div style={{ display: "flex", alignItems: "center" }} >
                                <Button
                                onClick={() => navigate(-1)}
                                className="btn-declaracion-m"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "0 10px",
                                }}
                                >
                                <ChevronLeft style={{ color: "#FFFBFA" }} />
                                </Button>
                                <p
                                onClick={() => navigate(-1)}
                                style={{
                                    margin: "0 0 0 10px",
                                    color: "#FFFBFA",
                                    fontFamily: "Degular",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    cursor: "pointer",
                                }}
                                >
                                <strong>Volver</strong>
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ minHeight: '100vh' }} className='containerFormPersona'>
                            <div className="header-container">
                                <Row style={{ textAlign: 'left', paddingTop: 5 }} gutter={0}>

                                    {/* mobile */}
                                    <Col className="gutter-row" xs={6} sm={2} md={2} lg={0}>
                                        <Avatar className="avatar-desktop" size={76} icon={<User stroke='bold' style={{ width: 44, height: 44 }} />} />
                                    </Col>
                                    <Col className="gutter-row" xs={17} sm={14} md={10} lg={0}>
                                        <Text className='title-persona' style={{ fontWeight: 'bold', lineHeight: 1.2 }} level={4} >Regístrate en portal persona</Text>
                                    </Col>
                                        <Row gutter={0}>
                                            {/*<Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                                <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                            </Col>*/}
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={0}>
                                                <p className='subtitle-persona' style={{ marginBottom: 0 }}>Ingresa la información solicitada para crear una cuenta</p>
                                            </Col>
                                            {/*<Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                                <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779', marginTop: 5 }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                            </Col>*/}
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={0}>
                                                <p className='subtitle-persona' style={{ marginTop: 10 }}> Recuerda que para crear una cuenta personal necesitas tu RUT.</p>
                                            </Col>
                                        </Row>
                                    {/* desktop */}
                                    <Col className="gutter-row" xs={0} sm={0} md={0} lg={1} xl={2} xxl={2}>
                                        <Avatar className="avatar-desktop" size={76} icon={<User stroke='bold' style={{ width: 44, height: 44 }} />} />
                                    </Col>
                                    <Col className="titulo" xs={0} sm={0} md={0} lg={22} xl={22} xxl={22} >
                                        <Row gutter={0}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} className='margen-title'>
                                                <Text className='title-persona'>Regístrate en portal persona</Text>
                                            </Col>
                                        </Row>
                                        <Row gutter={0}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <Text className='subtitle-persona' style={{ marginTop: '5px' }}>
                                                    Ingresa la información solicitada para crear una cuenta
                                                </Text>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <p className='subtitle-persona' style={{ marginTop: 0 }}>
                                                    Recuerda que para crear una cuenta personal necesitas tu RUT.
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Divider className='divider-persona'/>
                                </Row>
                            </div>

                            <Form form={form} onFinish={onFinish} className="form formCrear" layout="vertical" >
                                <Form.Item style={{ display: 'none' }} name='glosaPais'><Input /></Form.Item>
                                <Form.Item style={{ display: 'none' }} name='glosaAreaCargo'><Input /></Form.Item>

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name='nombre'
                                            label='Nombres'
                                            className="username custom-autocomplete"
                                            mark={true}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar nombres",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Ingresa nombres' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            label='Apellidos'
                                            name='apellidos'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar apellidos",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Ingresa apellidos' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name='numeroIdentificacion'
                                            label='Número de Identificación (RUT)'
                                            normalize={(value) => daFormatoRut(value)}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar RUT válido",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                                                }
                                            ]}
                                        >
                                            <Input placeholder='11.111.111-1' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name='fechaNacimiento' label='Fecha de nacimiento' className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar fecha de nacimiento",
                                                },
                                            ]}
                                        >
                                            {/*<DatePicker format={"DD/MM/YYYY"} placeholder='Selecciona la fecha' style={{ width: '100%' }} suffixIcon={<Calendar primaryColor='#666666' />} />*/}
                                            <DatePicker
                                                prevIcon={<ChevronLeft style={{ color: '#fffbfa', fontSize: 24 }}></ChevronLeft>}
                                                nextIcon={<ChevronRight style={{ color: '#fffbfa', fontSize: 24 }}></ChevronRight>}
                                                size='large'
                                                popupClassName='custom-calendar'
                                                locale={locale}
                                                format={"DD/MM/YYYY"}
                                                placeholder='Selecciona la fecha'
                                                style={{ width: '100%' }}
                                                suffixIcon={<Calendar primaryColor='#666666' />}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name='nacionalidadId' label="Nacionalidad" className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar nacionalidad",
                                                },
                                            ]}>
                                            <Select
                                                showSearch={false}
                                                filterOption={filterOption}
                                                placeholder="Selecciona nacionalidad"
                                                options={nacionalidades}
                                                popupClassName="dropdown-drpNacionalidad"
                                                className="drpNacionalidad"
                                                suffixIcon={isOpenNac ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenNac(open)}
                                                
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name='paisId' label='País' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresar país",
                                            },
                                        ]}>
                                            <Select
                                                showSearch={false}
                                                onChange={onChangePais}
                                                filterOption={filterOption}
                                                placeholder="Selecciona país"
                                                options={paises}
                                                loading={paises === null}
                                                popupClassName="dropdown-drpPais"
                                                className="drpPais"
                                                suffixIcon={isOpenPais ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenPais(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name='regionId' label='Región' rules={[
                                            {
                                                required: true,
                                                message: "Seleccionar región",
                                            },
                                        ]}>
                                            <Select
                                                notFoundContent={nameDropIsLoading === 'drpRegiones' ? 'Cargando...' : 'Debes seleccionar primero un País.'}
                                                showSearch={false}
                                                onChange={onChangeRegion}
                                                loading={nameDropIsLoading === 'drpRegiones'}
                                                placeholder="Selecciona región"
                                                filterOption={filterOption}
                                                options={regiones}
                                                popupClassName="dropdown-drpRegionesP"
                                                className="drpRegiones"
                                                suffixIcon={isOpenReg ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenReg(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name='comunaId' label='Ciudad'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Seleccionar ciudad",
                                                },
                                            ]}
                                        >
                                            <Select
                                                notFoundContent={nameDropIsLoading === 'drpComunas' ? 'Cargando...' : 'Debes seleccionar primero una Región.'}
                                                showSearch={false}
                                                loading={nameDropIsLoading === 'drpComunas'}
                                                placeholder="Selecciona ciudad"
                                                filterOption={filterOption}
                                                options={comunas}
                                                popupClassName="dropdown-drpCiudad"
                                                className="drpCiudad"
                                                suffixIcon={isOpenCiu ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenCiu(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name='direccion' label='Dirección' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa dirección",
                                            },
                                        ]}>
                                            <Input maxLength="150" placeholder='Ingresar dirección' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name='telefono'
                                            normalize={(value) => clearNumeroTelefono(value)}
                                            label='Teléfono fijo o Celular' className="username" rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar teléfono o celular",
                                                },
                                            ]}>
                                            <Input placeholder='Ingresar información' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item autoComplete="off" name='email' label='Correo electrónico' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresa tu correo electrónico",
                                            },
                                            {
                                                type: "email",
                                                message: "Ingresar correo electrónico",
                                            },
                                            {
                                                validator: (_, value) => value ? onValidateEmail(value) : Promise.resolve()
                                            }
                                        ]}>
                                            <Input autoComplete="off" placeholder='tumail@empresa.com' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name='profesionUOficio' label='Profesión u Oficio' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresar profesión u oficio",
                                            },
                                        ]}>
                                            <Input placeholder='Tu oficio' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item label='Cargo' name="cargoId">
                                            <Select
                                                showSearch={false}
                                                placeholder="Selecciona cargo"
                                                optionFilterProp="children"
                                                options={cargos}
                                                loading={cargos === null}
                                                popupClassName="dropdown-drpCargoP"
                                                className="drpCargo"
                                                suffixIcon={isOpenCar ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenCar(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item label='Área del cargo' name="areaCargoId">
                                            <Select
                                                showSearch={false}
                                                placeholder="Selecciona área"
                                                optionFilterProp="children"
                                                options={areaCargos}
                                                loading={areaCargos === null}
                                                onChange={onChangeAreaCargo}
                                                popupClassName="dropdown-drpAreaP"
                                                className="drpArea"
                                                suffixIcon={isOpenArea ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenArea(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={0}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Text className='text-mobile subtitle-pass-persona' style={{ marginTop: '5px' }}>
                                            Crear contraseña
                                        </Text>
                                    </Col>
                                </Row>
                                {!existeEmailBd?.tieneCuentakeyCloack ?
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12}>
                                            <Form.Item name='password' label='Crear contraseña' className={`username ${new RegExp(patternPassword).test(valuePassword) ? '' : 'error-pass'}`} rules={[
                                                {
                                                    required: true,
                                                    message: "Introduce la contraseña",
                                                },
                                                {
                                                    pattern: patternPassword,
                                                    message:  <div className="password-info">
                                                        <Text>Debe ingresar una contraseña de al menos 12 dígitos.</Text>
                                                        <Text>La contraseña debe tener al menos una minúscula y una mayúscula.</Text>
                                                        <Text>La contraseña debe tener al menos un número.</Text>
                                                        <Text>La contraseña debe tener al menos un símbolo especial [!@#$%^&*-_.].</Text>
                                                    </div>
                                                }
                                            ]}>
                                                <Input.Password 
                                                className={new RegExp(patternPassword).test(valuePassword) ? 'sucessValidation' : ''} 
                                                placeholder='Introduce la contraseña'
                                                iconRender={visible =>
                                                    visible ? <img src={show} alt='show' className='img-eye' /> : <img src={hide} alt='hide' />
                                                }/>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12}>
                                            {/*<Form.Item onPaste={e => {
                                                e.preventDefault();
                                                return false
                                            }} autoComplete="off" name='passwordRepeat' label='Repetir contraseña' className="username" rules={[
                                                {
                                                    required: true,
                                                    message: "Repite la contraseña",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidatePassword(value) : Promise.resolve()
                                                }
                                            ]}>
                                                <Input.Password className={valuePassword === valuePasswordRepeat ? 'sucessValidation' : ''} placeholder='Repite la contraseña' />
                                        </Form.Item>*/}
                                        <Form.Item
                                            onPaste={e => {
                                                e.preventDefault();
                                                return false;
                                            }}
                                            style={{ height: 48 }}
                                            autoComplete="off"
                                            name="passwordRepeat"
                                            label="Repetir contraseña"
                                            popupClassName='pass-repeat'
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Repite la contraseña",
                                                },
                                                {
                                                    validator: (_, value) =>
                                                        value ? onValidatePassword(value) : Promise.resolve(),
                                                },
                                            ]}
                                        >
                                            <Input.Password
                                                className={valuePassword === valuePasswordRepeat ? 'sucessValidation' : ''}
                                                placeholder="Repite la contraseña"
                                                iconRender={visible =>
                                                    visible ? <img src={show} alt='show' /> : <img src={hide} alt='hide' />
                                                }
                                            />
                                        </Form.Item>
                                        </Col>
                                    </Row> : null}
                                <Row gutter={{ }}>
                                    
                                    <Col style={{ textAlign: 'left' }} className="col-terminos" xs={24} sm={24} md={24} lg={24}>
                                        <Form.Item normalize={(value) => value ? value : undefined} name='terminos' valuePropName="checked" rules={[
                                            {
                                                required: true,
                                                //message: "Debes aceptar los Términos y Condiciones y la Política de Privacidad para poder continuar.",
                                            },
                                        ]}>
                                            <Space size={8} align="start">
                                                <Checkbox className='custom-hover-checkbox' style={{
                                                    position: 'relative',
                                                }} onChange={handleCheckboxChange}></Checkbox>
                                                <Text className='termi'>He leído y acepto los <Link href="#" style={{ fontWeight: 'bold' }} className='letraVerde' onClick={() => onDownloadTerminosYcondiciones()}> Términos y Condiciones y la Política de Privacidad</Link></Text>
                                            </Space>
                                            {/*<Checkbox className='check-crea-cuenta-persona' style={{ fontWeight: '400' }}>He leído y acepto los <Link href="#" style={{ fontWeight: 'bold' }} className='letraVerde' onClick={() => onDownloadTerminosYcondiciones()}> Términos y Condiciones y la Política de Privacidad</Link></Checkbox>*/}
                                            {/*<Row>
                                                <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1}><Checkbox className=''></Checkbox></Col>
                                                <Col xs={22} sm={22} md={22} lg={23} xl={23} xxl={23} className='col-texto-check'><Text className='termi'>He leído y acepto los <Link href="#" style={{ fontWeight: 'bold' }} className='letraVerde' onClick={() => onDownloadTerminosYcondiciones()}> Términos y Condiciones y la Política de Privacidad</Link></Text></Col>
                                            </Row>*/}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]} justify="center">
                                    <Col xs={24} sm={12} md={4} lg={5}>
                                        <Button
                                        className="btn-mobile btn-form-persona-volver"
                                        htmlType="button"
                                        onClick={() => onVolver()}
                                        
                                        >
                                        Volver
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={4} lg={5}>
                                        <Button
                                            htmlType="button"
                                            onClick={() => onCrearCuenta()}
                                            disabled={!isCheckboxChecked}
                                            className={`${
                                                isCheckboxChecked
                                                    ? "btn-mobile btn-form-persona"
                                                    : "btn-mobile btn-form-persona-disabled"
                                            }`}
                                            >
                                            Crear cuenta
                                        </Button>
                                    </Col>
                                </Row>
                                <p className="text-tienes-cuenta font-semibold text-muted" style={{ textAlign: 'center' }}>
                                    ¿Ya tienes una cuenta?{" "}
                                    <Link to="#" onClick={() => keycloak.login({ redirectUri: `${process.env.REACT_APP_URL}/puente` })} className="text-dark font-bold letraVerdeInicio">
                                        Inicia Sesión
                                    </Link>
                                </p>
                            </Form>
                        </Col>
                        {/*<Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={10}>
                            <div style={{ width: '45%', position: 'fixed' }}>
                                <div className="right-section">
                                    <div className="background-image"></div>
                                </div>
                            </div>
                        </Col>*/}

                    </Row>
                </Content>
            </Layout>
            <ModalExitoCrearCuenta redirectToLogin={true} fueCorrecto={accionCorrecta} isModalOpen={isModalOpen} handleOk={handleOk} tipoCuenta={"Persona"} poseeCuentaKeyCloack={existeEmailBd?.tieneCuentakeyCloack} />
        </>
    )
}

export default FormCrearCuentaPersonas