import { useEffect, useState } from 'react'
import MetodosPago from '../../components/metodos/MetodosPago'
import '../../assets/styles/ProductoPersona.styles.scss'
import { Typography, Row, Col, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import ModalAvisos from '../../components/modal/ModalAvisos'

const { Title } = Typography
const MediosDePagoPersona = () => {
  const navigate = useNavigate()
  const { estado } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dataModal, setDataModal] = useState(null)

  useEffect(() => {
    if (estado) {
      setDataModal({
        mensaje: 'Te enviamos una notificación a tu correo.',
        fueCorrecto: estado === 'OK',
        titulo: estado === 'OK' ? 'Medio de pago agregado con éxito.' : 'Ha ocurrido un error, inténtelo nuevamente.',
        msjBoton: 'Aceptar'
      })

      setIsModalOpen(true)
    }


  }, [estado])

  return (
    <>
      <ModalAvisos
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mensaje={dataModal?.mensaje}
        titulo={dataModal?.titulo}
        fueCorrecto={dataModal?.fueCorrecto}></ModalAvisos>
      <Row justify="space-between">
        <Col xs={9} sm={14} md={14} lg={18} xxl={20}>
          <Title level={4} style={{ textAlign: 'left', marginTop: 15 }}>Mis Tarjetas</Title>
        </Col>
        <Col xs={15} sm={10} md={10} lg={6} xxl={4}>
          <Button style={{ marginTop: 15 }} shape='round' type='primary' onClick={() => navigate('/nuevo-metodo-pago')}>Agregar nuevo medio de pago</Button>
        </Col>
      </Row>
      <MetodosPago />
    </>
  )
}

export default MediosDePagoPersona