import React from 'react'
import { Avatar, Card, Col, Row, Typography } from 'antd'
import { useSelector } from 'react-redux'
import './Khipu.styles.scss';
import { Content } from 'antd/es/layout/layout';
import { CheckCircleOutlined, CreditCardOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Text, Title } = Typography

const Cancelado = () => {

  const usuario = useSelector(state => state.aplicacion.usuario)
  return (

    <Content>
      <Row justify="center" >
        <Col md={12} xs={24} >
          <Card className="card-container">
            <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<ExclamationCircleOutlined />}/>
            <Title level={3}>
              Pago no efectuado
            </Title>
            <Text>
              Tu pago ha sido cancelado. Vuelva a seleccionar el producto y reintente.
            </Text >

          </Card>
        </Col>
      </Row>

    </Content>


  )
}

export default Cancelado