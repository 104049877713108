import { CheckCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ModalExitoEditar = ({ isModalOpen, handleOk, handleCancel }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={300}
        footer={[
          <Button key="submit" type="primary" shape="round" onClick={() => navigate('/home')}>
            Volver al perfil de usurio
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Avatar style={{ backgroundColor: '#2EEBFF' }} size={80} icon={<CheckCircleOutlined />} />
          <h2>Edicion de datos exitosa</h2>
          <p style={{ fontSize: 16 }}>
            Te enviamos un correo para notificar los cambios realizados
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default ModalExitoEditar