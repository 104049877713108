import React from 'react'
import { Typography, Row, Col, Card } from 'antd'
import '../personas/accesosDirectos/accesos.style.scss'
import { useNavigate } from 'react-router'
import { PerfilUsuarioId } from "../../enum/enums";
import { useSelector } from 'react-redux';
import AccesosAdminEmpresa from './accesosDirectos/AccesosAdminEmpresa';
import AccesosConsumidorEmpresa from './accesosDirectos/AccesosConsumidorEmpresa';
import AccesosDirectosPersona from './accesosDirectos/AccesosPersona';
const { Text, Title } = Typography

const HomePersona = () => {
  const navigate = useNavigate()
  const usuario = useSelector(state => state.aplicacion.usuario)

  return (
    <>
      <Row gutter={{ xs: 8, sm: 10, md: 20, lg: 20 }}>
        <Col span={24}>
          <Title level={3} style={{ fontWeight: 'bold' }}>
            Bienvenido(a) al nuevo marketplace de soluciones tecnológicas de la Bolsa de Santiago
          </Title>
        </Col>
        <Col span={24}>
          <Text style={{ fontSize: 16 }}>
            {usuario?.perfilId === PerfilUsuarioId.PERSONA_NATURAL ?
              'En este portal podrás gestionar tu cuenta y suscripciones, ver los productos que ofrecemos actualmente y contratar los que necesites.' :
              'En este portal podrás gestionar la cuenta de tu empresa, los usuarios asociados y las suscripciones contratadas, como también ver los productos que ofrecemos actualmente.'}
          </Text >
        </Col>
        <Col span={24}>
          <Text style={{ fontSize: 16 }}>
            A continuación te dejamos accesos directos a las distintas secciones de este sitio.
          </Text >
        </Col>
      </Row>
      {usuario?.perfilId === PerfilUsuarioId.ADMIN_EMPRESA ? <AccesosAdminEmpresa></AccesosAdminEmpresa> :
        usuario?.perfilId === PerfilUsuarioId.PERSONA_NATURAL ? <AccesosDirectosPersona></AccesosDirectosPersona> :
          <AccesosConsumidorEmpresa></AccesosConsumidorEmpresa>
      }
    </>
  )
}

export default HomePersona