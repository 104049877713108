import { useEffect, useState } from 'react'
import { consultaEstadoPago } from '../../services/suscripcionApi'
import { CheckCircleOutlined, CreditCardOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal, Row, Col, Card, Spin, Typography } from 'antd'
import { MedioPagoId, EstadoPagoId, TipoPagoKhipuId } from '../../enum/enums'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { PerfilUsuarioId } from '../../enum/enums'
import { setRefrescarNotificaciones } from '../../redux/reducers/AppReducer'
import './Modal.styles.scss'

const ModalValidandoKhipu = ({ isModalOpen, onClose, idTransaccion, status, tipoAccionId }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [pagoSuscripcion, setPagoSuscripcion] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const perfilId = useSelector(state => state.aplicacion.usuario.perfilId)

    useEffect(() => {
        const fetchData = async (idTransaccionKhipu) => {
            setIsLoading(true)

            // se consulta estado hasta obtener el pago
            const intervalId = setInterval(async () => {
                try {
                    const res = await consultaEstadoPago(MedioPagoId.KIPHU, idTransaccionKhipu)
                    if (res && res.accionFueCorrecta && (res.data.idEstadoPago === EstadoPagoId.APROBADO || res.data.idEstadoPago === EstadoPagoId.RECHAZADO)) {
                        setPagoSuscripcion(res.data)
                        setIsLoading(false)
                        clearInterval(intervalId)
                        dispatch(setRefrescarNotificaciones(true))
                    }
                } catch (error) {
                    console.log(error)
                    setIsLoading(false)
                    clearInterval(intervalId)
                }
            }, 1000)

        }

        if (idTransaccion && isModalOpen && status !== 'CANCELADO') {
            fetchData(idTransaccion)
        }
        else if (status === 'CANCELADO') {
            setPagoSuscripcion({
                idEstadoPago: EstadoPagoId.RECHAZADO
            })
            setIsLoading(false)
            //probar pago prx mes
        }
    }, [idTransaccion, isModalOpen])

    const onAceptar = () => {
        if (tipoAccionId === TipoPagoKhipuId.CONTRATACION) {
            if (pagoSuscripcion?.idEstadoPago === EstadoPagoId.APROBADO && perfilId === PerfilUsuarioId.ADMIN_EMPRESA) {
                navigate("/productosEmpresa")
            }
            else if (pagoSuscripcion?.idEstadoPago === EstadoPagoId.APROBADO) {
                navigate("/productos")
            }
            else {
                onClose()
            }
        }
        else {
            onClose()
        }
    }

    return (
        <Modal
            closable={false}
            centered
            open={isModalOpen}
            onOk={onClose}
            width={450}
            footer={[]}
        >
            <Row justify="center" >
                <Col span={24} style={{ textAlign: 'center' }} >
                    {isLoading ?
                        <div>
                            <Spin className='tooltipValidando' tip="Estamos validando tu pago" size="large" spinning={isLoading} >
                                <div style={{ height: 80 }} />
                            </Spin>
                        </div> :
                        <div  >
                            {pagoSuscripcion?.idEstadoPago === EstadoPagoId.APROBADO ?
                                <Avatar style={{ backgroundColor: '#00FF91' }} size={80} icon={<CheckCircleOutlined />} /> :
                                <Avatar style={{ backgroundColor: '#ff4201' }} size={80} icon={<CreditCardOutlined />} />}

                            {pagoSuscripcion && (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <p className='msjTitulo'>
                                                {pagoSuscripcion.idEstadoPago === EstadoPagoId.APROBADO ? 'Pago exitoso' : 'Ocurrió un problema con el pago'}
                                            </p>
                                        </Col>
                                    </Row>

                                    {tipoAccionId === TipoPagoKhipuId.CONTRATACION ? <>
                                        <Row>
                                            <Col span={24}>
                                                {pagoSuscripcion.idEstadoPago === EstadoPagoId.APROBADO ?
                                                    <>
                                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>Te enviamos una notificación por correo con el detalle de tu suscripción e instrucciones.</p>
                                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>Para ver tus productos contratados haz click en el siguiente botón:</p>
                                                    </>
                                                    :
                                                    <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>
                                                        Por favor, vuelve a intentar pagar tu suscripción, si aún así sigues teniendo problemas contáctate con soporte al correo <span style={{ color: '#ff4201' }}> sacbursatil@bolsadesantiago.com</span>
                                                    </p>
                                                }
                                            </Col>
                                        </Row>
                                        <Button style={{ marginTop: 15 }} key="submit" type="primary" shape="round" onClick={() => onAceptar()}>
                                            {pagoSuscripcion.idEstadoPago === EstadoPagoId.APROBADO ? 'Ir a Mis Productos' : 'Aceptar'}
                                        </Button>
                                    </> : <>
                                        <Row>
                                            <Col span={24}>
                                                {pagoSuscripcion.idEstadoPago === EstadoPagoId.APROBADO ?
                                                    <>
                                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>Te enviamos una notificación por correo con el detalle de tu suscripción.</p>
                                                        <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>La fecha de vencimiento del producto se ha actualizado, para más información haz click en el siguiente botón:</p>
                                                    </>
                                                    :
                                                    <p style={{ fontSize: 16, marginTop: 0, marginBottom: 0, textAlign: 'center' }}>
                                                        Por favor, vuelve a intentar pagar tu suscripción, si aún así sigues teniendo problemas contáctate con soporte al correo <span style={{ color: '#ff4201' }}> sacbursatil@bolsadesantiago.com</span>
                                                    </p>
                                                }
                                            </Col>
                                        </Row>
                                        <Button style={{ marginTop: 15 }} key="submit" type="primary" shape="round" onClick={() => onAceptar()}>
                                            {pagoSuscripcion.idEstadoPago === EstadoPagoId.APROBADO ? 'Ir al producto' : 'Aceptar'}
                                        </Button>
                                    </>}
                                </>)
                            }
                        </div>}
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalValidandoKhipu