import { Typography } from 'antd';
import HistorialPagos from '../../components/historialPagos/HistorialPagos';

const { Title } = Typography;

const Pagos = () => {
  return (
    <>
      <Title level={4} style={{ textAlign: 'left', fontWeight: 'bold' }}>Historial de pagos</Title>
      <br></br>
      <HistorialPagos />
    </>
  )
}

export default Pagos