import React, { useState, useEffect } from 'react';
import { Row, Col, Avatar, Tooltip } from 'antd';
import { getInicial, CapitalizeFirstLetter } from '../../services/Helpers/stringHelper';
import Paragraph from 'antd/es/typography/Paragraph';
import { PerfilUsuarioId } from '../../enum/enums';

const NombreUsuario = ({ isMenuCollapsed, usuario }) => {
    return (
        <>
            {isMenuCollapsed ?
                <Row style={{ marginTop: 20 }} gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                    <Col span={24}>
                        <Tooltip placement="right" title={CapitalizeFirstLetter(`${usuario?.nombres} ${usuario?.apellidos}`)}>
                            <Avatar
                                style={{
                                    backgroundColor: '#ff4201',
                                    verticalAlign: 'middle',
                                }}
                                size="large"
                                shape='circle'
                            >
                                {`${getInicial(usuario?.nombres)}${getInicial(usuario?.apellidos)}`}
                            </Avatar>
                        </Tooltip>
                    </Col>
                </Row> :
                <Row style={{ marginTop: 20 }} gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                    <Col span={8}>
                        <Avatar
                            style={{
                                backgroundColor: '#ff4201',
                                verticalAlign: 'middle',
                            }}
                            size="large"
                            shape='circle'
                        >
                            {`${getInicial(usuario?.nombres)}${getInicial(usuario?.apellidos)}`}
                        </Avatar>
                    </Col>
                    <Col span={15} >
                        <div className='containerNombre'>
                            <Paragraph style={{ margin: 0, textAlign: 'left' }}>{CapitalizeFirstLetter(`${usuario?.nombres} ${usuario?.apellidos}`)}</Paragraph>

                            {usuario?.perfilId === PerfilUsuarioId.PERSONA_NATURAL ?
                                <Paragraph style={{ fontStyle: 'italic', margin: 0, textAlign: 'left' }}>{usuario?.profesionOficio}</Paragraph> :
                                <Paragraph style={{ fontStyle: 'italic', margin: 0, textAlign: 'left' }}>{CapitalizeFirstLetter(usuario?.nombreCliente)}</Paragraph>}
                        </div>
                    </Col>
                </Row>}
        </>
    );
};

export default NombreUsuario