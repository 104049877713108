import React, { useState } from 'react'
import Title from 'antd/es/typography/Title'
import locale from 'antd/es/date-picker/locale/es_ES'
import { Divider, Space, Typography } from 'antd';
import { UserAddOutlined, UserDeleteOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { Button, Select, Row, Col, Form, Input, DatePicker } from 'antd';
import { esRutValido } from '../../../services/Helpers/RutHelper';
import { AddUser, Calendar, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Delete } from 'react-iconly';

import delUser from '../../../assets/images/userd.svg';

const { Text } = Typography;

const Paso2 = ({ setPasoActual, form, pasoActual, cargos, areaCargos, nacionalidades, paises }) => {
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const [isOpenNac, setIsOpenNac] = useState(false);
    const [isOpenResidencia, setIsOpenResidencia] = useState(false);
    const [isOpenCargo, setIsOpenCargo] = useState(false);
    const [isOpenArea, setIsOpenArea] = useState(false);

    const onNextPage = async () => {
        form.validateFields(['representantesLegales'], { validateOnly: false, recursive: true })
            .then((values) => {
                setPasoActual(2)
            }).catch((errorInfo) => {
                console.log(errorInfo)
            })
    }

    const onValidateRut = async (value) => {
        const esValido = esRutValido(value)

        if (esValido) {
            return Promise.resolve()
        }
        else {
            return Promise.reject(new Error('Ingresa un Rut válido'))
        }
    }

    return (
        <div className='paso2' style={{ display: `${pasoActual === 1 ? '' : 'none'}` }}>
            <br></br>
            <Form.List name="representantesLegales">
                {(fields, { add, remove }) => (
                    <div                    >
                        {fields.map((field, index) => (
                            <div key={index}>
                                {index > 0 ? <Divider className='divider-paso2'></Divider> : null}
                                {/*<Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} style={{ textAlign: 'left' }}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                        <Space>
                                            <Text className='title-representante' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                Datos Representante legal {index > 0 ? `#${index + 1}` : ''}
                                            </Text>
                                            {fields.length > 1 ? <Button onClick={() => remove(field.name)} className="btmEliminaRepresentane" style={{ paddingBottom: 0, color: '#0E9753', fontFamily: 'PT Serif', fontSize: 16 }} icon={<UserDeleteOutlined />} type="link">Eliminar representante</Button> : null}
                                        </Space>
                                    </Col>
                                </Row>*/}
                                <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }} align="middle" style={{ textAlign: 'left' }}>
                                    <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: 'start', marginBottom: '8px' }} >
                                        <Text className='text-mobile subtitle-pass-empresa' style={{ marginTop: '15px', marginBottom: '15px' }}>
                                            Datos Representante legal {index > 0 ? `#${index + 1}` : ''}
                                        </Text>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: window.innerWidth < 768 ? 'start' : '' }} className='row-eliminar'>
                                        {fields.length > 1 && (
                                            <>
                                        {/*<Button 
                                            onClick={() => remove(field.name)} 
                                            className="btmEliminaRepresentane" 
                                            style={{ paddingBottom: 0, color: '#0E9753', fontFamily: 'PT Serif', fontSize: 16, fontWeight: '700 !important' }} 
                                            icon={<img src={delUser} alt='delete' />} 
                                            type="link"
                                        >
                                            Eliminar representante
                                        </Button>*/}
                                            <Row gutter={0} className='row-button'>
                                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                                                    <img src={delUser} alt='delete'/>
                                                </Col>
                                                <Col xs={1} sm={23} md={23} lg={23} xl={23} xxl={23} className='padd-delete boton-text'>
                                                    <Button 
                                                        onClick={() => remove(field.name)} 
                                                        className="btmEliminaRepresentane" 
                                                        style={{ paddingBottom: 0, color: '#0E9753', fontFamily: 'PT Serif', fontSize: 16, fontWeight: '700 !important' }} 
                                                        type="link"
                                                    >
                                                        Eliminar representante
                                                    </Button>
                                                </Col>
                                            </Row>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className='padd-formp2'>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name={[field.name, 'nombreRepresentante']}
                                            label='Nombres Representante'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar nombre",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Ingresa nombres' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name={[field.name, 'apellidosRepresentante']}
                                            label='Apellidos Representante'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar apellidos",
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Ingresa apellidos' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name={[field.name, 'rut']}
                                            label='Número de identificación (RUT)'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresa número de identificación",
                                                },
                                                {
                                                    validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                                                }
                                            ]}
                                        >
                                            <Input placeholder='11.111.111-1' className='custom-placeholder' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name={[field.name, 'nacionalidadId']}
                                            label="Nacionalidad"
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar nacionalidad",
                                                },
                                            ]}>
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                placeholder="Selecciona una nacionalidad"
                                                options={nacionalidades}
                                                dropdownClassName="dropdown-drpNac"
                                                suffixIcon={isOpenNac ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenNac(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            name={[field.name, 'paisResidenciaId']}
                                            label="País de residencia"
                                            className="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar país de residencia",
                                                },
                                            ]}>
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                placeholder="Selecciona país de residencia"
                                                options={paises}
                                                dropdownClassName="dropdown-drpResidencia"
                                                suffixIcon={isOpenResidencia ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenResidencia(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ingresar una fecha de nacimiento",
                                                }]}
                                            name={[field.name, 'fechaNacimientoRep']}
                                            label='Fecha de nacimiento'
                                            className="username">
                                            {/*<DatePicker locale={locale} format={"DD/MM/YYYY"} placeholder='Selecciona fecha' style={{ width: '100%' }} suffixIcon={<Calendar primaryColor='#666666' />}/>*/}
                                            <DatePicker
                                                prevIcon={<ChevronLeft style={{ color: '#fffbfa', fontSize: 24 }}></ChevronLeft>}
                                                nextIcon={<ChevronRight style={{ color: '#fffbfa', fontSize: 24 }}></ChevronRight>}
                                                size='large'
                                                popupClassName='custom-calendar'
                                                locale={locale}
                                                format={"DD/MM/YYYY"}
                                                placeholder='Selecciona la fecha'
                                                style={{ width: '100%' }}
                                                suffixIcon={<Calendar primaryColor='#666666' />}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name={[field.name, 'cargoId']} label="Cargo" className="username">
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                placeholder="Selecciona cargo"
                                                options={cargos}
                                                dropdownClassName="dropdown-drpCargo"
                                                suffixIcon={isOpenCargo ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenCargo(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name={[field.name, 'areaCargoId']} label="Área del cargo" className="username">
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                placeholder="Selecciona área de cargo"
                                                options={areaCargos}
                                                dropdownClassName="dropdown-drpArea"
                                                suffixIcon={isOpenArea ? <ChevronUp /> : <ChevronDown />}
                                                onDropdownVisibleChange={(open) => setIsOpenArea(open)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={6}>
                                        <Form.Item name={[field.name, 'email']} label='Correo electrónico' className="username" rules={[
                                            {
                                                required: true,
                                                message: "Ingresar un correo electrónico",
                                            },
                                            {
                                                type: "email",
                                                message: "Ingresa un email válido",
                                            }
                                        ]}>
                                            <Input placeholder='correo@correo.cl' className='custom-placeholder'/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Divider orientation="right" orientationMargin={0} className='divider-paso2'>

                            
                        </Divider>
                        <Row gutter={[20, 20]} justify='start'>
                            <Col span={24} style={{ textAlign: "left" }}>
                                <Text className='text-mobile subtitle-pass-empresa'>
                                    Agregar representante legal
                                </Text>
                            </Col>
                            <Col span={24} style={{ textAlign: "left" }}>
                                <Button
                                    
                                    type="primary"
                                    className="btn-representante"
                                    htmlType="button"
                                    onClick={() => add()}
                                >
                                    <Row>
                                        <Col span={3} style={{ marginTop: 2 }}> <AddUser/></Col>
                                        <Col span={21} style={{ marginTop: 3 }}><span className='' > Agregar representante</span></Col>
                                    </Row>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}
            </Form.List>
            <br />
            <Row justify="center" gutter={[20, 20]}  className='padd-rep'>
                {/*<Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        htmlType="default"
                        className="btnVolver"
                        htmlType="button"
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        shape="round"
                        onClick={() => setPasoActual(0)}
                    >
                        Volver
                    </Button>
                </Col>
                <Col xs={12} sm={7} md={6} lg={5} xl={5} xxl={4}>
                    <Button
                        type="primary"
                        htmlType="button"
                        className="btnAzul"
                        onClick={() => onNextPage()}
                        style={{ paddingRight: 40, paddingLeft: 40 }}
                        shape="round"
                    >
                        Siguiente
                    </Button>
                </Col>*/}
                <Col xs={24} sm={12} md={8} lg={6}>
                                        <Button
                                        className="btn-mobile btn-form-pasos-volver"
                                        htmlType="button"
                                        onClick={() => setPasoActual(0)}
                                        >
                                        Volver
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={6}>
                                        <Button
                                        className="btn-mobile btn-form-pasos"
                                        type="primary"
                                        htmlType="button"
                                        onClick={() => onNextPage()}
                                        >
                                        Siguiente
                                        </Button>
                                    </Col>
            </Row>
            

        </div>
    )
}

export default Paso2