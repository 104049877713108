import { createSlice } from '@reduxjs/toolkit'

const ProductoSlice = createSlice({
    name: 'producto',
    initialState: {
        desplegarProductosEnCard: true,
        productosFiltrados: null,
        productosOriginales: null,
        productoContratar: null,
        productosContratablesFiltrados: null,
        productosContratablesOriginales: null,
        productoSeleccionadoBreadcumps: {
            nombre: '',
            id: null
        }
    },
    reducers: {
        setNombreProductoSeleccionado(state, action) {
            state.productoSeleccionadoBreadcumps = action.payload
        },
        setProductoContratar(state, action) {
            state.productoContratar = action.payload
        },
        setTipoDespliegueProductos(state, action) {
            state.desplegarProductosEnCard = action.payload
        },
        setProductos(state, action) {
            state.productosOriginales = action.payload
        },
        setProductosFiltrados(state, action) {
            state.productosFiltrados = action.payload
        },
        setProductosContratables(state, action) {
            state.productosContratablesOriginales = action.payload
        },
        setProductosContratablesFiltrados(state, action) {
            state.productosContratablesFiltrados = action.payload
        }
    }
})

export const { setNombreProductoSeleccionado, setTipoDespliegueProductos, setProductos, setProductosFiltrados, setProductoContratar, setProductosContratables, setProductosContratablesFiltrados } = ProductoSlice.actions

export default ProductoSlice.reducer