export function esRutValido(rut) {
    if (typeof rut !== "string") {
        return false;
    }
    if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
        return false;
    }

    rut = cleanRut(rut);

    var t = parseInt(rut.slice(0, -1), 10);
    var m = 0;
    var s = 1;

    while (t > 0) {
        s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
        t = Math.floor(t / 10);
    }

    var v = s > 0 ? "" + (s - 1) : "K";
    return v === rut.slice(-1);
}

export function cleanRut(rut) {
    return typeof rut === "string"
        ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
        : "";
}

export function daFormatoRut(rutDni) {
    rutDni = cleanRut(rutDni);

    var result = rutDni.slice(-4, -1) + "-" + rutDni.substr(rutDni.length - 1);
    for (var i = 4; i < rutDni.length; i += 3) {
        result = rutDni.slice(-3 - i, -i) + "." + result;
    }
    return result;
}

//recibe rut completo, y lo retorna sin DV
export function getRutSinDv(rutDni) {
    let result = cleanRut(rutDni)

    if (rutDni) {
        result = parseInt(result.slice(0, -1))
    }

    return result;
}

//recibe rut completo, y retorna solo el DV
export function getDvRut(rutDni) {
    let result = cleanRut(rutDni)

    if (rutDni) {
        result = rutDni.charAt(rutDni.length - 1)
    }

    return result;
}

