import { useSelector } from "react-redux"
import DetalleProducto from "../../components/detalleProducto/DetalleProducto"

const DetalleProductoPersona = () => {
    return (
        <>
            <DetalleProducto></DetalleProducto>
        </>
    )
}

export default DetalleProductoPersona