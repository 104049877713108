import { PublicAxios } from "../services/Helpers/publicInterceptorAxios"

const urlApi = process.env.REACT_APP_BACKEND_URL

export const enviaMailCambioPassword = async (email) => {
    return PublicAxios.put(`${urlApi}/Usuario/EmailPassword/${email}`).then((res) => { return res.data })
}

export const enviaMailBienvenida = async (email) => {
    return PublicAxios.put(`${urlApi}/Usuario/EmailBienvenida/${email}`).then((res) => { return res.data })
}

export const sendContacto = async (data) => {
    return PublicAxios.post(`${urlApi}/Contacto/Marketplace`, data).then((res) => { return res.data })
}

export const getPaises = async () => {
    return PublicAxios.get(`${urlApi}/util/paises`).then((res) => { return res.data })
}

export const getEncuesta = async () => {
    return PublicAxios.get(`${urlApi}/util/Encuesta`).then((res) => { return res.data })
}

export const getEjecutivos = async () => {
    return PublicAxios.get(`${urlApi}/util/ejecutivos`).then((res) => { return res.data })
}

export const getNacionalidades = async () => {
    return PublicAxios.get(`${urlApi}/util/nacionalidades`).then((res) => { return res.data })
}

export const getCargos = async () => {
    return PublicAxios.get(`${urlApi}/util/cargos`).then((res) => { return res.data })
}

export const getAreaCargos = async () => {
    return PublicAxios.get(`${urlApi}/util/areaCargo`).then((res) => { return res.data })
}

export const getRubrosComerciales = async () => {
    return PublicAxios.get(`${urlApi}/util/rubroComercial`).then((res) => { return res.data })
}

export const getRegiones = async (paisId) => {
    return PublicAxios.get(`${urlApi}/util/regiones?idPais=${paisId}`).then((res) => { return res.data })
}

export const getComunas = async (ciudadId) => {
    return PublicAxios.get(`${urlApi}/util/comunas?idCiudad=${ciudadId}`).then((res) => { return res.data })
}

export const savePersonaNatural = async (data) => {
    return PublicAxios.post(`${urlApi}/Usuario/Crear`, data).then((res) => { return res.data })
}

export const existeRutRegistrado = async (rut) => {
    return PublicAxios.get(`${urlApi}/Usuario/existe?rut=${rut}`).then((res) => { return res.data })
}

export const existeEmail = async (email) => {
    return PublicAxios.get(`${urlApi}/Usuario/Cuenta?email=${email}`).then((res) => { return res.data })
}