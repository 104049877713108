import { CreditCardOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal, Row, Col } from 'antd'
import './Modal.styles.scss'

const ModalAdvertenciaBajaSuscripcion = ({ idProducto, isModalOpen, onClose, onAceptar }) => {
    return (
        <div>
            <Modal
                closable={false}
                centered
                open={isModalOpen}
                onOk={onClose}
                width={450}
                footer={[
                    <Button key="cancelar" shape="round" onClick={onClose}>
                        Volver a atrás
                    </Button>,
                    <Button key="submit" type="primary" shape="round" onClick={onAceptar}>
                        Cancelar suscripción
                    </Button>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<CreditCardOutlined />} />
                    <Row>
                        <Col span={24}>
                            <p className='msjTitulo'>
                                ¿Estás seguro que deseas cancelar esta suscripción?
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {idProducto === "1" ?
                                <p>
                                    No te preocupes, si cancelas este producto podrás volver a contratarlo posteriormente.
                                </p> :
                                <p>
                                    Si cancelas, el producto quedará habilitado hasta que que se cumpla el último periodo de pago que realizaste.
                                </p>
                            }
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default ModalAdvertenciaBajaSuscripcion