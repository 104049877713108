import Calendar from "react-calendar";
import styled, { css } from "styled-components";

const getEventsStyles = ({ events }) => {
  let styles = "";
  events.forEach((event) => {
    styles += `
      abbr[aria-label='${event}'] {
        font-weight: bold;
        color: white; //cambiar por los 400
      }
    `;
  });
  return styles;
};

export const StyleContainer = styled.div`
  position: relative;
  border-radius: 20px 4px 20px 4px;
  // border: 2px solid #FF3700;
  padding: 16px 24px;
  background-color: #3D3D3D;
  width: 360px;
`;

export const StyledIconPosition = styled.div`
  bottom: 2px;
  height: 16px;
  position: relative ;
  width: 16px;
`;
export const StyledText = styled.div`
  color: #3D3D3D;
  flex: 1;
  font-family: 'Degular';
  font-size: 16;
  font-style: var(--p-3-regular-font-style);
  font-weight: 700;
  letter-spacing: 4%;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
`;

export const StyleCloseContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 4px 4px 20px 4px;
  flex-direction: row;
  display: flex;
  gap: 4px;
  padding: 6px 8px 6px 16px;
  position: relative;
`;

export default styled(Calendar)`
  // width: 320px;
  max-width: 100%;
  padding: 4px;
  font-family: var(--p-1-regular-font-family);
  border-radius: 12px;
  line-height: var(--p-1-regular-line-height);
  background-color: #3D3D3D;
  border-radius: 20px 4px 20px 4px;
  border: 2px solid #FF3700;
  box-shadow: var(--big-shadow: 0.5em 0.5em 0.75em 0px rgba(28, 28, 28, 0.12));
  &,
  & *,
  & *:before,
  & *:after {
    box-sizing: border-box;
    text-decoration: none;
    border: transparent;
    // background-color: #F7F7F7;
    background-color: transparent;
  }
  button {
    margin: 0;
    border: 0;
    outline: none;
    background: transparent;
  }
  button:enabled:hover {
    cursor: pointer;
    background-color: #F8E2DA;
    background: #F8E2DA;
    color: #3D3D3D
  }

  /* Navigation */
  .react-calendar__tile:disabled {
    background-color: transparent;
    color: #F7F7F7;
  }
  .react-calendar__navigation {
    display: flex !important;
    margin-bottom: 24px;
    > button {
      background-image: url(assets/icons/icons-for-dark/Iconly-3.svg);
      color: #3D3D3D;
      min-width: 44px;
      background: none;
      background-clip: content-box, padding-box;
      font-size: 28px

      border-radius: 12px;
      [disabled] {
        background-color: white; //cambiar por los 400
      }
    }
    .react-calendar__navigation__label__labelText {
      color: #CCCCCC;
      font-family: 'PT Serif';

      font-style: var(--h-4-bold-font-style);
      font-weight: 700;
      letter-spacing: 4%;
      line-height: 32px;
      text-transform: capitalize;
    }
    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
      display: ${({ showYearControls }) =>
        showYearControls ? "block" : "none"};
    }
  }
  /* End Navigation */

  .react-calendar--doubleView {
    width: 700px;
    .react-calendar__viewContainer {
      display: flex;
      margin: 8px;
    }
    .react-calendar__viewContainer > * {
      width: 50%;
      margin: 8px;
    }
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    color: #CCCCCC;
    font-family: 'Degular';
    font-size: 16px;
    font-style: var(--p-3-bold-font-style);
    font-weight: 700;
    letter-spacing: 4%;
    line-height: 24px;
    white-space: nowrap;
    border-top: 1px solid #FF3700;
    margin-bottom: 4px;
    text-decoration: none;
    .react-calendar__month-view__weekdays__weekday {
      padding: 16px;
    }
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
    .react-calendar__tile {
      display: flex;
      font-size: var(--action-l-font-size);
      align-items: center;
      justify-content: center;
      padding: 8px;
    }
  }
  .react-calendar__month-view__days__day {
    font-family: 'Degular';
    font-size: 16px;
    font-style: var(--p-3-bold-font-style);
    font-weight: 700;
    letter-spacing: 4%;
    line-height: 24px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    color: var(--color-color-semantics-grayscale-100);
    background-color: #F7F7F7;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    font-family: 'Degular';
    font-size: 16px;
    font-style: var(--p-3-bold-font-style);
    font-weight: 700;
    letter-spacing: 4%;
    line-height: 24px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    color: var(--color-color-semantics-grayscale-600);
  }

  .react-calendar__century-view__decades__decade,
  .react-calendar__decade-view__years__year,
  .react-calendar__year-view__months__month {
    color: var(--color-color-semantics-grayscale-100);
    font-family: 'Degular';
    font-size: 16px;
    font-style: var(--p-3-bold-font-style);
    font-weight: 700;
    height: 24px;
    left: 0;
    letter-spacing: 4%;
    line-height:24px;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 8px;
  }

  .react-calendar__tile {
    max-width: 100%;
    margin: 4px 0px;
    background: none;
    text-align: center;
    width: 44px;
    height: 44px;
    padding: 0;
    border: 2px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
    text-transform: capitalize;
    :disabled {
      background-color: #3D3D3D;
    }
    :focus {
      background-color: #3D3D3D;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__tile--hasActive {
    border-radius: 8px;
    background-color: #F7F7F7;
    border: 2px solid white; //cambiar por los 400
    font-weight: bold;
    color: white; //cambiar por los 400
  }
  .react-calendar__tile--active {
    font-weight: bold;
    background-color: #3D3D3D;
    // color: #3D3D3D;
    border-radius: 8px;
    border: 2px solid white; //cambiar por los 400
  }

  .react-calendar__tile--active:hover:focus {
    color: #3D3D3D;
  }

  .react-calendar__tile--active:focus {
    font-weight: bold;
    background-color: #3D3D3D;
    color: #F7F7F7;
    border-radius: 8px;
    border: 2px solid white; //cambiar por los 400
  }
  ${({ showHighLightDayNow }) =>
    showHighLightDayNow &&
    css`
      .react-calendar__tile--now {
        font-weight: bolder;
        color: white; //cambiar por los 400
      }
    `}
  ${(props) => getEventsStyles(props)}
`;

export const StyledNavigationLabel = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 900;
  font-size: var(--action-l-font-size);
`;

export const StyledYearLabel = styled.span`
  font-size: var(--action-l-font-size);
  margin-top: 8px;
  font-weight: normal;
`;