import { Col, Button, Form, Input, Row, Select, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CapitalizeFirstLetter } from '../../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../../services/Helpers/RutHelper';
import { existeEmail, getPaises, getCargos, getCiudades, getAreaCargos, getRegiones, getComunas } from '../../../services/publicApi'
import { setIsLoading, setRefrescarNotificaciones } from '../../../redux/reducers/AppReducer';
import ModalAvisos from '../../modal/ModalAvisos';
import { getDetalleUsuario, updateUsuarioEmpresa } from '../../../services/empresaApi';
import { useNavigate, useParams } from 'react-router';
import './usuario.style.scss'

const _ = require('lodash');
const dayjs = require('dayjs')
const { Title } = Typography;

const EditarUsuarioEmpresa = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { idUsuarioEditar } = useParams()
    const [usuario, setUsuario] = useState(null)
    const [isOpenModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)
    const [formIsSubmiting, setFormIsSubmiting] = useState(false)


    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const data = await getDetalleUsuario(idUsuarioEditar)
                setUsuario(data.usuario)
            }
            catch (error) {
                console.log(error)
                dispatch(setIsLoading(false))
            }
            finally {
            }
        }
        fetchData()
    }, [idUsuarioEditar])

    useEffect(() => {
        const fetchData = async () => {
            if (usuario) {
                Promise.all([
                    getCargos(),
                    getAreaCargos(),
                ]).then((values) => {
                    setCargos(values[0])
                    setAreaCargos(values[1])
                }).finally(() =>
                    dispatch(setIsLoading(false))
                );
            }
        }
        fetchData()
    }, [usuario])

    const onValidateEmail = async (value) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
        const result = regex.test(value);

        if (result && value !== usuario.email) {
            const data = await existeEmail(value)

            if (data.tieneCuentaOnBoarding || data.tieneCuentakeyCloack) {
                return Promise.reject(new Error('Email ingresado ya se encuentra registrado'))
            }
            else {
                return Promise.resolve()
            }
        }
    }

    const onFinish = async (values) => {
        try {
            setFormIsSubmiting(true)
            const dataUpdated = {}

            if (values.telefono !== usuario.celular)
                dataUpdated.celular = values.telefono
            if (values.correo !== usuario.email)
                dataUpdated.email = values.correo
            if (values.cargoId !== usuario.cargoId)
                dataUpdated.cargoId = values.cargoId
            if (values.areaCargoId !== usuario.areaCargoId)
                dataUpdated.areaCargoId = values.areaCargoId

            dataUpdated.usuarioId = parseInt(idUsuarioEditar)

            const formValues = { ...values }
            formValues.usuarioId = parseInt(idUsuarioEditar)
            formValues.dataUpdated = dataUpdated

            if (!_.isEqual({}, dataUpdated)) {
                const result = await updateUsuarioEmpresa(formValues)

                if (result?.accionFueCorrecta) {
                    setDataModal({
                        titulo: 'Edición de datos exitosa',
                        mensaje: 'Se envió un correo para notificar los cambios realizados',
                        fueCorrecto: true
                    })

                    dispatch(setRefrescarNotificaciones(true))
                    setOpenModal(true)
                }
            }
        }
        catch (error) {
            console.log(error)
            setDataModal({
                mensaje: '¡Ha ocurrido un error, por favor inténtelo nuevamente!',
                fueCorrecto: false
            })
            setOpenModal(true)
        }
        finally {
            setFormIsSubmiting(false)

        }
    };

    const oncCloseModal = () => {
        navigate(`/detalle-usuario/${idUsuarioEditar}`)
        setOpenModal(false)
    }

    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0].label
        form.setFieldValue('glosaAreaCargo', glosa)
    }

    return (
        <>
            {usuario ? <>
                <ModalAvisos
                    isModalOpen={isOpenModal}
                    onClose={() => oncCloseModal()}
                    mensaje={dataModal?.mensaje}
                    fueCorrecto={dataModal?.fueCorrecto}
                    titulo={dataModal?.titulo}
                ></ModalAvisos>
                <Title level={4} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 24 }}>Edición de datos usuario</Title>
                <br />
                <Form className='formLabelStrongs formEdicionDatos' form={form} layout='vertical' onFinish={onFinish}
                    initialValues={{
                        telefono: usuario.celular,
                        correo: usuario.email,
                        cargoId: usuario.cargoId,
                        areaCargoId: usuario.areaCargoId,
                        glosaAreaCargo: usuario.areaCargo
                    }}
                >
                    <Form.Item style={{ display: 'none' }} name='glosaAreaCargo'><Input /></Form.Item>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label="Nombres" className="labelReadonly" tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} placeholder={CapitalizeFirstLetter(usuario?.nombres)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Apellidos' className="labelReadonly" tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} placeholder={CapitalizeFirstLetter(usuario?.apellidos)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item className="labelReadonly" label='Número de identificación (RUT)' tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} placeholder={daFormatoRut(usuario?.numeroIdentificacion)} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item label='Fecha de nacimiento' className="labelReadonly" tooltip="Por tu seguridad este dato solo puede ser modificado a través de Soporte Marketplace.">
                                <Input readOnly={true} placeholder={dayjs(usuario?.fechaNacimiento).format('DD/MM/YYYY')} bordered={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='telefono'
                                label='Télefono fijo o Celular'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese su télefono fijo o Celular'
                                        // pattern: /^(\+56)?(9)([0-9]{7})$/,
                                        // message: 'Ejemplo +56912345678 o 912345678',
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='correo'
                                label='Correo electrónico'
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingresa un correo electrónico",
                                    },
                                    {
                                        type: "email",
                                        message: "Ingresa un email válido",
                                    },
                                    {
                                        validator: (_, value) => value ? onValidateEmail(value) : Promise.resolve()
                                    }
                                ]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='cargoId'
                                label='Cargo'
                            >
                                <Select
                                    showSearch
                                    placeholder='Seleccione un cargo'
                                    optionFilterProp='children'
                                    options={cargos}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name='areaCargoId'
                                label='Área del Cargo'
                            >
                                <Select
                                    showSearch
                                    placeholder='Seleccione un area'
                                    optionFilterProp='children'
                                    options={areaCargos}
                                    onChange={onChangeAreaCargo}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 5, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={11} sm={11} md={8} lg={5}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    className="btncancelar"
                                    style={{ width: '100%' }}
                                    danger
                                    onClick={() => navigate(`/detalle-usuario/${idUsuarioEditar}`)}
                                >
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={11} sm={11} md={8} lg={5}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={formIsSubmiting}
                                    style={{ width: '100%' }}
                                >
                                    Guardar cambios
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </> : null}
        </>
    )
}

export default EditarUsuarioEmpresa