import React, { useState } from 'react'
import { Row, Col, Card, Collapse, Typography } from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import './contacto.style.scss'
const { Panel } = Collapse;

const PreguntasFrecuentesPersona = ({ onDownloadGuiaInicio }) => {
    const [activeKey, setActiveKey] = useState([])

    const handlePanelChange = (key) => {
        setActiveKey(key);
    }

    return (
        <div className="preguntasFrecuentes">
            <Row gutter={[16, 16]}>
                <Col className='gutter-row cardpreguntas' xs={24} sm={24} md={24} xl={24} xxl={24} style={{ marginTop: 20 }} >
                    <Card style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 50px -20px' }}>
                        <Collapse
                            activeKey={activeKey}
                            onChange={handlePanelChange}
                            accordion
                            expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
                            expandIconPosition="end"
                            bordered={false}
                        >
                            <Panel
                                header={
                                    <div>
                                        <span style={{ display: 'flex', textAlign: 'left' }}><strong>¿Qué es Portal Marketplace?</strong></span>
                                    </div>
                                }
                                key="21"
                            >
                                <p>Es un portal web donde usuarios pueden revisar la oferta de productos digitales que tiene la Bolsa de Santiago a disposición del mercado bursátil y financiero. </p>
                                <p>Dentro de una gran variedad de funcionalidades que tiene este portal, se pueden contratar productos de manera rápida y sencilla, como también autogestionar suscripciones y darse de baja cuando lo deseen, todo en un mismo sitio. </p>
                                <p> Por el momento los productos que se ofrecen son limitados, en la sección Productos puedes revisar cuáles son. Estamos trabajando para poder continuar incorporando más productos en el futuro de manera que gran parte de los servicios que tiene la Bolsa y nuam sean contratados y gestionados a través de este portal. </p>

                            </Panel>

                            <Panel
                                header={
                                    <div>
                                        <span style={{ display: 'flex', textAlign: 'left' }}><strong>¿Quién puede contratar productos?</strong></span>
                                    </div>
                                }
                                key="263"
                            >
                                <p>Actualmente, Marketplace está dirigido a usuarios de Chile. Solo pueden crear una cuenta y contratar productos personas naturales con cédula chilena vigente y empresas que tengan RUT vigente.</p>
                            </Panel>
                            <Panel
                                header={
                                    <div>
                                        <span style={{ display: 'flex', textAlign: 'left' }}><strong>¿Qué necesito para poder crear una cuenta Persona?</strong></span>
                                    </div>
                                }
                                key="22"
                            >
                                <p>
                                    Para poder crear una cuenta como persona natural solo necesitas completar los datos solicitados en el formulario de registro del portal.
                                </p>
                            </Panel>
                            <Panel
                                header={
                                    <div>
                                        <span style={{ display: 'flex', textAlign: 'left' }}><strong>¿Cómo puedo contratar productos?</strong></span>
                                    </div>
                                }
                                key="23"
                            >
                                <p>Primero debes haber creado una cuenta, una vez la hayas activado podrás ingresar con tus credenciales al Portal Persona y desde ahí en la sección Catálogo de productos contratar aquellos que más te interesen.</p>
                                <p> A continuación, te dejamos una <span style={{ cursor: 'pointer', color:'#00ff91', textDecoration: 'underline' }} onClick={() => onDownloadGuiaInicio(1)}><strong> Guía de inicio Persona</strong></span> que te puede ayudar con el proceso de creación de cuenta y contratación de productos.</p>
                            </Panel>
                            <Panel
                                header={
                                    <div>
                                        <span style={{ display: 'flex', textAlign: 'left' }}><strong>¿Cómo es la firma de contratos?</strong></span>
                                    </div>
                                }
                                key="24"
                            >
                                <p>En Portal Marketplace al momento de contratar productos debes leer y aceptar los Términos y Condiciones asociados al servicio que se desea comprar para poder continuar con la contratación y habilitación del producto.</p>
                                <p>En la sección Productos de este sitio, si te diriges al detalle de cada producto podrás ver de manera previa el contrato (Términos y Condiciones) asociado a cada servicio.</p>
                                <p> Te sugerimos que si eres una Empresa descargues el documento con anterioridad y lo valides con las áreas legales correspondientes antes de continuar con la contratación.</p>
                            </Panel>
                            <Panel
                                header={
                                    <div>
                                        <span style={{ display: 'flex', textAlign: 'left' }}><strong>¿Qué medios de pago se aceptan?</strong></span>
                                    </div>
                                }
                                key="25"
                            >
                                <p>Los medios de pago permitidos para la contratación de productos son tarjetas de crédito, tarjetas de débito y transferencias electrónicas.</p>
                                <p>Los pagos deben ser realizados de manera manual mensual o anualmente (según corresponda) para poder mantener activa las suscripciones. No realizaremos cobros automáticos a tus tarjetas o cuenta bancaria.</p>
                            </Panel>
                            <Panel
                                header={
                                    <div>
                                        <span style={{ display: 'flex', textAlign: 'left' }}><strong>¿Puedo cancelar mis suscripciones en cualquier momento?</strong></span>
                                    </div>
                                }
                                key="26"
                            >
                                <p>Para aquellos productos de renovación automática, puedes cancelar cuando quieras tus suscripciones en Marketplace, podrás seguir utilizando el producto hasta que se cumpla el último periodo de pago o de prueba.</p>
                                <p> Para el resto de productos que no son de renovación automática no es necesario cancelar la suscripción, ya que automáticamente se da de baja cuando se cumple el último periodo de pago o de prueba.</p>
                            </Panel>
                        </Collapse>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default PreguntasFrecuentesPersona