import React from 'react'
import { Typography, Row, Col, Card } from 'antd'
import { UserOutlined, CreditCardOutlined, ShoppingOutlined, AppstoreOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import { PerfilUsuarioId } from '../../../enum/enums'
import { useSelector } from 'react-redux';
const { Text, Title } = Typography

const AccesosConsumidorEmpresa = () => {
    const navigate = useNavigate()
    const usuario = useSelector(state => state.aplicacion.usuario)

    return (
        <>
            <Row justify="center" gutter={{ xs: 8, sm: 10, md: 20, lg: 20 }} style={{ marginTop: 30 }}>
                <Col xs={8} sm={7} md={4} lg={3} xl={4} xxl={3}>
                    <Card
                        className="AccesoRapidoHome"
                        onClick={() => navigate('/perfil')}
                    >
                        <UserOutlined className="avatarAccesoRapido" />
                        <p > Mi Perfil </p>
                    </Card>
                </Col>
                <Col xs={8} sm={7} md={4} lg={3} xl={4} xxl={3}>
                    <Card
                        className="AccesoRapidoHome"
                        onClick={() => navigate('/solicitudes')}
                    >
                        <UserOutlined className="avatarAccesoRapido" />
                        <p > Mis solicitudes </p>
                    </Card>
                </Col>
                <Col xs={8} sm={7} md={4} lg={3} xl={4} xxl={3}>
                    <Card
                        className="AccesoRapidoHome"
                        onClick={() => navigate('/productos')}
                    >
                        <ShoppingOutlined className="avatarAccesoRapido" />
                        <p > Mis productos </p>
                    </Card>
                </Col>
                <Col xs={8} sm={7} md={4} lg={3} xl={4} xxl={3}>
                    <Card
                        className="AccesoRapidoHome"
                        onClick={() => navigate('/producto-servicio')}
                    >
                        <AppstoreOutlined className="avatarAccesoRapido" />
                        <p > Catálogo de productos</p>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AccesosConsumidorEmpresa