import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSubtituloHeader } from '../redux/reducers/AppReducer';
const BreadcrumbContext = createContext();

// se manejan en redux para reemplazar en caso que corresponda
const items = [
  {
    path: '/home',
    title: 'Inicio',
    subtitle: ''
  },
  {
    path: '/crear-usuario',
    title: 'Inicio / Usuarios y permisos / Agregar usuario',
    subtitle: ''
  },
  {
    path: '/perfilEmpresa/datosFacturacion',
    title: 'Inicio / Perfil empresa / Editar Datos facturación',
    subtitle: 'Estos son los datos de la empresa'
  },
  {
    path: '/perfilEmpresa/informacion',
    title: 'Inicio / Perfil empresa / Editar Datos Empresa',
    subtitle: 'Estos son los datos de la empresa'
  },
  {
    path: '/perfilEmpresa',
    title: 'Inicio / Perfil empresa',
    subtitle: 'Estos son los datos de la empresa'
  },
  {
    path: '/perfil',
    title: 'Inicio / Mi Perfil',
    subtitle: 'Estos son tus datos personales'
  },
  {
    path: '/contactenos',
    title: 'Inicio / Centro de ayuda',
    subtitle: ''
  },
  {
    path: '/Usuarios',
    title: 'Inicio / Usuarios y permisos',
    subtitle: 'Estos son los usuarios asociados a la empresa y sus permisos'
  },
  {
    path: '/encuesta',
    title: 'Inicio / Encuesta de satisfacción',
    subtitle: ''
  },
  {
    path: '/editar-perfil',
    title: 'Inicio / Mi Perfil / Editar Perfil',
    subtitle: 'Estos son tus datos personales'
  },
  {
    path: '/solicitudes',
    title: 'Inicio / Solicitudes',
    subtitle: ''
  },
  {
    path: '/medios-de-pago',
    title: 'Inicio / Mis medios de pago',
    subtitle: 'Estos son tus medios de pago'
  },
  {
    path: '/detalleSolicitud/',
    title: 'Incio / Solicitudes / Detalle de solicitud',
    subtitle: ''
  },
  {
    path: '/detalle-usuario/',
    title: ' Inicio / Usuarios y permisos / Detalle de usuario',
    subtitle: ''
  },
  {
    path: '/editar-usuario/',
    title: 'Inicio / Usuarios y permisos / Detalle de usuario / Editar datos',
    subtitle: ''
  },
  {
    path: '/nuevo-metodo-pago',
    title: 'Inicio / Mis medios de pago / Agregar nuevo medio de pago',
    subtitle: 'Estos son tus medios de pago'
  },
  {
    path: '/detalle-producto-servicio/',
    title: 'Inicio / Catálogo de productos / ##',
    subtitle: 'Estos son los productos que tenemos disponibles',
  },
  {
    path: '/contratar-producto/',
    title: 'Inicio / Catálogo de productos / ##-ID- / Contratar ##',
    subtitle: ''
  },
  {
    path: '/periodo-prueba/',
    title: 'Inicio / Catálogo de productos / ##-ID- / Contratar ##',
    subtitle: ''
  },
  {
    path: '/productosEmpresa',
    title: 'Inicio / Productos de la empresa',
    subtitle: 'Estos son tus productos contratados',
  },
  {
    path: '/productos',
    title: 'Inicio / Mis Productos',
    subtitle: 'Estos son tus productos contratados',
  },
  {
    path: '/producto-servicio',
    title: 'Inicio / Catálogo de productos',
    subtitle: 'Estos son los productos que tenemos disponibles',
  },
  {
    path: '/historial-pagos',
    title: 'Inicio / Historial de pagos',
    subtitle: 'Este es tu historial de pagos'
  },
  {
    path: '/detalle-producto-servicio/',
    title: 'Inicio / Catálogo de productos / Detalle',
    subtitle: 'Estos son los productos que tenemos disponibles',
  },
  {
    path: '/detalle-producto/',
    title: 'Inicio / Mis Productos / ##',
    subtitle: ''
  },
  {
    path: '/detalle-empresa-producto/',
    title: 'Inicio / Productos de la empresa / ##',
    subtitle: ''
  }
]

export const BreadcrumbProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const productoBreadCumps = useSelector(state => state.producto.productoSeleccionadoBreadcumps)
  const dispatch = useDispatch()

  useEffect(() => {
    const updateBreadcrumbs = () => {
      const path = window.location.pathname;
      const item = items.filter(x => path.includes(x.path))[0]
      let title = item?.title ?? path
      title = title.replaceAll('##', productoBreadCumps?.nombre).replace('-ID-', `-${productoBreadCumps?.id}-`)
      const subtitle = item?.subtitle ?? ''

      const updatedBreadcrumbs = {
        label: title,
        url: window.location.pathname
      };

      setBreadcrumbs(updatedBreadcrumbs)
      dispatch(setSubtituloHeader(subtitle))
    };

    updateBreadcrumbs();

    const handlePopState = () => {
      updateBreadcrumbs();
    };

  }, [window.location.pathname, productoBreadCumps]);

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('mensaje de error');
  }
  return context;
};
