import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setIsLoading } from "../../../redux/reducers/AppReducer"
import { Button, Row, Col, Card, Avatar, Tag, Flex } from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import './usuario.style.scss'
import { getUsuariosEmpresa } from "../../../services/empresaApi"
import { useNavigate } from "react-router"
import { getInicial, CapitalizeFirstLetter, getInicialPorPalabra } from "../../../services/Helpers/stringHelper"
import { daFormatoRut } from "../../../services/Helpers/RutHelper"
import ModalEliminarUsuario from "../Modal/ModalEliminarUsuario"
import ModalEditarRol from "../Modal/ModalEditarRol"
import TablaUsuarios from "../Tablas/TablaUsuarios"
import { setUsuarios } from "../../../redux/reducers/AppReducer"

const Usuarios = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const usuarios = useSelector(state => state.aplicacion.usuariosFiltrados)
    const desplegarTabla = useSelector(state => state.aplicacion.desplegarUsuariosEnTabla)
    const [refreshData, setRefreshdata] = useState(true)
    const [showModalEliminar, setShowModalEliminar] = useState(false)
    const [showModalRol, setShowModalRol] = useState(false)
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null)
    const idUsuarioSession = useSelector(state => state.aplicacion.usuario.id)

    const onCloseModal = () => {
        setUsuarioSeleccionado(null)
        setShowModalEliminar(false)
        setShowModalRol(false)
    }

    const onSelectUsuarioEliminar = (usuario) => {
        setUsuarioSeleccionado(usuario)
        setShowModalEliminar(true)
    }

    useEffect(() => {
        const fetchData = async () => {
            if (refreshData) {
                try {
                    dispatch(setIsLoading(true))
                    const data = await getUsuariosEmpresa()
                    dispatch(setUsuarios(data))
                }
                catch (error) {
                    console.log(error)
                }
                finally {
                    dispatch(setIsLoading(false))
                    setRefreshdata(false)
                }
            }
        }
        fetchData()
    }, [refreshData])

    const onEditarRol = (usuario) => {
        setUsuarioSeleccionado(usuario)
        setShowModalRol(true)
    }

    return (
        <>
            <ModalEliminarUsuario usuarioSeleccionado={usuarioSeleccionado} isModalOpen={showModalEliminar} handleOk={() => setRefreshdata(true)} handleCancel={onCloseModal}></ModalEliminarUsuario>
            <ModalEditarRol usuario={usuarioSeleccionado} isModalOpen={showModalRol} handleOk={() => setRefreshdata(true)} handleCancel={onCloseModal}></ModalEditarRol>
            <Row gutter={0}>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} style={{ textAlign: 'right', marginBottom: 20 }}>
                    <Button onClick={() => navigate('/crear-usuario')} danger type="primary" shape="round">Agregar usuario</Button>
                </Col>
            </Row>

            {desplegarTabla ?
                <TablaUsuarios onEditarRol={onEditarRol} onEliminarUsuario={onSelectUsuarioEliminar} usuarios={usuarios}></TablaUsuarios> :
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {usuarios?.map((item, index) => (
                        <Col key={index} className="gutter-row" xs={24} sm={24} md={24} lg={8} xxl={8} style={{ marginBottom: '25px' }}>
                            <Card title="" className="cardUsuarios">
                                <Row gutter={4} style={{ textAlign: 'left' }}>
                                    <Col className="gutter-row" xs={4} sm={4} md={2} lg={5} xxl={3}>
                                        <Avatar
                                            style={{
                                                backgroundColor: '#ff4201',
                                                verticalAlign: 'middle',
                                            }}
                                            size="large"
                                            shape='circle'
                                        >
                                            {getInicialPorPalabra(item.nombreUsuario, 2)}
                                        </Avatar>
                                    </Col>


                                    <Col style={{ textAlign: 'left' }} className="titulo" xs={17} sm={10} md={10} lg={18} xl={12} xxl={12}>
                                        <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <label>{CapitalizeFirstLetter(item.nombreUsuario)}</label>
                                            </Col>
                                        </Row>
                                        <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                <label><strong>Rut:</strong> {daFormatoRut(item.rutUsuario)}</label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: 'left' }}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col span={24}><label><strong>Rol asignado:</strong></label> </Col>
                                            <Col span={24}><label>{item.rolAsignado}</label> </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: 'left' }}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col span={24}><label><strong>Productos:</strong></label> </Col>
                                            <Col span={24}><label>{item.cantidadProductos} {item.cantidadProductos > 1 || item.cantidadProductos === 0 ? 'productos' : 'producto'}</label> </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 15 }}>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        {item.estado === 'Activado' ?
                                            <Tag style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 5, paddingTop: 5 }} color="#ff4201">Activo</Tag> :
                                            <Tag style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 5, paddingTop: 5 }} color="default">Inactivo</Tag>
                                        }
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 15 }}>
                                    <Col xs={24} sm={24} md={24} lg={24} xll={24} xxl={19} style={{ textAlign: 'left' }}>
                                        <Flex justify='space-between'>
                                            <Button disabled={item.estado !== 'Activado'} onClick={() => navigate(`/editar-usuario/${item.idUsuario}`)} className="btnEditar" type="text"><FileSearchOutlined style={{ color: '#0F00D8', fontSize: 25 }} />Editar datos</Button>
                                            <Button disabled={item.idUsuario === idUsuarioSession || item.estado !== 'Activado'} onClick={() => onEditarRol(item)} className="btnEditar" type="text"><FileSearchOutlined style={{ color: '#0F00D8', fontSize: 25 }} />Editar rol</Button>
                                        </Flex>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 15 }}>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Button onClick={() => navigate(`/detalle-usuario/${item.idUsuario}`)} style={{ width: '100%' }} type="primary" shape="round" htmlType="button">
                                            Datos y productos asociados
                                        </Button>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 10 }}>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Button className="btncancelar" onClick={() => onSelectUsuarioEliminar(item)} disabled={item.estado !== 'Activado' || item.idUsuario === idUsuarioSession} style={{ width: '100%' }} type="primary" shape='round' danger >
                                            Eliminar usuario
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    ))}
                </Row>
            }
        </>
    )
}

export default Usuarios