import { useEffect, useState } from "react";
import { CheckOutlined, TagOutlined, QuestionCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Spin, Divider, Input, Row, Select, Avatar, Typography, Form, Tooltip, Radio } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import DescripcionOtroTipoProducto from "../productoServicio/planes/DescripcionOtroTipo";
import { CapitalizeFirstLetter } from "../../services/Helpers/stringHelper";
import Link from 'antd/es/typography/Link'
import { setProductoContratar } from "../../redux/reducers/ProductoReducer";
import { setIsLoading, setRefrescarNotificaciones } from '../../redux/reducers/AppReducer';
import { getEjecutivos } from "../../services/publicApi";
import { SeparadorMiles } from "../../services/Helpers/stringHelper";
import { getDetalleProductoContratar, getValorUF } from "../../services/api";
import { validarCuponDescuento, generarSuscripcion } from "../../services/suscripcionApi";
import { useNavigate, useParams } from 'react-router-dom'
import { getDatosFacturacion } from "../../services/empresaApi";
import parse from 'html-react-parser';
import { getDocumento } from "../../services/documentoApi";
import { saveAs } from 'file-saver'
import PlanProducto from "../productoServicio/planes/PlanProducto";
import SeleccionMetodoPago from "../metodos/SeleccionMetodoPago";
import ModalAvisos from "../modal/ModalAvisos";
import { setNombreProductoSeleccionado } from "../../redux/reducers/ProductoReducer";
import ModalAvisoPago from "../modal/ModalAvisoPago";
import ModalValidandoKhipu from "../modal/ModalValidandoKhipu";
import { PerfilUsuarioId, TipoProductoId, TipoPagoKhipuId } from "../../enum/enums";
import { redirectPostTransbank } from "../Transbank/FormRedirect";
import DescripcionPlanApi from "../productoServicio/DescripcionPlanApi";
import DescripcionPlanDashboard from "../productoServicio/planes/DescripcionPlanDashboard";
import DescripcionPlanInnovationLabs from "../productoServicio/DescripcionPlanInnovationLabs";
import './Contratar.scss'
const { Title } = Typography;

const ContratarProducto = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const producto = useSelector(state => state.producto.productoContratar)
  const usuario = useSelector(state => state.aplicacion.usuario)
  const [ejecutivos, setEjecutivos] = useState(null)
  const [valorUf, setValorUf] = useState(null)
  const [nombrePlan, setNombrePlan] = useState(null)
  const [cupon, setCupon] = useState(null)
  const [precio, setPrecio] = useState(0)
  const { idProducto, estado, idTransaccion } = useParams()
  const [form] = Form.useForm()
  const [isModalOpenAvisoPago, setIsModalOpenAvisoPago] = useState(false)
  const [openModalKhipu, setOpenModalKhipu] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [datosFacturacion, setDatosFacturacion] = useState(null)
  const watchTipoDocumento = Form.useWatch("tipoDocumento", form)

  const [dataModal, setDataModal] = useState({
    mensaje: "",
    titulo: "",
    fueCorrecto: false,
  })

  const onchangeTipoDocumento = async (value) => {
    if (value === 'F' && datosFacturacion === null) {
      try {
        dispatch(setIsLoading(true))
        const data = await getDatosFacturacion()
        setDatosFacturacion(data)
      }
      catch (error) {
        console.log(error)
      }
      finally {
        dispatch(setIsLoading(false))
      }
    }
  }

  const desplegarModalResultadoPago = () => {
    if (estado) {
      if (estado !== 'PROCESANDO' && estado !== 'CANCELADO') {
        setIsModalOpenAvisoPago(true)
      }
      else {
        setOpenModalKhipu(true)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (usuario) {
        if (!usuario.habilitadoComprar)
          navigate('/home')

        try {
          const data = await getEjecutivos()
          const uf = await getValorUF()

          setEjecutivos(data)
          setValorUf(uf)
        }
        catch (error) {
          console.log(error)
        }
      }
    }
    fetchData()
  }, [idProducto, usuario])

  //entra si viene de sitio publico o refrezca la pagina
  useEffect(() => {
    const fetchData = async () => {
      if (!producto) {
        try {
          dispatch(setIsLoading(true))
          const data = await getDetalleProductoContratar(idProducto)

          if (data) {
            dispatch(setProductoContratar(data))
            dispatch(setNombreProductoSeleccionado({ nombre: data?.nombre, id: idProducto }))
          }
          else {
            navigate('/producto-servicio')
          }
        }
        catch (error) {
          console.log(error)
        }
        finally {
          dispatch(setIsLoading(false))
          desplegarModalResultadoPago()
        }
      } else {
        const precio = producto?.planesProducto ? producto.planesProducto.filter(x => x.idPlanProducto === parseInt(idProducto))[0].precio : producto.precio
        setNombrePlan(producto?.planesProducto?.filter(x => x.idPlanProducto === parseInt(idProducto))[0])
        setPrecio(precio)
      }
    }
    fetchData()
  }, [producto])

  const onValidarCupon = async () => {
    const cuponAplicar = form.getFieldValue('cuponDescuento')

    if (cuponAplicar) {
      try {
        dispatch(setIsLoading(true))
        const data = await validarCuponDescuento({ codigo: cuponAplicar, montoAplicarDescuento: Math.round(precio * valorUf) })

        if (data?.esCuponValido) {
          setCupon(data)
        }
        else {
          setCupon(null)
        }
      }
      catch (error) {
        console.log(error)
      }
      finally {
        dispatch(setIsLoading(false))
      }
    }
  }

  const onFinish = async (values) => {
    try {
      dispatch(setIsLoading(true))
      values.idProducto = parseInt(idProducto)
      values.producto = producto?.nombre
      values.cuponDescuento = cupon?.codigo

      const data = await generarSuscripcion(values)

      if (data && data.accionFueCorrecta && data.data?.simplifiedTransferUrl) {
        window.location.replace(data.data.simplifiedTransferUrl)
      }
      else if (data && data.accionFueCorrecta && data.data?.token) {
        redirectPostTransbank(data.data.token, data.data.url)
      }
      else {
        //se informa resultado en modal
        if (usuario?.perfilId === PerfilUsuarioId.CONSUMIDOR_EMPRESA) {
          setDataModal({
            fueCorrecto: data.accionFueCorrecta,
            mensaje: data.accionFueCorrecta ? "Se ha generado una solicitud para contratar el producto." : "Ha ocurrido un error, inténtelo nuevamente.",
            titulo: data.accionFueCorrecta ? 'Éxito' : 'Error'
          })

          dispatch(setRefrescarNotificaciones(true))
        }
        else {
          setDataModal({
            fueCorrecto: data.accionFueCorrecta,
            titulo: data.accionFueCorrecta ? "Te enviamos una notificación por correo con el detalle de tu suscripción e instrucciones." : "Ocurrió un problema con el pago",
            mensaje: data.accionFueCorrecta ? '' : ''
          })
        }
        setIsModalOpen(true)
        dispatch(setRefrescarNotificaciones(true))
      }
    }
    catch (error) {
      //se informa resultado en modal
      setDataModal({
        fueCorrecto: false,
        titulo: 'Ocurrió un problema con el pago',
        mensaje: 'Por favor, vuelve a intentar pagar tu suscripción, si aún así sigues teniendo problemas contáctate con soporte al correo sacbursatil@bolsadesantiago.com'
      })
      setIsModalOpen(true)
      console.log(error)
    }
    finally {
      dispatch(setIsLoading(false))
    }
  }

  const onCloseModal = () => {
    setIsModalOpen(false)
    if (dataModal.fueCorrecto && usuario?.perfilId !== PerfilUsuarioId.CONSUMIDOR_EMPRESA) {
      navigate("/productos")
    }
    else {
      navigate("/solicitudes")
    }
  }

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onSeleccionarMedioPago = (idMedioPago, idTarjeta = null) => {
    form.setFieldValue('idMedioPago', parseInt(idMedioPago))
    form.setFieldValue('idTarjeta', idTarjeta ? parseInt(idTarjeta) : null)
  }

  const onCloseModalAvisoPago = () => {
    if (estado === 'OK' && (usuario.perfilId === PerfilUsuarioId.PERSONA_NATURAL || usuario.perfilId === PerfilUsuarioId.CONSUMIDOR_EMPRESA)) {
      navigate('/productos')
    }
    if (estado === 'OK' && usuario.perfilId === PerfilUsuarioId.ADMIN_EMPRESA) {
      navigate('/productosEmpresa')
    }
    else if (estado === 'NOK') {
      setIsModalOpenAvisoPago(false)
    }

  }

  const onCloseModalKhipu = () => {
    setOpenModalKhipu(false)
  }

  const onDownloadDocumentacion = async (idtipoDocumento) => {
    dispatch(setIsLoading(true))
    try {
      const docRes = await getDocumento(idProducto, idtipoDocumento)
      if (docRes) {
        const blobFile = new Blob(
          [docRes.buffer],
          { type: docRes.contentType }
        )
        saveAs(blobFile, docRes.fileName)
      } else {
        console.log("Respuesta nula")
      }
    } catch (error) {
      console.error("Error al descargar documento")
      console.error(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  return (
    <div>
      <ModalValidandoKhipu
        isModalOpen={openModalKhipu}
        onClose={() => onCloseModalKhipu()}
        idTransaccion={idTransaccion}
        tipoAccionId={TipoPagoKhipuId.CONTRATACION}
        status={estado}
      ></ModalValidandoKhipu>
      <ModalAvisoPago
        isModalOpen={isModalOpenAvisoPago}
        onClose={() => onCloseModalAvisoPago()}
        fueCorrecto={estado === 'OK'}
      ></ModalAvisoPago>
      <ModalAvisos
        isModalOpen={isModalOpen}
        onClose={() => onCloseModal()}
        mensaje={dataModal?.mensaje}
        titulo={dataModal?.titulo}
        fueCorrecto={dataModal?.fueCorrecto}></ModalAvisos>

      <p style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 24, marginTop: 10, marginBottom: 10 }}>Producto a contratar</p>
      <br />
      <Card style={{ background: '#FFF', boxShadow: '0px 0px 50px -20px rgba(0, 0, 0, 0.25)' }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 }}>
          <Form form={form} onFinish={onFinish} className="form" layout='inline' style={{ width: '100%' }}>
            <Col xs={0} sm={1} md={1} lg={1} xl={2} xxl={1}>
              <Avatar className="colorLetra colorFondo" style={{ marginTop: 18 }} size={48} icon={<TagOutlined />} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={11} xl={11} xxl={11} style={{ paddingLeft: 5 }}>
              <p style={{ textAlign: 'justify', fontWeight: 'bold', marginBottom: 0, fontSize: 16 }}>{producto?.nombre}</p>
              <p className="descripcionDetalle descripcionProducto" style={{ textAlign: 'justify', marginBottom: 4, marginTop: 8, maxWidth: '80%' }}>
                {parse(producto?.descripcion ?? '')}
              </p>
              {producto?.idTipoProducto === TipoProductoId.PACK_APIS && (
                <p className="descripcionDetalle descripcionProducto" style={{ textAlign: 'justify', marginBottom: 25, marginTop: 0, fontWeight: '400', maxWidth: '80%' }}>
                  La licencia otorgada al contratar esta API es solo para uso interno del cliente, si deseas distribuir la información entregada por API Brain Data a terceros contáctate con nosotros para revisar las tarifas y condiciones asociadas.
                </p>)}
              <Row>
                <Col xs={24} sm={24} md={24} lg={20} xl={24} xxl={21}>
                  <PlanProducto idTipoProducto={producto?.idTipoProducto} planProducto={{ nombreTipoPlan: nombrePlan?.nombrePlan, idTipoPlan: nombrePlan?.idTipoPlan, cantidadDashboard: nombrePlan?.cantidadDashboard, subtitulo: nombrePlan?.subtitulo }} ajustarAlto={true} maxHeigthTitulo={35}></PlanProducto>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={21} xxl={16} style={{ textAlign: 'left' }}>
                  <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)', marginTop: 5 }}> UF {precio} /mes </Title>
                </Col>
              </Row>
              <div>
                <p style={{ textDecoration: 'underline', textAlign: 'justify', fontWeight: 'bold' }}>Este plan incluye:</p>
                {producto?.planesProducto ? <>
                  {producto.planesProducto.filter(x => x.idPlanProducto === parseInt(idProducto)).map((item, index) => {
                    return <div key={index} style={{ maxWidth: '80%' }}>
                      {producto.idTipoProducto === TipoProductoId.PACK_APIS ?
                        <DescripcionPlanApi
                          requesAlMes={item?.cantidadRequest}
                          mercadoProducto={item?.mercados}
                          nombrePlan={item?.nombreTipoPlan}
                          agnoInfoHistorica={item?.infoHistorica}
                          tiempoDelay={item?.periodoTiempo}>
                        </DescripcionPlanApi> : producto.idTipoProducto === TipoProductoId.PACK_REPORTES ?
                          <DescripcionPlanDashboard texto={item.descripcionPlanDashboard}></DescripcionPlanDashboard> :
                          producto.idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                            <DescripcionPlanInnovationLabs descripcion={item.descripcionPlanDashboard} cantidadNucleos={item.cantidadNucleos} memoriaRam={item.memoriaRam} usuariosSugeridosInnovationLab={item.usuariosSugeridosInnovationLab}></DescripcionPlanInnovationLabs> :
                            <DescripcionOtroTipoProducto plan={item}></DescripcionOtroTipoProducto>}
                    </div>
                  })}
                </> : null}
              </div>

              {usuario?.perfilId === PerfilUsuarioId.ADMIN_EMPRESA && (
                <div style={{ maxWidth: '80%' }}>
                  <Row style={{ textAlign: 'left' }}>
                    <Col span={24}>
                      <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>Tipo de documento</p>
                      <Form.Item name='tipoDocumento' style={{ marginRight: '0' }} rules={[
                        {
                          required: true,
                          message: "Selecciona tipo de documento",
                        },
                      ]}>
                        <Radio.Group onChange={e => onchangeTipoDocumento(e.target.value)}>
                          <Radio style={{ fontWeight: 'normal' }} value={'B'}>Boleta</Radio>
                          <Radio style={{ fontWeight: 'normal' }} value={'F'}>Factura</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  {watchTipoDocumento === 'F' && datosFacturacion && (
                    <>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                          <Title level={4} style={{ textAlign: 'left', fontWeight: 'bold', marginTop: 10 }}>Datos de facturación:</Title>
                        </Col>
                      </Row>
                      <div className="formVerticialContrata">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                            <Form.Item label="Nombres" className="ant-form-vertical">
                              <Input placeholder={CapitalizeFirstLetter(datosFacturacion.nombre)} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} xl={12} xxl={12}>
                            <Form.Item label='Apellidos' className="ant-form-vertical">
                              <Input readOnly={true} placeholder={CapitalizeFirstLetter(datosFacturacion.apellidos)} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
                            <Form.Item label='Teléfono fijo o Celular' className="ant-form-vertical">
                              <Input readOnly={true} placeholder={datosFacturacion.telefono} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
                            <Form.Item label='País' className="ant-form-vertical">
                              <Input readOnly={true} placeholder={datosFacturacion.pais} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
                            <Form.Item label='Región' className="ant-form-vertical">
                              <Input readOnly={true} placeholder={datosFacturacion.region} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
                            <Form.Item label="Comuna" className="ant-form-vertical">
                              <Input readOnly={true} placeholder={datosFacturacion.comuna} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                            <Form.Item label='Dirección' className="ant-form-vertical">
                              <Input readOnly={true} placeholder={datosFacturacion.direccionFacturacion} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
                            <Form.Item label='Correo electrónico' className="ant-form-vertical">
                              <Input readOnly={true} placeholder={datosFacturacion.emailFacturacion} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
                            <Form.Item label='Cargo' className="ant-form-vertical">
                              <Input readOnly={true} placeholder={datosFacturacion.cargo} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} xl={12} xxl={12}>
                            <Form.Item label='Área del cargo' className="ant-form-vertical">
                              <Input readOnly={true} placeholder={datosFacturacion.areaCargo} bordered={false} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            {datosFacturacion.solicitaHes ?
                              <Form.Item label='Hoja de entrada servicios (HES/OC)' tooltip="Cuando corresponda, envía tu documento HES u OC al siguiente correo sacbursatil@bolsadesantiago.com" className="ant-form-vertical">
                                <Input readOnly={true} placeholder={datosFacturacion.solicitaHes ? 'SI' : 'NO'} bordered={false} />
                              </Form.Item> :
                              <Form.Item label='Hoja de entrada servicios (HES/OC)' className="ant-form-vertical">
                                <Input readOnly={true} placeholder={datosFacturacion.solicitaHes ? 'SI' : 'NO'} bordered={false} />
                              </Form.Item>
                            }
                          </Col>
                        </Row>
                      </div>
                    </>)}
                </div>)}
            </Col>

            <Col xs={24} sm={24} md={12} lg={11} xl={11} xxl={11}>
              {usuario?.perfilId !== PerfilUsuarioId.CONSUMIDOR_EMPRESA ? <>
                <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>Seleccionar medio de pago</p>
                <SeleccionMetodoPago onSelectMedioPago={onSeleccionarMedioPago}></SeleccionMetodoPago>
                <Form.Item className="hiddenInput" name='idMedioPago' rules={[
                  {
                    required: true,
                    message: "Debes seleccionar un medio de pago.",
                  },
                ]}>
                  <Input />
                </Form.Item>
                {/* <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Button onClick={() => navigate('/nuevo-metodo-pago')} type="primary" shape="round">Agregar otro medio de pago</Button>
                </div> */}
                {usuario?.perfilId !== PerfilUsuarioId.CONSUMIDOR_EMPRESA ? <>
                  <div style={{ textAlign: 'left' }} className='terminos'>
                    <Form.Item style={{ marginInlineEnd: 0 }} name='ejecutivoId' tooltip="Campo opcional" label='Seleccionar ejecutivo' className='ant-form-vertical rounded-input labeltitulo' >
                      <Select
                        showSearch={false}
                        style={{ width: '100%' }}
                        filterOption={filterOption}
                        placeholder="Seleccionar ejecutivo"
                        loading={ejecutivos === null}
                        options={ejecutivos}
                      />
                    </Form.Item>
                  </div> </> : null}
                <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>Código de descuento</p>


                <Form.Item name='idTarjeta' style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name='cuponDescuento' className='rounded-input' style={{ marginRight: '0' }}>
                  <Input suffix={cupon ? <CheckCircleOutlined style={{ color: '#00FF91' }} /> : null} placeholder="Ingresa un código de descuento si tienes" autoComplete="off" />
                </Form.Item>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 10 }}>
                  <Button onClick={() => onValidarCupon()} type="primary" shape="round">Aplicar código</Button>
                </div>
                <div className='terminos'>
                  <Form.Item normalize={(value) => value ? value : undefined} name='terminos' valuePropName="checked" rules={[
                    {
                      required: true,
                      message: "Debes aceptar los Términos y Condiciones del producto.",
                    },
                  ]}>
                    <Checkbox style={{ fontWeight: '400', marginBottom: 20 }}>He leído y acepto los <Link href="#" style={{ fontFamily: "roboto" }} className='letraNaranja' onClick={() => onDownloadDocumentacion(1)}> Términos y Condiciones</Link> de este producto</Checkbox>
                  </Form.Item>
                </div>


                <Divider className='dividerTotal' />
              </> : null}
              <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '75%' }}>
                <p style={{ textAlign: 'justify', fontWeight: 'bold', fontSize: 16, marginTop: 0 }}>Detalle de tu compra</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ textAlign: 'left', margin: 0, fontWeight: '400' }}>{producto?.nombre}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ textAlign: 'left', margin: 0, fontWeight: '400' }}>Suscripción "{nombrePlan?.nombrePlan ?? 'Advance'}"</p>
                  <p style={{ textAlign: 'right', margin: 0, fontWeight: '400' }}>{SeparadorMiles(precio)} UF</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Tooltip placement="top" title="Valor obtenido de la Comisión para el Mercado Financiero (CMF)."><p style={{ textAlign: 'left', margin: 0, fontWeight: '400' }}>Valor actual UF <QuestionCircleOutlined style={{ fontSize: 12 }} /></p></Tooltip>
                  <p style={{ textAlign: 'right', margin: 0, fontWeight: '400' }}>{valorUf ? `$ ${SeparadorMiles(valorUf)}` : <Spin spinning={true} size='middle' />}  </p>
                </div>
                {cupon ?
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="letraColorCliente" style={{ textAlign: 'left', fontWeight: 'bold', margin: 0 }}>Descuento ({SeparadorMiles(cupon?.porcentajeDescuento)}%)</p>
                    <p style={{ textAlign: 'right', margin: 0, fontWeight: '400' }}>-${cupon?.montoDescontado ? SeparadorMiles(cupon?.montoDescontado) : '0'}</p>
                  </div> : null}


              </div>
              <Divider className='dividerTotal' />
              <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '75%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ textAlign: 'left', margin: 0, fontWeight: 'bold', fontSize: 16 }}>Total a pagar</p>
                  <p style={{ textAlign: 'right', margin: 0, fontWeight: 'bold' }}>${cupon?.precioConDescuento ? SeparadorMiles(cupon?.precioConDescuento) : SeparadorMiles(Math.round(precio * valorUf))}</p>
                </div>
              </div>
              {usuario?.perfilId === PerfilUsuarioId.PERSONA_NATURAL ?
                <div style={{ textAlign: 'left' }}>
                  <p style={{ marginBottom: 20, fontSize: 16 }}><strong>Documento a emitir:</strong> Boleta</p>
                </div> : null
              }
              {producto?.idTipoProducto !== TipoProductoId.INNOVATION_LAB ?
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 30 }}>
                  <Button htmlType="submit" type="primary" shape="round">{usuario?.perfilId === PerfilUsuarioId.CONSUMIDOR_EMPRESA ? 'Solicitar' : 'Ir a pagar'}</Button>
                </div> : null}
            </Col>
          </Form>
        </Row >
      </Card >
    </div >
  )
}

export default ContratarProducto