import { useState, useEffect } from "react";
import DescripcionOtroTipoProducto from "../../../productoServicio/planes/DescripcionOtroTipo";
import { Flex, Button, Col, Input, Divider, Typography, Form, Tooltip, Spin, Checkbox } from "antd";
import { SeparadorMiles } from "../../../../services/Helpers/stringHelper";
import { saveAs } from 'file-saver'
import { QuestionCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { getDocumento } from "../../../../services/documentoApi";
import PlanProducto from '../../../productoServicio/planes/PlanProducto'
import Link from 'antd/es/typography/Link'
import { validarCuponDescuento } from "../../../../services/suscripcionApi";
import { setIsLoading, setRefrescarNotificaciones } from "../../../../redux/reducers/AppReducer";
import { useDispatch } from "react-redux";
import { updateEstadoSolicitud } from "../../../../services/empresaApi";
import ModalAvisos from "../../../modal/ModalAvisos";
import { useNavigate } from "react-router";
import { TipoSolicitudId, EstadoSolicitudId, TipoProductoId } from "../../../../enum/enums";
import DescripcionPlanApi from "../../../productoServicio/DescripcionPlanApi";
import DescripcionPlanDashboard from "../../../productoServicio/planes/DescripcionPlanDashboard";
import DescripcionPlanInnovationLabs from "../../../productoServicio/DescripcionPlanInnovationLabs";
const dayjs = require('dayjs')
const { Title } = Typography;

const ContratacionProducto = ({ valorUf, solicitud }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [cupon, setCupon] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dataModal, setDataModal] = useState(null)
    const [precio, setPrecio] = useState(0)

    useEffect(() => {
        if (solicitud) {
            setPrecio(solicitud.productoContratado.valor)
        }

    }, [solicitud])

    const onValidarCupon = async () => {
        const cuponAplicar = form.getFieldValue('cuponDescuento')

        if (cuponAplicar) {
            try {
                dispatch(setIsLoading(true))
                const data = await validarCuponDescuento({ codigo: cuponAplicar, montoAplicarDescuento: Math.round(precio * valorUf) })

                if (data?.esCuponValido) {
                    setCupon(data)
                }
                else {
                    setCupon(null)
                }
            }
            catch (error) {
                console.log(error)
            }
            finally {
                dispatch(setIsLoading(false))
            }
        }
    }

    const onDownloadDocumentacion = async (idtipoDocumento) => {
        dispatch(setIsLoading(true))
        try {
            const docRes = await getDocumento(solicitud.productoContratado.productoId, idtipoDocumento)
            if (docRes) {
                const blobFile = new Blob(
                    [docRes.buffer],
                    { type: docRes.contentType }
                )
                saveAs(blobFile, docRes.fileName)
            } else {
                console.log("Respuesta nula")
            }
        } catch (error) {
            console.error("Error al descargar documento")
            console.error(error)
        } finally {
            dispatch(setIsLoading(false))
        }
    }

    const onFinish = async (values) => {
        try {
            const mensaje = values.apruebaRechaza ? 'Solicitud aprobada correctamente, será redirigido para finalizar la contratación del producto.' : 'Solicitud Rechazada correctamente.'
            dispatch(setIsLoading(true))
            const data = await updateEstadoSolicitud({
                idSolicitud: solicitud.idSolicitud,
                apruebaSolicitud: values.apruebaRechaza
            })

            dispatch(setRefrescarNotificaciones(true))
            setDataModal({
                fueCorrecto: data.accionFueCorrecta,
                titulo: data.accionFueCorrecta ? 'Éxito' : 'Error',
                mensaje: data.accionFueCorrecta ? mensaje : 'ha ocurrido un error inténtelo nuevamente.',
                apuebaSolicitud: values.apruebaRechaza
            })
        }
        catch (error) {
            console.log(error)
            setDataModal({
                fueCorrecto: false,
                titulo: "Error al administrar solicitud.",
                mensaje: 'ha ocurrido un error inténtelo nuevamente.',
                apuebaSolicitud: values.apruebaRechaza
            })
        }
        finally {
            dispatch(setIsLoading(false))
            setIsModalOpen(true)
        }
    }

    const onCloseModal = () => {
        if (solicitud.idTipoSolicitud === TipoSolicitudId.CONTRATACION_PRODUCTO_PRUEBA && dataModal.apuebaSolicitud && dataModal.fueCorrecto) {
            navigate(`/periodo-prueba/${solicitud.productoContratado.productoId}`)
        }
        else if (solicitud.idTipoSolicitud === TipoSolicitudId.CONTRATACION_PRODUCTO_PAGO && dataModal.apuebaSolicitud && dataModal.fueCorrecto) {
            navigate(`/contratar-producto/${solicitud.productoContratado.productoId}`)
        }
        else if (dataModal.fueCorrecto) {
            navigate('/solicitudes')
        }
        else {
            setIsModalOpen(false)
        }
    }

    const onAdminSolicitud = (apruebaSolicitud) => {
        form.setFieldValue('apruebaRechaza', apruebaSolicitud)
        form.submit()
    }

    return (
        <>
            <ModalAvisos
                isModalOpen={isModalOpen}
                onClose={() => onCloseModal()}
                mensaje={dataModal?.mensaje}
                titulo={dataModal?.titulo}
                fueCorrecto={dataModal?.fueCorrecto}></ModalAvisos>
            <Form form={form} onFinish={onFinish} className="form" layout='inline' style={{ width: '100%' }}>
                <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: 'left' }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>Solicitud de contratación</p>
                    <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>{solicitud?.productoContratado?.nombre}</p>
                    <p style={{ textAlign: 'justify', color: '#838383', fontWeight: '400' }}>{solicitud?.productoContratado?.descripcion}</p>
                    {solicitud.idEstadoSolicitud !== EstadoSolicitudId.ESPERA_APROBACION ? <>
                        <p style={{ textAlign: 'justify', fontWeight: 'bold', marginBottom: 0, paddingBottom: 0 }}>Fecha de {solicitud.idEstadoSolicitud === EstadoSolicitudId.APROBADA ? 'aprobación' : 'rechazo'}: <span></span></p>
                        <p style={{ textAlign: 'justify', color: '#838383', fontWeight: '400', marginTop: 0, paddingTop: 0 }}>{dayjs(solicitud.fechaActualizacion).format('DD/MM/YYYY')}</p>
                    </> : null}
                    <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>Tipo de suscripción</p>
                    <PlanProducto ajustarAlto={true} idTipoProducto={solicitud?.idTipoProducto} planProducto={solicitud?.productoContratado?.tipoPlan} justificarInicio={true}></PlanProducto>
                    {" "}
                    <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)' }}>UF {SeparadorMiles(solicitud?.productoContratado?.valor)} /Mes</Title>
                    {solicitud?.idTipoProducto === TipoProductoId.PACK_APIS ?
                        <DescripcionPlanApi
                            requesAlMes={solicitud?.productoContratado?.tipoPlan?.requesAlMes}
                            mercadoProducto={solicitud?.mercado}
                            nombrePlan={solicitud?.productoContratado?.tipoPlan?.nombreTipoPlan}
                            agnoInfoHistorica={solicitud?.productoContratado?.tipoPlan?.agnoInfoHistorica}
                            tiempoDelay={solicitud?.productoContratado?.tipoPlan?.tiempoDelay}
                        >
                        </DescripcionPlanApi> : solicitud?.idTipoProducto === TipoProductoId.PACK_REPORTES ?
                            <DescripcionPlanDashboard texto={solicitud?.productoContratado?.tipoPlan.descripcionDashboard}></DescripcionPlanDashboard> :
                            solicitud?.idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                <DescripcionPlanInnovationLabs descripcion={solicitud?.productoContratado?.descripcionPlanDashboard} usuariosSugeridosInnovationLab={solicitud?.productoContratado?.usuariosSugeridosInnovationLab} cantidadNucleos={solicitud?.productoContratado?.tipoPlan.cantidadNucleos} memoriaRam={solicitud?.productoContratado?.tipoPlan.memoriaRam}></DescripcionPlanInnovationLabs> :
                                <DescripcionOtroTipoProducto plan={solicitud?.productoContratado?.tipoPlan}></DescripcionOtroTipoProducto>}
                </Col>
                {/* espera aprobaacion */}
                {solicitud?.idEstadoSolicitud === 1 ? <>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div>
                            <p style={{ textAlign: 'justify', fontWeight: 'bold' }}> Código de descuento</p>
                            <Form.Item name='cuponDescuento' style={{ marginRight: '0' }}>
                                <Input suffix={cupon ? <CheckCircleOutlined style={{ color: '#00FF91' }} /> : null} placeholder="Ingresa un código de descuento si tienes" autoComplete="off" />
                            </Form.Item>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 10 }}>
                                <Button onClick={() => onValidarCupon()} type="primary" shape="round">Aplicar código</Button>
                            </div>
                        </div>
                        <div className='terminos'>
                            <Form.Item style={{ display: 'none' }} name='apruebaRechaza'>
                                <Input></Input>
                            </Form.Item>

                            <Form.Item normalize={(value) => value ? value : undefined} name='terminos' valuePropName="checked" rules={[
                                {
                                    required: true,
                                    message: "Debes aceptar los Términos y Condiciones del producto.",
                                },
                            ]}>
                                <Checkbox style={{ fontWeight: '400', marginBottom: 20 }}>He leído y acepto los <Link href="#" style={{ fontFamily: "roboto" }} className='letraNaranja' onClick={() => onDownloadDocumentacion(1)}> Términos y Condiciones</Link> de este producto</Checkbox>
                            </Form.Item>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '75%' }}>
                            <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>Detalle de tu solicitud</p>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={{ textAlign: 'left', margin: 0, fontWeight: '400' }}>{solicitud?.productoContratado.nombre}</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={{ textAlign: 'left', margin: 0, fontWeight: '400' }}>Suscripción "{solicitud?.productoContratado?.tipoPlan.nombreTipoPlan ?? 'Advance'}"</p>
                                <p style={{ textAlign: 'right', margin: 0, fontWeight: '400' }}>{SeparadorMiles(precio)} UF</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Tooltip placement="top" title="Valor obtenido de la Comisión para el Mercado Financiero (CMF)."><p style={{ textAlign: 'left', margin: 0, fontWeight: '400' }}>Valor actual UF <QuestionCircleOutlined style={{ fontSize: 12 }} /></p></Tooltip>
                                <p style={{ textAlign: 'right', margin: 0, fontWeight: '400' }}>{valorUf ? `$ ${SeparadorMiles(valorUf)}` : <Spin spinning={true} size='middle' />}  </p>
                            </div>
                            {cupon ?
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p className="letraColorCliente" style={{ textAlign: 'left', fontWeight: 'bold', margin: 0 }}>Descuento ({SeparadorMiles(cupon?.porcentajeDescuento)}%)</p>
                                    <p style={{ textAlign: 'right', margin: 0, fontWeight: '400' }}>-${cupon?.montoDescontado ? SeparadorMiles(cupon?.montoDescontado) : '0'}</p>
                                </div> : null}
                        </div>

                        <br></br>

                        {solicitud.idEstadoSolicitud === EstadoSolicitudId.ESPERA_APROBACION ?
                            <Flex justify='flex-end' >
                                <Button onClick={() => onAdminSolicitud(false)} danger style={{ marginRight: 15 }} shape="round">Rechazar solicitud</Button>
                                <Button onClick={() => onAdminSolicitud(true)} shape="round" type="primary">Aceptar solicitud</Button>
                            </Flex> : null}
                    </Col>
                </> : null}
            </Form>
        </>
    )
}

export default ContratacionProducto