import { Avatar, Col, Form, Layout, Row, Steps, Divider, Typography, Space, Button } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Title from 'antd/es/typography/Title'
import { UserOutlined, ExclamationCircleOutlined, LeftCircleFilled } from '@ant-design/icons'
import ModalAvisos from '../modal/ModalAvisos'
import { useEffect, useState } from 'react';
import { complementarCuentaEmpresa } from '../../services/empresaApi'
import { getPaises, getRubrosComerciales, getNacionalidades, getCargos, getAreaCargos } from '../../services/publicApi'
import { getRutSinDv, getDvRut } from '../../services/Helpers/RutHelper'
import { convertToBase64 } from '../../services/Helpers/stringHelper'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import Paso1 from './pasosFormCompletar/Paso1'
import Paso2 from './pasosFormCompletar/Paso2'
import Paso3 from './pasosFormCompletar/Paso3'
import './pasosFormCompletar/FormCompletarCuenta.scss'
import { Calendar, ChevronDown, ChevronLeft, ChevronUp, User } from 'react-iconly'
import ModalCompletarDatosEmpresa from '../modal/ModalCompletarDatosEmpresa'

const { Text } = Typography

const FormCompletarCuentaEmpresa = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const usuario = useSelector(state => state.aplicacion.usuario)
    const [pasoActual, setPasoActual] = useState(0)
    const [accionCorrecta, setAccionCorrecta] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [paises, setPaises] = useState(null)
    const [formIsSubmitting, setFormIsSubmitting] = useState(false)
    const [rubrosComerciales, setRubrosComerciales] = useState(null)
    const [nacionalidades, setNacionalidades] = useState(null)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            Promise.all([getPaises(),
            getNacionalidades(),
            getCargos(),
            getAreaCargos(),
            getRubrosComerciales()]).then((values) => {
                setPaises(values[0])
                setNacionalidades(values[1])
                setCargos(values[2])
                setAreaCargos(values[3])
                setRubrosComerciales(values[4])
            }).finally(() =>
                console.log()
            );
        }
        fetchData()
    }, [])

    const handleOk = () => {
        if (accionCorrecta)
            navigate('/home')
        else {
            setFormIsSubmitting(false)
            setIsModalOpen(false)
        }
    };

    const onFinish = async (values) => {
        
        if (!formIsSubmitting) {
            try {
                setFormIsSubmitting(true)
                const formData = new FormData()

                values.datosFacturacion.solicitaHes = values.datosFacturacion.solicitaHes === 0 ? false : true
                values.numeroIdentificacion = getRutSinDv(usuario.rutEmpresa)

                values.representantesLegales?.map((item) => {
                    item.rutDV = getDvRut(item.rut)
                    item.rut = getRutSinDv(item.rut)
                    item.fechaNacimiento = item.fechaNacimientoRep ? item.fechaNacimientoRep.toDate() : null
                    item.glosaPaisResidencia = paises.filter(x => x.value === item.paisResidenciaId)[0].label
                    item.glosaAreaCargo = areaCargos.filter(x => x.value === item.areaCargoId)[0]?.label
                })

                formData.append("file", values.documentoTributario[0].originFileObj)
                formData.append("numeroIdentificacion", values.numeroIdentificacion)
                formData.append("direccion", values.direccion)
                formData.append("rubroComercialId", values.rubroComercialId)
                formData.append("informacionExtraAdministrador", JSON.stringify(values.informacionExtraAdministrador))
                formData.append("representantesLegales", JSON.stringify(values.representantesLegales))
                formData.append("datosFacturacion", JSON.stringify(values.datosFacturacion))

                const result = await complementarCuentaEmpresa(formData)

                if (result?.accionFueCorrecta) {
                    setAccionCorrecta(true)
                }
                else {
                    setAccionCorrecta(false)
                }
            }
            catch (error) {
                setAccionCorrecta(false)
                console.log(error)
            }
            finally {
                setIsModalOpen(true)
                setFormIsSubmitting(false)
            }
        }
    }

    const items = [
        {
          title: <span className={pasoActual === 0 ? 'span-paso1' : 'span-paso1'}>Datos Empresa</span>,
          description: <span className={pasoActual === 0 ? 'span-paso-d' : 'span-paso-d'}>Administrador</span>,
          icon: <span className={pasoActual === 0 ? 'custom-icon' : 'custom-icon'}>01</span>,
        },
        {
          title: <span className={pasoActual >= 1 ? 'span-paso1' : 'span-paso2-volver'}>Datos representantes</span>,
          description: <span className={pasoActual >= 1 ? 'span-paso-d2' : 'span-paso-d2-volver'}>Representante legal</span>,
          icon: <span className={pasoActual >= 1 ? 'custom-icon' : 'custom-icon-inactivo'}>02</span>,
        },
        {
          title: <span className={pasoActual === 2 ? 'span-paso1' : 'span-paso2-volver'}>Datos finales</span>,
          description: <span className={pasoActual === 2 ? 'span-paso-d2' : 'span-paso-d2-volver'}>Facturación</span>,
          icon: <span className={pasoActual === 2 ? 'custom-icon' : 'custom-icon-inactivo'}>03</span>,
        },
    ];

    return (
        <>
            <Layout className="app-layout">
                <Content className="content">
                    <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                        <Col xs={24} sm={24} md={24} lg={0}>
                            <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                               {/*<Col offset={2} className="gutter-row" xs={2} sm={2} md={2} lg={2}>
                                    <LeftCircleFilled onClick={() => navigate(-1)} style={{ fontSize: 30, color: '#ff4201' }} />
                                </Col>
                                <Col className="gutter-row" xs={15} sm={8} md={6} lg={22}>
                                    <p style={{ marginTop: 7 }}><strong> Creación Portal de Personas</strong></p>
                                </Col>*/}
                                {/*<Col xs={24} sm={24} md={24} lg={0} style={{ minHeight: 72, padding: '22px 20px', backgroundColor: '#181818', textAlign: 'left' }}>
                                <Space>
                                    <Button onClick={() => navigate(-1)} className='btn-volver-form'><ChevronLeft stroke='bold' className='chevron-mobile' style={{ color: '#FFFBFA', width: 16 }}/></Button>
                                    <p onClick={() => navigate(-1)} style={{ color: '#FFFBFA', fontWeight: 'bold', fontSize: 20, fontFamily: 'Degular', marginLeft: 15 }}>Volver</p>
                                </Space>
                                </Col>*/}

                                <Col xs={24} sm={24} md={24} lg={0} style={{ minHeight: 72, backgroundColor: "#181818", textAlign: "left" }} className='margen-declaracion'>
                                    <div style={{ display: "flex", alignItems: "center" }} >
                                        <Button
                                        onClick={() => navigate(-1)}
                                        className="btn-declaracion-m"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0 10px",
                                        }}
                                        >
                                        <ChevronLeft style={{ color: "#FFFBFA" }} />
                                        </Button>
                                        <p
                                        onClick={() => navigate(-1)}
                                        style={{
                                            margin: "0 0 0 10px",
                                            color: "#FFFBFA",
                                            fontFamily: "Degular",
                                            fontWeight: "bold",
                                            fontSize: 20,
                                            cursor: "pointer",
                                        }}
                                        >
                                        <strong>Volver</strong>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className="left-section">
                                <div className="header-container">
                                    <Row style={{ textAlign: 'left' }} gutter={{ xs: 4, sm: 0, md: 5, lg: 10 }}>

                                        {/* mobile */}
                                        {/*<Col className="gutter-row" xs={3} sm={2} md={2} lg={0}>
                                            <Avatar style={{ backgroundColor: '#0F00D8' }} className="avatar" size={50} icon={<UserOutlined />} />
                                        </Col>
                                        <Col className="gutter-row" xs={21} sm={14} md={10} lg={0}>
                                            <Title level={4} className='title' >Regístrate en Portal empresas</Title>
                                        </Col>
                                        <Row gutter={{ xs: 4, sm: 24, md: 5, lg: 10 }}>
                                            <Col className="gutter-row" xs={2} sm={2} md={2} lg={0}>
                                                <Avatar size={45} style={{ backgroundColor: 'white', color: '#F1D779' }} className="avatar" icon={<ExclamationCircleOutlined />} />
                                            </Col>
                                            <Col className="gutter-row" xs={22} sm={22} md={22} lg={0}>
                                                <p className='subtitle'> Completa la siguiente información para finalizar la creación de la cuenta</p>
                                            </Col>
                                        </Row>*/}
                                        <Col className="gutter-row" xs={6} sm={2} md={2} lg={0}>
                                            <Avatar className="avatar-desktop" size={76} icon={<User stroke='bold' style={{ width: 44, height: 44 }} />} />
                                        </Col>
                                        <Col className="gutter-row" xs={17} sm={14} md={10} lg={0}>
                                            <Text className='title-persona' style={{ fontWeight: 'bold', lineHeight: 1.2 }} level={4} >Regístrate en portal empresa</Text>
                                        </Col>
                                        <Row gutter={0}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={0}>
                                                <p className='subtitle-persona' style={{ marginBottom: 0 }}>Completa la siguiente informacion para finalizar la creación de la cuenta</p>
                                            </Col>
                                        </Row>
                                        {/* desktop */}
                                        <Col className="gutter-row" xs={0} sm={0} md={0} lg={3} xl={3} xxl={2}>
                                            <Avatar className="avatar-desktop" size={76} icon={<User stroke='bold' style={{ width: 44, height: 44 }} />} />
                                        </Col>
                                        <Col className="titulo" xs={0} sm={0} md={0} lg={21} xl={20} xxl={21} >
                                            <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} className='margen-title'>
                                                    <Text className='title-persona'>Regístrate en portal empresas</Text>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
                                                    <Text className='subtitle-paso' style={{ marginTop: '5px' }}>
                                                        Completa la siguiente información para finalizar la creación de la cuenta
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Divider className='divider-pasos'/>
                                    </Row>
                                </div>
                                <Row className='row-step' justify='center' align='middle'>
                                    <Col xs={0} sm={24} md={24} lg={13} xl={14} xxl={15}>
                                        <Steps className='steps-web' current={pasoActual} labelPlacement="vertical" items={items} />
                                    </Col>
                                </Row>
                                
                                <Row className="row-step-mobile">
                                    <Col xs={24} sm={0} md={0} lg={0} xl={0} xxl={0}>
                                        <div style={{ overflowX: 'auto', WebkitOverflowScrolling: 'touch' }}>
                                        <Steps
                                            className="steps-mobile"
                                            current={pasoActual}
                                            direction="horizontal"
                                            responsive={false}
                                            labelPlacement="vertical"
                                            size="small"
                                            items={items}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <Form
                                    initialValues={{ representantesLegales: [{}] }}
                                    autoComplete="off"
                                    form={form}
                                    onFinish={ onFinish}
                                    style={{ width: '100%' }}
                                    className="form form-crear-empresa"
                                    layout="vertical"
                                >
                                   
                                    <Paso1 
                                        rubrosComerciales={rubrosComerciales} 
                                        cargos={cargos} 
                                        areaCargos={areaCargos} 
                                        setPasoActual={setPasoActual} 
                                        form={form} 
                                        pasoActual={pasoActual}
                                         
                                    />
                                   
                                    <Paso2 
                                        cargos={cargos} 
                                        areaCargos={areaCargos} 
                                        nacionalidades={nacionalidades} 
                                        paises={paises} 
                                        setPasoActual={setPasoActual} 
                                        form={form} 
                                        pasoActual={pasoActual} 
                                    />
                                   
                                    <Paso3 
                                        isSubmiting={formIsSubmitting} 
                                        cargos={cargos} 
                                        areaCargos={areaCargos} 
                                        paises={paises} 
                                        setPasoActual={setPasoActual} 
                                        form={form} 
                                        pasoActual={pasoActual} 
                                    />
                                   
                                </Form>
                                <br></br>
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={0}>
                            <div className="right-section" style={{ minHeight: 'auto' }}>
                                <div className="background-image"></div>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
            <ModalCompletarDatosEmpresa  fueCorrecto={accionCorrecta} handleOk={handleOk} isModalOpen={isModalOpen} redirectToLogin={false}/>
        </>
    )
}

export default FormCompletarCuentaEmpresa