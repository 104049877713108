import { useNavigate } from 'react-router-dom';
import { Table, Row, Col, Card } from 'antd';
import { useMediaQuery } from 'react-responsive'
import { SeparadorMiles } from '../../../services/Helpers/stringHelper';

const dayjs = require('dayjs')

const TablaProductosUsuario = ({ productos }) => {
    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const columns = [
        {
            title: 'Producto',
            width: 100,
            dataIndex: 'nombreProducto',
            key: 'nombreProducto',
            sorter: (a, b) => a.nombreProducto.localeCompare(b.nombreProducto, 'es', { ignorePunctuation: true }),
        },
        {
            title: 'Estado',
            width: 100,
            dataIndex: 'estado',
            key: 'estado',
            sorter: (a, b) => a.estado.localeCompare(b.estado, 'es', { ignorePunctuation: true }),
        },
        {
            title: 'Tipo de plan',
            width: 150,
            dataIndex: 'tipoPlan',
            key: 'tipoPlan',
            sorter: (a, b) => a.tipoPlan.localeCompare(b.tipoPlan, 'es', { ignorePunctuation: true }),
        },
        {
            title: 'Tarifa',
            width: 100,
            dataIndex: 'tarifa',
            key: 'tarifa',
            sorter: (a, b) => a.tarifa - b.tarifa,
            render: (value) => <>{SeparadorMiles(value)}</>,
        },
        {
            title: 'Contratación',
            dataIndex: 'fechaContratacion',
            key: 'fechaContratacion',
            width: 100,
            sorter: (a, b) => a.fechaContratacion - b.fechaContratacion,
            render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>,
        },
        {
            title: 'Fecha vencimiento',
            dataIndex: 'fechaProximoPago',
            key: 'fechaProximoPago',
            width: 100,
            sorter: (a, b) => a.fechaProximoPago - b.fechaProximoPago,
            render: (value) => <>{dayjs(value).format('DD/MM/YYYY')}</>,
        }
    ];


    return (
        <>
            {isTabletOrMobile ?
                <>
                    {productos?.map((item, index) => (
                        <Card key={`prd-${index}`} className="cardTable" onClick={() => navigate(`/detalle-producto/${item.idProducto}/${item.idSuscripcion}`)}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="titulo"><strong>Nombre</strong></p>
                                </Col>
                                <Col span={12}>
                                    <p className="titulo"><strong>Estado</strong></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="subTitulo">{item.nombreProducto}</p>
                                </Col>
                                <Col span={12}>
                                    <p className="subTitulo">{item.estado}</p>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="titulo"><strong>Tipo de plan</strong></p>
                                </Col>
                                <Col span={12} >
                                    <p className="titulo"><strong>Tarifa</strong></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="subTitulo">{item.tipoPlan}</p>
                                </Col>
                                <Col span={12}>
                                    <p className="subTitulo">{SeparadorMiles(item.tarifa)}</p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="titulo"><strong>Contratación</strong></p>
                                </Col>
                                <Col span={12} >
                                    <p className="titulo"><strong>Próximo pago</strong></p>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                <Col span={12}>
                                    <p className="subTitulo">{dayjs(item.fechaContratacion).format('DD/MM/YYYY')}</p>
                                </Col>
                                <Col span={12}>
                                    <p className="subTitulo">{dayjs(item.fechaProximoPago).format('DD/MM/YYYY')}</p>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </>
                :
                <Table style={{ marginTop: '10px' }} dataSource={productos} rowKey='idProducto'
                    locale={{ emptyText: "Este usuario no tiene productos asociados." }} columns={columns}
                >
                </Table>}
        </>)
};

export default TablaProductosUsuario