import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotificaciones, setRefrescarNotificaciones } from "../../redux/reducers/AppReducer";
import { getNotificacionesUsuario } from "../../services/notificacionApi";

const ActualizaNotificaciones = () => {
    const dispatch = useDispatch()
    const refrescarNotificaciones = useSelector(state => state.aplicacion.refrescarNotificaciones)

    const getNotificaciones = async () => {
        const notificaciones = await getNotificacionesUsuario()

        dispatch(setNotificaciones(notificaciones))
        dispatch(setRefrescarNotificaciones(false))
    }

    useEffect(() => {
        const fetchData = async () => {
            if (refrescarNotificaciones) {
                setTimeout(getNotificaciones, 1000)
            }
        }
        fetchData()
    }, [refrescarNotificaciones])

    return (
        <></>
    );
};

export default ActualizaNotificaciones