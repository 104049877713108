import axiosConfig from "./Helpers/privateInterceptorAxios"
const typeDefs = require('../interface/typeDefs')
const urlApi = process.env.REACT_APP_BACKEND_URL
const axios = axiosConfig.iAxios

export const asignarRemoverProductosAUsuario = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/AsignarDesasignarProductos`, data).then((res) => { return res.data })
}

export const actualizarMedioPagoSuscripcion = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/UpdateMedioPago`, data).then((res) => { return res.data })
}

export const actualizarSuscripcion = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/Update`, data).then((res) => { return res.data })
}

export const asignarRemoverUsuariosAProducto = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/AsignarDesasignarUsuario`, data).then((res) => { return res.data })
}

export const generarSuscripcion = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/GeneraSuscripcion`, data).then((res) => { return res.data })
}

export const validarCuponDescuento = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/validaCupon`, data).then((res) => { return res.data })
}

export const guardarEncuestaSatisfaccion = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/EncuestaSatisfaccion/`, data).then((res) => { return res.data })
}

export const darBajaSuscripcion = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/Bajar/`, data).then((res) => { return res.data })
}

export const savePeriodoPrueba = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/PeriodoPrueba/`, data).then((res) => { return res.data })
}

export const getPlanesSuscripcion = async (idSuscripcion) => {
    return axios.get(`${urlApi}/Suscripcion/Planes/${idSuscripcion}`).then((res) => { return res.data })
}


/**
 * Identificador de la suscripción, asociada a un pack de Apis, a la cual se le debe regenerar su ApiKey
 * @param {number} idSuscripcion - identificador de la suscripcion
 * @returns {Promise<typeDefs.ResultadoDto>} Resultado de la operacion
 */
export const generaNuevaApiKey = async (idSuscripcion) => {
    return axios.put(`${urlApi}/Suscripcion/ApiStartup/ApiKey/${idSuscripcion}`).then((res) => { return res.data })
}


export const consultaEstadoPago = async (idMedioPago, idTransaccion) => {
    // ConsultaEstadoPago/:idMedioPago/:idTransaccion
    return axios.get(`${urlApi}/Suscripcion/ConsultaEstadoPago/${idMedioPago}/${idTransaccion}`).
        then((res) => { return res.data })
}

export const generaPagoProximoMes = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/GeneraPagoProximoMes`, data).then((res) => { return res.data })
}

export const generaSolicitudContratacionInnovationLab = async (idMotivoContacto, idTipoPlan) => {
    return axios.put(`${urlApi}/Suscripcion/InnovationLab/${idMotivoContacto}/${idTipoPlan}`).then((res) => { return res.data })
}

export const generaPagoProximoMesInnovationLabFree = async (data) => {
    return axios.post(`${urlApi}/Suscripcion/GeneraPagoProximoMesInnovationLab`, data).then((res) => { return res.data })
}