import { LogoutOutlined } from '@ant-design/icons';
import { Button,Tooltip } from 'antd';

const BtnSalir = ({ isMenuCollapsed, setOpenModalSalir }) => {
    return (
        <>
            {isMenuCollapsed ? <Tooltip placement="right" title='Cerrar sesión'><Button className="btnSalir" onClick={() => setOpenModalSalir()} type="text"><LogoutOutlined style={{ color: 'red', fontSize: 20 }} /></Button></Tooltip> :
                <Button className="btnSalir" onClick={() => setOpenModalSalir()} type="text"><LogoutOutlined style={{ color: 'red' }} />Cerrar sesión</Button>}
        </>
    );
};

export default BtnSalir