import React from 'react'
import TableMisSolicitudes from '../Table/TableMisSolicitudes'

const MisSolicitudes = () => {
  return (
   <>
   <TableMisSolicitudes></TableMisSolicitudes>
   </>
  )
}

export default MisSolicitudes