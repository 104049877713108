import { useEffect, useState } from 'react'
import { getDetalleSolicitud } from '../../../services/empresaApi'
import { setIsLoading } from '../../../redux/reducers/AppReducer'
import { useDispatch } from 'react-redux'
import { getValorUF } from '../../../services/api'
import { useNavigate } from 'react-router-dom'
import { getInicialPorPalabra, CapitalizeFirstLetter } from '../../../services/Helpers/stringHelper'
import { TipoSolicitudId } from '../../../enum/enums';
import { Avatar, Button, Card, Row, Space, Typography, Col, Tag } from "antd";
import ContratacionProducto from './TipoSolicitudes/ContratacionProducto';
import HabilitacionProducto from './TipoSolicitudes/HabilitacionProducto';
import ModificacionProducto from './TipoSolicitudes/ModificacionProducto';
const { Title } = Typography;

const DetalleSolicitud = ({ id }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [solicitud, setSolicitdud] = useState(null)
    const [ufActual, setUf] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setIsLoading(true))
                const data = await getDetalleSolicitud(id)
                const ufData = await getValorUF()
                setSolicitdud(data)
                setUf(ufData)
            }
            catch (error) {
                console.log(error)
            }
            finally {
                dispatch(setIsLoading(false))
            }
        }
        fetchData()
    }, [id])

    const renderComponent = (idTipoSolicitud) => {
        switch (idTipoSolicitud) {
            case TipoSolicitudId.CONTRATACION_PRODUCTO_PRUEBA:
            case TipoSolicitudId.CONTRATACION_PRODUCTO_PAGO:
                return <ContratacionProducto valorUf={ufActual} solicitud={solicitud}></ContratacionProducto>
            case TipoSolicitudId.HABILITACION_PRODUCTO:
                return <HabilitacionProducto solicitud={solicitud}></HabilitacionProducto>
            case TipoSolicitudId.MODIFICACION_PRODUCTO:
                return <ModificacionProducto solicitud={solicitud}></ModificacionProducto>
            default:
                return null
        }
    }

    return (
        <>
            <Title level={5} style={{ textAlign: 'left', fontWeight: 'bold' }}>Solicitud realizada por:</Title>
            <Card style={{ background: '#FFF', boxShadow: '0px 0px 50px -20px rgba(0, 0, 0, 0.25)', paddingLeft: 10 }}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <Space>
                            <Avatar
                                style={{
                                    backgroundColor: '#ff4201',
                                    verticalAlign: 'middle',
                                }}
                                size="large"
                                shape='circle'
                            >
                                {getInicialPorPalabra(solicitud?.nombreUSuarioSolicitud, 2)}
                            </Avatar>
                            <label>{CapitalizeFirstLetter(solicitud?.nombreUSuarioSolicitud)} </label>
                            <Tag style={{ marginLeft: '3%' }} className="tag">Usuario consumidor</Tag>
                        </Space>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {solicitud ? renderComponent(solicitud.idTipoSolicitud) : null}
                </Row>
            </Card>
        </>
    )
}

export default DetalleSolicitud