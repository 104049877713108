import React from 'react'
import '../../../assets/styles/CardContent.styles.scss'
import parse from 'html-react-parser';
import { SeparadorMiles } from '../../../services/Helpers/stringHelper';
import { CheckOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

const DescripcionOtroTipoProducto = ({ plan }) => {
    return (
        <>
            <div className='descripcionDashboard' style={{ maxWidth: '100%' }}>
                {
                    plan?.descripcionPlanDashboard?.split('<p>')?.map((x, index) => {
                        if (x?.trim() !== '') {
                            return <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}>{parse(`<p style="margin-bottom:10px">${x}`)}</span></p>
                        }
                    })
                }
                {plan?.cantidadRequest ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}> {SeparadorMiles(plan?.cantidadRequest)} Requests / {plan?.nombrePlan === 'Básico' ? 'día' : 'mes'}</span></p> : null}
                {plan?.mercados ? <p className='descripcion' style={{ textAlign: 'left' }}><CheckOutlined className="letraColorCliente" style={{ marginRight: 2 }} /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}>{plan?.mercados} </span></p> : null}
                {plan?.periodoTiempo ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" style={{ marginRight: 2 }} /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}>Delay de {plan?.periodoTiempo} minutos </span></p> : null}
                {plan?.seleccionaInstrumentos ? <p className='descripcion' style={{ textAlign: 'left' }}><CheckOutlined className="letraColorCliente" /> <span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}> Puedes seleccionar cualquier instrumento</span></p> : null}
                {plan?.infoHistorica ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" /><span style={{ display: 'block', marginLeft: 20, marginTop: -21 }}> Historicidad de {plan?.infoHistorica} {plan?.infoHistorica > 1 ? 'años' : 'año'}</span></p> : null}
                {plan?.usuariosSugeridosInnovationLab ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" style={{ marginRight: 2 }} />Usuarios: {plan?.usuariosSugeridosInnovationLab}</p> : null}
                {plan?.memoriaRam ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" style={{ marginRight: 2 }} />Memoria Ram: {plan?.memoriaRam}</p> : null}
                {plan?.cantidadNucleos ? <p className='descripcion' style={{ textAlign: 'justify' }}><CheckOutlined className="letraColorCliente" /> vCores: {plan?.cantidadNucleos}</p> : null}
            </div>
        </>
    )
}

export default DescripcionOtroTipoProducto