import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setIsLoading, setUsuario } from "../../../redux/reducers/AppReducer"
import { Button, Row, Col, Card, Avatar, Space } from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import './usuario.style.scss'
import { useNavigate } from "react-router"
import { getInicial, CapitalizeFirstLetter } from "../../../services/Helpers/stringHelper"
import { daFormatoRut } from "../../../services/Helpers/RutHelper"
import ModalEliminarUsuario from "../Modal/ModalEliminarUsuario"
import { getDetalleUsuario } from "../../../services/empresaApi"
import { asignarRemoverProductosAUsuario } from "../../../services/suscripcionApi"
import ModalEditarRol from "../Modal/ModalEditarRol"
import { useParams } from "react-router"
import TablaProductosUsuario from "../Tablas/TablaProductosUsuario"
import ModalAsignarProductosUsuario from "../Modal/ModalAsignarProductosUsuario"
import ModalAvisos from "../../modal/ModalAvisos"
const dayjs = require('dayjs')

const DetalleUsuarioProducto = () => {
    const dispatch = useDispatch()
    const { idUsuario } = useParams()
    const navigate = useNavigate()
    const [usuario, setUsuario] = useState(null)
    const [refreshData, setRefreshdata] = useState(true)
    const [showModalEliminar, setShowModalEliminar] = useState(false)
    const [showModalRol, setShowModalRol] = useState(false)
    const [showModalAsignacion, setShowModalAsignacion] = useState(false)
    const [showModalAvisos, setShowModalAvisos] = useState(false)
    const [dataModal, setDataModal] = useState(null)
    const idUsuarioSession = useSelector(state => state.aplicacion.usuario.id)
    const habilitadoComprar = useSelector(state => state.aplicacion.usuario.habilitadoComprar)

    useEffect(() => {
        const fetchData = async () => {
            if (refreshData) {
                try {
                    dispatch(setIsLoading(true))
                    const data = await getDetalleUsuario(parseInt(idUsuario))
                    setUsuario(data)
                }
                catch (error) {
                    console.log(error)
                }
                finally {
                    dispatch(setIsLoading(false))
                    setRefreshdata(false)
                }
            }
        }
        fetchData()
    }, [idUsuario, refreshData])

    const onCloseModal = () => {
        setShowModalEliminar(false)
        setShowModalRol(false)
    }

    const onSelectUsuarioEliminar = () => {
        setShowModalEliminar(true)
    }

    const onEditarRol = () => {
        setShowModalRol(true)
    }

    const onGuardarAsignacionProductos = async (productosSeleccionados) => {
        try {
            dispatch(setIsLoading(true))
            const result = await asignarRemoverProductosAUsuario({
                idUsuario: parseInt(idUsuario),
                idsProductos: productosSeleccionados
            })

            setDataModal({
                mensaje: result.mensaje === '' ? 'Actualización aplicada correctamente.' : result.mensaje,
                fueCorrecto: result.accionFueCorrecta,
                titulo: result.accionFueCorrecta ? 'Éxito' : 'Error',
                msjBoton: 'Aceptar'
            })

            if (result.accionFueCorrecta)
                setRefreshdata(true)
        }
        catch (error) {
            console.log(error)
            setDataModal({
                mensaje: 'Ha ocurrido un error, inténtelo nuevamente.',
                fueCorrecto: false,
                titulo: 'Error',
                msjBoton: 'Aceptar'
            })
        }
        finally {
            dispatch(setIsLoading(false))
            setShowModalAvisos(true)
        }
    }

    const onCloseModalAvisos = () => {
        setShowModalAvisos(false)
    }

    const habilitarBtnAsignarProducto = () => {
        if (!habilitadoComprar) {
            return true
        }
    }


    return (
        <>
            {usuario ? <>
                <ModalAvisos
                    isModalOpen={showModalAvisos}
                    onClose={() => onCloseModalAvisos()}
                    mensaje={dataModal?.mensaje}
                    titulo={dataModal?.titulo}
                    fueCorrecto={dataModal?.fueCorrecto}></ModalAvisos>
                <ModalAsignarProductosUsuario onGuardarAsignacionProductos={onGuardarAsignacionProductos} productosAsignados={usuario.productos} isModalOpen={showModalAsignacion} setShowModal={setShowModalAsignacion}></ModalAsignarProductosUsuario>
                <ModalEliminarUsuario usuarioSeleccionado={{ idUsuario: parseInt(idUsuario), idRolAsignado: usuario.usuario.perfilId }} isModalOpen={showModalEliminar} handleOk={() => setRefreshdata(true)} handleCancel={onCloseModal}></ModalEliminarUsuario>
                <ModalEditarRol usuario={{ idUsuario: parseInt(idUsuario), idRolAsignado: usuario.usuario.perfilId }} isModalOpen={showModalRol} handleOk={() => setRefreshdata(true)} handleCancel={onCloseModal}></ModalEditarRol>
                <Row gutter={5}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={7} xxl={9} style={{ marginBottom: '25px' }}>
                        <Card title="" className="cardUsuarios" style={{ marginTop: 10 }}>
                            <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
                                <Col>
                                    <Space>
                                        <Avatar
                                            style={{
                                                backgroundColor: '#ff4201',
                                                verticalAlign: 'middle',
                                            }}
                                            size="large"
                                            shape='circle'
                                        >
                                            {getInicial(usuario.usuario.nombres)}
                                        </Avatar>
                                        <label>{CapitalizeFirstLetter(`${usuario.usuario.nombres} ${usuario.usuario.apellidos}`)}</label>
                                    </Space>
                                </Col>
                            </Row>
                            <br></br>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col span={24}><label><strong>Rol asignado:</strong></label> </Col>
                                        <Col span={24}><label>{usuario.usuario.perfil}</label> </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col span={24}><label><strong>Productos:</strong></label> </Col>
                                        <Col span={24}><label>{usuario.usuario.cantidadProductos} {usuario.usuario.cantidadProductos > 1 || usuario.usuario.cantidadProductos === 0 ? 'productos' : 'producto'}</label> </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                    <Row style={{ marginTop: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col span={24}><label><strong>Rut:</strong></label> </Col>
                                        <Col span={24}><label>{daFormatoRut(usuario.usuario.numeroIdentificacion)}</label> </Col>
                                    </Row>
                                </Col>
                                {usuario.usuario.nacionalidad ?
                                    <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                        <Row style={{ marginTop: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col span={24}><label><strong>Nacionalidad:</strong></label> </Col>
                                            <Col span={24}><label>{usuario.usuario.nacionalidad}</label> </Col>
                                        </Row>
                                    </Col> : null}
                                {usuario.usuario.region ?
                                    <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                        <Row style={{ marginTop: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col span={24}><label><strong>Región:</strong></label> </Col>
                                            <Col span={24}><label>{usuario.usuario.region}</label> </Col>
                                        </Row>
                                    </Col> : null}

                                {usuario.usuario.ciudad ?
                                    <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                        <Row style={{ marginTop: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col span={24}><label><strong>Ciudad:</strong></label> </Col>
                                            <Col span={24}><label>{usuario.usuario.ciudad}</label> </Col>
                                        </Row>
                                    </Col> : null}
                                <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                    <Row style={{ marginTop: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col span={24}><label><strong>Fecha de nacimiento:</strong></label> </Col>
                                        <Col span={24}><label>{dayjs(usuario.usuario.fechaNacimiento).format('DD/MM/YYYY')}</label> </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                    <Row style={{ marginTop: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col span={24}><label><strong>Teléfono:</strong></label> </Col>
                                        <Col span={24}><label>{usuario.usuario.celular}</label> </Col>
                                    </Row>
                                </Col>
                                {usuario.usuario.cargo ?
                                    <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                        <Row style={{ marginTop: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col span={24}><label><strong>Cargo:</strong></label> </Col>
                                            <Col span={24}><label>{usuario.usuario.cargo}</label> </Col>
                                        </Row>
                                    </Col> : null}
                                {usuario.usuario.areaCargo ?
                                    <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                        <Row style={{ marginTop: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col span={24}><label><strong>Área del cargo:</strong></label> </Col>
                                            <Col span={24}><label>{usuario.usuario.areaCargo}</label> </Col>
                                        </Row>
                                    </Col> : null}
                                <Col xs={24} sm={24} md={24} lg={18} xl={24} xxl={24} style={{ textAlign: 'left' }}>
                                    <Row style={{ marginTop: 8, marginBottom: 8 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col span={24}><label><strong>Correo:</strong></label> </Col>
                                        <Col span={24}><label>{usuario.usuario.email}</label> </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                    <Button disabled={usuario.usuario.estado !== 'Activado'} onClick={() => navigate(`/editar-usuario/${usuario.usuario.id}`)} className="btnEditar" type="text"><FileSearchOutlined style={{ color: '#0F00D8', fontSize: 25 }} />Editar datos</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={12} xxl={12} style={{ textAlign: 'left' }}>
                                    <Button disabled={usuario.usuario.id === idUsuarioSession || usuario.usuario.estado !== 'Activado'} onClick={() => onEditarRol()} className="btnEditar" type="text"><FileSearchOutlined style={{ color: '#0F00D8', fontSize: 25 }} />Editar rol</Button>
                                </Col>

                            </Row>
                            <Row style={{ marginTop: 10 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xxl: 0 }}>
                                <Col xs={12} sm={8} md={12} lg={6} xl={24} xxl={12} style={{ textAlign: 'left' }} >
                                    <Button className="btncancelar" onClick={() => onSelectUsuarioEliminar()} disabled={usuario.usuario.id === idUsuarioSession || usuario.usuario.estado !== 'Activado'} style={{ marginTop: 8, minWidth: 220 }} type="primary" shape='round' danger >
                                        Eliminar usuario
                                    </Button>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} xl={24} xxl={9} style={{ textAlign: 'left' }}>
                                    <Button onClick={() => setShowModalAsignacion(true)}
                                        disabled={habilitarBtnAsignarProducto()}
                                        style={{ marginTop: 8 }} type="primary" shape='round' >
                                        Asignar / Remover productos
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={17} xxl={15} style={{ marginBottom: '25px' }}>
                        <TablaProductosUsuario productos={usuario.productos}></TablaProductosUsuario>
                    </Col>
                </Row></> : null}
        </>
    )
}

export default DetalleUsuarioProducto