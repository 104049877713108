export const AccionId = {
	LOGIN_USUARIO: 1,
}

export const TipoPagoKhipuId = {
	CONTRATACION: 1,
	PAGO_MENSUAL: 2,
}

export const EstadoPagoId = {
	INTENCION_PAGO: 1,
	APROBADO: 2,
	RECHAZADO: 3
}

export const EstadoSolicitudId = {
	ESPERA_APROBACION: 1,
	APROBADA: 2,
	RECHAZADA: 3
}

export const EstadoSuscripcionId = {
	VIGENTE: 1,
	DADO_DE_BAJA: 2,
	CADUCADA: 3,
	INACTIVA: 4,
	BORRADOR: 5
}

export const TipoPreguntaId = {
	ALTERNATIVA: 1,
	MULTI_ALTERNATIVA: 2,
	TEXTO: 3
}

export const PerfilUsuarioId = {
	PERSONA_NATURAL: 1,
	ADMIN_EMPRESA: 2,
	CONSUMIDOR_EMPRESA: 3
}

export const TipoSolicitudId = {
	CONTRATACION_PRODUCTO_PRUEBA: 1,
	CONTRATACION_PRODUCTO_PAGO: 2,
	MODIFICACION_PRODUCTO: 3,
	HABILITACION_PRODUCTO: 4
}

export const MedioPagoId = {
	ONE_CLICK: 1,
	KIPHU: 2,
	WEBPAY: 3
}

export const TipoProductoId = {
	API: 1,
	REPORTE: 2,
	PACK_REPORTES: 3,
	PACK_APIS: 4,
	INNOVATION_LAB: 5
}

export const TipoDocumentoId = {
	CONTRATO: 1,
	FOLLETO: 2,
	DOCUMENTACION: 3
}

export const MotivoContactoInnovationId = {
	CONTRATAR_PLAN: 2,
	MODIFICAR_PLAN: 6,
}
