import React from 'react'
import Inactividad from './utils/Inactividad'
import './App.css'
import AppRoutes from './routing'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import Keycloak from 'keycloak-js'
import { Provider } from 'react-redux'
import { store } from './redux/store/store'
import "@fontsource/roboto"
import { ConfigProvider, ThemeConfig } from 'antd'
import dayjs from "dayjs";
import "dayjs/locale/es";
import updateLocale from "dayjs/plugin/updateLocale";
import locale from "antd/es/locale/es_ES";
import "./index.css";

dayjs.extend(updateLocale);
dayjs.updateLocale("es", {
  weekStart: 1
});


const keycloakSetting = {
  url: process.env.REACT_APP_KEYCLOACK_URL,
  realm: process.env.REACT_APP_KEYCLOACK_REALM,
  clientId: process.env.REACT_APP_KEYCLOACK_CLIENT_ID
};

const authInstance = new Keycloak(keycloakSetting)

function App() {
  const eventLogger = (event, error) => {
    if (event === 'onAuthLogout') {
      window.location.href = process.env.REACT_APP_URL_ONBOARDING_PUBLICO
    }
  }

  const tokenLogger = (tokens) => {
    if (tokens.token) {
      localStorage.setItem('accesToken', tokens.token);
    }
  }

  /**
   * @type {ThemeConfig}
   */
  const themeConfig = {
    token: {

    },
    components: {
      Button: {
        colorPrimary: '#00FF91',
        primaryColor: '#000000E0',
        dangerColor: '#ff4201',
        colorError: "#ff4d4f",
        algorithm: true, // Enable algorith

      },
      Alert: {
        colorWarning: "black",
        colorWarningBg: "#E7FF00",
      },
      Pagination: {
        // itemActiveBg: '#ff4201',
        // itemActiveBgDisabled
      }
    }
  }

  return (

    <Provider store={store}>
      <ReactKeycloakProvider authClient={authInstance}
        onEvent={eventLogger}
        onTokens={tokenLogger}>

        <React.StrictMode>

          {/* <div className="App">
            <AppRoutes></AppRoutes>
          </div> */}

          {/* https://ant.design/docs/react/customize-theme */}
          <ConfigProvider
            locale={locale}
            theme={themeConfig}
          >
            <Inactividad></Inactividad>
          </ConfigProvider>
        </React.StrictMode>
      </ReactKeycloakProvider>
    </Provider >

  );
}

export default App;
