import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import Title from 'antd/es/typography/Title'
import ModalExitoCrearCuentaConsumidor from '../../modal/ModalExitoCrearCuentaConsumidor';
import { useEffect, useState } from 'react';
import { existeEmail, existeRutRegistrado, getCargos, getAreaCargos } from '../../../services/publicApi'
import { saveUsuarioEmpresa } from '../../../services/empresaApi'
import { esRutValido, getRutSinDv, getDvRut, cleanRut } from '../../../services/Helpers/RutHelper'
import locale from 'antd/es/date-picker/locale/es_ES'
import { useNavigate } from 'react-router'
import { daFormatoRut } from '../../../services/Helpers/RutHelper';
import { useDispatch } from 'react-redux';
import { setRefrescarNotificaciones } from '../../../redux/reducers/AppReducer';
const roles = [{ label: 'Administrador', value: 2 }, { label: 'Consumidor', value: 3 }]

const CrearUsuario = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [accionCorrecta, setAccionCorrecta] = useState(true)
    const [existeEmailBd, setExisteEmail] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [cargos, setCargos] = useState(null)
    const [areaCargos, setAreaCargos] = useState(null)
    const [formIsSubmiting, setFormIsSubmiting] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            Promise.all([
                getCargos(),
                getAreaCargos()]).then((values) => {
                    setCargos(values[0])
                    setAreaCargos(values[1])
                }).finally(() =>
                    console.log()
                );
        }
        fetchData()
    }, [])

    const handleOk = () => {
        navigate('/Usuarios')
        setIsModalOpen(false);
    };


    const onFinish = async (values) => {
        try {
            setFormIsSubmiting(true)
            values.DVnumeroIdentificacion = getDvRut(values.numeroIdentificacion)
            values.numeroIdentificacion = getRutSinDv(values.numeroIdentificacion)
            values.fechaNacimiento = values.fechaNacimiento ? values.fechaNacimiento.toDate() : null

            const result = await saveUsuarioEmpresa(values)

            if (result.accionFueCorrecta) {
                dispatch(setRefrescarNotificaciones(true))
                setAccionCorrecta(true)
            }
            else {
                setAccionCorrecta(false)
                throw new Error('error');
            }
        }
        catch (error) {
            setAccionCorrecta(false)
        }
        finally {
            setIsModalOpen(true)
            setFormIsSubmiting(false)
        }
    };

    const onValidateEmail = async (value) => {
        try {
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
            const result = regex.test(value);

            if (result) {
                setFormIsSubmiting(true)
                const data = await existeEmail(value)
                setExisteEmail(data)

                if (data.tieneCuentaOnBoarding) {
                    return Promise.reject(new Error('Email ingresado ya se encuentra registrado'))
                }
                else {
                    return Promise.resolve()
                }
            }
        }
        catch {

        }
        finally {
            setFormIsSubmiting(false)
        }
    }

    const onValidateRut = async (value) => {
        try {
            const esValido = esRutValido(value)
            const existe = esValido ? await existeRutRegistrado(cleanRut(value)) : false
            setFormIsSubmiting(true)

            if (esValido && !existe) {
                return Promise.resolve()
            }
            else if (!esValido) {
                return Promise.reject(new Error('Ingresa un Rut válido'))
            }
            else if (existe) {
                return Promise.reject(new Error('Este RUT ya tiene una cuenta creada'))
            }
        }
        catch {

        }
        finally {
            setFormIsSubmiting(false)
        }
    }

    const onChangeAreaCargo = (value) => {
        const glosa = areaCargos.filter(x => x.value === value)[0].label
        form.setFieldValue('glosaAreaCargo', glosa)
    }

    return (
        <>
            <ModalExitoCrearCuentaConsumidor redirectToLogin={false} fueCorrecto={accionCorrecta} isModalOpen={isModalOpen} handleOk={handleOk} />
            <Title level={4} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 24 }}>Nuevo usuario</Title>
            <br />
            <Form form={form} onFinish={onFinish} className="form formLabelStrongs" layout="vertical">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item
                            name='nombre'
                            label='Nombres'
                            className="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Ingresa nombres",
                                },
                            ]}
                        >
                            <Input placeholder='Nombres' />
                        </Form.Item>
                    </Col>
                    <Form.Item style={{ display: 'none' }} name='glosaAreaCargo'><Input /></Form.Item>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item
                            label='Apellidos'
                            name='apellidos'
                            className="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Ingresa apellidos",
                                },
                            ]}
                        >
                            <Input placeholder='Apellidos' />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item
                            name='numeroIdentificacion'
                            label='Número de identificación (RUT)'
                            normalize={(value) => daFormatoRut(value)}
                            rules={[
                                {
                                    required: true,
                                    message: "Ingresa número de identificación (RUT)",
                                },
                                {
                                    validator: (_, value) => value ? onValidateRut(value) : Promise.resolve()
                                }
                            ]}
                        >
                            <Input placeholder='11.111.111-1' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item name='fechaNacimiento' label='Fecha de nacimiento' className="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Ingresa fecha de nacimiento",
                                },
                            ]}
                        >
                            <DatePicker locale={locale} format={"DD/MM/YYYY"} placeholder='Selecciona fecha' style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item name='telefono' label='Teléfono fijo o Celular' className="username" rules={[
                            {
                                required: true,
                                message: "Ingresa un teléfono o celular",
                            },
                        ]}>
                            <Input placeholder='+569 12332155' />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item name='email' label='Correo electrónico' className="username" rules={[
                            {
                                required: true,
                                message: "Ingresa un correo electrónico",
                            },
                            {
                                type: "email",
                                message: "Ingresa un email válido",
                            },
                            {
                                validator: (_, value) => value ? onValidateEmail(value) : Promise.resolve()
                            }
                        ]}>
                            <Input placeholder='correo@correo.cl' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item label='Cargo' name="cargoId">
                            <Select
                                showSearch
                                placeholder="Selecciona un cargo"
                                optionFilterProp="children"
                                options={cargos}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item label='Área del Cargo' name="areaCargoId">
                            <Select
                                showSearch
                                placeholder="Selecciona un área del cargo"
                                optionFilterProp="children"
                                options={areaCargos}
                                onChange={onChangeAreaCargo}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={8}>
                        <Form.Item label='Rol' name="rolId"
                            tooltip="Administrador: puede gestionar usuarios de la empresa, contratar productos y acceder a estos.
                            Consumidor: puede acceder a los productos contratados que el administrador le asigne."
                            rules={[
                                {
                                    required: true,
                                    message: "Selecciona un rol",
                                },
                            ]}>
                            <Select
                                showSearch
                                placeholder="Selecciona un rol"
                                optionFilterProp="children"
                                options={roles}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <Button
                            type="primary"
                            danger
                            htmlType="button"
                            shape="round"
                            onClick={() => navigate('/Usuarios')}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={formIsSubmiting}
                        >
                            Crear usuario
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CrearUsuario