import { useEffect } from 'react'
import FormCrearCuentaPersonas from '../../components/formCrearcuentaPersona/FormCrearCuentaPersonas'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

const CrearCuenta = () => {
  const declaracionCompliance = useSelector(store => store.aplicacion.declaracionCompliance)
  const navigate = useNavigate()

  useEffect(() => {
    if (!declaracionCompliance)
      navigate('/')

  }, [declaracionCompliance])

  return (

    <FormCrearCuentaPersonas />
  )
}

export default CrearCuenta