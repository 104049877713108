import { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Typography } from 'antd'
import { TipoProductoId, MotivoContactoInnovationId } from '../../enum/enums';
import DescripcionOtroTipoProducto from '../productoServicio/planes/DescripcionOtroTipo';
import './Modal.styles.scss'
import ModalCambioSuscripcion from './ModalCambioSuscripcion';
import { PerfilUsuarioId } from '../../enum/enums';
import { useMediaQuery } from 'react-responsive'
import { Carousel } from 'antd';
import { useParams } from 'react-router';
import { getPlanesSuscripcion, generaSolicitudContratacionInnovationLab } from '../../services/suscripcionApi';
import PlanProducto from '../productoServicio/planes/PlanProducto';
import { SeparadorMiles } from '../../services/Helpers/stringHelper';
import { actualizarSuscripcion } from '../../services/suscripcionApi';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../redux/reducers/AppReducer';
import ModalAvisos from './ModalAvisos';
import { useNavigate } from 'react-router';
import DescripcionPlanApi from '../productoServicio/DescripcionPlanApi'
import DescripcionPlanDashboard from '../productoServicio/planes/DescripcionPlanDashboard';
import DescripcionPlanInnovationLabs from '../productoServicio/DescripcionPlanInnovationLabs';
const { Title } = Typography;

const ModalSuscripciones = ({ idTipoProducto, isModalOpen, handleCancel }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false)
    const idPerfilUsuario = useSelector(state => state.aplicacion.usuario.perfilId)
    const [nuevaSuscripcion, setNuevaSuscripcion] = useState(null)
    const [planes, setPlanes] = useState(null)
    const { idSuscripcion } = useParams()
    const [showModalResultado, setShowModalResultado] = useState(false)
    const [dataModal, setDataModal] = useState(null)


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPlanesSuscripcion(idSuscripcion)
                setPlanes(data)
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [idSuscripcion])

    const onUpdateSuscripcion = async () => {
        try {
            dispatch(setIsLoading(true))
            //si es pago con kiphu se debe re-dirigir a plataforma de pago(a menos que sea consumidor) , si es onceClick
            //se asocia el pago a la tarjeta de la suscripcion original a
            const result = await actualizarSuscripcion({
                idSuscripcion: parseInt(idSuscripcion),
                idProductoDestino: nuevaSuscripcion.idPlanProducto
            })

            let titulo = 'Se envió un correo para notificar la modificación de tu suscripción.'
            let mensaje = 'Modificación de suscripción exitosa'

            if (idPerfilUsuario === PerfilUsuarioId.CONSUMIDOR_EMPRESA) {
                titulo = 'Se ha generado una solicitud para la modificación de tu suscripción.'
                mensaje = 'Solicitud creada exitosamente.'
            }

            setDataModal({
                mensaje: result.accionFueCorrecta ? titulo : result.mensaje,
                fueCorrecto: result.accionFueCorrecta,
                titulo: result.accionFueCorrecta ? mensaje : 'Error',
                msjBoton: 'Aceptar'
            })
        }
        catch (error) {
            console.log(error)
            setDataModal({
                mensaje: 'Ha ocurrido un error, inténtelo nuevamente.',
                fueCorrecto: false,
                titulo: 'Error'
            })
        }
        finally {
            dispatch(setIsLoading(false))
            handleCancel()
            setIsModalOpenSuccess(false)
            setShowModalResultado(true)
        }
    }

    const onSeleccionarSuscripcion = async (nuevaSuscripcion) => {
        if (idTipoProducto === TipoProductoId.INNOVATION_LAB) {
            try {
                dispatch(setIsLoading(true))
                const data = await generaSolicitudContratacionInnovationLab(MotivoContactoInnovationId.MODIFICAR_PLAN, nuevaSuscripcion.idTipoPlan)

                setDataModal({
                    fueCorrecto: data.accionFueCorrecta,
                    titulo: data.accionFueCorrecta ? "Se ha generado una solicitud para modificar el plan del producto." : "Error",
                    mensaje: data.accionFueCorrecta ? 'Pronto será contactado por un ejecutivo para finalizar su modificación.' : 'Ha ocurrido un error, inténtelo nuevamente.',
                    msjBoton: 'Aceptar'
                })
            }
            catch (error) {
                console.log(error)
                setDataModal({
                    mensaje: 'Ha ocurrido un error, inténtelo nuevamente.',
                    fueCorrecto: false,
                    titulo: 'Error'
                })
            }
            finally {
                dispatch(setIsLoading(false))
                handleCancel()
                setShowModalResultado(true)
            }
        }
        else {
            setNuevaSuscripcion(nuevaSuscripcion)
            handleCancel()
            setIsModalOpenSuccess(true)
        }
    }

    const onCloseResultado = () => {
        if (dataModal?.fueCorrecto && PerfilUsuarioId.ADMIN_EMPRESA === idPerfilUsuario)
            navigate(`/productosEmpresa`)
        else if (dataModal?.fueCorrecto)
            navigate(`/productos`)
        else
            setShowModalResultado(false)
    }

    return (
        <div >
            <ModalAvisos
                isModalOpen={showModalResultado}
                onClose={() => onCloseResultado()}
                mensaje={dataModal?.mensaje}
                titulo={dataModal?.titulo}
                fueCorrecto={dataModal?.fueCorrecto}></ModalAvisos>
            <Modal footer={[]}
                // width={850}
                open={isModalOpen}
                // onOk={handleOk}
                onCancel={handleCancel}
                className='modalsuscription'
            >
                {isTabletOrMobile ?
                    <Carousel>
                        {planes?.planes?.map((item, index) => (
                            <div key={`pl-${index}`}>
                                <Card key={index} className='card-detail-prod' style={{ marginBottom: 35, marginLeft: 5, marginRight: 5 }} bordered={false}>
                                    <div >
                                        <PlanProducto
                                            idTipoProducto={planes?.idTipoProducto}
                                            ajustarAlto={true}
                                            planProducto={{
                                                idTipoPlan: item.idTipoPlan,
                                                nombreTipoPlan: item.nombrePlan,
                                                subtitulo: item.subtitulo
                                            }} />
                                    </div>
                                    {idTipoProducto !== TipoProductoId.INNOVATION_LAB && (
                                        <div style={{ textAlign: 'left' }}>
                                            <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)', marginTop: 0, marginLeft: '19%' }}>UF {SeparadorMiles(item.precio)} /mes</Title>
                                        </div>)}
                                    <div>
                                        <p style={{ textDecoration: 'underline', textAlign: 'justify', fontWeight: 'bold' }}>Este plan incluye:</p>
                                        {idTipoProducto === TipoProductoId.PACK_APIS ?
                                            <DescripcionPlanApi
                                                requesAlMes={item?.cantidadRequest}
                                                mercadoProducto={item?.mercados}
                                                nombrePlan={item.nombrePlan}
                                                agnoInfoHistorica={item?.infoHistorica}
                                                tiempoDelay={item?.periodoTiempo}>
                                            </DescripcionPlanApi> :
                                            idTipoProducto === TipoProductoId.PACK_REPORTES ?
                                                <DescripcionPlanDashboard texto={item?.descripcionPlanDashboard}></DescripcionPlanDashboard> :
                                                idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                                    <DescripcionPlanInnovationLabs descripcion={item.descripcionPlanDashboard} usuariosSugeridosInnovationLab={item.usuariosSugeridosInnovationLab} cantidadNucleos={item?.cantidadNucleos} memoriaRam={item?.memoriaRam}></DescripcionPlanInnovationLabs> :
                                                    <DescripcionOtroTipoProducto plan={item}></DescripcionOtroTipoProducto>}
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button disabled={planes?.idPlanContratado === item.idPlanProducto} onClick={() => onSeleccionarSuscripcion(item)} type='primary' shape='round'>Seleccionar suscripción</Button>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </Carousel> :
                    <>
                        <Row gutter={16}>
                            {planes?.planes?.map((item, index) => (
                                <Col style={{ marginBottom: 16 }} key={`pl-${index}`} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                    <Card className='card-detail-prod' bordered={false}>
                                        <div style={{ textAlign: 'center' }}>
                                            <PlanProducto
                                                ajustarAlto={true}
                                                maxHeigthTitulo={33}
                                                idTipoProducto={planes?.idTipoProducto}
                                                planProducto={{
                                                    idTipoPlan: item.idTipoPlan,
                                                    nombreTipoPlan: item.nombrePlan,
                                                    subtitulo: item.subtitulo
                                                }} />
                                        </div>
                                        {idTipoProducto !== TipoProductoId.INNOVATION_LAB && (
                                            <div style={{ textAlign: 'left' }}>
                                                <Title level={3} style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.88)', marginTop: 0 }}>UF {SeparadorMiles(item.precio)} /mes</Title>
                                            </div>)}
                                        <div>
                                            <p style={{ textDecoration: 'underline', textAlign: 'justify', fontWeight: 'bold' }}>Este plan incluye:</p>
                                            {idTipoProducto === TipoProductoId.PACK_APIS ?
                                                <DescripcionPlanApi
                                                    requesAlMes={item?.cantidadRequest}
                                                    mercadoProducto={item?.mercados}
                                                    nombrePlan={item.nombrePlan}
                                                    agnoInfoHistorica={item?.infoHistorica}
                                                    tiempoDelay={item?.periodoTiempo}>
                                                </DescripcionPlanApi> :
                                                idTipoProducto === TipoProductoId.PACK_REPORTES ?
                                                    <DescripcionPlanDashboard texto={item.descripcionPlanDashboard}></DescripcionPlanDashboard> :
                                                    idTipoProducto === TipoProductoId.INNOVATION_LAB ?
                                                        <DescripcionPlanInnovationLabs descripcion={item.descripcionPlanDashboard} usuariosSugeridosInnovationLab={item.usuariosSugeridosInnovationLab} cantidadNucleos={item?.cantidadNucleos} memoriaRam={item?.memoriaRam}></DescripcionPlanInnovationLabs> :
                                                        <DescripcionOtroTipoProducto plan={item}></DescripcionOtroTipoProducto>}
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <Button disabled={planes?.idPlanContratado === item.idPlanProducto} onClick={() => onSeleccionarSuscripcion(item)} type='primary' shape='round'>Seleccionar suscripción</Button>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </>}
            </Modal>

            <ModalCambioSuscripcion nuevaSuscripcion={nuevaSuscripcion} isOpen={isModalOpenSuccess} handleOk={() => onUpdateSuscripcion()} handleCancel={() => setIsModalOpenSuccess(false)} />
        </div>
    )
}

export default ModalSuscripciones