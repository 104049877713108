import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Avatar, Modal, Row, Col } from 'antd'
import { useKeycloak } from '@react-keycloak/web'
import './Modal.styles.scss'

const ModalCerrarSesion = ({ isModalOpen, onClose }) => {
    const { keycloak } = useKeycloak()

    return (
        <div >
            <Modal
                className="modalAvisos"
                closable={false}
                centered
                open={isModalOpen}
                onOk={() => keycloak.logout({ redirectUri: process.env.REACT_APP_URL_ONBOARDING_PUBLICO })}
                onCancel={onClose}
                width={450}
                okText="Cerrar sesión"
                cancelText="Cancelar"
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Avatar style={{ backgroundColor: '#ff4201' }} size={80} icon={<ExclamationCircleOutlined />} />

                    <Row>
                        <Col span={24}>
                            <p className='msjTitulo'>
                                ¿Estás seguro de salir?
                            </p>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default ModalCerrarSesion