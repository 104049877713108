import axiosConfig from "./Helpers/privateInterceptorAxios"
const urlApi = process.env.REACT_APP_BACKEND_URL
const axios = axiosConfig.iAxios

export const iniciarInscripcionTarjeta = async (data) => {
    return axios.get(`${urlApi}/MedioPago/IniciarInscripcion`, data).then((res) => { return res.data })
}

export const getMediosPago = async () => {
    return axios.get(`${urlApi}/MedioPago/MediosPago`).then((res) => { return res.data })
}

export const getTarjetasCliente = async () => {
    return axios.get(`${urlApi}/MedioPago/TarjetasCliente`).then((res) => { return res.data })
}

export const deleteMediosPago = async (id) => {
    return axios.delete(`${urlApi}/MedioPago/Delete/${id}`).then((res) => { return res.data })
}

export const getResultadoWebPay = async (id) => {
    return axios.get(`${urlApi}/MedioPago/ResultadoWebPay/${id}`).then((res) => { return res.data })
}

export const getHistorialPagos = async () => {
    return axios.get(`${urlApi}/MedioPago/Historial`).then((res) => { return res.data })
}