import { Table, Avatar, Tag, Button } from 'antd';
import { CapitalizeFirstLetter, getInicial } from '../../../services/Helpers/stringHelper';
import { daFormatoRut } from '../../../services/Helpers/RutHelper';
import { useSelector } from 'react-redux';

const TablaAsignacionDesasignacionUsuarios = ({ onAsignarDesasignarProductos, productosAsignados, productos }) => {
    const columns = [
        {
            title: 'Producto',
            dataIndex: 'NombreProducto',
            key: 'nombre',
        },
        {
            title: 'Descripción',
            dataIndex: 'DescripcionProducto',
            key: 'descripcion',
        },
        {
            title: 'Plan',
            dataIndex: 'NombreTipoPlan',
            key: 'NombreTipoPlan',
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, recordSelected) => {
            onAsignarDesasignarProductos(recordSelected?.map(x => { return { idProducto: x.IdProductoContratado, idSuscripcion: x.IdSuscripcion } }))
        }
    };

    return (
        <>
            <Table style={{ marginTop: '10px', width: '100%' }} dataSource={productos} rowKey='IdSuscripcion'
                rowSelection={{
                    selectedRowKeys: productosAsignados?.map(x => x.idSuscripcion),
                    hideSelectAll: true,
                    type: 'checkbox',
                    ...rowSelection,
                }}
                pagination={{ pageSize: 5 }}
                locale={{ emptyText: "No hay productos por asignar o remover." }} columns={columns}
            >
            </Table>
        </>)
};

export default TablaAsignacionDesasignacionUsuarios