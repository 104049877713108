import { useState } from 'react';
import { Avatar, Drawer, Button } from 'antd';
import OpcionesMenu from './opcionesMenu';
import { LogoutOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Space } from 'antd';
import ModalCerrarSesion from '../modal/ModalCerrarSesion';

const MenuMobile = ({ openMenuMobile, closeMenuMobile, handleMenuClick }) => {
    const [openModalSalir, setOpenModalSalir] = useState(false)

    const onSelectopcioneMenu = (key, label) => {
        handleMenuClick(key, label)
        closeMenuMobile()
    }

    return (
        <Drawer
            style={{ paddingLeft: '0px' }}
            placement={'left'}
            closable={false}
            onClose={closeMenuMobile}
            open={openMenuMobile}
            key={'left'}
        >
            <ModalCerrarSesion
                isModalOpen={openModalSalir}
                onClose={() => setOpenModalSalir(false)}
            ></ModalCerrarSesion>
            <Row style={{ marginTop: 30 }}>
                <Col span={24}>
                    <Space>
                        <Avatar style={{ backgroundColor: '#ff4201', marginLeft: 21 }} className="avatar" icon={<CloseCircleOutlined />} />
                        <p style={{ marginTop: '15px' }}><strong>Menú</strong></p>
                    </Space>
                </Col>
            </Row>
            <OpcionesMenu handleMenuClick={onSelectopcioneMenu}></OpcionesMenu>
            <Button type="text" className="btnSalir" onClick={() => setOpenModalSalir(true)}><LogoutOutlined style={{ color: 'red' }} />Cerrar sesión</Button>
        </Drawer>
    )
}

export default MenuMobile