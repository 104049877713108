import { useEffect } from 'react';
import ProductoYServicio from '../../components/productoServicio/ProductoServicio'
import { Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { setProductoContratar } from '../../redux/reducers/ProductoReducer';

const { Title } = Typography;

const ProductoServicio = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setProductoContratar(null))
    }
    fetchData()
  }, [])

  return (
    <div>
      <Title level={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>Categorías</Title>
      <ProductoYServicio />
    </div>
  )
}

export default ProductoServicio