import FormPerfilPersonaLectura from './FormPerfilPersonaLectura'

const IndexPersona = () => {
  return (
    <div>
      <FormPerfilPersonaLectura />
    </div>
  )
}

export default IndexPersona