import axiosConfig from "./Helpers/privateInterceptorAxios"
import { PublicAxios } from "../services/Helpers/publicInterceptorAxios"

const urlApi = process.env.REACT_APP_BACKEND_URL
const axios = axiosConfig.iAxios

export const getUsuariosEmpresa = async (data) => {
    return axios.get(`${urlApi}/empresa/usuarios`, data).then((res) => { return res.data })
}

export const crearCuentaEmpresa = async (data) => {
    return axios.post(`${urlApi}/empresa/crear`, data).then((res) => { return res.data })
}

export const existeEmpresaRegistradaByRut = async (rut) => {
    return PublicAxios.get(`${urlApi}/empresa/existe?rut=${rut}`).then((res) => { return res.data })
}

export const complementarCuentaEmpresa = async (data) => {
    return axios.post(`${urlApi}/empresa/complementar`, data,
        {
            "headers": {
                "content-type": 'multipart/form-data'
            }
        }
        ).then((res) => { return res.data })
}

export const getMisSolicitudes = async () => {
    return axios.get(`${urlApi}/Solicitud/solicitudes`).then((res) => { return res.data })
}

export const updateEstadoSolicitud = async (data) => {
    return axios.post(`${urlApi}/Solicitud/update`, data).then((res) => { return res.data })
}

export const getDetalleSolicitud = async (id) => {
    return axios.get(`${urlApi}/Solicitud/Solicitud/${id}`).then((res) => { return res.data })
}

export const deleteUsuario = async (id) => {
    return axios.delete(`${urlApi}/AdminUsuario/eliminar/${id}`).then((res) => { return res.data })
}

export const updateRolUsuario = async (data) => {
    return axios.post(`${urlApi}/AdminUsuario/ActualizaRol`, data).then((res) => { return res.data })
}

export const getDetalleUsuario = async (id) => {
    return axios.get(`${urlApi}/empresa/usuario/${id}`,).then((res) => { return res.data })
}

export const updateUsuarioEmpresa = async (data) => {
    return axios.post(`${urlApi}/AdminUsuario/Actualizar`, data).then((res) => { return res.data })
}

export const saveUsuarioEmpresa = async (data) => {
    return axios.post(`${urlApi}/AdminUsuario/Crear`, data).then((res) => { return res.data })
}

export const getDatosFacturacion = async () => {
    return axios.get(`${urlApi}/empresa/DatosFacturacion`).then((res) => { return res.data })
}

export const getPerfilEmpresa = async () => {
    return axios.get(`${urlApi}/empresa/empresa`).then((res) => { return res.data })
}

export const updateInfoEmpresa = async (data) => {
    return axios.post(`${urlApi}/AdminUsuario/ActualizarInfoPrincipalEmpresa`, data).then((res) => { return res.data })
}

export const updateDatosFacturacionEmpresa = async (data) => {
    return axios.post(`${urlApi}/AdminUsuario/ActualizarDatosFacturacion`, data).then((res) => { return res.data })
}

export const eliminarRepresentanteLegal = async (id) => {
    return axios.delete(`${urlApi}/empresa/RepresentanteLegal/${id}`).then((res) => { return res.data })
}

export const crearRepresentantesLegales = async (data) => {
    return axios.post(`${urlApi}/empresa/CrearRepresentanteLegal`, data).then((res) => { return res.data })
}