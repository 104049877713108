import { Image } from 'antd'
// import { default as AmericanExpress } from '../../assets/images/cards/AmericanExpress.svg'
import AmericanExpress from '../../assets/images/cards/AmericanExpress.png'

const CardTypes = {
    AmericanExpress: "AmericanExpress"
}
/**
 * @component
 * Renderiza la imagen de una tarjeta en base a la tarjeta especificada
 * @param {{cardType:string}} props
 */
const CardImage = ({ cardType }) => {


    // const AmericanExpress = ""
    const hasImage = () => {
        switch (cardType) {
            case CardTypes.AmericanExpress:
                return true
            default:
                return false
        }
    }

    const cardHasImage = hasImage()

    const getImage = () => {
        switch (cardType) {
            case CardTypes.AmericanExpress:
                return AmericanExpress

            default:
                return null
        }
    }
    return (
        <>
            {cardHasImage ? (
                <Image
                    src={getImage()}
                />
            ) : (
                <div style={{ flex: 1, backgroundColor: '#ff4201', borderRadius: 6 }}></div>
            )}
        </>
    )
}
export default CardImage