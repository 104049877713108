import { CreditCardOutlined } from '@ant-design/icons'
import { Avatar, Button, Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ModalDeleteMedioPago = ({ eliminacionCorrecta, isModalOpen, handleOk, handleCancel }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={350}
        footer={[
          <Button type='primary' danger key="back" shape='round' onClick={handleCancel}>
            {eliminacionCorrecta ? 'Aceptar' : 'Cancelar'}
          </Button>,
          <Button key="submit" type="primary" shape='round' onClick={() => navigate('/productos')}>
            Ir a mis productos
          </Button>
        ]}
      >
        {!eliminacionCorrecta ?
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<CreditCardOutlined />} />
            <h2>No es posible eliminar este medio de pago</h2>
            <p>
              Esta tarjeta está vinculada a un producto actualmente, primero modifica el medio de pago asociado
              en la sección <strong>"Mis productos"</strong> y luego elimina la tarjeta
            </p>
          </div> :
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Avatar style={{ backgroundColor: '#FF4201' }} size={80} icon={<CreditCardOutlined />} />
            <h2>Tarjeta eliminada correctamente.</h2>
          </div>}
      </Modal>
    </div>
  )
}

export default ModalDeleteMedioPago