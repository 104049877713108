import { useEffect } from "react"
import { Row, Col, Card } from "antd"
import { useDispatch, useSelector } from "react-redux"
import Productos from "../personas/Productos"
import { getProductosUsuario } from "../../services/api"
import { setProductos, setProductosFiltrados } from "../../redux/reducers/ProductoReducer"
import { setIsLoading } from "../../redux/reducers/AppReducer"

const ProductosPersona = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(store => store.aplicacion.isLoading)

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setIsLoading(true))
        const data = await getProductosUsuario()
        dispatch(setProductos(data))
        dispatch(setProductosFiltrados(data))
      }
      catch (error) {
        console.log(error)
      }
      finally {
        dispatch(setIsLoading(false))
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {isLoading ?
        <Row>
          <Col className="gutter-row" xs={24} style={{ marginBottom: '25px' }}>
            <Card>
              <p style={{ color: '#838383', fontSize: 16 }}> cargando...</p>
            </Card>
          </Col>
        </Row> : <Productos></Productos>}
    </>
  )
}

export default ProductosPersona